import
  PropTypes
from 'prop-types';

import {
  styled,
} from '@mui/material';

import {
  VFillBoxVH
} from 'ui/common/components/boxes';

import {
  HFillContainerVH
} from 'ui/common/components/containers';

import {
  BodyMedium,
  BodySmall,
  HeadlineSmall,
} from 'ui/common/components/typography';

import {
  PrimaryButton,
} from './buttons';

const StyledBox = styled(VFillBoxVH)`
  padding: 1rem;
  max-width: 420px;
`;

const StyledImage = styled('img')`
  width: 250px;
  height: 250px;
  margin-bottom: 3rem;
`;

const StyledBody = styled(BodyMedium)`
  margin: 1rem 0;
`;

const StyledButton = styled(PrimaryButton)`
  margin-top: 3rem;
`;

const StyledContainer = styled(HFillContainerVH)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  left: 0,
  zIndex: 69,
  paddingBottom: '6rem',
  backgroundColor: theme.palette.standard.white,
  '@media screen and (min-height: 700px)': {
    height: 'calc(100% - 6rem)',
  },
}))

export const Feedback = props => {
  const {imageUrl, headerText, bodyText, infoText, buttonText, buttonAction, type } = props;

  return (
    <StyledContainer>
      <StyledBox>
        <StyledImage src={imageUrl}/>
        <HeadlineSmall color="secondary">{headerText}</HeadlineSmall>
        <StyledBody>{bodyText}</StyledBody>
        <BodySmall color={type === 'Success' && 'primary.p30' || 'error'}>{infoText}</BodySmall>
        <StyledButton text={buttonText} onClick={buttonAction}/>
      </StyledBox>
    </StyledContainer>
  );
};

Feedback.displayName = 'Feedback';

Feedback.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  headerText: PropTypes.string,
  bodyText: PropTypes.string,
  infoText: PropTypes.string,
  buttonText: PropTypes.string,
  type: PropTypes.string,
  buttonAction: PropTypes.func.isRequired,
};

Feedback.defaultProps = {
  headerText: 'Completed!',
  bodyText: 'You have finished.',
  infoText: 'You can now go back.',
  buttonText: 'Back',
  type: 'Success',
};
