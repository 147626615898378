import PropTypes from 'prop-types';

import {
  styled,
  TextField as TextFieldProto,
} from '@mui/material';

import {
  TextFieldVariant,
} from 'enums';

import React from 'react';

import { NumericFormat } from 'react-number-format';

import { InfoText } from '../InfoText';

const StyledTextField = styled(TextFieldProto)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p99,
  marginBottom: '1rem',
}));

const NumberFormatCustom = React.forwardRef((props, ref) => {

  const { inputRef, onChange, ...other } = props;
  
  return (
    <NumericFormat
      {...other}
      getInputRef={ref || inputRef}
      allowNegative={false}
      onValueChange={(values) => {
          onChange({
              target: {
                  name: props.name,
                  value: values.value,
              },
          });
      }}
    />
  );
})

NumberFormatCustom.displayName = 'NumberFormatCustom';



export const Number = props => {

  const {
    groupName,
    title,
    description,
    questionId,
    questionComments,
    upsertCommentLoading,
    handleComment,
    userId,
    ...other
  } = props;

  return (
    <React.Fragment>
      <InfoText
        title={title}
        description={description}
        groupName={groupName}
        questionId={questionId}
        questionComments={questionComments}
        upsertCommentLoading={upsertCommentLoading}
        handleComment={handleComment}
        userId={userId}
      />

      <StyledTextField
        {...other}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
      />

    </React.Fragment>
  )
};

Number.displayName = 'Number';

Number.propTypes = {
  variant: PropTypes.oneOf(Object.values(TextFieldVariant)),
  value: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  groupName: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  endAdornment: PropTypes.any,
  questionId: PropTypes.any,
  questionComments: PropTypes.any,
  upsertCommentLoading: PropTypes.bool,
  handleComment: PropTypes.any,
  userId: PropTypes.string,
};

Number.defaultProps = {
  variant: TextFieldVariant.Filled,
  value: '',
  title: '',
  groupName: '',
  description: '',
  placeholder: '',
  helperText: '',
  error: false,
  userId: '',
};
