import {
  gql,
} from '@apollo/client';

export const ScheduleGInsert = gql`
  mutation ScheduleGinsert($params: ScheduleGinsertParams) {
    scheduleGinsert(params: $params) {
      body {
        schedules {
          ...ScheduleFragment
        }
      }
    }
  }

  fragment ScheduleFragment on Schedule {
    active
    createdTimestamp
    modifiedTimestamp
    week
    year
    personId
    id
    person {
      ...PersonFragment
    }
    tasks {
      ...TaskFragment
    }
  }

  fragment PersonFragment on Person {
    active
    coachPersonId
    createdTimestamp
    email
    gender
    givenName
    id
    mentorPersonId
    modifiedTimestamp
    personTypeId
    surname
    telNo
    yearOfStudy
  }

  fragment TaskFragment on Task {
    id
    title
    session
    scheduledStartTimestamp
    scheduledEndTimestamp
    location
  }
`;

export const ScheduleGInsert2Weeks = gql`
  mutation ScheduleGInsert2Weeks($currentParams: ScheduleGinsertParams, $nextWeekParams: ScheduleGinsertParams) {
    currentInsert: scheduleGinsert(params: $currentParams) {
      body {
        schedules {
          ...ScheduleFragment
        }
      }
    }

    nextWeekInsert: scheduleGinsert(params: $nextWeekParams) {
      body {
        schedules {
          ...ScheduleFragment
        }
      }
    }
  }

  fragment ScheduleFragment on Schedule {
    active
    createdTimestamp
    modifiedTimestamp
    week
    year
    personId
    id
    weeklyPlanTask {
      ...TaskFragment
    }
    person {
      ...PersonFragment
    }
    tasks {
      ...TaskFragment
    }
  }

  fragment PersonFragment on Person {
    active
    coachPersonId
    createdTimestamp
    email
    gender
    givenName
    id
    mentorPersonId
    modifiedTimestamp
    personTypeId
    surname
    telNo
    yearOfStudy
  }

  fragment TaskFragment on Task {
    id
    title
    session
    scheduledStartTimestamp
    scheduledEndTimestamp
    location
    statusMnemonic
  }
`;