import PropTypes from 'prop-types';

import {
  styled,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import React from 'react';
import { InfoText } from '../InfoText';

const StyledFormControl = styled(FormControl)`
  padding: 0 0 16px 0;
  color: primary;
`;

export const SingleSelect = props =>

  <React.Fragment>
    <InfoText
      title={props.title}
      description={props.description}
      groupName={props.groupName}
      questionId={props.questionId}
      questionComments={props.questionComments}
      upsertCommentLoading={props.upsertCommentLoading}
      handleComment={props.handleComment}
      userId={props.userId}
    />
  
    <StyledFormControl>
      <RadioGroup
        value={props.value}
        onChange={props.onChange}
      >
        { props.options && props.options[0] instanceof Object &&
          props.options.map(({key, value}) =>
            <FormControlLabel key={key}  value={value} control={<Radio />} label={key} />
          )
        ||
          props.options.map((value) =>
            <FormControlLabel key={value} value={value} control={<Radio />} label={value} />
          )
        }
      </RadioGroup>
    </StyledFormControl>
  </React.Fragment>
;

SingleSelect.displayName = 'SingleSelect';

SingleSelect.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  groupName: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  questionId: PropTypes.any,
  questionComments: PropTypes.any,
  upsertCommentLoading: PropTypes.bool,
  handleComment: PropTypes.any,
  userId: PropTypes.string,
};

SingleSelect.defaultProps = {
  value: '',
  groupName: '',
  label: 'Label',
  onChange: () => console.log('value selected'),
  userId: '',
};
