import React from 'react';

import {
  usePrevious,
} from 'hooks/usePrevious';

export const FetchParseAs = {
  Json: 'json',
  Blob: 'blob',
};

/**
 * Helper function for the hook to fetch the specified URL.
 * @param {string | URL | Request} url The URL to fetch
 * @param {FetchParseAs} [parseAs] How the response body should be parsed. Default is JSON.
 * @returns {Promise<Blob | *>} The response data
 * @throws {Error} If the network request failed or if it returned a non-200 status code.
 */
const fetchData = async ({
  url,
  parseAs,
}) => {

  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  let data;

  switch (parseAs) {
    case FetchParseAs.Blob:
      data = await response.blob();
      break;

    case FetchParseAs.Json:
    default:
      data = await response.json();
      break;
  }

  return data;
};

/**
 * Hook to fetch arbitrary data
 * @param {string | URL | Request | undefined | null} url The URL to fetch. Nothing will happen if
 * it's falsy.
 * @param {FetchParseAs} [parseAs] How the response body should be parsed. Default is JSON.
 * @returns {{data: * | undefined, loading: boolean, error: Error | undefined, refetch: function():
 *   Promise<void>}}
 */
export const useFetch = ({
  url,
  parseAs = FetchParseAs.Json,
}) => {

  const previousUrl = usePrevious(url);
  const [fetching, setFetching] = React.useState(false);
  const [error, setError] = React.useState();
  const [data, setData] = React.useState();

  // As soon as the URL changes loading will be set to `true`. Then during the next render cycle,
  // the fetch will begin.
  const loading = Boolean((url && url !== previousUrl) || fetching);

  /**
   * Function to refetch the URL in the props.
   *
   * *Note:* This doesn’t accept a new URL, nor does it return the response data. The props and
   * return of the hook itself should be used for that.
   * @type {function(): Promise<void>}
   */
  const refetch = React.useCallback(async () => {

    if (!url) {
      setFetching(false);
      setData(undefined);
      return;
    }

    setFetching(true);
    setData(undefined);
    setError(undefined);

    try {
      const data = await fetchData({
        url,
        parseAs,
      });

      setFetching(false);
      setData(data);
    } catch (error) {
      setFetching(false);
      setError(error);
    }
  }, [
    parseAs,
    url,
  ]);

  // Effect to automatically fetch the URL, if truthy.
  React.useEffect(() => {

    if (!url) {
      setFetching(false);
      setData(undefined);
      return;
    }

    let mounted = true;

    setFetching(true);
    setData(undefined);
    setError(undefined);

    fetchData({
      url,
      parseAs,
    })
      .then(data => {
        if (!mounted) {
          return;
        }

        setFetching(false);
        setData(data);
      })
      .catch(error => {
        if (!mounted) {
          return;
        }

        setFetching(false);
        setData(undefined);
        setError(error);
      });

    return () => {
      mounted = false;
    };
  }, [
    parseAs,
    url,
  ]);

  return {
    loading,
    error,
    data,
    refetch,
  };
};
