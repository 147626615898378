import React from 'react';
import ReactDOM from 'react-dom/client';
import 'whatwg-fetch';

import {
  RecoilRoot,
} from 'recoil';

import {
  FormFactorAtom,
} from 'atoms';

import {
  getFormFactorInfo,
} from 'common';

import * as serviceWorkerRegistration from 'serviceWorkerRegistration';

import {
  App,
} from 'App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <React.StrictMode>
    <RecoilRoot
      initializeState={({set}) => {
        set(FormFactorAtom, getFormFactorInfo({
          windowDimensions: {
            width: window.innerWidth,
            height: window.innerHeight,
          },
        }));
      }}>

      <App/>

    </RecoilRoot>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
