import {
  styled,
} from '@mui/material';

import {
  HBox,
} from 'ui/common/components/boxes/HBox';

export const HFillBox = styled(HBox)`
  flex: 1 1 auto;
`;

HFillBox.displayName = 'HFillBox';
