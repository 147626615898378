import PropTypes from 'prop-types';

import {
  styled,
} from '@mui/material';

import React from 'react';

import { InfoText } from '../InfoText';
import { DatePicker as MuiDatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { enGB } from 'date-fns/locale';

const StyledDateTimePicker = styled(DateTimePicker)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p99,
  marginBottom: '16px',
}));

const StyledDatePicker = styled(MuiDatePicker)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p99,
  marginBottom: '16px',
}));

export const DatePicker = props =>

  <React.Fragment>

    <InfoText
      title={props.title}
      description={props.description}
      groupName={props.groupName}
      questionId={props.questionId}
      questionComments={props.questionComments}
      upsertCommentLoading={props.upsertCommentLoading}
      handleComment={props.handleComment}
      userId={props.userId}
    />

    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      { props.variant === 'DateTime' &&
        <StyledDateTimePicker
          {...props}
        />
      }
      { props.variant === 'Date' &&
        <StyledDatePicker
          {...props}
        />
      }
    </LocalizationProvider>

  </React.Fragment>
;

DatePicker.displayName = 'DatePicker';

DatePicker.propTypes = {
  variant: PropTypes.string,
  value: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  groupName: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  endAdornment: PropTypes.any,
  questionId: PropTypes.any,
  questionComments: PropTypes.any,
  upsertCommentLoading: PropTypes.bool,
  handleComment: PropTypes.any,
  userId: PropTypes.string,
};

DatePicker.defaultProps = {
  variant: 'DateTime',
  title: '',
  description: '',
  groupName: '',
  placeholder: '',
  helperText: '',
  error: false,
  userId: '',
};
