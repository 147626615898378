import {
  AppAuthError,
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  BasicQueryStringUtils,
  DefaultCrypto,
  FetchRequestor,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  LocalStorageBackend,
  RedirectRequestHandler,
  TokenRequest,
} from '@openid/appauth';

import {
  unixTime,
} from 'common';

import {
  AuthCodeTimeout,
  OidcRedirectTimeout,
} from 'enums';

/**
 * The requestor to use for all OIDC operations. This causes the library to use fetch to perform
 * HTTP requests.
 * @type {FetchRequestor}
 */
const requestor = new FetchRequestor();

/**
 * The handler to use for token-based requests.
 * @type {BaseTokenRequestHandler}
 */
const tokenHandler = new BaseTokenRequestHandler(requestor);

/**
 * Required class to make AppAuth look in the search for the query parameters, instead of the hash
 */
class SearchQueryStringUtils extends BasicQueryStringUtils {
  parse (input, useHash) {
    // Always use the search for query strings, and not the hash.
    return super.parse(input, false);
  }
}

const mapAndThrowAppAuthError = (error) => {

  // AppAuthError is not actually an instance of Error, and thus breaks Sentry error reporting.
  if (error instanceof AppAuthError) {

    let extras = undefined;

    try {
      extras = JSON.stringify(error.extras);
    } catch {}

    throw new Error(`Error during OIDC configuration: ${error.message} ${extras || ''}`);
  }

  throw error;
};

/**
 * Get the authorization service configuration from the specified issuer URL.
 * @param {string} openIdIssuerUrl
 * @returns {Promise<AuthorizationServiceConfiguration>}
 */
export const getAuthorizationServiceConfiguration = async (openIdIssuerUrl) => {

  try {
    return await AuthorizationServiceConfiguration.fetchFromIssuer(
      openIdIssuerUrl,
      requestor,
    );
  } catch (error) {
    mapAndThrowAppAuthError(error);
  }
};

/**
 * Initiates an OIDC authorization request. This function will redirect the user to the IDP
 * provider.
 * @param {Object} params
 * @param {AuthorizationServiceConfiguration} params.authorizationServiceConfiguration
 * @param {string} params.clientId
 * @param {string} params.redirectUri
 * @param {string} params.scope
 * @param {string} params.responseType
 * @param {Object.<string, string>} [params.extras]
 * @param {string} [params.state]
 * @param {number} [params.timeout]
 * @return {Promise<void>} A promise that will never resolve. It will only reject after the timeout
 * is reached. This is done because the user should be redirected once this function is called. The
 * rejection after a timeout is a safety mechanism to avoid waiting for an infinite amount of time.
 */
export const oidcLogin = async ({
  authorizationServiceConfiguration,
  clientId,
  redirectUri,
  scope,
  responseType,
  extras = {},
  state = undefined,
  timeout = OidcRedirectTimeout,
}) => {

  const authorizationHandler = new RedirectRequestHandler();

  // Make the authorization request
  authorizationHandler.performAuthorizationRequest(
    authorizationServiceConfiguration,
    new AuthorizationRequest({
      client_id: clientId,
      redirect_uri: redirectUri,
      scope,
      response_type: responseType,
      state,
      extras,
    }),
  );

  return new Promise(
    (resolve, reject) => setTimeout(
      () => reject(new Error('Auth request timed out')),
      timeout,
    ),
  );
};

/**
 * Retrieves the auth code, state, and code verifiers from the current location. This must be
 * called after the user is returned from the IDP.
 * @param [timeout] The timeout to wait for the authorization listener to trigger. This operation
 * should happen instantaneously, however this timeout exists as a safety mechanism in case the
 * listener never gets triggered.
 * @returns {Promise<{
 *  sessionId?: string,
 *  authCode: string,
 *  state: string,
 *  codeVerifier?: string
 * }>} The auth
 * and verifier codes can be uses to make an auth token request. The state can be used to restore
 * any local state that was lost due to the OIDC redirect.
 */
export const getAuthCodeFromLocation = async (timeout = AuthCodeTimeout) => {

  const notifier = new AuthorizationNotifier();

  const authorizationHandler = new RedirectRequestHandler(
    new LocalStorageBackend(),
    new SearchQueryStringUtils(),
    window.location,
    new DefaultCrypto(),
  );
  authorizationHandler.setAuthorizationNotifier(notifier);

  return new Promise((resolve, reject) => {
    notifier.setAuthorizationListener(async (request, response, error) => {

      const url = new URL(window.location.href);
      const sessionId = url.searchParams.get('sid') ?? undefined;

      if (error) {

        reject(new Error(`${error.error}: ${error.errorDescription}`));
        return;
      }

      if (!response?.code) {

        reject(new Error('Missing response code'));
        return;
      }

      clearAuthSearchParameters();

      resolve({
        sessionId,
        authCode: response.code,
        state: response.state,
        codeVerifier: request?.internal?.code_verifier,
      });
    });

    authorizationHandler
      .completeAuthorizationRequestIfPossible()
      .catch(reject);

    setTimeout(() => {
      reject(new Error('Timeout while retrieving auth code'));
    }, timeout);
  });
};

/**
 * Retrieves the access token and state from the current location. This must be called after the
 * user is returned from the IDP.
 * @param [timeout] The timeout to wait for the authorization listener to trigger. This operation
 * should happen instantaneously, however this timeout exists as a safety mechanism in case the
 * listener never gets triggered.
 * @returns {Promise<{state: string, tokens: Tokens}>} The user's auth tokens and state. The state
 * can be used to restore any local state that was lost due to the redirect.
 */
export const getTokensFromLocation = async (timeout = AuthCodeTimeout) => {

  const issuedAt = unixTime();
  const notifier = new AuthorizationNotifier();

  const authorizationHandler = new RedirectRequestHandler(
    new LocalStorageBackend(),
    new SearchQueryStringUtils(),
    window.location,
    new DefaultCrypto(),
  );
  authorizationHandler.setAuthorizationNotifier(notifier);

  return new Promise((resolve, reject) => {

    notifier.setAuthorizationListener(async (request, response, error) => {

      const url = new URL(window.location.href);
      const accessToken = url.searchParams.get('access_token') ?? undefined;
      const idToken = url.searchParams.get('id_token') ?? undefined;
      const expiresIn = Number(url.searchParams.get('expires_in'));

      clearAuthSearchParameters();

      if (error) {

        reject(new Error(`${error.error}: ${error.errorDescription}`));
        return;
      }

      if (!accessToken) {

        reject(new Error('Missing access token'));
        return;
      }

      resolve({
        state: response.state,
        tokens: {
          id: idToken,
          access: accessToken,
          expiresIn: isNaN(expiresIn) ? undefined : expiresIn,
          issuedAt,
        },
      });
    });

    authorizationHandler
      .completeAuthorizationRequestIfPossible()
      .catch(reject);

    setTimeout(() => {
      reject(new Error('Timeout while retrieving auth tokens'));
      clearAuthSearchParameters();
    }, timeout);
  });
};

/**
 * Requests auth tokens using the provided auth code.
 * @param {Object} params
 * @param {string} params.authCode
 * @param {string} params.clientId
 * @param {string} params.redirectUri
 * @param {AuthorizationServiceConfiguration} params.authorizationServiceConfiguration
 * @param {string} [params.codeVerifier]
 * @returns {Promise<TokenResponse>}
 */
export const getTokenWithAuthCode = async ({
  authCode,
  clientId,
  redirectUri,
  authorizationServiceConfiguration,
  codeVerifier,
}) => {
  try {
    return await tokenHandler.performTokenRequest(
      authorizationServiceConfiguration,
      new TokenRequest({
        client_id: clientId,
        redirect_uri: redirectUri,
        grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
        code: authCode,
        refresh_token: undefined,
        extras: (codeVerifier && { code_verifier: codeVerifier }) || {},
      }),
    );
  } catch (error) {
    mapAndThrowAppAuthError(error);
  }
};

/**
 * Gets a new set of auth tokens using the provided refresh token.
 * @param {Object} params
 * @param {string} params.clientId
 * @param {string} params.redirectUri
 * @param {string} params.refreshToken
 * @param {AuthorizationServiceConfiguration} params.authorizationServiceConfiguration
 * @param {Object.<string, string>} [params.extras]
 * @returns {Promise<TokenResponse>}
 */
export const refreshOidcTokens = async ({
  clientId,
  redirectUri,
  refreshToken,
  authorizationServiceConfiguration,
  extras = {},
}) => {

  try {
    // If the current access token is no longer considered valid then request a new one
    return await tokenHandler.performTokenRequest(
      authorizationServiceConfiguration,
      new TokenRequest({
        client_id: clientId,
        redirect_uri: redirectUri,
        grant_type: GRANT_TYPE_REFRESH_TOKEN,
        code: undefined,
        refresh_token: refreshToken,
        extras,
      }),
    );
  } catch (error) {
    mapAndThrowAppAuthError(error);
  }
};

/**
 * Ends the OIDC authorization session. This function will redirect the user to the OIDC provider
 * in order to clear the session cookies, and then the user will be redirected back to the logout
 * redirect URI.
 * @param {Object} params
 * @param {AuthorizationServiceConfiguration} params.authorizationServiceConfiguration
 * @param {string} params.logoutRedirectUri
 * @param {string} params.sessionId
 * @returns {Promise<void>}
 */
export const oidcLogout = async ({
  authorizationServiceConfiguration,
  logoutRedirectUri,
  sessionId,
}) => {

  // The logout endpoint will fail with an error when the sessionId is invalid.
  if (!sessionId) {
    return;
  }

  const url = new URL(authorizationServiceConfiguration.endSessionEndpoint);
  url.searchParams.set('post_logout_redirect_uri', logoutRedirectUri);
  url.searchParams.set('sid', sessionId);

  window.location.assign(url);
};

/**
 * Utility function that indicates if the current location has an auth code in its query parameters.
 * @return {boolean}
 */
export const hasAuthCodeInLocation = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('code') != null;
};

/**
 * Utility function that indicates if the current location has an access token in its query
 * parameters.
 * @return {boolean}
 */
export const hasTokenInLocation = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('access_token') != null;
};

/**
 * Extracts the error code from the current location, if applicable.
 * @return {string|null} The error code if present, `null` otherwise.
 */
export const getErrorCodeFromLocation = () => {
  const params = new URLSearchParams(window.location.search);

  if (params.get('response_type') !== 'error') {
    return null;
  }

  return params.get('response_code');
};

/**
 * Extracts the error object from the current location, if applicable.
 * @returns {Object} The error object if present, `null` otherwise.
 */
export const getErrorObjectFromLocation = () => {
  const params = new URLSearchParams(window.location.search);

  if (!params.get('error')) {
    return null;
  }

  return {
    error: params.get('error'),
    description: params.get('error_description'),
    hint: params.get('hint'),
  };
};

/**
 * Clears all OIDC-related search parameters from the current location and replaces its history
 * state.
 */
export const clearAuthSearchParameters = () => {
  const url = new URL(window.location.href);

  url.searchParams.delete('response_type');
  url.searchParams.delete('response_code');
  url.searchParams.delete('access_token');
  url.searchParams.delete('id_token');
  url.searchParams.delete('acr_values');
  url.searchParams.delete('code');
  url.searchParams.delete('expires_in');
  url.searchParams.delete('scope');
  url.searchParams.delete('session_state');
  url.searchParams.delete('sid');
  url.searchParams.delete('state');
  url.searchParams.delete('token_type');
  url.searchParams.delete('username');

  window.history.replaceState(null, null, url);
};
