import {
    styled,
  } from '@mui/material';
  
  import {
    TextAlign,
  } from 'enums';

import { VContainerVH } from './VContainerVH';
  
  export const VContainerTextCenterVH = styled(VContainerVH)`
    text-align: ${TextAlign.Center};
  `;
  
  VContainerTextCenterVH.displayName = 'VContainerTextCenterVH';