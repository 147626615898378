import React from 'react';
import PropTypes from 'prop-types';

import {
  SpinnerType,
  ModalType,
} from 'enums';

import {
  Modal,
  Header,
  Footer,
  Spinner,
  VFillBox,
  VFillContainer,
  VFillContainerVH,
} from 'ui/common/components';

import { 
  styled,
} from '@mui/material';

const StyledContainer = styled(VFillContainer)`
  padding-bottom: 90px;
`;

export const Scene = React.forwardRef((props, ref) => {

  return (

    <React.Fragment>

      <VFillBox>

        { props.loading && props.spinnerType === SpinnerType.FullScreen &&

          <VFillContainerVH>

            <Spinner
              text={props.spinnerText}/>

          </VFillContainerVH>
        }

        { (!props.loading || props.spinnerType === SpinnerType.Modal) &&

          <React.Fragment>

            { props.headerVisible &&

                <Header 
                  title={props.title}
                  handleBackButton={props.handleBackButton}
                />

            }

            <StyledContainer>
              {props.children}
            </StyledContainer>

          </React.Fragment>
        }

      </VFillBox>

      { props.loading && props.spinnerType === SpinnerType.Modal &&

        <Modal
          open={props.loading}>

          <VFillContainerVH>

            <Spinner
              text={props.spinnerText}/>

          </VFillContainerVH>
        </Modal>
      }

      { !props.loading && !!props.modalVisible &&

        <Modal
          open={props.modalVisible}
          type={props.modalType}
          title={props.modalTitle}
          message={props.modalMessage}
          onClose={props.onModalClose}/>
      }

    { props.footerVisible &&

      <Footer/>

    }

    </React.Fragment>
  );
});

Scene.displayName = 'Scene';

Scene.propTypes = {
  loading: PropTypes.bool,
  spinnerType: PropTypes.oneOf(Object.values(SpinnerType)),
  spinnerText: PropTypes.string,
  headerVisible: PropTypes.bool,
  footerVisible: PropTypes.bool,
  title: PropTypes.string,
  modalVisible: PropTypes.bool,
  modalType: PropTypes.oneOf([ModalType.Info, ModalType.Warning, ModalType.Error]),
  modalTitle: PropTypes.string,
  modalMessage: PropTypes.string,
  handleBackButton: PropTypes.func,
  onModalClose: PropTypes.func,
};

Scene.defaultProps = {
  loading: false,
  spinnerType: SpinnerType.Modal,
  spinnerText: 'Loading...',
  headerVisible: false,
  footerVisible: false,
  title: '',
  modalVisible: false,
  modalType: ModalType.Info,
  modalTitle: '',
  modalMessage: '',
  onModalClose: undefined,
};
