import React from 'react';

import {
  useQuery,
} from '@apollo/client';

import {
  IdentityGroupType,
} from 'enums';

import {
  PeoplePerPage,
} from 'config';

import {
  QueryPeople,
} from 'gql/person/queries';

/**
 * Hook to query a list of people
 * @param {string} userId The user ID of the current user
 * @param {IdentityGroupType} userType The user type of the current user
 * @param {number} [page=0] The page of results to query. If set to 0 or less, then all people will
 * be queried without pagination.
 * @returns {{loading: boolean, error: Error|null, peopleList: Object[]|null, pageInfo: PageInfo,refetch: function}}
 */
export const usePeopleList = ({
  userId,
  userType,
  page = 0,
}) => {

  const filterParams = userType === IdentityGroupType.Admin
    ? {}
    : {
      filterProperties: [
        {
          operator: 'IN',
          fieldNames: [
            userType === IdentityGroupType.Mentor
              ? 'mentorPersonId'
              : 'coachPersonId',
          ],
          value: [
            userId,
          ],
        },
      ],
      filterLogicGate: 'AND',
    };

  const paginationParams = page <= 0
    ? {}
    : {
      first: PeoplePerPage,
      offset: (page - 1) * PeoplePerPage,
    };

  const params = {
    ...filterParams,
    orderBy: [
      {
        fieldNames: [
          'personTypeMnemonic',
        ],
        direction: 'WEIGHTEDASC',
        values: [
          {
            value: 'ADMIN',
            weight: 1,
          },
          {
            value: 'MENTOR',
            weight: 2,
          },
          {
            value: 'COACH',
            weight: 3,
          },
          {
            value: 'INTERN',
            weight: 4,
          },
        ],
      },
      {
        fieldNames: [
          'givenName',
        ],
        direction: 'ASC',
      },
      {
        fieldNames: [
          'surname',
        ],
        direction: 'ASC',
      },
    ],
    ...paginationParams,
  };

  const {
    loading,
    error,
    data,
    refetch,
  } = useQuery(QueryPeople, {
    variables: {
      params: params,
    },
  });

  const {
    peopleList,
    pageInfo,
  } = React.useMemo(() => {

    if (loading || !data?.personQueryFilter?.body?.persons) {
      return [];
    }

    const people = data?.personQueryFilter?.body?.persons || [];

    const peopleList = people.map(i => ({
      id: i.id,
      firstName: i.givenName,
      lastName: i.surname,
      fullName: `${i.givenName} ${i.surname}`,
      school: i.schools && i.schools.length > 0 && i.schools.map((school) => school.title).join(', '),
      yearOfStudy: i.yearOfStudy,
      personType: i.personType.title,
    }));

    const pageInfo = data?.personQueryFilter?.body?.pageInfo || {
      totalResults: peopleList.length,
      indexPage: null,
      totalPages: null,
    };

    return {
      peopleList,
      pageInfo,
    };
  }, [
    data?.personQueryFilter?.body?.pageInfo,
    data?.personQueryFilter?.body?.persons,
    loading,
  ]);

  return {
    loading,
    error,
    peopleList,
    pageInfo,
    refetch,
  };
};
