export const personDetailsByIdQueryParams = (user) => {
  if(user){
    return {
      params: {
        filterProperties:[{
            operator: 'IN',
            fieldNames: ['personId'],
            value: [user],
        }],
        filterLogicGate: 'AND'
      }
    }
  }

  return null;
};

export const personDetailsByEmailQueryParams = (email) => {
  if(email){
    return {
      params: {
        filterProperties:[
          {
            operator: 'IN',
            fieldNames: ['email'],
            value: [email],
          },
          {
            operator: 'IN',
            fieldNames: ['personTypeMnemonic'],
            value: ['INTERN']
          }
        ],
        filterLogicGate: 'AND'
      }
    }
  }

  return null;
  
}