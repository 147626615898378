import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';

import {
  HashRouter,
} from 'react-router-dom';

import {
  Asset,
} from 'enums';

import {
  useEnvironment,
} from 'hooks';

import {
  ApolloRoot,
  InsightsContext,
  AssetPrefetch,
  FormFactorContext,
  DataLoader,
} from 'ui/app/components';

import {
  ThemeProvider,
  createTheme
} from '@mui/material';

import { ThutoTheme } from 'ui/app/themes';

export const AppRoot = props => {

  const theme = createTheme(ThutoTheme);  

  const {
    environment,
  } = useEnvironment();

  if (environment?.variables?.assetsBaseUrl) {
    Asset.baseUrl = environment.variables.assetsBaseUrl;
  }

  return (

    <ThemeProvider theme={theme}>
      <ApolloRoot>
        <HashRouter>

          <FormFactorContext/>
          <InsightsContext/>

          <DataLoader>

            <AssetPrefetch>
              {props.children}
            </AssetPrefetch>

          </DataLoader>

        </HashRouter>
      </ApolloRoot>
    </ThemeProvider>
  );
};

AppRoot.displayName = 'AppRoot';

AppRoot.context = {
  component: AppRoot.displayName,
};
