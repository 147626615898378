import {
  styled,
} from '@mui/material';

import {
  AlignItems,
} from 'enums';

import {
  VFillBox,
} from 'ui/common/components/boxes/VFillBox';

export const VFillBoxH = styled(VFillBox)`
  align-items: ${AlignItems.Center};
`;

VFillBoxH.displayName = 'VFillBoxH';
