import {
  Workbook,
} from 'exceljs';

import {
  extension,
} from 'mime-types';

import {
  isNonEmptyArray,
} from 'common/utility';

import {
  AllowedWorkbookImages,
  ReportConfig,
} from 'config';

export const buildGenerateReportVariables = (params) => {

  return {
    variables: {
      params,
    },
  };
};

/**
 * Extracts all the headings from the specified array of records.
 * @param {Object.<string, *>[]} data An array of records, where the key of each record is the
 * heading.
 * @returns {string[]}
 */
const getHeadings = (data) => {

  /** @type {Set<string>} */
  const columns = new Set();

  for (const entry of data) {
    for (const key of Object.keys(entry)) {
      columns.add(key);
    }
  }

  return Array.from(columns);
};

/**
 * Returns a 2D array of data where each column stars with a heading followed by the data.
 * @param {Object.<string, *>[]} data The array of objects to turn into a data table.
 * @returns {*[][]}
 */
export const getDataTable = (data) => {

  if (!isNonEmptyArray(data)) {
    return [];
  }

  const headings = getHeadings(data);
  const tableData = [
    headings,
  ];

  for (const entry of data) {

    const row = [];

    for (const heading of headings) {
      row.push(entry[heading] ?? '');
    }

    tableData.push(row);
  }

  return tableData;
};

/**
 * Returns a 2D array of data where each row stars with a heading followed by the data.
 * @param {Object.<string, *>[]} data The array of objects to turn into an inverse data table.
 * @returns {*[][]}
 */
export const getInverseDataTable = (data) => {

  if (!isNonEmptyArray(data)) {
    return [];
  }

  const tableData = getDataTable(data);

  const inverseTableData = [];

  for (let rowIndex = 0; rowIndex < tableData.length; rowIndex++) {

    const row = tableData[rowIndex];

    for (let colIndex = 0; colIndex < row.length; colIndex++) {

      if (!Array.isArray(inverseTableData[colIndex])) {
        inverseTableData[colIndex] = [];
      }

      inverseTableData[colIndex][rowIndex] = row[colIndex] ?? '';
    }
  }

  return inverseTableData;
};

/**
 * @typedef {Object} Report
 * @property {ReportDetails} details
 * @property {Object.<string, string|number>[]} data
 */

/**
 * @typedef {Object} ReportDetails
 * @property {string} [logoFileId]
 * @property {string} title
 * @property {ReportMetaData} [metaData]
 */

/**
 * @typedef {Object} ReportMetaData
 * @property {Object.<string, string|number>[]} [filters]
 * @property {Object.<string, string|number>[]} [summary]
 */

/**
 * Generates a report workbook from the report data.
 * @param {string} title The title that will be added to the top of the report.
 * @param {Report} report The report JSON data.
 * @param {Blob | undefined} [logo] The logo to use in the report. If absent or undefined, then no
 * logo will be added.
 * @returns {Promise<import('exceljs').Workbook>}
 * @throws {Error} If something goes wrong during Workbook generation.
 */
export const generateReportWorkBook = async ({
  title,
  report,
  logo,
}) => {

  const workBook = new Workbook();

  const workSheet = workBook.addWorksheet(ReportConfig.WorkSheetName);

  workSheet.columns = [...Array(ReportConfig.HeaderColumnSpan)]
    .map(() => ({ width: ReportConfig.HeaderColumnWidth }));

  const cursor = {
    col: 1,
    row: 1,
  };

  // Logo
  if (logo && AllowedWorkbookImages.includes(extension(logo.type))) {

    const logoId = workBook.addImage({
      buffer: await logo.arrayBuffer(),
      extension: extension(logo.type),
    });

    workSheet.getRow(cursor.row).height = ReportConfig.LogoRowHeight;

    // This is used to get the dimensions of the image. Seems to be the fastest way.
    const logoBitmap = await window.createImageBitmap(logo);

    workSheet.addImage(logoId, {
      tl: {
        col: cursor.col - 1,
        row: cursor.row - 1,
      },
      ext: {
        width: logoBitmap.width * (ReportConfig.LogoImageHeight / logoBitmap.height),
        height: ReportConfig.LogoImageHeight,
      },
    });

    cursor.row += 1;
  }

  // Title
  const titleRow = workSheet.insertRow(cursor.row, [title]);
  titleRow.font = {
    size: 14,
    bold: true,
  };
  titleRow.alignment = {
    vertical: 'middle',
    horizontal: 'center',
  };
  workSheet.mergeCells(
    cursor.row,
    cursor.col,
    cursor.row,
    cursor.col + Math.max(0, ReportConfig.HeaderColumnSpan - 1),
  );

  cursor.row += 2;

  // Metadata filters
  if (isNonEmptyArray(report?.details?.metaData?.filters)) {

    const metaDataTable = getInverseDataTable(report.details.metaData.filters);
    const metaDataRows = workSheet.insertRows(cursor.row, metaDataTable);
    metaDataRows.forEach((row) => {
      row.eachCell(
        { includeEmpty: true },
        (cell, colNumber) => {

          if (colNumber === 1) {
            cell.font = {
              bold: true,
            };
          }

          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        },
      );
    });

    cursor.row += metaDataTable.length + 1;
  }

  // Data
  const dataTable = getDataTable(report?.data);
  const [
    dataHeaderRow,
    ...dataTableRows
  ] = dataTable;

  workSheet.addTable({
    name: 'DataTable',
    ref: workSheet.getCell(cursor.row, cursor.col).fullAddress.address,
    headerRow: true,
    totalsRow: false,
    style: {
      theme: 'TableStyleLight15',
    },
    columns: dataHeaderRow.map(name => ({
      name,
      filterButton: true,
    })),
    rows: dataTableRows,
  });

  cursor.row += dataTable.length + 1;

  // Metadata summary
  if (isNonEmptyArray(report?.details?.metaData?.summary)) {

    const metaDataTable = getInverseDataTable(report?.details?.metaData?.summary);
    const metaDataRows = workSheet.insertRows(cursor.row, metaDataTable);
    metaDataRows.forEach((row) => {
      row.eachCell(
        { includeEmpty: true },
        (cell, colNumber) => {

          if (colNumber === 1) {
            cell.font = {
              bold: true,
            };
          }

          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        },
      );
    });

    cursor.row += metaDataTable.length + 1;
  }

  return workBook;
};
