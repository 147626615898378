import { useQuery } from '@apollo/client';
import { QuerySchools } from 'gql/school/queries';
import { useEffect, useState } from 'react';

export const useSchools = () => {

  const { loading, error, data, } = useQuery(QuerySchools, {
    variables: {
      params: {}
    },
  });

  const [schools, setSchools] = useState([]);

  useEffect(() => {
    if (!loading && data) {
      const details = data.schoolQueryFilter.body.schools?.map(t => ({
        id: t.id,
        title: t.title,
        telNo: t.telNo,
        location: t.location,
        description: t.description,
      })) || [];

      setSchools(details);
    }
  }, [loading, data]);
  
  return {
    loading,
    error,
    data,
    schools
  };
}
