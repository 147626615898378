import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled, Tab, Tabs } from '@mui/material';

const StyledTabs = styled(Tabs)(({ theme }) => `
  margin-bottom: 1rem;
  border-bottom: 1px solid ${theme.palette.neutral.n90};
`);

const StyledTab = styled(Tab)({
  "& .MuiTabs-indicator": {
    maxWidth: '3rem',
  },
});

export const TaskViewTabs = ({ tabs, defaultTab, renderTabContent, activeTab, onChange }) => {
  const [value, setValue] = useState(defaultTab || 0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue); 
  };

  return (
    <React.Fragment>
      <StyledTabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
        {tabs.map((tab, index) => (
          <StyledTab key={index} label={tab.label} value={index} />
        ))}
      </StyledTabs>

      <React.Fragment key={value}>
        {renderTabContent(activeTab)}
      </React.Fragment>
    </React.Fragment>
  );
};

TaskViewTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
  })).isRequired,
  defaultTab: PropTypes.number,
  activeTab: PropTypes.number,
  renderTabContent: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
