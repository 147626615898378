import {
  gql,
} from '@apollo/client';
  
export const QueryTaskTemplate = gql`
  query TaskTemplateQueryFilter($params: TaskTemplateQueryFilterParams) {
    taskTemplateQueryFilter(params: $params) {
      body {
        message
        ok
        taskTemplates {
          ...TaskTemplateFragment
        }
      }
    }
  }
  
  fragment TaskTemplateFragment on TaskTemplate {
    active
    createdTimestamp
    description
    shortDescription
    id
    mnemonic
    modifiedTimestamp
    personTypeId
    requiresReview
    title
    assessmentMnemonic
    yearOfStudy
    showOnCreateTaskList
    manualTaskReviewBypass
  }
  
`;