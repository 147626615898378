import {
  styled,
} from '@mui/material';

import {
  AlignItems,
} from 'enums';

import {
  HFillContainer,
} from 'ui/common/components/containers/HFillContainer';

export const HFillContainerV = styled(HFillContainer)`
  align-items: ${AlignItems.Center};
`;

HFillContainerV.displayName = 'HFillContainerV';
