import PropTypes from 'prop-types';

import React from 'react';

import { InfoText } from '../../InfoText';

import {
  format,
  getDay,
} from 'date-fns';

import { ScheduleCard } from './ScheduleCard';

import { TitleMedium } from 'ui/common/components/typography';

import { styled } from '@mui/material';

import { getDateRangeLabelFromWeek } from 'common';

import { useSchedule } from 'hooks';

import { VContainerVH } from 'ui/common/components/containers';

import { Spinner } from 'ui/common/components/Spinner';

const StyledDayTitle = styled(TitleMedium)`
  margin-top: 1rem;
`;

export const SchedulerViewer = props => {

  const { value } = props;

  const [groupedTasks, setGroupedTasks] = React.useState();
  const [weekDate, setWeekDate] = React.useState();

  const {schedule, loading, error} = useSchedule(value);

  React.useEffect(() => {
    if (!loading && schedule) {
      handleWeekSelect(schedule.tasks);
      setWeekDate(getDateRangeLabelFromWeek(schedule.week));
    }
  }, [schedule, loading]);

  const handleWeekSelect = (scheduledTasks) => {

    const groupMap = {};

    scheduledTasks.forEach(task => {
      const day = getDay(task.scheduledEndTimestamp); 
      if (groupMap[day]) {
        groupMap[day].tasks.push(task);
      } else {
        groupMap[day] = {
          day: day,
          groupName: format(task.scheduledEndTimestamp, 'EEEE'),
          tasks: [task],
        }
      }
    });

    const groupedArray = Object.values(groupMap);

    groupedArray.sort((a, b) => a.day - b.day);

    setGroupedTasks(groupedArray);
  }

  if (loading) {
    return (
    <VContainerVH>
      <Spinner text='Loading...'/>
    </VContainerVH>
    );
  }

  if (!loading && !error && !schedule) {
    return (
      <VContainerVH>
        <TitleMedium>No scheduled tasks found.</TitleMedium>
      </VContainerVH>
    );
  }

  if (!loading && error) {
    return (
      <VContainerVH>
        <TitleMedium>Something went wrong when retrieving the responses.</TitleMedium>
      </VContainerVH>
    );
  }

  return (
    !loading && schedule && <React.Fragment>
      <InfoText title={weekDate} groupName={'Scheduled Tasks'}/>

      { groupedTasks &&
        groupedTasks.map((group) => (

          <React.Fragment key={group.groupName}>

          <StyledDayTitle>{group.groupName}</StyledDayTitle>

            {group.tasks.map((task) => (
              <ScheduleCard
                key={task.id}
                id={task.id}
                name={task.title}
                scheduledStartTimestamp={task.scheduledStartTimestamp}
                scheduledEndTimestamp={task.scheduledEndTimestamp}
                location={task.location}
                note={task.note}
                showButton={false}>
              </ScheduleCard>
            ))}
          </React.Fragment>
        ))
      }

    </React.Fragment>
  );
};

SchedulerViewer.displayName = 'SchedulerViewer';

SchedulerViewer.propTypes = {
  value: PropTypes.string,
};

SchedulerViewer.defaultProps = {
  value: '',
}
