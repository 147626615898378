import {
  styled,
} from '@mui/material';

import {
  JustifyContent,
} from 'enums';

import {
  VBox,
} from 'ui/common/components/boxes/VBox';

export const VBoxV = styled(VBox)`
  justify-content: ${JustifyContent.Center};
`;

VBoxV.displayName = 'VBoxV';
