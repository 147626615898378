import {
  styled,
} from '@mui/material';

import {
  JustifyContent,
} from 'enums';

import {
  VContainer,
} from 'ui/common/components/containers/VContainer';

export const VContainerV = styled(VContainer)`
  justify-content: ${JustifyContent.Center};
`;

VContainerV.displayName = 'VContainerV';
