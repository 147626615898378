import {
  styled,
} from '@mui/material';

import {
  VContainer,
} from 'ui/common/components/containers/VContainer';

export const VFillContainer = styled(VContainer)`
  flex: 1 1 auto;
`;

VFillContainer.displayName = 'VFillContainer';
