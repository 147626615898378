import {
  Typography,
} from '@mui/material';

import {
  TypographyVariant,
} from 'enums';

export const BodyLarge = props =>

  <Typography
    color='secondary'
    {...props}
    fontWeight={300}
    fontSize='1.188rem'
    variant={TypographyVariant.Body1}>

    {props.children}
  </Typography>
;

BodyLarge.displayName = 'BodyLarge';
