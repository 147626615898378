import React from 'react';
import PropTypes from 'prop-types';

import {
  Badge,
  IconButton,
  styled,
} from '@mui/material';

import { ChatBubbleOutline } from '@mui/icons-material';

import {
  VContainer,
  VFillContainerVH,
} from './containers';

import { Modal } from './Modal';
import { Comments } from './Comments';

const StyledIconButton = styled(IconButton)`
  width: auto;
  position: absolute;
  right: 0;
  bottom: 0.5rem;
`;

const StyledAnchorContainer = styled(VContainer)`
  position: relative;
`;

const BadgeStyled = styled(Badge)`
  .MuiBadge-badge {
    font-size: 0.5rem;
    height: 10px;
    width: 10px;
    min-width: 10px;
  }
`;

const ModalContentContainer = styled(VFillContainerVH)`
  align-items: flex-start;
`;

export const InlineComment = ({
  questionId,
  comments,
  upsertCommentLoading,
  handleComment,
  userId,
}) => {

  const [ showCommentModal, setShowCommentModal ] = React.useState(false);

  const closeModal = () => {
    setShowCommentModal(false);
  };

  const handleClick = () => {
    setShowCommentModal(true);
  };

  return (

    <React.Fragment>

      <Modal
        open={showCommentModal}>

        <ModalContentContainer>

          <Comments
            userId={userId}
            onClick={(value) => handleComment(value, questionId)}
            comments={comments}
            upsertCommentLoading={upsertCommentLoading}
            showCancelButton={true}
            onCancelClick={closeModal}
          />

        </ModalContentContainer>
      </Modal>

      <StyledAnchorContainer>
        <StyledIconButton onClick={handleClick}>
          <BadgeStyled badgeContent={comments && comments.length > 0 && comments.length || 0} color='error'/>
          <ChatBubbleOutline />
        </StyledIconButton>
      </StyledAnchorContainer>

    </React.Fragment>
  );
};


InlineComment.displayName = 'InlineComment';

InlineComment.propTypes = {
  questionId: PropTypes.string,
  comments: PropTypes.any,
  upsertCommentLoading: PropTypes.bool,
  handleComment: PropTypes.func,
  userId: PropTypes.string,
};

InlineComment.defaultProps = {
  questionId: '',
  userId: '',
  upsertCommentLoading: false,
  handleComment: () => {},
};
