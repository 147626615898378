import React from 'react';
import PropTypes from 'prop-types';

import {
  styled,
} from '@mui/material';

import {
  VFillContainerV,
} from 'ui/common/components/containers';

import {
  HBoxV,
} from 'ui/common/components/boxes';

import {
  BodySmall,
  TitleSmall,
} from 'ui/common/components/typography';

import {
  PrimaryButton,
} from 'ui/common/components/buttons';

import { format } from 'date-fns';

const TaskBox = styled(HBoxV)(
  ({ theme }) => `
  border-radius: 12px;
  padding: 12px;
  margin-top: 12px;
  `,
);

const TaskButton = styled(PrimaryButton)(
  ({ theme }) => `
  width: unset;
  background-color: ${theme.palette.primary.p40};
  `,
);

const TitleText = styled(TitleSmall)`
  margin: 0.25rem 0;
`;

const BodyText = styled(BodySmall)`
  margin: 0.25rem 0;
`;

export const ScheduleCard = ({
  name,
  scheduledStartTimestamp,
  scheduledEndTimestamp,
  location,
  note,
  showButton,
  onClick,
}) => {

  return (

    <React.Fragment>

      <TaskBox backgroundColor="primary.p100">

        <VFillContainerV>
          <TitleText color="primary.p10">{name}</TitleText>
          <BodyText>{`Start | ${format(scheduledStartTimestamp, 'EEEE, do MMMM h:mm aa')}`}</BodyText>
          <BodyText>{`Due | ${format(scheduledEndTimestamp, 'EEEE, do MMMM h:mm aa')}`}</BodyText>
          {location !== '' && <BodyText>{location}</BodyText>}
          {note !== '' && <BodyText>{note}</BodyText>}
        </VFillContainerV>
        
        {showButton && <TaskButton text={'Edit'} onClick={onClick} />}
        
      </TaskBox>

    </React.Fragment>
  );
};

ScheduleCard.displayName = 'ScheduleCard';

ScheduleCard.propTypes = {
  name: PropTypes.string.isRequired,
  scheduledStartTimestamp: PropTypes.string,
  scheduledEndTimestamp: PropTypes.string,
  location: PropTypes.string,
  note: PropTypes.string,
  showButton: PropTypes.bool,
  onClick: PropTypes.func,
};

ScheduleCard.defaultProps = {
  scheduledStartTimestamp: '',
  scheduledEndTimestamp: '',
  location: '',
  note: '',
  showButton: true,
  onClick: () => {},
};
