import
  PropTypes
from 'prop-types';

import {
  styled,
} from '@mui/material';

import {
  FileUploadOutlined,
  DeleteOutlined,
} from '@mui/icons-material';

import {
  Spacing,
} from 'enums';


import {
  PrimaryButton,
} from 'ui/common/components/buttons';

import {
  VContainerVH,
} from 'ui/common/components/containers';

import {
  BodyMedium,
  LabelSmall,
} from 'ui/common/components/typography';
import React from 'react';
import { InfoText } from '../InfoText';
import { DocumentName } from '../DocumentName';

const UploadContainer = styled(PrimaryButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p100,
  borderRadius: '4px 4px 0px 0px',
  padding: '17px 8px',
  whiteSpace: 'wrap',
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.neutral.n80}`,
  "&:hover": {
    backgroundColor: theme.palette.primary.p99,
    boxShadow: 'none',
  }
}));

const Text = styled(BodyMedium)`
  margin-top: ${Spacing.Small2};
  margin-bottom: ${Spacing.Small2};
`;

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const FileUpload = (props)  => {

  const removeFile = (event) => {
    if (props.value) {
      event.preventDefault();
      event.target.value = null;

      props.onChange && props.onChange({
        target: {
          value: undefined
        }
      });
    }
  }

  const handleFileUpload = (event) => {

    let file;

    if (event != null && event.target != null && event.target.files != null && event.target.files.length > 0) {
      file = event.target.files[0];
    }

    props.onChange && props.onChange({
      target: {
        value: file
      }
    });
  }

  const dragOverHandler = (event) => {
    event.preventDefault();
  }

  const dropHandler = (event) => {

    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      const target = event.dataTransfer.items[0];

      if (target.kind === "file") {
        const file = target.getAsFile();

        if (file != null &&
          (file.name?.includes('.pdf') ||
          file.name?.includes('.gif') || 
          file.name?.includes('.jpg') || 
          file.name?.includes('.jpeg') || 
          file.name?.includes('.png'))) {
            handleFileUpload({target: {files: [file]}});
        }
      }
    }
  }

  return (

    <React.Fragment>
      <InfoText title={props.title} description={props.description} groupName={props.groupName}/>

      <UploadContainer 
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        onDragOver={(e) => dragOverHandler(e)}
        onDrop={(e) => dropHandler(e)}>
          <VContainerVH>
            { props.value &&
              <DeleteOutlined color='secondary'/>
              ||
              <FileUploadOutlined color='secondary'/>
            }
            { props.value && (typeof props.value === 'string' || props.value instanceof String) &&
              <DocumentName fileId={props.value}/>
              ||
              <Text>
                {props.value && props.value.name || 'Drag & drop files or browse'}
              </Text>
            }
            <LabelSmall color='secondary'>
              Supported formats: JPEG, PNG, GIF, PDF 
            </LabelSmall>
            <VisuallyHiddenInput
              type="file"
              accept=".gif,.jpg,.jpeg,.png,.pdf"
              onDrop={dropHandler} 
              onClick={removeFile}
              onChange={handleFileUpload}
            />
          </VContainerVH>
      </UploadContainer>
    </React.Fragment>
  )
};

FileUpload.displayName = 'FileUpload';

FileUpload.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  groupName: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

FileUpload.defaultProps = {
  groupName: '',
  onChange: (event) => console.log(event.target.value),
};