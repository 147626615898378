import {
  Typography,
} from '@mui/material';

import {
  TypographyVariant,
} from 'enums';

export const TitleSmall = props =>

  <Typography
    color="secondary"
    {...props}
    fontSize="0.812rem"
    variant={TypographyVariant.Subtitle2}>

    {props.children}
  </Typography>
;

TitleSmall.displayName = 'TitleSmall';
