import {
  gql,
} from '@apollo/client';

export const PersonUpsertWithCognito = gql`
  mutation PersonUpsertWithCognito($params: PersonUpsertParams) {
    personUpsertWithCognito(params: $params) {
      body {
        upsertResult {
          command
          ok
          row
        }
        ok
        message
      }
      statusCode
    }
  }
`;

export const PersonUpsertById = gql`
  mutation PersonUpsert($params: PersonUpsertParams) {
    personUpsert(params: $params) {
      body {
        upsertResult {
          command
          ok
          row
        }
        ok
        message
      }
      statusCode
    }
  }
`;


export const PersonSchoolUpsert = gql`
  mutation PersonSchoolUpsert($params: PersonSchoolUpsertParams) {
    personSchoolUpsert(params: $params) {
      body {
        upsertResult {
          command
          ok
        }
        ok
        message
      }
      statusCode
    }
  }
`;