import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, IconButton, Toolbar, styled, Container } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { HFillBox } from 'ui/common/components/boxes';
import { BodyLarge } from 'ui/common/components/typography';
import {
  useIdentity,
} from 'hooks';

import { useNavigate, useLocation } from 'react-router-dom';

import { getBackNavigationPath } from '../../../common/utility.js'

const NavTextContainer = styled(Container)`
  margin-top: 6px;

  @media (min-width: 1200px) {
    max-width: 100%;
  }
`;

const ToolbarContainer = styled(Toolbar)`
  padding-left: 0 !important;
`;

export const Header = (props) => {  
  const {
    user,
  } = useIdentity({
    context: Header.context,
  });

  const userName = !user?.name && 'Unknown' || `${user?.name || ''}`;

  const navigate = useNavigate();
  const location = useLocation();

  const handleBackButtonClick = () => {
    const navigateToPath = getBackNavigationPath(); 
    navigate(navigateToPath);
  };

  return (
    <React.Fragment>
      <AppBar 
      position='sticky'
      color='standard'
      elevation={0}
      >
        <ToolbarContainer>
        <HFillBox>

          { location.pathname !== '/home' && (
            <IconButton color='secondary' onClick={handleBackButtonClick}>
              <ArrowBack />
            </IconButton>
          )}
          <NavTextContainer>
            {location.pathname =='/home' && (
              <BodyLarge color>{`Welcome, ${userName}`}</BodyLarge>
            )}

            {location.pathname !=='/home' && props.title && (
              <BodyLarge>{props.title}</BodyLarge>
            )}
          </NavTextContainer>

          </HFillBox>

        </ToolbarContainer>
      </AppBar>

    </React.Fragment>
  );
};

Header.displayName = 'Header';

Header.propTypes = {
  title: PropTypes.string,
  showBackButton: PropTypes.bool,
  handleBackButton: PropTypes.func,
};

Header.defaultProps = {
  title: '',
  showBackButton: false,
};
