import {
  styled,
} from '@mui/material';

import {
  JustifyContent,
} from 'enums';

import {
  HBox,
} from 'ui/common/components/boxes/HBox';

export const HBoxH = styled(HBox)`
  justify-content: ${JustifyContent.Center};
`;

HBoxH.displayName = 'HBoxH';
