import {
  styled,
} from '@mui/material';

import {
  AlignItems,
  JustifyContent,
} from 'enums';

import {
  HContainer,
} from 'ui/common/components/containers/HContainer';

export const HContainerVH = styled(HContainer)`
  align-items: ${AlignItems.Center};
  justify-content: ${JustifyContent.Center};
`;

HContainerVH.displayName = 'HContainerVH';
