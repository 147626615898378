import md5 from 'md5';

import {
  createLocalInsights,
} from 'insights';

const DefaultContext = {};
const DefaultProviders = {};

const insights = {};

export const useInsights = ({
  context = DefaultContext,
  providers = DefaultProviders,
} = {}) => {

  const key = md5(JSON.stringify(context));

  if (!insights[key]) {

    insights[key] = createLocalInsights({
      context,
      providers,
    });
  }

  return insights[key];
}
