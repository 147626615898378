import {
  atom,
} from 'recoil';

import {
  DefaultIdentity,
  DefaultPersonDetails,
} from 'enums';

export const IdentityAtom = atom({
  key: 'identity-identity',
  default: DefaultIdentity,
});

export const UserPersonDetailsAtom = atom({
  key: 'identity-user-details',
  default: DefaultPersonDetails,
});
