import {
  RoutePath,
} from 'enums';

import {
  Profile,
  EditProfile
} from 'ui/profile';

/**
 * @type {RouteConfig[]}
 */
export const ProfileRoutes = [
  {
    element: <Profile />,
    path: RoutePath.Profile,
    children: [],
  },
  {
    element: <EditProfile />,
    path: RoutePath.EditProfile,
    children: [],
  }
];