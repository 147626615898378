import React from 'react';

import { useIdentity, usePersonDetails } from 'hooks';
import { Spinner, VFillContainerVH } from 'ui/common';
import { getWeekRange } from 'common';
import { useWeeklyPlans } from 'hooks/useWeeklyPlans';
import { taskListWeeklyPlansQueryParams } from 'gql/task/queryParams';
import { sub } from 'date-fns';
import { personDetailsByIdQueryParams } from 'gql/person/queryParams';

export const DataLoader = props => {

  const { user, loggedIn } = useIdentity({
    screen: 'DataLoader',
    journey: 'DataLoader',
    component: 'DataLoader',
  });

  const weeklyRange = getWeekRange();

  const { data, loading: weeklyPlanLoading, error, refetch } = useWeeklyPlans(
    taskListWeeklyPlansQueryParams(user?.id, [sub(weeklyRange.lastWeek[0], {weeks: 1}), weeklyRange.nextWeek[1]]),
    !loggedIn
  );

  const { loading: personLoading, data: personDetailsData, error: personDetailError, refetch: personRefetch } = usePersonDetails(personDetailsByIdQueryParams(user?.id), true);

  const reload = React.useCallback(() => refetch(), [refetch]);
  const fetchPersonDetails = React.useCallback(() => personRefetch(), [personRefetch]);

  React.useEffect(() => {
    if (loggedIn) {
      
      if (!weeklyPlanLoading && !data && !error) {
        reload();
      }

      if (!personLoading && !personDetailsData && !personDetailError) {
        fetchPersonDetails();
      }
    }
  }, [
    loggedIn,
    weeklyPlanLoading,
    data,
    error,
    personLoading,
    personDetailsData,
    personDetailError,
    reload,
    fetchPersonDetails,
  ]);

  return (

    <React.Fragment>

      { (!!weeklyPlanLoading) &&

        <VFillContainerVH>
          <Spinner/>
        </VFillContainerVH>
      }

      {!weeklyPlanLoading && props.children}
    </React.Fragment>
  );
};

DataLoader.defaultProps = {
};
