export const MinPasswordLength = 8;

export const IdentityStatus = {
  LoggedIn: 'LoggedIn',
  LoggedOut: 'LoggedOut',
};

export const LogoutReason = {
  UserInitiated: 'UserInitiated',
  SessionExpired: 'SessionExpired',
};

export const IdentityGroupType = {
  Admin: 'Admin',
  Mentor: 'Mentor',
  Intern: 'Intern',
  Coach: 'Coach',
};

/**
 * @typedef {Object} Tokens
 * @property {string} [id] The OIDC ID token. This is contains declarations about the user after he
 * was authenticated.
 * @property {string|undefined} access The access token. Used to access remote resources.
 * @property {string} [refresh] The refresh token that can be used to obtain a new set of tokens.
 * @property {number} [expiresIn] The number of seconds for how long the access tokens are valid
 * for.
 * @property {number|undefined} issuedAt The unix epoch (in seconds) for when the tokens were
 * issued.
 * authentication flow.
 */

/**
 * @typedef {Object} UserIdentity
 * @property {Tokens} tokens The set of tokens associated with the current user.
 * @property {User} user The readable user details
 * @property {string} identityStatus The current identity status. This indicates if the user is
 * @property {string} logoutReason The reason the user was logged out, if applicable.
 * logged in, out, etc.
 */

/**
 * @type {UserIdentity}
 */
export const DefaultIdentity = {
  tokens: {
    id: undefined,
    access: undefined,
    refresh: undefined,
    expiresIn: undefined,
    issuedAt: undefined,
    sessionId: undefined,
  },
  user: {
    id: undefined,
    email: undefined,
    name: 'Unknown',
    surname: 'Unknown',
    group: 'Unknown',
  },
  identityStatus: IdentityStatus.LoggedOut,
  logoutReason: undefined,
  initialized: false,
};

export const DefaultPersonDetails = {
  id: undefined,
  email: undefined,
  givenName: undefined,
  surname: undefined,
  yearOfStudy: undefined,
  mentorid: undefined,
  mentorGivenName: undefined,
  mentorSurname: undefined,
  teachingPhaseMnemonic: undefined,
  schools: [],
};

/**
 * The buffer period (in seconds) to be used when determining if an access token is still valid.
 * An access token is considered invalid if it is already expired or if it will expire within the
 * buffer period.
 * @type {number}
 */
export const AccessTokenExpiryBuffer = 20;

/**
 * Used by the OIDC flow. The number of milliseconds to wait for the user to be redirected to the
 * OIDC login page.
 * @type {number}
 */
export const OidcRedirectTimeout = 20_000;

/**
 * Used by the OIDC flow. The number of milliseconds to wait for the auth code to be detected on
 * page load. This should practically be instantaneous, but this timeout is used as an escape hatch
 * from infinite loading.
 * @type {number}
 */
export const AuthCodeTimeout = 1_000;
