import React from 'react';

import {
  useQuery,
} from '@apollo/client';

import {
  QueryTaskList,
} from 'gql/task/queries';

import {
  mapTaskFromQuery,
  markInactiveChildTasks,
} from 'common';

/**
 * @typedef {Object} PageInfo
 * @property {number} totalResults The total number of results, independent of pagination.
 * @property {null|number} indexPage The current page that is returned. It will be `null` if
 * pagination is not used.
 * @property {null|number} totalPages The total number of pages in the results. It will be `null`
 * if pagination is not used.
 */

/**
 * Convenience function to get the tasks and page info from the given GQL result data. This maps
 * the task data into a more convenient shape and removes inactive child tasks.
 * @param {Object} data The raw data returned by the query.
 * @param {IdentityGroupType} userType The current user's type.
 * @returns {{tasks: Object[], pageInfo: PageInfo}}
 */
const getTasksAndPageInfo = ({
  data,
  userType,
}) => {

  const tasks = data?.taskQueryFilter?.body?.tasks || [];

  const taskList = markInactiveChildTasks(tasks.map(task => mapTaskFromQuery(task, userType)))
    .filter(task => !task.calculatedFields.inactive);

  const pageInfo = data?.taskQueryFilter?.body?.pageInfo || {
    totalResults: taskList.length,
    indexPage: null,
    totalPages: null,
  };

  return {
    taskList,
    pageInfo,
  };
};

export const useTaskList = ({
  queryParams,
  userType,
}) => {

  const {
    loading,
    error,
    data,
    refetch,
  } = useQuery(
    QueryTaskList,
    {
      variables: queryParams,
      skip: queryParams == null,
      fetchPolicy: 'no-cache',
    },
  );

  const {
    taskList,
    pageInfo,
  } = React.useMemo(() => getTasksAndPageInfo({
    data,
    userType,
  }), [
    userType,
    data,
  ]);

  return {
    loading,
    error,
    data,
    taskList,
    pageInfo,
    refetch,
  };
};
