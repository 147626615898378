export const QueryFetchPolicies = {
  CacheAndNetwork: 'cache-and-network',
  CacheFirst: 'cache-first',
  CacheOnly: 'cache-only',
  NetworkOnly: 'network-only',
  NoCache: 'no-cache',
};

export const MutationFetchPolicies = {
  NetworkOnly: 'network-only',
  NoCache: 'no-cache',
};
