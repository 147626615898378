import React from 'react';

import {
  useRecoilState,
} from 'recoil';

import {
  Environment,
} from 'enums';

import {
  EnvironmentAtom,
} from 'atoms';

import {
  getFullEnvironment,
} from 'common';

import {
  CommonEnvironmentConfig,
  EnvironmentConfig,
} from 'config';

const detectEnvironment = () => {

  for (const env of Object.values(EnvironmentConfig)) {

    if (!env || !Array.isArray(env.hostNames)) {
      continue;
    }

    for (const hostName of env.hostNames) {

      if (hostName === window.location.hostname) {

        return env;
      }
    }
  }

  return null;
};

const DefaultDetectedEnvironment = getFullEnvironment({
  environmentConfig: detectEnvironment(),
  commonEnvironmentConfig: CommonEnvironmentConfig,
});

const DefaultProductionEnvironment = getFullEnvironment({
  environmentConfig: EnvironmentConfig[Environment.Production],
  commonEnvironmentConfig: CommonEnvironmentConfig,
});

/**
 * @param [params]
 * @param [params.environmentName]
 * @param [params.autoDetect]
 * @returns {{environment: EnvironmentConfig, platform: string}}
 */
export const useEnvironment = ({
  environmentName,
  autoDetect = true,
} = {}) => {

  const [
    environment,
    setEnvironment,
  ] = useRecoilState(EnvironmentAtom);

  React.useEffect(() => {

    if (environment) {
      return;
    }

    const selectedEnvironment = (!!autoDetect && detectEnvironment()) || EnvironmentConfig[environmentName] || EnvironmentConfig[Environment.Production];
    const newEnvironment = getFullEnvironment({
      environmentConfig: selectedEnvironment,
      commonEnvironmentConfig: CommonEnvironmentConfig,
    });

    setEnvironment(newEnvironment);
  }, [
    environmentName,
    autoDetect,
    environment,
    setEnvironment,
  ]);

  return {
    environment: environment || DefaultDetectedEnvironment || DefaultProductionEnvironment,
  };
}
