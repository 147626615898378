import {
  Environment,
} from 'enums';

const shouldTrace = context => context && context.environment !== Environment.Production;

export const traceInformation = ({
  context,
  args,
}) => shouldTrace(context) && console.info(
  ...args, {
    context,
  }
);

export const traceWarning = ({
  context,
  args,
}) => shouldTrace(context) && console.warn(
  ...args, {
    context,
  }
);

export const traceError = ({
  context,
  args,
}) => shouldTrace(context) && console.error(
  ...args, {
    context,
  }
);

export const tag = ({
  context,
  args,
}) => shouldTrace(context) && console.log(
  ...args, {
    context,
  }
);

export const useInsightsConsole = () => ({
  traceInformation,
  traceWarning,
  traceError,
  tag,
});
