import { useQuery } from '@apollo/client';
import { ScheduleIdAtom } from 'atoms';
import { mapTaskFromQuery } from 'common';
import { InputTypeMnemonic, StatusMnemonic } from 'enums';
import { QueryTaskDetails } from 'gql/task/queries';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';

export const useWeeklyPlans = (params, skip = false) => {

  const { loading, error, data, refetch } = useQuery(QueryTaskDetails, {
    variables: params,
    fetchPolicy: 'no-cache',
    skip: skip,
  });

  const [scheduleIdMap, setScheduleIdMap] = useRecoilState(ScheduleIdAtom);

  const [weeklyPlanList, setWeeklyPlanList] = React.useState();

  const setScheduleIdMapCallback = React.useCallback((approvedSchedules) => {
    setScheduleIdMap(approvedSchedules);
  }, [setScheduleIdMap]);

  const setWeeklyPlanListCallback = React.useCallback((weeklyPlans) => {
    setWeeklyPlanList(weeklyPlans);
  }, [setWeeklyPlanList]);

  useEffect(() => {
    if (!loading && data) {

      if (data.taskQueryFilterDetails?.body?.tasks) {
        const mappedPlanTasks = data.taskQueryFilterDetails?.body?.tasks.map(mapTaskFromQuery);

        const approvedSchedules = {};

        mappedPlanTasks.forEach(plan => {
          if (plan.calculatedFields.effectiveStatus === StatusMnemonic.Completed) {
            const targetQuestion = plan.questions.find(q => q.inputType === InputTypeMnemonic.Schedular);

            approvedSchedules[targetQuestion.answer] = true;
          }
        });

        setWeeklyPlanListCallback(mappedPlanTasks);
        setScheduleIdMapCallback(approvedSchedules);
      }
    }
  }, [loading, data, setScheduleIdMapCallback, setWeeklyPlanListCallback]);

  return {
    loading,
    error,
    data,
    scheduleIdMap,
    weeklyPlanList,
    refetch,
  };
}

