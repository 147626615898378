export const InsightsDataBlacklist = [
  '\\d{2}(0[1-9]|1[012])(0[1-9]|[12]\\d|3[01])(\\d{7})', //  id number
  '[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+', //  email
  '\\+?\\d{3,4}\\s?\\d{3}\\s?\\d{4}', // phone number
  '\\d{4}-?\\d{4}-?\\d{4}-?\\d{4}', // credit card
];

export const InsightLevel = {
  Fatal: 'fatal',
  Error: 'error',
  Warning: 'warning',
  Log: 'log',
  Info: 'info',
  Debug: 'debug',
  Critical: 'critical',
};

export const TagTypes = {
  Event: 'event',
  View: 'view',
};

export const EventNames = {
  //Built-ins, refer to https://developers.google.com/tag-platform/gtagjs/reference/events for parameter details
  View: 'screen_view',
  PageView: 'page_view',
  Purchase: 'purchase',
  AddToCart: 'add_to_cart',

  //Custom events, parameters are free form,
  Error: 'app_error',
};

export const CommonSentryIgnoreErrors = [
];

export const CommonSentryTagKeys = [
  'environment',
  'user',
  'screen',
  'journey',
  'component',
  'formFactor',
  'traceId',
  'hook',
  'gqlOperationType',
  'gqlOperationName',
  'upstream',
];
