import {
  DefaultFormFactors,
  FormFactor,
  Orientation,
} from 'enums';

/**
 * Helper method to detect the current form factor
 * @param {Map<number, number>} formFactorMap A map that associates screen form factors with
 * breakpoints. It _must_ follow ascending order by breakpoint.
 * @param {number} screenWidth The current width of the screen.
 * @returns {*|undefined} The form factor, if one could be found, or else `undefined`.
 */
export const getFormFactor = ({
  formFactorMap = parseFormFactors(DefaultFormFactors),
  screenWidth,
}) => {

  let currentFormFactor;

  for (const [formFactor, breakPoint] of formFactorMap.entries()) {

    if (breakPoint > screenWidth) {
      break;
    }

    currentFormFactor = formFactor;
  }

  return currentFormFactor;
};

/**
 * Helper method to parse a form factor object into a map that can be used to efficiently detect
 * the current form factor. All invalid entries will be filtered out.
 * @param {Object} formFactors
 * @return {Map<number, number>}
 */
export const parseFormFactors = formFactors => {

  if (!formFactors) {
    return new Map();
  }

  const sortedAndFilteredFormFactors = [];

  for (const entry of Object.entries(formFactors)) {

    const formFactor = Number(entry[0]);
    const breakpoint = Number(entry[1]);

    if (isNaN(formFactor) || isNaN(breakpoint)) {
      continue;
    }

    sortedAndFilteredFormFactors.push([formFactor, breakpoint]);
  }

  sortedAndFilteredFormFactors.sort((a, b) => a[1] - b[1]);

  return new Map(sortedAndFilteredFormFactors);
};

/**
   * Create a function that gets the best matching value for the current from factor from the specified map. The algorithm
   * uses the "mobile first" principle, so only values that match the current form factor or
   * smaller will be taken into consideration.
   * @param {Object.<number, *>} values An object that maps form factors to any value. Note that
   * these form factors must be keys in the `formFactors` prop, otherwise those entries will
   * be ignored.
   * @example
   * // Assume current form factor is Mobile
   *
   * getFormFactorValue({
   *   [FormFactor.Feature]: 'feature',
   *   [FormFactor.Mobile]: 'mobile',
   * }) === 'mobile'
   * @example
   * // Assume current form factor is Desktop
   *
   * getFormFactorValue({
   *   [FormFactor.Feature]: 'feature',
   *   [FormFactor.Tablet]: 'tablet',
   * }) === 'tablet'
   * @example
   * // Assume current form factor is Mobile
   *
   * getFormFactorValue({
   *   [FormFactor.Tablet]: 'tablet',
   * }) === undefined
   * @returns {*|undefined} Returns the best-matching value or undefined if none was found.
   */
export const createGetFormFactorValue = (
  currentFormFactor,
) => values => {

  if (!values) {
    return;
  }

  if (values.hasOwnProperty(currentFormFactor)) {
    return values[currentFormFactor];
  }

  let chosenFormFactor = -1;
  let chosenValue = undefined;

  for (const [formFactorString, value] of Object.entries(values)) {

    const formFactor = Number(formFactorString);

    if (formFactor <= currentFormFactor && formFactor >= chosenFormFactor) {
      chosenFormFactor = formFactor;
      chosenValue = value;
    }
  }

  return chosenValue;
};

export const getFormFactorInfo = ({
  formFactors = DefaultFormFactors,
  windowDimensions,
} = {}) => {

  const formFactorMap = parseFormFactors(formFactors);

  const formFactor = getFormFactor({
    formFactorMap,
    screenWidth: windowDimensions.width,
  });

  const dimensions = {
    width: windowDimensions.width,
    height: windowDimensions.height,
  };

  const isFeature = formFactor <= FormFactor.Feature;
  const isMobile = formFactor <= FormFactor.Mobile;
  const isDesktop = formFactor === FormFactor.Desktop;
  const isTablet = formFactor === FormFactor.Tablet;
  const orientation = (dimensions.width >= dimensions.height && Orientation.Landscape) || Orientation.Portrait;
  const getFormFactorValue = createGetFormFactorValue(formFactor);

  return {
    formFactor,
    dimensions,
    isFeature,
    isMobile,
    isDesktop,
    isTablet,
    orientation,
    getFormFactorValue,
  };
};
