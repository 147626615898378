import {
  Typography,
} from '@mui/material';

import {
  TypographyVariant,
} from 'enums';

export const TitleMedium = props =>

  <Typography
    color="secondary"
    {...props}
    fontSize="1rem"
    variant={TypographyVariant.Subtitle2}>

    {props.children}
  </Typography>
;

TitleMedium.displayName = 'TitleMedium';
