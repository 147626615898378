export const RoutePath = {
  Login: '/identity/login',
  Home: '/home',
  TaskDetails: '/tasks/:id',
  Account: '/account',
  Help: '/help',
  Profile: '/account',
  TaskTemplate: '/add-task',
  TaskList: '/task-list',
  InternTasks: '/task-list/intern',
  MyTasks: '/task-list/my-tasks',
  CreateTask: '/add-task/create',
  CreateAssessmentTask: '/add-task/create/assessment',
  EditTask: '/add-task/edit',
  HelpAndSupport: '/help-and-support',
  AdminUserOnboarding: '/admin/user-onboarding',
  Onboarding: '/onboarding',
  EditProfile: '/profile/edit',
  Reporting: '/reporting',
};

export const JourneyName = {
  App: 'App',
  Home: 'Home',
  Identity: 'Identity',
  TaskDetails: 'TaskDetails',
  TaskTemplate: 'TaskTemplate',
  TaskList: 'TaskList',
  InternTasks: 'InternTasks',
  MyTasks: 'MyTasks',
  CreateTask: 'CreateTask',
  CreateAssessmentTask: 'CreateAssessmentTask',
  EditTask: 'EditTask',
  HelpAndSupport: 'HelpAndSupport',
  AdminUserOnboarding: 'OnboardNewUsers',
  Onboarding: 'Onboarding',
  Profile: 'Profile',
  EditProfile: 'EditProfile',
  Reporting: 'Reporting',
};

export const ScreenName = {
  Login: 'Login',
  Home: 'Home',
  TaskDetails: 'View Task',
  TaskTemplate: 'Add Task',
  Account: 'Account',
  TaskList: 'Task List',
  InternTasks: 'Task List',
  MyTasks: 'Task List',
  CreateTask: 'Create Task',
  EditTask: 'Edit Task',
  HelpAndSupport: 'Help & Support',
  Help: 'Help',
  AdminUserOnboarding: 'Admin Portal',
  Onboarding: 'Onboarding',
  Profile: 'Profile',
  EditProfile: 'Profile',
  Reporting: 'Reporting',
};

export const Navigate = {
  Back: -1,
  Forward: 1,
};
