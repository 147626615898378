import { useMutation } from '@apollo/client';
import { PersonUpsertWithCognito } from 'gql/person/mutations';

export const usePersonCognitoUpsert = () => {

  const [upsertPersonCognito, { data, loading, error }] = useMutation(PersonUpsertWithCognito);

  return [
    upsertPersonCognito,
    {
      loading,
      error,
      data,
    }
  ];
}
