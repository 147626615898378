import React from 'react';

import PropTypes from 'prop-types';

import {
  styled,
} from '@mui/material';

import {
  ButtonVariant,
} from 'enums';

import {
  ReportPollInterval,
} from 'config';

import {
  generateReportWorkBook,
  isNonEmptyArray,
  isString,
  saveWorkBook,
} from 'common';

import {
  FetchParseAs,
  useFetch,
  useFileDownload,
} from 'hooks';

import {
  BodyMedium,
  PrimaryButton,
  SpinnerButton,
  TitleMedium,
} from 'ui/common';

const StyledGroupName = styled(TitleMedium)`
  margin-top: 1rem;
`;

const StyledDescription = styled(BodyMedium)`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const ReportDownloader = ({
  groupName = '',
  reportFileId,
}) => {

  const [reportPolling, setReportPolling] = React.useState(false);
  const [reportUrl, setReportUrl] = React.useState();
  const [workBook, setWorkBook] = React.useState();
  const [workBookError, setWorkBookError] = React.useState();

  const {
    data: reportData,
    loading: reportLoading,
    error: reportError,
    startPolling: reportStartPolling,
    stopPolling: reportStopPolling,
  } = useFileDownload(reportFileId);

  /**
   * Boolean used to indicate if the report is done generating on the backend.
   * @type {boolean}
   */
  const reportGenerated = Boolean(!reportLoading && reportData?.tag?.includes?.('"GENERATED"'));

  const {
    loading: reportFetchLoading,
    error: reportFetchError,
    data: reportFetchData,
    refetch: reportFetchRefetch,
  } = useFetch({
    url: reportUrl,
    parseAs: FetchParseAs.Json,
  });

  /**
   * Boolean to indicate if the report is empty. This prevents wasting computation on generating
   * an empty report.
   * @type {boolean}
   */
  const isReportEmpty = Boolean(
    (reportGenerated && !reportData?.signedUrl) ||
    (reportUrl && !reportFetchLoading && !isNonEmptyArray(reportFetchData?.data)),
  );

  const logoFileId = isReportEmpty
    ? undefined
    : reportFetchData?.details?.logoFileId;

  const title = isReportEmpty
    ? undefined
    : reportFetchData?.details?.title;

  const fileName = isString(title) && !title.toLowerCase().endsWith('.xlsx')
    ? `${title}.xlsx`
    : title;

  const {
    data: logoData,
    loading: logoLoading,
    error: logoError,
    refetch: logoRefetch,
  } = useFileDownload(logoFileId);

  const {
    loading: logoFetchLoading,
    error: logoFetchError,
    data: logoFetchData,
    refetch: logoFetchRefetch,
  } = useFetch({
    url: logoData?.signedUrl,
    parseAs: FetchParseAs.Blob,
  });

  /**
   * Boolean to indicate if the logo is empty. This determines whether the logo will be used in the
   * report.
   * @type {boolean}
   */
  const isLogoEmpty = Boolean(
    reportGenerated && reportUrl && !reportFetchLoading && !logoFileId,
  );

  const loading = reportLoading || reportPolling || reportFetchLoading || logoLoading || logoFetchLoading;
  const error = reportError || reportFetchError || logoError || logoFetchError || workBookError;

  /**
   * Starts downloading the report JSON document. Note that this will do nothing if the report isn't
   * generated yet or if the signed URL is missing.
   */
  const onDownloadReport = () => {

    if (isReportEmpty) {
      return;
    }

    if (workBook) {

      saveWorkBook({
        workBook,
        fileName,
      });

      return;
    }

    // Kicks off fetching the report JSON document
    setReportUrl(reportData.signedUrl);
  };

  /**
   * Re-fetches any failed data and resumes generating the report.
   */
  const onRetry = () => {

    if (reportError) {
      reportStartPolling(ReportPollInterval);
      setReportPolling(true);
    }

    if (reportFetchError) {
      reportFetchRefetch();
    }

    if (logoError) {
      logoRefetch();
    }

    if (logoFetchError) {
      logoFetchRefetch();
    }

    if (workBookError) {
      setWorkBookError(undefined);
    }
  };

  // Effect to automatically start polling if the report file ID prop is set, restart polling if it
  // changes, and stop polling if it is falsy.
  React.useEffect(() => {

    if (!reportFileId) {
      setReportPolling(false);
      return;
    }

    reportStartPolling(ReportPollInterval);
    setReportPolling(true);

    return () => {
      reportStopPolling();
    };
  }, [
    reportFileId,
    reportStartPolling,
    reportStopPolling,
  ]);

  // Effect to stop polling as soon as the report is generated or if an error occurred.
  React.useEffect(() => {

    if (
      !reportGenerated &&
      !reportError
    ) {
      return;
    }

    reportStopPolling();
    setReportPolling(false);
  }, [
    reportError,
    reportGenerated,
    reportStopPolling,
  ]);

  // Effect to generate the report Excel sheet after all the required data has been fetched.
  React.useEffect(() => {

    if (
      !reportGenerated ||
      isReportEmpty ||
      (!isReportEmpty && !reportFetchData) ||
      (!isLogoEmpty && !logoFetchData) ||
      error
    ) {
      return;
    }

    generateReportWorkBook({
      title,
      report: reportFetchData,
      logo: logoFetchData,
    })
      .then(workBook => {
        setWorkBook(workBook);

        return saveWorkBook({
          workBook,
          fileName,
        });
      })
      .catch(error => {
        setWorkBookError(error);
        setWorkBook(undefined);
      });
  }, [
    error,
    fileName,
    isLogoEmpty,
    isReportEmpty,
    logoFetchData,
    reportFetchData,
    reportGenerated,
    title,
  ]);

  return (

    <React.Fragment>

      {!groupName &&

        <StyledGroupName>
          {groupName}
        </StyledGroupName>
      }

      {!!isReportEmpty &&

        <StyledDescription
          color={'error'}>

          Report has no data for the selected dates and filters.
        </StyledDescription>
      }

      {!loading && !!error &&

        <React.Fragment>
          <StyledDescription
            color={'error'}>

            Could not retrieve report. Please try again.
          </StyledDescription>

          <PrimaryButton
            text={'Retry'}
            onClick={onRetry}/>
        </React.Fragment>
      }

      {!error &&

        <SpinnerButton
          variant={ButtonVariant.Contained}
          text={workBook ? 'Save' : 'Download'}
          disabled={isReportEmpty}
          loading={loading}
          onClick={onDownloadReport}/>
      }
    </React.Fragment>
  );
};

ReportDownloader.displayName = 'ReportDownloader';

ReportDownloader.propTypes = {
  groupName: PropTypes.string,
  reportFileId: PropTypes.string.isRequired,
};
