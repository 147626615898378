import {
  gql,
} from '@apollo/client';

export const SchoolUpsert = gql`
  mutation SchoolUpsert($params: SchoolUpsertParams) {
    schoolUpsert(params: $params) {
      body {
        upsertResult {
          command
          ok
        }
        ok
        message
      }
      statusCode
    }
  }
`;