import React from 'react';

import {
  Asset,
  JourneyName,
  ScreenName,
} from 'enums';

import {
  Feedback,
  HeadlineSmall,
  PrimaryButton,
  Scene,
  TaskForm,
} from 'ui/common';

import {
  useTaskDetails,
  useTaskUpsert,
} from 'hooks';

import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import {
  buildTaskUpsertVariables,
} from 'common';

import { validateTaskForm } from 'common/forms';
import { styled } from '@mui/material';

const StyledHeader = styled(HeadlineSmall)`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const StyledButton = styled(PrimaryButton)`
  margin-top: 1rem;
`;

export const EditTask = () => {

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const taskId = searchParams.get('id');

  const {
    loading,
    error,
    taskDetail,
    refetch,
  } = useTaskDetails(taskId);

  const [upsertTask, {loading: upsertLoading, data: upsertData, error: upsertError}] = useTaskUpsert();

  React.useEffect(() => {
    if (!loading && taskDetail) {

      const fields = {
        title: taskDetail.title,
        description: taskDetail.description || taskDetail.shortDescription,
        scheduledStartTimestamp: taskDetail.scheduledStartTimestamp,
        scheduledEndTimestamp: taskDetail.scheduledEndTimestamp,
        location: taskDetail.location || '',
        note: taskDetail.note || '',
      };

      setFormFields(fields);
    }
  }, [loading, taskDetail]);

  const [formFields, setFormFields] = React.useState();

  const [isFormValid, setIsFormValid] = React.useState(true);
  const [validation, setValidation] = React.useState({});

  const handleFormFieldChange = (e, field) => {
    setFormFields(prevFields => {

      let value = e.target?.value;

      if (field === 'scheduledStartTimestamp' || field === 'scheduledEndTimestamp') {
        value = e.toISOString();
      }

      const newFields = {
        ...prevFields,
        [field]: value,
      };

      const errorMap = validateTaskForm(newFields);

      setValidation(errorMap);
      setIsFormValid(Object.keys(errorMap).length === 0);

      return newFields;
    });
  };

  const handleOnEdit = () => {
    upsertTask(buildTaskUpsertVariables([{
      id: taskDetail.id,
      title: formFields.title,
      description: formFields.description !== '' && formFields.description || taskDetail.description,
      scheduledStartTimestamp: formFields.scheduledStartTimestamp,
      scheduledEndTimestamp: formFields.scheduledEndTimestamp,
      location: formFields.location !== '' && formFields.location || null,
      note: formFields.note !== '' && formFields.note || null,
    }]));
  };

  return (

    <Scene
      headerVisible={true}
      title={ScreenName.EditTask}
      footerVisible={true}
      loading={loading || upsertLoading}
    >

      <React.Fragment>

        {!loading && formFields && !upsertData &&
          <React.Fragment>
            <StyledHeader>
              {taskDetail.title}
            </StyledHeader>

            <TaskForm
              formFields={formFields}
              handleFormFieldChange={handleFormFieldChange}
              validation={validation}
            />

            <StyledButton
              text={'Save Changes'}
              onClick={handleOnEdit}
              disabled={!isFormValid}
            />
          </React.Fragment>
        }

        {!loading && error &&
        <Feedback 
          imageUrl={Asset.Image.Other.BrokenPencil}
          headerText='Something Went Wrong!'
          bodyText="We encountered a hiccup while fetching your task details. Not to worry, though – we're on it!"
          infoText="Please try loading your task again. If the issue persists, reach out to us for support"
          type="Error"
          buttonText='Try Again'
          buttonAction={() => refetch()}/>
        }

        {!upsertLoading && upsertError &&
          <Feedback 
            imageUrl={Asset.Image.Other.BrokenPencil}
            headerText='Something Went Wrong!'
            bodyText="We encountered a hiccup while editing your task. Not to worry, though – we're on it!"
            infoText="Please try editing your task again. If the issue persists, reach out to us for support"
            type="Error"
            buttonText='Try Again'
            buttonAction={() => handleOnEdit()}/>
        }

        { !upsertLoading && upsertData &&
          <Feedback 
            imageUrl={Asset.Image.Other.BookStack}
            headerText={'Task Edited'}
            bodyText={'Great job editing your task! Your dedication shines bright.'}
            infoText={"Your task has been updated in your task list."}
            buttonText='Go Back'
            buttonAction={() => navigate('/home')}/>
          }
      </React.Fragment>
      
    </Scene>
  );
};

EditTask.displayName = 'EditTask';

EditTask.context = {
  screen: ScreenName.EditTask,
  journey: JourneyName.EditTask,
  component: EditTask.displayName,
};
