import PropTypes from 'prop-types';

import {
  styled,
} from '@mui/material';

import React from 'react';

import {
  TitleMedium,
  BodyMedium,
} from '../typography';

import { useFileDownload } from 'hooks';
import { TextButton } from '../buttons';
import { HFillContainerVH } from '../containers';

const StyledGroupName = styled(TitleMedium)`
  margin-bottom: 1rem;
`;

const StyledTitle = styled(TitleMedium)`
  margin-bottom: 0.5rem;
`;

const StyledDescription = styled(BodyMedium)`
  margin-bottom: 1rem;
`;

const StyledButton = styled(TextButton)`
  padding: 0;
  text-transform: none;
`;

const StyledButtonInner = styled(HFillContainerVH)`
  padding: 0 !important;
  justify-content: space-between;
`;

const StyledButtonText = styled(BodyMedium)`
  padding: 0;
`;

export const DocumentViewer = props => {

  const {data, loading, error} = useFileDownload(props.fileId);

  const handleViewFile = () => {
    if (data && data.signedUrl) {
      window.open(data.signedUrl, '_blank');
    }
  };

  return (

    <React.Fragment>
      {props.groupName !== '' && <StyledGroupName>{props.groupName}</StyledGroupName>}
      <StyledTitle>{props.title}</StyledTitle>

      {!loading && data &&
        <StyledButton onClick={handleViewFile}>
          <StyledButtonInner>
            <StyledButtonText color="primary">{data.name}</StyledButtonText>
            <StyledButtonText color="primary">View File</StyledButtonText>
          </StyledButtonInner>
        </StyledButton>
      }

      {!loading && error && <StyledDescription>{'Could not retrieve file'}</StyledDescription>}

      {loading && <StyledDescription>{'Loading...'}</StyledDescription>}
    </React.Fragment>
  );
};

DocumentViewer.displayName = 'DocumentViewer';

DocumentViewer.propTypes = {
  title: PropTypes.string,
  groupName: PropTypes.string,
  fileId: PropTypes.string.isRequired,
};

DocumentViewer.defaultProps = {
  title: 'Attachment',
  groupName: '',
};
