import { useMutation } from '@apollo/client';
import { GenerateReport } from 'gql/reporting/mutations';

export const useGenerateReport = () => {

  const [generateReport, { data, loading, error }] = useMutation(GenerateReport);

  return [
    generateReport,
    {
      loading,
      error,
      data,
    }
  ];
}
