import
  PropTypes
from 'prop-types';

import {
  Dropdown,
} from 'ui/common/components/form/Inputs/Dropdown';

import React from 'react';

export const RangeSelector = props => { 
  const {
    onChange,
    ranges
  } = props;

  const [state, setState] = React.useState({selectedStart: null, selectedEnd: null});
  
  React.useEffect(() => {
      const newState = {selectedStart: ranges.start.default, selectedEnd: ranges.end.default};
      setState(newState);
      onChange && onChange(newState);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ranges]);

  const handleChange = (target, value) => {
    setState((prevState) => {
      const newState = {
        ...prevState,
        [target]: value,
      };

      onChange && onChange(newState);

      return newState;
    }); 
  };
  
  return (
    <React.Fragment>
      {
        ranges.start.options && 
        <Dropdown
        key='selectedStartRangeSelector'
        label={ranges.start.label}
        options={ranges.start.options}
        value={state.selectedStart || ''}
        onChange={(event) => handleChange('selectedStart', event.target.value)}
      />
      }

      {
        ranges.end.options &&
          <Dropdown
          key='selectedEndRangeSelector'
          label={ranges.end.label}
          options={ranges.end.options}
          value={state?.selectedEnd || ''}
          onChange={(event) => handleChange('selectedEnd', event.target.value)}
        />
      }      
    </React.Fragment>
  );
};

RangeSelector.displayName = 'RangeSelector';

RangeSelector.propTypes = {
    onChange: PropTypes.func,
    ranges: PropTypes.object,

};

RangeSelector.defaultProps = {
    periodType: 'week'
};