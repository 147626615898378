import {
    RoutePath,
} from 'enums';
  
import {
  Reporting,
} from 'ui/reporting';
  
  /**
   * @type {RouteConfig[]}
   */
  export const ReportingRoutes = [
    {
      element: <Reporting />,
      path: RoutePath.Reporting,
      children: [],
    }
  ];
  