export const Orientation = {
  Portrait: 'portrait',
  Landscape: 'landscape',
};

export const LayoutDirection = {
  Horizontal: 'horizontal',
  Vertical: 'vertical',
};

export const LineHeight = {
  Normal: 'normal',
};

export const FontWeight = {
  Regular: 400,
  Medium: 500,
  SemiBold: 600,
  Bold: 700,
};

export const FontDisplay = {
  Auto: 'auto',
  Block: 'block',
  Swap: 'swap',
  Fallback: 'fallback',
  Optional: 'optional',
};

export const FontStyle = {
  Normal: 'normal',
  Italic: 'italic',
};

export const TextAlign = {
  Auto: 'auto',
  Left: 'left',
  Right: 'right',
  Center: 'center',
  Justify: 'justify',
};

export const TextDecorationLine = {
  None: 'none',
  Underline: 'underline',
  LineThrough: 'line-through',
  UnderlineLineThrough: 'underline line-through',
};

export const TextDecorationStyle = {
  Solid: 'solid',
  Double: 'double',
  Dotted: 'dotted',
  Dashed: 'dashed',
  None: 'none',
};

export const TextTransform = {
  None: 'none',
  Uppercase: 'uppercase',
  Lowercase: 'lowercase',
  Capitalize: 'capitalize',
};

export const PointerEvents = {
  Auto: 'auto',
  None: 'none',
  BoxNone: 'box-none',
  BoxOnly: 'box-only',
};

export const Cursor = {
  Default: 'default',
  Pointer: 'pointer',
};

export const Visibility = {
  Visible: 'visible',
  Hidden: 'hidden',
};

export const BorderStyle = {
  Solid: 'solid',
  Dotted: 'dotted',
  Dashed: 'dashed',
  None: 'none',
};

export const AlignContent = {
  Start: 'flex-start',
  End: 'flex-end',
  Center: 'center',
  Stretch: 'stretch',
  SpaceBetween: 'space-between',
  SpaceAround: 'space-around',
};

export const AlignItems = {
  Start: 'flex-start',
  End: 'flex-end',
  Center: 'center',
  Stretch: 'stretch',
  Baseline: 'baseline',
};

export const AlignSelf = {
  Auto: 'auto',
  Start: 'flex-start',
  End: 'flex-end',
  Center: 'center',
  Stretch: 'stretch',
  Baseline: 'baseline',
};

export const Direction = {
  Auto: 'auto',
  LeftToRight: 'ltr',
  RightToLeft: 'rtl',
};

export const Display = {
  None: 'none',
  Flex: 'flex',
};

export const BoxSizing = {
  ContentBox: 'content-box',
  BorderBox: 'border-box',
};

export const FlexDirection = {
  Row: 'row',
  RowReverse: 'row-reverse',
  Column: 'column',
  ColumnReverse: 'column-reverse',
};

export const FlexWrap = {
  Wrap: 'wrap',
  NoWrap: 'nowrap',
  WrapReverse: 'wrap-reverse',
};

export const JustifyContent = {
  Start: 'flex-start',
  End: 'flex-end',
  Center: 'center',
  SpaceBetween: 'space-between',
  SpaceAround: 'space-around',
  SpaceEvenly: 'space-evenly',
};

export const Overflow = {
  Visible: 'visible',
  Hidden: 'hidden',
  Scroll: 'scroll',
  Auto: 'auto',
};

export const Position = {
  Absolute: 'absolute',
  Relative: 'relative',
};

export const ResizeMode = {
  Cover: 'cover',
  Contain: 'contain',
  Stretch: 'stretch',
  Repeat: 'repeat',
  Center: 'center',
};

export const UserSelect = {
  None: 'none',
  Auto: 'auto',
  Text: 'text',
};

export const WordBreak = {
  Normal: 'normal',
  BreakAll: 'break-all',
  KeepAll: 'keep-all',
};

export const OverflowWrap = {
  Normal: 'normal',
  BreakWord: 'break-word',
  Anywhere: 'anywhere',
};

export const VerticalAlign = {
  Baseline: 'baseline',
  Sub: 'sub',
  Super: 'super',
  TextTop: 'text-top',
  TextBottom: 'text-bottom',
  Middle: 'middle',
  Top: 'top',
  Bottom: 'bottom',
};

export const WhiteSpace = {
  Normal: 'normal',
  Nowrap: 'nowrap',
  Pre: 'pre',
  PreWrap: 'pre-wrap',
  PreLine: 'pre-line',
  BreakSpaces: 'break-spaces',
};

export const TextOverflow = {
  Clip: 'clip',
  Ellipsis: 'ellipsis',
};

export const Hyphens = {
  None: 'none',
  Manual: 'manual',
  Auto: 'auto',
};

export const InputTypes = {
  Text: 'text',
  Date: 'date',
  Number: 'number',
  Url: 'url',
  Email: 'email',
  PhoneNumber: 'tel',
  Password: 'password',
  Search: 'search',
  Color: 'color',
};

export const InputMode = {
  None: 'none',
  Text: 'text',
  Decimal: 'decimal',
  Numeric: 'numeric',
  Tel: 'tel',
  Search: 'search',
  Email: 'email',
  Url: 'url',
};

export const ObjectFit = {
  Contain: 'contain',
  Cover: 'cover',
  Fill: 'fill',
  None: 'none',
  ScaleDown: 'scale-down',
};

export const FillRule = {
  NonZero: 'nonzero',
  EvenOdd: 'evenodd',
};

export const BorderRadius = {
  Icon: '2px',
  InputButton: '4px',
  Input: '8px',
  Card: '10px',
  IconBackground: '16px',
  Ellipse: '50%',
};

export const Spacing = {
  None: '0px',
  Small1: '1px',
  Small2: '4px',
  Small3: '8px',
  Small4: '12px',
  Medium1: '16px',
  Medium2: '24px',
  Medium3: '32px',
  Large1: '40px',
  Large2: '48px',
  Large3: '56px',
  Large4: '64px',
  Large5: '72px',
};

export const TransitionDuration = {
  Standard: '0.3s',
  Long: '1.5s',
  ExtraLong: '2s',
};

export const AnimationTimingFunction = {
  Linear: 'linear',
  Ease: 'ease',
  EaseIn: 'ease-in',
  EaseOut: 'ease-out',
  EaseInOut: 'ease-in-out',
  StepEnd: 'step-end',
};

export const RelativeDirection = {
  Left: 'left',
  Right: 'right',
  Up: 'up',
  Down: 'down',
};

export const ScrollbarWidth = {
  Auto: 'auto',
  Thin: 'thin',
  None: 'none',
};

export const DominantBaseline = {
  Auto: 'auto',
  TextBottom: 'text-bottom',
  Alphabetic: 'alphabetic',
  Ideographic: 'ideographic',
  Middle: 'middle',
  Mathematical: 'mathematical',
  Hanging: 'hanging',
  TextTop: 'text-top',
};

export const UnicodeCharacters = {
  NonBreakingSpace: '\u00a0',
};

export const SpinnerType = {
  FullScreen: 'FullScreen',
  Modal: 'Modal',
};

export const Alignment = {
  Vertical: 'vertical',
  Horizontal: 'horizontal',
};
