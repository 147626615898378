import PropTypes from 'prop-types';

import {
  styled,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormHelperText,
} from '@mui/material';
import React from 'react';
import { InfoText } from '../InfoText';

const StyledFormControl = styled(FormControl)`
  padding: 0 0 16px 0;
  color: primary;
`;

const getCheckboxDefaultState = (options, value) => {
  const defaultState = {};

  options.forEach(o => {
    defaultState[o] = false;
  });

  if (value != null && value !== '' && JSON.parse(value)) {
    JSON.parse(value).forEach(v => {
      defaultState[v] = true;
    });
  }

  return defaultState;
};

export const MultiSelect = props => {

  const {
    title,
    description,
    groupName,
    options,
    value,
    onChange,
    onBlur,
    error,
    helperText,
    questionId,
    questionComments,
    upsertCommentLoading,
    handleComment,
    userId,
  } = props;

  const [state, setState] = React.useState(getCheckboxDefaultState(options, value));

  const handleChange = (event) => {

    setState((prevState) => {

      const newState = {
        ...prevState,
        [event.target.name]: event.target.checked,
      };

      if (onChange) {
        const resultList = Object.entries(newState).filter(([k, v]) => v).map(([k, v]) => k);
        onChange(JSON.stringify(resultList));
      }
      
      return newState;
    }); 
  };

  return (
    <React.Fragment>
      <InfoText
        title={title}
        description={description}
        groupName={groupName}
        questionId={questionId}
        questionComments={questionComments}
        upsertCommentLoading={upsertCommentLoading}
        handleComment={handleComment}
        userId={userId}
      />

      <StyledFormControl
          error={error}
          component="fieldset"
          variant="standard"
          onBlur={onBlur}
        >
          <FormGroup>

            {options.map((o) =>
              <FormControlLabel
                key={o}
                label={o}
                control={
                  <Checkbox
                    checked={state[o]}
                    name={o}
                    onChange={handleChange}/>
                }
              />
            )}
          </FormGroup>
          <FormHelperText>{ helperText }</FormHelperText>
      </StyledFormControl>
    </React.Fragment>
  );
};

MultiSelect.displayName = 'MultiSelect';

MultiSelect.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  groupName: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  options: PropTypes.array,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  questionId: PropTypes.any,
  questionComments: PropTypes.any,
  upsertCommentLoading: PropTypes.bool,
  handleComment: PropTypes.any,
  userId: PropTypes.string,
};

MultiSelect.defaultProps = {
  value: '',
  groupName: '',
  error: false,
  helperText: '',
  label: 'Label',
  onChange: () => console.log('value selected'),
  onBlur: () => {},
  userId: '',
};
