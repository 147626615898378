import React from 'react';
import PropTypes from 'prop-types';

import {
  useNavigate,
} from 'react-router-dom';

import {
  Drawer,
  MenuList as MenuListProto,
  MenuItem,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';

import {
  Logout,
} from '@mui/icons-material';

import {
  MainMenuItems,
  MainMenuAdminItems,
  Spacing,
  IdentityGroupType,
  MainMenuMentorItems,
} from 'enums';

import {
  VFillBox,
} from 'ui/common/components/boxes';
import { VersionWatermark } from '../VersionWatermark';

import {
  useIdentity,
} from 'hooks';

const MenuList = styled(MenuListProto)`
  width: 260px;
`;

const MenuBox = styled(VFillBox)`
  justify-content: space-between;
  margin-bottom: ${Spacing.Small4};
`

export const MainMenu = props => {

  const navigate = useNavigate();

  const navigateTo = React.useCallback(path => navigate(path), [
    navigate,
  ]);

  const { user } = useIdentity({});
  const userType = user.group || IdentityGroupType.Intern; 

  const menuItems = React.useMemo(() => {

    const items = [];

    let itemsConfig = MainMenuItems;

    if (userType !== IdentityGroupType.Intern) {
      itemsConfig = itemsConfig.concat(MainMenuMentorItems);
    }

    if (userType === IdentityGroupType.Admin) {
      itemsConfig = itemsConfig.concat(MainMenuAdminItems);
    }

    for (const item of itemsConfig) {

      if (!item || !item.path || !item.text) {
        continue;
      }

      const Icon = item.icon || <React.Fragment/>;

      items.push(
        <MenuItem
          key={item.id}
          onClick={() => navigateTo(item.path)}>

          <ListItemIcon>
            <Icon />
          </ListItemIcon>

          <ListItemText>
            {item.text}
          </ListItemText>

        </MenuItem>
      );
    }

    return items;
  }, [
    navigateTo,
    userType
  ]);

  return (

    <Drawer
      anchor={'right'}
      open={props.visible}
      onClose={props.onClose}>

      <VersionWatermark/>

      <MenuBox>
        <MenuList>
          {menuItems}
        </MenuList>

        <MenuItem
          onClick={props.logout}
        >

          <ListItemIcon>
            <Logout/>
          </ListItemIcon>

          <ListItemText>
            Logout
          </ListItemText>
        </MenuItem>
        
      </MenuBox>

    </Drawer>
  )
};

MainMenu.displayName = 'MainMenu';

MainMenu.propTypes = {
  visible: PropTypes.bool,

  onClose: PropTypes.func,
  logout: PropTypes.func,
};

MainMenu.defaultProps = {
  visible: true,

  onClose: undefined,
};
