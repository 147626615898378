import {
  withProfiler,
} from 'insights/sentry';

import {
  AppRoot,
} from 'ui/app';

import {
  Navigator,
  Routes,
} from 'ui/navigation';

export const App = withProfiler(() => 

    <AppRoot>
      <Navigator
        routes={Routes}/>
    </AppRoot>
);

App.displayName = 'App';

App.context = {
  component: App.displayName,
};

