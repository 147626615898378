export const getGenerateReportParams = (mnemonic, filterProperties) => {
  return {
    params: {
      reportMnemonic: mnemonic,
      filterProperties: filterProperties,
    }
  };
}

export const getAllReportsParams = () => {
  return {
    params: {
      orderBy: [
        {
            fieldNames: [
                'title'
            ],
            direction: 'ASC'
          }
      ]
    }
  }
}
