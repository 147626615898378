export const taskDetailsPageParams = (taskId, personId, linkedTaskId, parentTaskId) => {
  return {
    taskDetailsParams: {
      filterProperties:[{
          operator: 'IN',
          fieldNames: ['taskId'],
          value: [taskId],
      }],
      filterLogicGate: 'AND',
    },
    taskInteractionsParams: {
      filterProperties:[{
          operator: 'IN',
          fieldNames: ['taskId'],
          value: [taskId],
      }],
      filterLogicGate: 'AND',
    },
    personParams: {
      filterProperties:[{
          operator: 'IN',
          fieldNames: ['personId'],
          value: [personId],
      }],
      filterLogicGate: 'AND',
    },
    childTaskParams: {
      filterProperties:[{
          operator: 'IN',
          fieldNames: ['parentTaskId'],
          value: [taskId],
      }],
      filterLogicGate: 'AND',
    },
    linkedTaskParams: {
      filterProperties:[{
          operator: 'IN',
          fieldNames: ['taskId'],
          value: [linkedTaskId],
      }],
      filterLogicGate: 'AND',
    },
    internTaskParams: {
      filterProperties:[{
          operator: 'IN',
          fieldNames: ['taskId'],
          value: [parentTaskId],
      }],
      filterLogicGate: 'AND',
    },
  };
};
