import PropTypes from 'prop-types';

import {
  styled,
} from '@mui/material';

import React from 'react';

import {
  BodyMedium,
  TitleMedium,
} from '../typography';

import { InlineComment } from '../InlineComment';

const StyledGroupName = styled(TitleMedium)`
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

const StyledTitle = styled(TitleMedium)`
  margin-bottom: 0.5rem;
`;

const StyledDescription = styled(BodyMedium)`
  margin-bottom: 1rem;
  padding-right: 2rem;
`;

export const InfoText = props =>

<React.Fragment>
  {props.groupName !== '' && <StyledGroupName>{props.groupName}</StyledGroupName>}
  {props.title !== '' && <StyledTitle>{props.title}</StyledTitle>}
  {props.description !== '' && <StyledDescription dangerouslySetInnerHTML={{ __html: props.description }}></StyledDescription>}
  {props.questionId &&
    <InlineComment
      questionId={props.questionId}
      userId={props.userId}
      comments={props.questionComments}
      upsertCommentLoading={props.upsertCommentLoading}
      handleComment={props.handleComment}
    />
  }
</React.Fragment>
;

InfoText.displayName = 'InfoText';

InfoText.propTypes = {
  groupName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  questionId: PropTypes.any,
  questionComments: PropTypes.any,
  upsertCommentLoading: PropTypes.bool,
  handleComment: PropTypes.any,
  userId: PropTypes.string,
};

InfoText.defaultProps = {
  groupName: '',
  title: '',
  description: '',
  userId: '',
  upsertCommentLoading: false,
};
