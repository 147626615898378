import {
  styled,
} from '@mui/material';

import {
  AlignItems,
  JustifyContent,
} from 'enums';

import {
  HFillContainer,
} from 'ui/common/components/containers/HFillContainer';

export const HFillContainerVH = styled(HFillContainer)`
  align-items: ${AlignItems.Center};
  justify-content: ${JustifyContent.Center};
`;

HFillContainerVH.displayName = 'HFillContainerVH';
