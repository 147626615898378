import React from 'react';

/**
 * Hook that returns the previous value for the specified variable.
 * @param {*} value This can be any value bound to the render cycle, like a state or prop variable.
 * @returns {*} Returns the initial value for the first render and will then return the previous
 * value on later renders.
 */
export const usePrevious = value => {

  const ref = React.useRef(value);

  React.useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};
