import {
  RoutePath,
} from 'enums';

import {
  TaskList,
  InternTasks,
  MyTasks,
} from 'ui/task-list';

/**
 * @type {RouteConfig[]}
 */
export const TaskListRoutes = [{
    element: <TaskList/>,
    path: RoutePath.TaskList,
    children: [
      {
        element: <InternTasks />,
        path: RoutePath.InternTasks,
        children: [],
      },
      {
        element: <MyTasks />,
        path: RoutePath.MyTasks,
        children: [],
      }
    ],
  },
];
