import {
  Typography,
} from '@mui/material';

import {
  TypographyVariant,
} from 'enums';

export const HeadlineLarge = props =>

  <Typography
    color="secondary"
    {...props}
    fontWeight={800}
    fontSize='2.062rem'
    variant={TypographyVariant.Heading4}>

    {props.children}
  </Typography>
;

HeadlineLarge.displayName = 'HeadlineLarge';
