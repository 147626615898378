import {
  IdentityGroupType,
  JourneyName,
  RoutePath,
  ScreenName,
} from 'enums';

import {
  Scene,
  ToggleButton,
} from 'ui/common';

import React, { useEffect, useMemo } from 'react';

import _ from 'lodash';

import {
  useIdentity,
} from 'hooks';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const handleLocation = (loc, user, setToggleValue) => {
  if (loc.pathname === '/task-list') {
    if ((user.group === IdentityGroupType.Mentor || user.group === IdentityGroupType.Coach || user.group === IdentityGroupType.Admin)) {
      setToggleValue('Intern Tasks');
    } else {
      setToggleValue('My Tasks');
    }
  }
  if (loc.pathname.includes('/task-list/intern')) {
    setToggleValue('Intern Tasks');
  }
  if (loc.pathname.includes('/task-list/my-tasks')) {
    setToggleValue('My Tasks');
  }
};

export const TaskList = () => {
  const { user } = useIdentity(TaskList.context);
  const location = useLocation();
  const navigate = useNavigate();
  const [toggleValue, setToggleValue] = React.useState();

  const handleToggle = useMemo(() =>
    _.debounce((value) => {
      if (value === 'Intern Tasks' && !location.pathname.includes('/task-list/intern')) {
        navigate('/task-list/intern', {replace: location.pathname === RoutePath.TaskList});
      } else if (value === 'My Tasks' && !location.pathname.includes('/task-list/my-tasks')) {
        navigate('/task-list/my-tasks', {replace: location.pathname === RoutePath.TaskList});
      }
    }, 300), [navigate, location]);

  useEffect(() => {
    handleToggle(toggleValue);
  }, [toggleValue, handleToggle]);

  useEffect(() => {
    handleLocation(location, user, setToggleValue);
  }, [location, user]);

  return (
    <Scene
      headerVisible={true}
      title={ScreenName.TaskList}
      footerVisible={true}
    >
      <React.Fragment>
        {(user.group === IdentityGroupType.Mentor || user.group === IdentityGroupType.Coach ) &&
          <ToggleButton
            value={toggleValue}
            options={['Intern Tasks', 'My Tasks']}
            onChange={(e) => setToggleValue(e.target.value)}
          />
        }
        <Outlet />
      </React.Fragment>
    </Scene>
  );
};

TaskList.displayName = 'TaskList';

TaskList.context = {
  screen: ScreenName.TaskList,
  journey: JourneyName.TaskList,
  component: TaskList.displayName,
};
