import React from 'react';

import PropTypes from 'prop-types';

import {
  Pagination,
  styled,
} from '@mui/material';

import Tabs from '@mui/material/Tabs';

import Tab from '@mui/material/Tab';

import Grid from '@mui/material/Grid';

import {
  format,
  startOfDay,
} from 'date-fns';

import {
  filterTasksBySelectedFilters,
  getDayOfWeekAndDate,
  isFunction,
} from 'common';

import {
  TaskList,
} from 'ui/common/components/TaskList';

import {
  BodyMedium,
  BodySmall,
  TitleLarge,
  TitleMedium,
} from 'ui/common/components/typography';

import {
  PrimaryButton,
} from 'ui/common/components/buttons';

import {
  VBox,
  VBoxH,
} from 'ui/common/components/boxes';

const NoMatchesContainer = styled(VBox)`
  margin-bottom: 2rem;
`;

const StyledTabs = styled(Tabs)(({ theme }) => `
  margin-bottom: 1rem;
  border-bottom: 1px solid ${theme.palette.neutral.n90};
  `,
);

const StyledTab = styled(Tab)({
  '& .MuiTabs-indicator': {
    maxWidth: '3rem',
  },
});

const ClearFilterButton = styled(PrimaryButton)`
  margin-top: 1.8rem;
  @media (min-width: 600px) {
    max-width: 228px;
  }
`;

export const ScheduleTabs = ({
  tabData,
  userType,
  defaultTab,
  filters,
  user,
  clearFilters,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(defaultTab || 0);

  const handleTabChange = (_event, newValue) => {
    setSelectedTab(newValue);
  };

  const groupTasksByDay = (tasks) => {

    if (!Array.isArray(tasks) || tasks.length === 0) {
      return [];
    }

    const groupedTasks = {};

    for (const task of tasks) {

      const {
        dayOfWeek,
        formattedDate,
        sortableDate,
      } = getDayOfWeekAndDate(task.scheduledEndTimestamp);

      const key = `${sortableDate} - ${dayOfWeek} - ${formattedDate}`;

      groupedTasks[key] = [
        ...(groupedTasks[key] || []),
        { ...task },
      ];
    }

    return Object.entries(groupedTasks).sort(([a], [b]) => {
      const dateA = startOfDay(new Date(a.split(' - ')[0]));
      const dateB = startOfDay(new Date(b.split(' - ')[0]));
      return dateA - dateB;
    });
  };

  const tabContent = React.useMemo(() => {

    const tab = tabData[selectedTab];

    if (!Array.isArray(tab?.data)) {
      return <React.Fragment/>;
    }

    if (tab.data.length <= 0) {
      return (
        <BodyMedium>
          No available tasks.
        </BodyMedium>
      );
    }

    const filteredTasks = filterTasksBySelectedFilters(tab.data, filters);

    if (filteredTasks.length <= 0) {
      return (
        <React.Fragment>
          <NoMatchesContainer>

            <TitleLarge>
              {
                tab.totalPages > 1
                  ? 'No Matches Found For This Page'
                  : 'No Matches Found'
              }
            </TitleLarge>

            <BodyMedium>
              We couldn’t find any tasks matching your filters.
            </BodyMedium>

            <BodyMedium>
              Please adjust your search filtering criteria.
            </BodyMedium>

            {clearFilters &&
              <ClearFilterButton
                onClick={clearFilters}>

                Clear Filters
              </ClearFilterButton>
            }
          </NoMatchesContainer>

          {tab.totalPages > 1 &&

            <Pagination
              count={tab.totalPages}
              page={tab.indexPage}
              onChange={(_event, value) => {
                isFunction(tab.onPageChange) && tab.onPageChange(value);
              }}/>
          }
        </React.Fragment>
      );
    }

    const tasks = groupTasksByDay(filteredTasks)
      .map(([day, tasks]) => {

        if (tasks.length <= 0) {
          return <React.Fragment key={day}/>;
        }

        const {
          dayOfWeek,
          formattedDate,
          sortableDate,
        } = getDayOfWeekAndDate(tasks[0].scheduledEndTimestamp);

        return (
          <React.Fragment
            key={day}>

            <Grid
              container
              justifyContent="space-between">

              <Grid
                item
                xs={6}>

                <TitleMedium>
                  {formattedDate}
                </TitleMedium>
              </Grid>

              <Grid
                item
                xs={6}
                style={{ textAlign: 'right' }}>

                <BodySmall>
                  {sortableDate === format(new Date(), 'yyyy-MM-dd') ? 'Today' : dayOfWeek}
                </BodySmall>
              </Grid>
            </Grid>

            <TaskList
              tasks={tasks}
              userType={userType}
              user={user}/>
          </React.Fragment>
        );
      });

    return (
      <React.Fragment>

        {tasks}

        {tab.totalPages > 1 &&

          <Pagination
            count={tab.totalPages}
            page={tab.indexPage}
            onChange={(_event, value) => {
              isFunction(tab.onPageChange) && tab.onPageChange(value);
            }}/>
        }
      </React.Fragment>
    );
  }, [
    clearFilters,
    filters,
    selectedTab,
    tabData,
    user,
    userType,
  ]);

  if (!Array.isArray(tabData) || tabData.length <= 0) {
    return (
      <VBoxH>
        <BodyMedium>
          No tasks available.
        </BodyMedium>
      </VBoxH>
    );
  }

  return (
    <React.Fragment>
      <StyledTabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="fullWidth">

        {tabData.map((tab, index) => (
          <StyledTab
            key={index}
            label={tab.title}/>
        ))}
      </StyledTabs>

      {tabContent}
    </React.Fragment>
  );

};

ScheduleTabs.displayName = 'ScheduleTabs';

ScheduleTabs.propTypes = {
  tabData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      layoutType: PropTypes.string,
      data: PropTypes.array,
      indexPage: PropTypes.number,
      totalPages: PropTypes.number,
      onPageChange: PropTypes.func,
    }),
  ).isRequired,
  userType: PropTypes.string.isRequired,
  defaultTab: PropTypes.number,
  filters: PropTypes.any,
  user: PropTypes.any,
  clearFilters: PropTypes.func,
};

ScheduleTabs.defaultProps = {
  defaultTab: 0,
};
