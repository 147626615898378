export const StatusMnemonic = {
  Completed: 'COMPLETED',
  Open: 'OPEN',
  Scheduled: 'SCHEDULED',
  InProgress: 'IN PROGRESS',
  Rejected: 'REJECTED',
  Overdue: 'OVERDUE',
  Approved: 'APPROVED',
  Review: 'REVIEW',
  AwaitMentor: 'AWAIT MENTOR RESPONSE',
  AwaitCoach: 'AWAIT COACH RESPONSE',
  Absent: 'ABSENT',
};

export const TaskTypeMnemonic = {
  InternCheckin: 'INTERNCHKIN',
  InternCheckout: 'INTERNCHKOUT',
  InternPlanUpcomingWeek: 'INTERNWKPLN',
  InternLessonObservation: 'INTERNLESSOBS',
  InternLessonPlan: 'INTERNLESSPLAN',
  InternLessonPreparation: 'INTERNLESSPREP',
  InternLessonPresentation: 'INTERNLESSPRES',
  InternPeerGroupDiscussion: 'INTERNPRGRPDISC',
  InternSchoolRequirements: 'INTERNSCHREQ',
  InternTutoringSession: 'INTERNTUT',
  InternExtramuralActivity: 'INTERNEXTRAMURAL',
  InternAcademicSupportRequest: 'INTERNACSUP',
  InternAcademicResults: 'NTERNACARES',
  InternSelfAssesment: 'INTERNSELFASS',
  InternWorkshopAttendance: 'INTERNWRKSHPATT',
  InternAssistantTeaching: 'INTERNASSTEACH',
  InternLessonReflection: 'INTERNLESSREF',
  MentorLessonObservation: 'MENTORLESSOBS',
};

export const InputTypeMnemonic = {
  ToggleButton: 'TGLBTN',
  DateTime: 'DTTM',
  ShortText: 'SHRTTXT',
  LongText: 'LNGTXT',
  SingleSelect: 'SNGLSLCT',
  FileUpload: 'ATTCHMNT',
  Dropdown: 'DRPDWN',
  Geolocation: 'LCTN',
  Rating: 'RTNG',
  Number: 'NMBR',
  MultiSelect: 'MLTSLCT',
  Schedular: 'SCHDLR',
  DurationPicker: 'DRTN',
  RatingLongtext: 'RTNGLNGTXT',
  MentorEvalRating: 'MENTOREVALRATING',
  InternEvalRating: 'INTERNEVALRATING',
  AbsLength: 'ABSLENGTH',
};

export const QuestionMnemonic = {
  CheckIn: 'AYAS',
  Grade: 'GRADESELECT',
};

export const QuestionProperties = {
  Group: 'GRP',
  Order: 'ORDER',
  Required: 'REQUIRED',
  Mnemonic: 'MNEMONIC',
  ParentQuestion: 'PRNTQSTN',
  Default: 'DEFAULT',
  Placeholder: 'PLCHLDR',
  Options: 'OPTIONS',
  Min: 'MIN',
  Max: 'MAX',
  RequireReview: 'REQREV',
  ChildUpdateField: 'CHDTSKUPF',
  Regex: 'REGEX',
  EvalCrit: 'EVALCRIT',
};

export const SubmitType = {
  Save: 'SAVE',
  Submit: 'SUBMIT',
};

export const TeachingPhaseMnemonic = {
  Foundation: 'Foundation',
  Intermediate: 'Intermediate',
};

export const EvalRatingIdentityGroup = {
  Mentor: 'MENTOREVALRATING',
  Intern: 'INTERNEVALRATING',
}

export const AssessmentMnemonic={
  AcademicAchiement: 'ACADACHIEV',
  Development: 'PRFPERDV',
  PedagogicalSkill: 'PEDKNOWSKILL',
  AssessmentDataAnalysis: 'ASSDATANALYSIS',
  ContentKnowledge: 'CONTKNOW',
  Reading: 'ASSREAD',
  ClassroomManagement: 'CLSSMNGMT',
  ComInterpersonalSkill: 'CMINTSKL',
  IDIIInstruction: 'DFINCINDINS',
  DiversityCompetence: 'DIVCMP',
  CulturalCompentenceIncl: 'CLTCMPINCL',
  FlexibilityAdaptability: 'FLXADPT',
  TechnologyIntegration: 'TCHINTGRTN',
  ReflectivePrac: 'RFLCTPRCTS',
  CollabTeamwork: 'CLLBRTMWK',
  EthicalProConduct: 'ETHPRFCNDCT',
}

export const AssessmentFullName={
  AcademicAchiement: 'Academic achievement',
  Development: 'Professional / Personal development',
  PedagogicalSkill: 'Pedagogical Knowledge and Skills',
  AssessmentDataAnalysis: 'Assessment & Data Analysis',
  ContentKnowledge: 'Content knowledge',
  Reading: 'Reading',
  ClassroomManagement: 'Classroom Management',
  ComInterpersonalSkill: 'Communication and Interpersonal Skills',
  IDIIInstruction: 'Differentiation, Inclusivity; Individualised Instruction',
  DiversityCompetence: 'Diversity competence',
  CulturalCompentenceIncl: 'Cultural Competency & Inclusivity',
  FlexibilityAdaptability: 'Flexibility and adaptability',
  TechnologyIntegration: 'Technology integration',
  ReflectivePrac: 'Reflective Practice',
  CollabTeamwork: 'Collaboration and teamwork',
  EthicalProConduct: 'Ethical and professional conduct',
}