import * as Sentry from '@sentry/react';

import {
  BrowserTracing
} from '@sentry/tracing';

import {
  Environment,
  InsightLevel,
  CommonSentryTagKeys,
} from 'enums';

let config = {
  dsn: undefined,
  maxBreadcrumbs: 100,
  environment: Environment.Production,
  tagKeys: CommonSentryTagKeys.map(str => str.toLowerCase()),
  enabled: true,
  enableConsoleBreadcrumbs: false,
  normalizeDepth: 10,
};

const setTags = context => {

  if (!context) {
    return;
  }

  // Clear Global Tags before setting new ones.
  for (const tagKey of config.tagKeys) {
    Sentry.setTag(tagKey, undefined);
  }

  for (const [key, value] of Object.entries(context)) {

    if (typeof key !== 'string') {
      continue;
    }

    const tagKey = key.toLowerCase();

    if (config.tagKeys.indexOf(tagKey) < 0) {
      continue;
    }

    const stringValue = typeof value === 'string'
      ? value
      : JSON.stringify(value);

    Sentry.setTag(tagKey, stringValue);
  }
};

const setExtras = context => {

  if (!context) {
    return;
  }

  const extrasContext = {};

  for (const [key, value] of Object.entries(context)) {

    if (!key || config.tagKeys.indexOf(key.toLowerCase()) > -1) {
      continue;
    }

    extrasContext[key] = value;
  }

  Sentry.setContext('Extra Data', extrasContext);
};

const parseArgs = args => {

  const ret = {
    error: undefined,
    innerErrors: [],
    message: undefined,
    data: {},
  };

  if (!Array.isArray(args)) {
    return ret;
  }

  const messages = [];

  for (const arg of args) {

    if (!arg) {
      continue;
    }

    if (arg instanceof Error && !ret.error) {

      ret.error = arg;
      continue;
    }

    if (arg instanceof Error && ret.error) {

      ret.innerErrors.push(arg);
      continue;
    }

    if (typeof arg === 'object') {

      ret.data = {
        ...ret.data,
        ...arg,
      };

      continue;
    }

    messages.push(String(arg));
  }

  if (messages.length > 0) {
    ret.message = messages.join(' ');
  }

  return ret;
};

const breadcrumb = (
  insightLevel = InsightLevel.Info, {
    context = {},
    args,
  },
) => {

  if (!config.dsn) {
    throw Error('Sentry has to be initialised before using it.');
  }

  if (context?.user) {
    Sentry.setUser({
      username: context.user,
    });
  }

  let {
    message,
    data,
  } = parseArgs(args);

  data = {
    ...context,
    ...data,
  };

  setTags(data);

  Sentry.addBreadcrumb({
    level: insightLevel,
    category: data.category || context.journey,
    message,
    data,
  });
};

export const traceInformation = ({
  context,
  args,
}) => breadcrumb(InsightLevel.Info, {
  context,
  args,
});

export const traceWarning = ({
  context,
  args,
}) => breadcrumb(InsightLevel.Warning, {
  context,
  args,
});

export const traceError = ({
  context,
  args,
}) => {

  if (!config.dsn) {
    throw Error('Sentry has to be initialised before using it.');
  }

  if (context?.user) {
    Sentry.setUser({
      username: context.user,
    });
  }

  let {
    error,
    innerErrors,
    message,
    data,
  } = parseArgs(args);

  data = {
    innerErrors,
    ...context,
    ...data,
  };

  setTags(data);
  setExtras(data);

  Sentry.captureException(error || Error(message));
};

export const tag = ({
  context,
  args,
}) => breadcrumb(InsightLevel.Log, {
  context,
  args,
});

/**
 * @param {Sentry.BrowserOptions} params
 */
export const useInsightsSentry = ({
  dsn,
  maxBreadcrumbs = config.maxBreadcrumbs,
  environment = config.environment,
  tagKeys = config.tagKeys,
  enabled = config.enabled,
  enableConsoleBreadcrumbs = config.enableConsoleBreadcrumbs,
  normalizeDepth = config.normalizeDepth,
  tracingOrigins = [],
  ...params
}) => {

  if (!dsn) {
    throw Error('A valid dsn is required to use Sentry.');
  }

  if (config.dsn !== undefined && dsn !== config.dsn) {
    environment !== Environment.Production && console.warn('Changing the dsn during a session could result in unexpected behaviour.');
  }

  // TODO: This causes issues with environment changes after first init
  if (!config.dsn) {

    Sentry.init({
      dsn,
      maxBreadcrumbs,
      environment,
      enabled,
      normalizeDepth,
      integrations: [
        new Sentry.Integrations.Breadcrumbs({
          console: enableConsoleBreadcrumbs,
          dom: true,
          fetch: true,
          history: true,
          sentry: true,
          xhr: true,
        }),
        new BrowserTracing({
          tracePropagationTargets: [
            'localhost',
            /^\//,
            ...tracingOrigins
          ]
        }),
      ],
      ...params,
    });

    const configTagKeys = [];

    if (Array.isArray(tagKeys)) {

      for (const tagKey of tagKeys) {

        if (typeof tagKey !== 'string') {
          continue;
        }

        configTagKeys.push(tagKey.toLowerCase());
      }
    }

    config = {
      dsn,
      maxBreadcrumbs,
      environment,
      tagKeys: configTagKeys,
      enabled,
      enableConsoleBreadcrumbs,
      normalizeDepth,
    };
  }

  return {
    traceInformation,
    traceWarning,
    traceError,
    tag,
  };
};

export {
  startTransaction,
  getCurrentHub,
  withProfiler,
} from '@sentry/react';

export {
  spanStatusfromHttpCode,
} from '@sentry/tracing';
