import { useQuery } from '@apollo/client';
import { QueryPersonTypes } from 'gql/person/queries';
import { useEffect, useState } from 'react';

export const usePersonTypes = () => {

  const { loading, error, data, } = useQuery(QueryPersonTypes, {
    variables: {
      params: {
        filterProperties: [
          { operator: "IN", fieldNames: ["personTypeMnemonic"], value: ["MENTOR", "INTERN", "COACH"]}
        ],
        filterLogicGate: "OR"
      }
    },
  });

  const [personTypes, setPersonTypes] = useState([]);

  useEffect(() => {
    if (!loading && data) {
      const types = data.personTypeQueryFilter.body.personTypes?.map(t => ({
        description: t.description,
        mnemonic: t.mnemonic,
        title: setPersonTypeTitle(t.mnemonic, t.title),
        id: t.id,

      })) || [];

      setPersonTypes(types);
    }
  }, [loading, data]);
  
  return {
    loading,
    error,
    data,
    personTypes
  };
}


export const setPersonTypeTitle = (mnemonic, title) => {

  if(mnemonic === 'MENTOR'){
    return 'On-Site Mentor';
  }else if(mnemonic === 'COACH'){
    return 'Online Mentor';
  }

  return title;

}
