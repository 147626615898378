import {
  styled,
} from '@mui/material';

import {
  AlignItems,
  JustifyContent,
} from 'enums';

import {
  VFillContainer,
} from 'ui/common/components/containers/VFillContainer';

export const VFillContainerVH = styled(VFillContainer)`
  align-items: ${AlignItems.Center};
  justify-content: ${JustifyContent.Center};
`;

VFillContainerVH.displayName = 'VFillContainerVH';
