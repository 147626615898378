import React from 'react';

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
  Observable,
} from '@apollo/client';

import {
  onError,
} from '@apollo/client/link/error';

import {
  getCurrentHub,
  spanStatusfromHttpCode,
  startTransaction,
} from 'insights/sentry';

import {
  useInsights,
} from 'hooks';

import {
  IdentityStatus,
  MutationFetchPolicies,
  QueryFetchPolicies,
} from 'enums';

import {
  useEnvironment,
  useIdentity,
} from 'hooks';

const getTraceId = (headers) => {

  if (!headers) {
    return;
  }

  let traceId = headers.get('x-amzn-trace-id');

  if (traceId) {
    traceId = traceId.replace('Root=', '');
  }

  return traceId;
};

const getTraceIdFromOperation = operation => {

  const context = operation.getContext();

  return getTraceId(context?.response?.headers);
};

const isThutoExpiredError = (graphQLErrors) => {

  if (!Array.isArray(graphQLErrors)) {
    return false;
  }

  const regex = /session\s+has\s+expired/i;

  for (const error of graphQLErrors) {

    if (error?.status === 401 && regex.test(error?.message)) {
      return true;
    }
  }

  return false;
};

const isGluuAuthError = networkError => networkError?.response?.status === 401 && networkError?.result?.error === 'access_denied';

const isSessionExpired = ({
  networkError,
  graphQLErrors,
}) => isGluuAuthError(networkError) || isThutoExpiredError(graphQLErrors);

export const ApolloRoot = props => {

  const {
    identityStatus,
    user,
    getAuthHeaders,
    logout,
  } = useIdentity({
    context: ApolloRoot.context,
  });

  const {
    environment,
  } = useEnvironment();

  const {
    traceInformation,
    traceError,
  } = useInsights({
    context: ApolloRoot.context,
  });

  const cache = React.useMemo(() => new InMemoryCache(), []);

  const httpLink = React.useMemo(() => new HttpLink({
    uri: environment.variables.paths.gateway.graphql,
  }), [
    environment.variables.paths.gateway.graphql
  ]);

  const authLink = React.useMemo(() => new ApolloLink(async (operation, forward) => {

    const {
      authenticated = true,
    } = operation.getContext();

    let authHeaders = {};
    let userHeaders = {};
    let additionalHeaders = {};

    if (authenticated) {
      authHeaders = await getAuthHeaders();
    }

    if (user?.id) {
      userHeaders['x-user-id'] = user.id;
    }

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...userHeaders,
        ...authHeaders,
        ...additionalHeaders,
        apikey: environment.variables.apiKey,
      },
    }));

    return forward(operation);
  }), [
    environment.variables.apiKey,
    getAuthHeaders,
    user,
  ]);

  const errorLink = React.useMemo(() => onError(({
    graphQLErrors,
    networkError,
    operation,
  }) => {

    const traceId = getTraceIdFromOperation(operation);

    const sessionExpired = isSessionExpired({
      graphQLErrors,
      networkError
    });

    const metadata = {
      gqlOperationType: operation?.query?.definitions?.[0]?.operation,
      gqlOperationName: operation?.operationName,
      traceId,
      sessionExpired,
    };

    if (Array.isArray(graphQLErrors)) {

      for (const error of graphQLErrors) {

        traceError(
          new Error(`[${metadata.gqlOperationName}]: Message: ${error.message}, Status: ${error.status}`),
          metadata,
        );

        error.traceId = traceId;
      }
    }

    if (networkError instanceof Error) {

      traceError(
        networkError,
        metadata,
      );

      networkError.traceId = traceId;
    }

    if (sessionExpired) {

      logout({
        sessionExpired: true,
      });
    }
  }), [
    logout,
    traceError,
  ]);

  const sentryTransactionLink = React.useMemo(() => new ApolloLink((operation, forward) => {

    const transaction = startTransaction({
      name: operation?.operationName,
      op: 'http',
      description: `Fetch ${operation?.operationName}`,
    });

    getCurrentHub()
      .configureScope(scope => scope.setSpan(transaction));

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'sentry-trace': `${transaction.traceId}-${transaction.spanId}-${Number(transaction.sampled)}`,
      },
    }));

    return forward(operation).map(response => {

      const status = response?.errors?.[0]?.status ?? 200;

      transaction.finish(spanStatusfromHttpCode(status));

      return response;
    });
  }), []);

  const cacheFallbackLink = React.useMemo(() => new ApolloLink((operation, forward) => {

    if (!forward) {
      return null;
    }

    return new Observable(observer => {

      const subscription = forward(operation)
        .subscribe({
          next: result => observer.next(result),
          error: networkError => {

            // Each query must explicitly opt-in to allowing cache fallback to avoid unexpected behaviour.
            const { useCacheOnError } = operation.getContext();

            if (networkError && useCacheOnError === true) {

              try {

                const result = cache.read({
                  query: operation.query,
                  variables: operation.variables,
                });

                if (result) {
                  observer.next({
                    data: result
                  });
                  // Error should still be reported so it triggers the errorLink for reporting to insights.
                  observer.error(networkError);
                  observer.complete();
                  return;
                }
              } catch(err) {

                const traceId = getTraceIdFromOperation(operation);

                const metadata = {
                  gqlOperationType: operation?.query?.definitions?.[0]?.operation,
                  gqlOperationName: operation?.operationName,
                  traceId,
                };

                traceError(
                  err,
                  'Error reading cache after network error',
                  metadata
                );
              }
            }

            observer.error(networkError);
          },
          complete: observer.complete.bind(observer)
        });

      return () => {

        if (subscription) {
          subscription.unsubscribe();
        }
      };
    });
  }), [
   cache,
   traceError,
  ]);

  const client = React.useMemo(() => new ApolloClient({
    cache,
    link: from([
      authLink,
      errorLink,
      cacheFallbackLink,
      sentryTransactionLink,
      httpLink,
    ]),
    defaultOptions: {
      watchQuery: {
        /*
          notifyOnNetworkStatusChange is required for the loading state to be updated whilst a refetch is in progress.
        */
        notifyOnNetworkStatusChange: true,
        fetchPolicy: QueryFetchPolicies.CacheAndNetwork,
      },
      mutate: {
        fetchPolicy: MutationFetchPolicies.NoCache,
      },
    },
  }), [
    cache,
    authLink,
    errorLink,
    cacheFallbackLink,
    sentryTransactionLink,
    httpLink,
  ]);

  React.useEffect(() => {

    if (identityStatus === IdentityStatus.LoggedOut) {

      try {

        traceInformation('Clearing Apollo Cache');

        client.clearStore();

      } catch (error) {
        traceError(error);
      }
    }
  }, [
    identityStatus,
    client,
    traceInformation,
    traceError,
  ]);

  return (

    <ApolloProvider
      client={client}>

      {props.children}
    </ApolloProvider>
  );
};

ApolloRoot.displayName = 'ApolloRoot';

ApolloRoot.context = {
  component: ApolloRoot.displayName,
};
