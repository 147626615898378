import { useQuery } from '@apollo/client';
import { mapTaskFromQuery } from 'common';
import { QuerySchedule } from 'gql/schedule/queries';
import { useEffect, useState } from 'react';

export const useSchedule = (scheduleId) => {

  const { loading, error, data, } = useQuery(QuerySchedule, {
    variables: {
      params:  {
        filterProperties: [
          {
          operator: "IN",
          fieldNames: ["scheduleId"],
          value: [scheduleId]
          }
        ],
        filterLogicGate: "OR"
      },
    },
    skip: scheduleId == null,
  });

  const [schedule, setSchedule] = useState();

  useEffect(() => {
    if (!loading && data) {
      if (data.scheduleQueryFilter?.body?.schedules && data.scheduleQueryFilter?.body?.schedules[0]) {
        const ts = data.scheduleQueryFilter?.body?.schedules[0];
        setSchedule({
          id: ts.id,
          week: ts.week,
          year: ts.year,
          tasks: ts.tasks.map((task) => mapTaskFromQuery(task)),
        });
      }
    }
  }, [loading, data]);
  
  return {
    loading,
    error,
    data,
    schedule
  };
}
