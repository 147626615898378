import {
  Asset,
} from 'enums';

import
  PropTypes
from 'prop-types';


import {
  HFillContainerV,
  HeadlineLarge,
  PrimaryButton,
  ShortText,
  Feedback,
  BodyMedium,
  TitleMedium,
  Spinner,
  VContainerVH
} from 'ui/common';


import { 
  styled
 } from '@mui/material';

import {
  useSchoolUpsert,
} from 'hooks';

import React, { useState } from 'react';

import {
  useNavigate,
} from 'react-router-dom';

import { 
  buildSchoolUpsertVariables,
} from 'common/school';


const StyledHeadline = styled(HeadlineLarge)`
  margin-top: 0.5rem;
`;

const StyledErrorBody = styled(BodyMedium)(({ theme }) => `
  background-color: ${theme.palette.error.light};
  padding: 1em;
  margin-bottom: 1em;
`,
);

export const School = props => {

  const navigate = useNavigate();

  const [upsertSchool, {loading: upsertSchoolLoading, data: upsertSchoolData}] = useSchoolUpsert();
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [newSchoolDetails, setNewSchoolDetails] = useState({});


  const generateMnemonic = (title) => {
    return title.replace(/\s+/g, '').replace(/[aeiou]/ig, '').toUpperCase();
  }

  const handleFieldChange = (question, value) => {

      setNewSchoolDetails((prevState) => {
        const newFields = {
          ...prevState,
          [question]: value
        };

      setIsFormValid(
          (!!newFields['title']) &&
          (!!newFields['address'] ) &&
          (!!newFields['telNo']) &&
          (!!newFields['description']  )
        );

        return newFields;

      });

  };

  const handleOnCreate = () => {  

      let upsertProperties = {
        title: newSchoolDetails['title'],
        mnemonic: generateMnemonic(newSchoolDetails['title']),
        address: newSchoolDetails['address'],
        telNo: newSchoolDetails['telNo'],
        description: newSchoolDetails['description']  
      }

      upsertSchool(buildSchoolUpsertVariables([upsertProperties]));

  };

  if (upsertSchoolLoading) {
    return (
    <VContainerVH>
      <Spinner text='Loading...'/>
    </VContainerVH>
    );
  }


  return (

    <React.Fragment>
      { !upsertSchoolLoading &&  upsertSchoolData?.schoolUpsert?.body?.upsertResult?.[0]?.ok == true &&
        <Feedback 
          imageUrl={Asset.Image.Other.BookStack}
          headerText={'School Registered'}
          bodyText={'Great job registering a new school!'}
          infoText={"The school has been successfully created."}
          buttonText='Go Back'
          buttonAction={() => navigate('/home')}/>
      } 


      <StyledHeadline>
          Register a new school
      </StyledHeadline>

      <ShortText
        key='schoolTitle'
        title='Provide the title name of the school'
        placeholder='School Title'
        label='School Title'
        value={newSchoolDetails.title}
        onChange={(event) => {
          handleFieldChange('title', event.target.value);
        }}
      />

      <ShortText
        key='schoolAddress'
        title='Provide the address of the school'
        placeholder='School Address'
        label='School Address'
        value={newSchoolDetails.address}
        onChange={(event) => {
          handleFieldChange('address', event.target.value);
        }}
      />
      
      <ShortText
        key='schoolTelno'
        title='Provide the telno of the school'
        placeholder='School Telno'
        label='School Telno'
        value={newSchoolDetails.telNo}
        onChange={(event) => {
          handleFieldChange('telNo', event.target.value);
        }}
      />

    <ShortText
        key='schoolDesc'
        title='Provide a short description for the school'
        placeholder='School Description'
        label='School Description'
        value={newSchoolDetails.description}
        onChange={(event) => {
          handleFieldChange('description', event.target.value);
        }}
      />

      {(!upsertSchoolLoading && upsertSchoolData?.schoolUpsert?.body?.ok == false) && 
        <React.Fragment>
          <StyledErrorBody>
            <TitleMedium color='error'>
              {upsertSchoolData?.schoolUpsert.body.message ? upsertSchoolData?.schoolUpsert.body.message : "We encountered a hiccup while registering the new school." } 
            </TitleMedium>
          </StyledErrorBody>
        </React.Fragment>
      }

      <HFillContainerV>
        <PrimaryButton
          text='Submit'
          onClick={() => handleOnCreate()}
          disabled={!isFormValid}
        />
      </HFillContainerV>

      
      </React.Fragment>
  );
};

School.displayName = 'Admin School Creation';

School.propTypes = {
  mode: PropTypes.string,
};

School.defaultProps = {
  mode: ''
};