import {
  styled,
} from '@mui/material';

import {
  VBox,
} from 'ui/common/components/boxes/VBox';

export const VFillBox = styled(VBox)`
  flex: 1 1 auto;
`;

VFillBox.displayName = 'VFillBox';
