import React from 'react';

import PropTypes from 'prop-types';

import {
  ButtonGroup,
  styled,
} from '@mui/material';

import {
  isString,
} from 'common';

import {
  VContainerV,
} from 'ui/common/components/containers';

import {
  BodyMedium,
} from 'ui/common/components/typography';

import {
  SecondaryButton,
} from 'ui/common/components/buttons';

const StyledButton = styled(SecondaryButton, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({
  theme,
  isActive,
}) => ({
  backgroundColor: isActive && theme.palette.primary.p90,
  color: isActive
    ? theme.palette.primary.p20
    : theme.palette.secondary.s40,
  borderColor: theme.palette.secondary.s60,
  whiteSpace: 'normal',
  fontSize: '0.7rem',
  padding: '10px',

  '&:focus': {
    backgroundColor: isActive && theme.palette.primary.p90,
  },
  '&:active': {
    backgroundColor: isActive && theme.palette.primary.p90,
  },
}));

const BaseContainer = styled(VContainerV)`
  padding: 0;
  margin-bottom: 32px;
`;

const ButtonContainer = styled(ButtonGroup)`
  padding: 0;
  margin-top: 32px;
`;

export const ToggleButton = ({
  options,
  title,
  value,
  onChange,
}) => {

  const renderButtons = () => {

    const buttons = [];

    for (const option of options) {

      if (isString(option)) {

        buttons.push(
          <StyledButton
            key={option}
            isActive={option === value}
            onClick={() => onChange({
              target: {
                value: option,
              },
            })}>

            {option}
          </StyledButton>,
        );
      }

      if (!option?.value || !option?.display) {
        continue;
      }

      buttons.push(
        <StyledButton
          key={option.value}
          isActive={option.value === value}
          onClick={() => onChange({
            target: {
              value: option.value,
            },
          })}>

          {option.display}
        </StyledButton>,
      );
    }

    return buttons;
  };

  if (!Array.isArray(options) || options.length === 0) {
    return <React.Fragment/>;
  }

  return (

    <BaseContainer>

      <BodyMedium>

        {title}
      </BodyMedium>

      <ButtonContainer
        color="primary"
        variant="outlined">

        {renderButtons()}
      </ButtonContainer>
    </BaseContainer>
  );
};

ToggleButton.displayName = 'ToggleButton';

ToggleButton.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        display: PropTypes.string,
      }),
    ]),
  ).isRequired,
};

ToggleButton.defaultProps = {
  title: '',
  value: '',
  onChange: (event) => console.log(`Value Changed to: ${event.target.value}`),
};
