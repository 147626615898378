import {
  JourneyName,
  ScreenName,
} from 'enums';

import {
  BodyMedium,
  HeadlineLarge,
  VContainer,
} from 'ui/common';

import React from 'react';

import {
  useIdentity,
} from 'hooks';


import {
  styled,
} from '@mui/material';

import { TaskTabs } from './TaskTabs';

const StyledHeadline = styled(HeadlineLarge)`
  margin-top: 2rem;
`;

const StyledBody = styled(BodyMedium)`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const MyTasks = () => {

  const { user } = useIdentity(MyTasks.context);

  return (

    <React.Fragment>

      <VContainer>
        <StyledHeadline>
          TASK LIST
        </StyledHeadline>

        <StyledBody>
          Explore your complete record of tasks, submissions, and feedback.
        </StyledBody>

        <TaskTabs
          user={user}
          targetUser={user}/>
      </VContainer>

    </React.Fragment>
  );
};

MyTasks.displayName = 'MyTasks';

MyTasks.context = {
  screen: ScreenName.MyTasks,
  journey: JourneyName.MyTasks,
  component: MyTasks.displayName,
};
