import {
  DefaultIdentity,
  IdentityStatus,
  StorageKeys,
} from 'enums';

import {
  getItem,
  setItem,
  removeItem,
} from 'api/storage';

/**
 * @param {Object} params
 * @param {UserIdentity} params.identity
 */
export const storeIdentity = async ({
  identity,
}) => {

  if (identity.tokens?.id && !identity.tokens?.refresh){
    return;
  }

  return await setItem({
  session: true,
  key: StorageKeys.Identity,
  value: {
    tokens: {
      refresh: identity.tokens?.refresh || 'ish',
      id: identity.tokens?.id,
      sessionId: identity.tokens?.sessionId,
    },
    user: identity.user,
    identityStatus: identity.identityStatus,
  },
})};

export const getStoredIdentity = async () => {

  const storedIdentity = await getItem({
    session: true,
    key: StorageKeys.Identity,
    fallback: DefaultIdentity,
  });

  const identityStatus = Object.values(IdentityStatus).includes(storedIdentity.identityStatus)
    ? storedIdentity.identityStatus
    : IdentityStatus.LoggedOut;

  return {
    ...storedIdentity,
    identityStatus,
  };
};

export const removeStoredIdentity = async () => {

  await removeItem({
    session: true,
    key: StorageKeys.Identity,
  });
};
