import
  PropTypes
from 'prop-types';

import {
  styled,
  Button,
} from '@mui/material';

import {
  ButtonVariant,
} from 'enums';

import { TitleSmall } from '../typography/';

const StyledButton = styled(Button)`
  white-space: nowrap;
  width: 100%;
  padding: 10px 24px; 
  border-radius: 100px;
`;

const StyledText = styled(TitleSmall)(({theme}) =>`
  color: ${theme.palette.primary.p99};
`);

export const PrimaryButton = props =>
  <StyledButton
    {...props}
    variant={ButtonVariant.Contained}>
    <StyledText>
      {props.text || props.children}
    </StyledText>
  </StyledButton>
;

PrimaryButton.displayName = 'PrimaryButton';

PrimaryButton.propTypes = {
  text: PropTypes.string,
};

PrimaryButton.defaultProps = {
  text: '',
};
