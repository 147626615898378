import React from 'react';
import PropTypes from 'prop-types';

import {
  styled,
} from '@mui/material';

import {
  VFillContainerV,
} from 'ui/common/components/containers';

import {
  HBoxV,
} from 'ui/common/components/boxes';

import {
  LabelMedium,
  TitleSmall,
} from 'ui/common/components/typography';

import {
  PrimaryButton,
} from 'ui/common/components/buttons';

import { useNavigate } from 'react-router-dom';

const TaskBox = styled(HBoxV)(
  ({ theme }) => `
  border-radius: 12px;
  padding: 12px;
  margin-top: 12px;
  `,
);

const TaskButton = styled(PrimaryButton)(
  ({ theme }) => `
  width: unset;
  background-color: ${theme.palette.primary.p40};
  `,
);

const TitleText = styled(TitleSmall)`
  margin: 0.25rem 0;
`;

export const InternCard = ({
  id,
  name,
  school,
  personType,
}) => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/task-list/intern?id=${id}`);
  }

  return (

    <React.Fragment>

      <TaskBox backgroundColor="primary.p100">

        <VFillContainerV>
          <LabelMedium color={'primary'}>{school}</LabelMedium>
          {
            personType &&
            <LabelMedium color={'secondary'}>{personType}</LabelMedium>
          }        
          <TitleText color="primary.p10">{name}</TitleText>
        </VFillContainerV>
        
          <TaskButton text={'View'} onClick={handleClick} />
        
      </TaskBox>

    </React.Fragment>
  );
};


InternCard.displayName = 'InternCard';

InternCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  school: PropTypes.string,
  personType: PropTypes.string
};

InternCard.defaultProps = {
  school: ''
};
