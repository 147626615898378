import {
  styled,
} from '@mui/material';

import {
  AlignItems,
  JustifyContent,
} from 'enums';

import {
  VContainer,
} from 'ui/common/components/containers/VContainer';

export const VContainerVH = styled(VContainer)`
  align-items: ${AlignItems.Center};
  justify-content: ${JustifyContent.Center};
`;

VContainerVH.displayName = 'VContainerVH';
