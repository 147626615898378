import {
  styled,
} from '@mui/material';

import {
  JustifyContent,
} from 'enums';

import {
  VFillBox,
} from 'ui/common/components/boxes/VFillBox';

export const VFillBoxV = styled(VFillBox)`
  justify-content: ${JustifyContent.Center};
`;

VFillBoxV.displayName = 'VFillBoxV';
