import PropTypes from 'prop-types';

import {
  styled,
  TextField as TextFieldProto,
  MenuItem,
} from '@mui/material';

import { InfoText } from '../InfoText';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UserPersonDetailsAtom } from 'atoms';

import {
  QuestionMnemonic,
  TeachingPhaseMnemonic,
} from 'enums';

const StyledTextField = styled(TextFieldProto)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p99,
  marginBottom: '1rem',
}));

export const Dropdown = props => {

  const {
    title,
    description,
    groupName,
    options,
    mnemonic,
    onChange,
    questionId,
    questionComments,
    upsertCommentLoading,
    handleComment,
    userId,
    ...other
  } = props;

  const person = useRecoilValue(UserPersonDetailsAtom);
  const [ filteredOptions, setFilteredOptions ] = React.useState(options);

  const onChangeCallback = React.useCallback((value) => {
    onChange({target: {value: value}});
  }, [onChange]);

  React.useEffect(() => {
    if (mnemonic === QuestionMnemonic.Grade && filteredOptions.length === options.length) {
      if (person.teachingPhaseMnemonic === TeachingPhaseMnemonic.Foundation) {
        
        if (!options.some((o) => o === other.value) ) {
          onChangeCallback(options[0]);
        }
        setFilteredOptions(options.slice(0, 4));
        
      } else {

        if (!options.some((o) => o === other.value) ) {
          onChangeCallback(options[4]);
        }
        setFilteredOptions(options.slice(4, options.length));
      }
    }
    else if(mnemonic !== QuestionMnemonic.Grade ){
      setFilteredOptions(options);
    }
  }, [options, filteredOptions, mnemonic, person, other.value, onChangeCallback]);

  return (
    <React.Fragment>

      <InfoText
        title={title}
        description={description}
        groupName={groupName}
        questionId={questionId}
        questionComments={questionComments}
        upsertCommentLoading={upsertCommentLoading}
        handleComment={handleComment}
        userId={userId}
      />

      <StyledTextField
        {...other}
        onChange={(e) => onChangeCallback(e.target.value)}
        select>
        {filteredOptions.map((option) =>
          <MenuItem key={option} value={option}>{option}</MenuItem>
        )}
      </StyledTextField>

    </React.Fragment>
  );
};

Dropdown.displayName = 'DropDown';

Dropdown.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  groupName: PropTypes.string,
  label: PropTypes.string,
  mnemonic: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  questionId: PropTypes.any,
  questionComments: PropTypes.any,
  upsertCommentLoading: PropTypes.bool,
  handleComment: PropTypes.any,
  userId: PropTypes.string,
};

Dropdown.defaultProps = {
  id: `dropdown-${Math.random()}`,
  title: '',
  description: '',
  groupName: '',
  mnemonic: '',
  value: '',
  label: 'Label',
  onChange: () => console.log('Dropdown Changed'),
  userId: '',
};
