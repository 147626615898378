import { useQuery } from '@apollo/client';
import { QueryPeople } from 'gql/person/queries';
import { useEffect, useState } from 'react';

export const usePersons = (personTypes) => {

  const { loading, error, data, } = useQuery(QueryPeople, {
    variables: {
      params: {
        filterProperties:[{
            operator: 'IN',
            fieldNames: ['personTypeMnemonic'],
            value: personTypes,
        }],
        filterLogicGate: 'OR'
      }
    },
  });

  const [persons, setPersons] = useState([]);

  useEffect(() => {
    if (!loading && data) {
      const details = data.personQueryFilter.body?.persons?.map(t => ({
        id: t.id,
        givenName: t.givenName,
        surname: t.surname,
        userType: t.personType.mnemonic,
        schools: t.schools?.map((school) =>  {school.title, school.id, school.description}),

      })) || [];

      setPersons(details);
    }
  }, [loading, data]);
  
  return {
    loading,
    error,
    data,
    persons
  };
}
