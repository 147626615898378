import {
  AuthorizationRequest,
} from '@openid/appauth';

import {
  getAuthCodeFromLocation,
  getAuthorizationServiceConfiguration,
  getTokenWithAuthCode,
  hasAuthCodeInLocation,
  oidcLogin,
  oidcLogout,
  refreshOidcTokens,
} from 'api';

import {
  useEnvironment,
} from 'hooks/useEnvironment';

/**
 * @returns {AuthenticationHook}
 */
export const useOIDCAuthentication = () => {

  const {
    environment,
  } = useEnvironment();

  const redirectUri = environment.variables.paths.ui.redirectUri;

  /**
   * This login function should be called twice. Once for the redirection to the OIDC provider, and
   * thereafter again after the user is redirected back to this app with the auth code.
   *
   * Whatever state needs to be preserved should be passed via the `state` parameter as a string.
   * This state will be returned via the `extras` parameter when this function is called for the
   * second time.
   * @type {loginCallback}
   */
  const login = async ({
    state,
  } = {}) => {

    const authorizationServiceConfiguration = await getAuthorizationServiceConfiguration(environment.variables.paths.idp.openIdIssuer);

    // If no auth code is present in the query parameters then we need to redirect the user to the
    // OIDC login page.
    if (!hasAuthCodeInLocation()) {

      return oidcLogin({
        authorizationServiceConfiguration,
        clientId: environment.variables.clientId,
        redirectUri: redirectUri,
        scope: environment.variables.scope,
        extras: {
          access_type: 'offline',
          response_mode: 'query',
        },
        responseType: AuthorizationRequest.RESPONSE_TYPE_CODE,
        state,
        timeout: 60_000,
      });
    }

    const {
      sessionId,
      authCode,
      state: responseState,
      codeVerifier,
    } = await getAuthCodeFromLocation();

    const tokenResponse = await getTokenWithAuthCode({
      clientId: environment.variables.clientId,
      redirectUri: redirectUri,
      authorizationServiceConfiguration,
      authCode: authCode,
      codeVerifier: codeVerifier,
    });

    return {
      success: true,
      tokens: {
        sessionId,
        id: tokenResponse.idToken,
        access: tokenResponse.accessToken,
        refresh: tokenResponse.refreshToken,
        expiresIn: tokenResponse.expiresIn,
        issuedAt: tokenResponse.issuedAt,
      },
      extras: {
        state: responseState,
      },
    };
  };

  /**
   * @type {logoutCallback}
   */
  const logout = async (tokens) => {

    const authorizationServiceConfiguration = await getAuthorizationServiceConfiguration(environment.variables.paths.idp.openIdIssuer);
    
    const url = new URL(authorizationServiceConfiguration.endSessionEndpoint);
    url.searchParams.set('logout_uri', redirectUri);
    url.searchParams.set('client_id', environment.variables.clientId);

    window.location.assign(url);

    await oidcLogout({
      authorizationServiceConfiguration,
      logoutRedirectUri: redirectUri,
      sessionId: tokens.sessionId,
    });
  };

  /**
   * @type {refreshAuthenticationCallback}
   */
  const refreshAuthentication = async (tokens) => {

    const authorizationServiceConfiguration = await getAuthorizationServiceConfiguration(environment.variables.paths.idp.openIdIssuer);

    const tokenResponse = await refreshOidcTokens({
      clientId: environment.variables.clientId,
      redirectUri: redirectUri,
      refreshToken: tokens.refresh,
      authorizationServiceConfiguration,
    });

    return {
      ...tokens,
      id: tokens.id,
      access: tokenResponse.accessToken,
      refresh: tokenResponse.refreshToken,
      expiresIn: tokenResponse.expiresIn,
      issuedAt: tokenResponse.issuedAt,
    };
  };

  /**
   * @type {getAuthHeadersCallback}
   */
  const getAuthHeaders = tokens => ({
    Authorization: `Bearer ${tokens.access}`,
    apikey: environment.variables.apiKey,
  });

  /**
   * @type {logoutCallback}
   */
  const resetPassword = async () => {
    
    const authorizationServiceConfiguration = await getAuthorizationServiceConfiguration(environment.variables.paths.idp.openIdIssuer);

    // A bit of a hack to get the appropriate domain to trigger the forgot password journey in Cognito
    const authDomain = authorizationServiceConfiguration.endSessionEndpoint.replace('logout', 'forgotPassword')
    
    const url = new URL(authDomain);
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('client_id', environment.variables.clientId);
    url.searchParams.set('redirect_uri', redirectUri);

    window.location.assign(url);

  };

  return {
    login,
    logout,
    refreshAuthentication,
    getAuthHeaders,
    forgotPassword: () => {throw Error('Not implemented')},
    resetPassword,
  };
};
