export const KnownUnhandledErrors = [
  'Load failed',
  'Fetch is aborted',
  'AbortError: AbortError',
];

export const KnownFirebaseErrors = [
  'Load failed',
  'TypeError: cancelled',
];

export const sentryBeforeSend = event => {

  if (!Array.isArray(event?.exception?.values)) {
    return event;
  }

  for (const error of event.exception.values) {

    if (error.type === 'FirebaseError') {
      return null;
    }

    if (!error?.mechanism || error.mechanism.handled) {
      continue;
    }

    if (KnownUnhandledErrors.includes(error.value)) {
      error.mechanism.handled = true;
    }

    if (KnownFirebaseErrors.includes(error.value)) {

      const {
        breadcrumbs,
      } = event;
      const startIndex = breadcrumbs.length - 1;

      let latestHttpBreadcrumb;

      for (let index = startIndex; index >= 0; index--) {

        const {
          type,
        } = breadcrumbs[index];

        if (type === 'http') {

          latestHttpBreadcrumb = breadcrumbs[index];
          break;
        }
      }

      if (latestHttpBreadcrumb?.data?.url.includes('firebase')) {
        return null;
      }
    }
  }

  return event;
};

export const sentryBeforeBreadcrumb = (breadcrumb, hint) => {

  try {

    if (breadcrumb.category !== 'fetch' || typeof breadcrumb.data?.url !== 'string' || !breadcrumb.data.url.includes('/graphql')) {
      return breadcrumb;
    }

    const requestBody = JSON.parse(hint?.input?.[1]?.body || '{}');

    if (requestBody?.operationName) {
      breadcrumb.data.operationName = requestBody.operationName;
    }

    return breadcrumb;
  } catch {
    return breadcrumb;
  }
};
