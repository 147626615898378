/**
 * Sets how many tasks are displayed per page, app wide.
 * @type {number}
 */
export const TasksPerPage = 20;

/**
 * Sets how many people are displayed per page, app wide.
 * @type {number}
 */
export const PeoplePerPage = 1000;
