export const serializeValue = (value = null) => JSON.stringify(value);

export const deserializeValue = (serializedValue = null) => JSON.parse(serializedValue);

export const getStorageKeys = async ({
  session = false,
} = {}) => {

  if (session) {
    return Object.keys(sessionStorage);
  }

  return Object.keys(localStorage);
};

export const clear = async ({
  session = false,
} = {}) => {

  if (session) {
    sessionStorage.clear();
    return;
  }

  localStorage.clear();
};

export const setItem = async ({
  key,
  value,
  session = false,
}) => {

  const serializedValue = serializeValue(value);

  if (session) {
    sessionStorage.setItem(key, serializedValue);
    return;
  }

  localStorage.setItem(key, serializedValue);
};

export const getItem = async ({
  key,
  fallback,
  session = false,
}) => {

  try {

    if (session) {
      const serializedValue = sessionStorage.getItem(key);

      return deserializeValue(serializedValue) ?? fallback;
    }

    const serializedValue = localStorage.getItem(key);

    return deserializeValue(serializedValue) ?? fallback;
  } catch {
    return fallback;
  }
};

export const removeItem = async ({
  key,
  session = false,
}) => {

  if (session) {

    sessionStorage.removeItem(key);
    return;
  }

  localStorage.removeItem(key);
};
