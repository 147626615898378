import React from 'react';

import {
  useNavigate,
} from 'react-router-dom';

import {
  styled,
} from '@mui/material';

import {
  JourneyName,
  ScreenName,
  IdentityGroupType,
  Asset,
  SpinnerType,
} from 'enums';

import {
  getCurrentDayRangeFormatted,
  getWeekRange,
} from 'common';

import {
  useTaskList,
  useIdentity,
} from 'hooks';

import {
  Scene,
  TitleMedium,
  ScheduleTabs,
  VFillContainer,
  HFillContainerV,
  TextButton,
  DashboardBanner,
  Feedback,
} from 'ui/common';

import {
  tasksInternQueryParams,
} from 'gql/task/queryParams';

const TitleContainer = styled(HFillContainerV)`
  padding: 2rem 0 1rem 0;
  justify-content: space-between;
`;

const StyledBaseContainer = styled(VFillContainer)`
  padding: 0 0.5rem;
  flex: 0;
`;

export const InternHome = () => {

  const [taskListDailyPage, setTaskListDailyPage] = React.useState(1);
  const [taskListWeeklyPage, setTaskListWeeklyPage] = React.useState(1);

  const { user } = useIdentity({
    context: InternHome.context,
  });

  const userType = user.group || IdentityGroupType.Intern;

  // Current tasks
  const taskListDailyParams = React.useMemo(() => tasksInternQueryParams({
    userId: user.id,
    dateRange: getCurrentDayRangeFormatted(),
    page: taskListDailyPage,
  }), [
    user.id,
    taskListDailyPage,
  ]);

  const {
    loading: loadingDaily,
    error: errorDaily,
    taskList: taskListDaily,
    pageInfo: pageInfoDaily,
    refetch: refetchDaily,
  } = useTaskList({
    queryParams: taskListDailyParams,
    userType: userType,
  });

  // Weekly tasks
  const taskListWeeklyParams = React.useMemo(() => tasksInternQueryParams({
    userId: user.id,
    dateRange: getWeekRange().currentWeek,
    page: taskListWeeklyPage,
  }), [
    user.id,
    taskListWeeklyPage,
  ]);

  const {
    loading: loadingWeekly,
    error: errorWeekly,
    taskList: taskListWeekly,
    pageInfo: pageInfoWeekly,
    refetch: refetchWeekly,
  } = useTaskList({
    queryParams: taskListWeeklyParams,
    userType: userType,
  });

  const tabData = [
    {
      title: 'Current',
      layoutType: 'day',
      data: taskListDaily,
      indexPage: taskListDailyPage,
      totalPages: pageInfoDaily?.totalPages || 1,
      onPageChange: (page) => setTaskListDailyPage(page),
    },
    {
      title: 'Weekly',
      layoutType: 'week',
      data: taskListWeekly,
      indexPage: taskListWeeklyPage,
      totalPages: pageInfoWeekly?.totalPages || 1,
      onPageChange: (page) => setTaskListWeeklyPage(page),
    },
  ];

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/add-task');
  };

  const handleRefresh = () => {
    refetchDaily();
    refetchWeekly();
  };

  return (

    <Scene
      loading={loadingDaily || loadingWeekly}
      spinnerType={SpinnerType.Modal}
      headerVisible={true}
      footerVisible={true}>

      <StyledBaseContainer>

        <React.Fragment>

          <DashboardBanner
            userId={user.id}
            userType={userType}/>

          {!errorDaily && !errorWeekly &&

            <React.Fragment>

              <TitleContainer>

                <TitleMedium>
                  TASK DASHBOARD
                </TitleMedium>

                <TextButton
                  text="+ Add Task"
                  onClick={handleClick}/>
              </TitleContainer>

              <ScheduleTabs
                tabData={tabData}
                userType={userType}
                user={user}/>

            </React.Fragment>
          }

          {(errorDaily || errorWeekly) &&

            <Feedback
              imageUrl={Asset.Image.Other.BrokenPencil}
              headerText={'Something Went Wrong!'}
              bodyText={'We encountered a hiccup while fetching your tasks. Not to worry, though – we\'re on it!'}
              infoText={'Please try loading your task again. If the issue persists, reach out to us for support'}
              type={'Error'}
              buttonText={'Try Again'}
              buttonAction={() => handleRefresh()}/>
          }
        </React.Fragment>
      </StyledBaseContainer>
    </Scene>
  );
};

InternHome.displayName = 'InternHome';

InternHome.context = {
  screen: ScreenName.Home,
  journey: JourneyName.Home,
  component: InternHome.displayName,
};
