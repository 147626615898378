import {
  styled,
} from '@mui/material';

import {
  JustifyContent,
} from 'enums';

import {
  HFillContainer,
} from 'ui/common/components/containers/HFillContainer';

export const HFillContainerH = styled(HFillContainer)`
  justify-content: ${JustifyContent.Center};
`;

HFillContainerH.displayName = 'HFillContainerH';
