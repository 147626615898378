import PropTypes from 'prop-types';

import React from 'react';
import { InfoText } from '../../InfoText';
import { format, getDay } from 'date-fns';
import { ScheduleCard } from './ScheduleCard';
import { TitleMedium } from 'ui/common/components/typography';
import { styled } from '@mui/material';
import { getDateRangeLabelFromWeek } from 'common';
import { DefaultSchedule, InputTypeMnemonic, StatusMnemonic } from 'enums';
import { ScheduleTask } from './ScheduleTask';
import { useUpcomingSchedules } from 'hooks';

const StyledDayTitle = styled(TitleMedium)`
  margin-top: 1rem;
`;

export const SchedulerInput = props => {

  const { title, description, groupName, value, onChange, setIsolatedField } = props;

  const [ editSchedule, setEditSchedule ] = React.useState(DefaultSchedule);
  
  const [ getUpcomingSchedules, { loading: loadingSchedules, currentSchedule, nextWeekSchedule }] = useUpcomingSchedules();

  const [weekDate, setWeekDate] = React.useState();
  const [selectedTask, setSelectedTask] = React.useState();
  const [groupedTasks, setGroupedTasks] = React.useState();

  const getUpcomingSchedulesCallback = React.useCallback(() => {
    getUpcomingSchedules();
  }, [getUpcomingSchedules]);

  const handleTasksForEditScheduleCallback = React.useCallback((scheduledTasks) => {
    const groupMap = {};

    scheduledTasks.forEach(task => {
      const day = getDay(task.scheduledEndTimestamp); 
      if (groupMap[day]) {
        groupMap[day].tasks.push(task);
      } else {
        groupMap[day] = {
          day: day,
          groupName: format(task.scheduledEndTimestamp, 'EEEE'),
          tasks: [task],
        }
      }
    });

    const groupedArray = Object.values(groupMap);

    groupedArray.sort((a, b) => a.day - b.day);

    setGroupedTasks(groupedArray);
  }, [setGroupedTasks]);

  const handleEditScheduleCallback = React.useCallback((editSchedule) => {
    handleTasksForEditScheduleCallback(editSchedule.tasks);
    onChange(editSchedule.id);
    setWeekDate(getDateRangeLabelFromWeek(editSchedule.week));
  }, [handleTasksForEditScheduleCallback, onChange, setWeekDate]);

  React.useEffect(() => {
    if (!loadingSchedules && (currentSchedule.id == null || nextWeekSchedule.id == null)) {
      getUpcomingSchedulesCallback();
    } else if (!loadingSchedules && currentSchedule.id && nextWeekSchedule.id) {
      
      if (editSchedule.id) {
        handleEditScheduleCallback(editSchedule);
      }
      else if (currentSchedule?.weeklyPlanTask?.id === value || ![StatusMnemonic.Approved, StatusMnemonic.Completed].includes(currentSchedule?.weeklyPlanTask?.statusMnemonic)) {
        setEditSchedule(currentSchedule);
        handleEditScheduleCallback(editSchedule);
      } else if (nextWeekSchedule?.weeklyPlanTask?.id === value || ![StatusMnemonic.Approved, StatusMnemonic.Completed].includes(nextWeekSchedule?.weeklyPlanTask?.statusMnemonic)) {
        setEditSchedule(nextWeekSchedule);
        handleEditScheduleCallback(editSchedule);
      }
    }

  }, [loadingSchedules, currentSchedule, nextWeekSchedule, getUpcomingSchedulesCallback, editSchedule, handleEditScheduleCallback, value]);

  const handleTaskButton = (task) => {
    setSelectedTask(task);
    setIsolatedField(InputTypeMnemonic.Schedular);
  };

  const clearSelectedTask = () => {
    setSelectedTask(undefined);
    setIsolatedField(undefined);
  }

  const handleScheduleStateChange = (prev, task) => {
    const newTasks = prev.tasks.filter(t => t.id !== task.id).concat([task]);

    const newValue = {
      ...prev,
      tasks: newTasks,
    };

    return newValue;
  };

  const onTaskSave = (task) => {

    setEditSchedule((prev) => handleScheduleStateChange(prev, task));
    clearSelectedTask();
  };

  if (selectedTask) {
    return (
      <ScheduleTask
        task={selectedTask}
        clearSelectedTask={clearSelectedTask}
        onTaskSave={onTaskSave}
      />
    );
  }

  return (
    <React.Fragment>
      <InfoText title={title} description={description || weekDate} groupName={groupName}/>

      { groupedTasks &&
        groupedTasks.map((group) => (

          <React.Fragment key={group.groupName}>

          <StyledDayTitle>{group.groupName}</StyledDayTitle>

            {group.tasks.map((task) => (
              <ScheduleCard
                key={task.id}
                id={task.id}
                name={task.title}
                scheduledStartTimestamp={task.scheduledStartTimestamp}
                scheduledEndTimestamp={task.scheduledEndTimestamp}
                location={task.location}
                note={task.note}
                showButton={task.title !== 'Daily Check In' && task.title !== 'Daily Check Out'}
                onClick={() => handleTaskButton(task)}>
              </ScheduleCard>
            ))}
          </React.Fragment>
        ))
      }

      { !editSchedule.id && groupedTasks == null &&
        <TitleMedium>All available weeks have been scheduled.</TitleMedium>
      }

    </React.Fragment>
  );
};

SchedulerInput.displayName = 'SchedulerInput';

SchedulerInput.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  groupName: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  setIsolatedField: PropTypes.func,
};

SchedulerInput.defaultProps = {
  title: '',
  description: '',
  groupName: '',
  value: '',
  onChange: () => {},
  setIsolatedField: () => {},
}
