const getLogo = path => ({
  Thuto: `${path}/images/logos/thuto-trust.png`,
});

const getOther = path => ({
  Tree: `${path}/images/other/splashScreen.png`,
  BookStack: `${path}/images/other/BookStack.png`,
  BrokenPencil: `${path}/images/other/BrokenPencil.png`,
  PinnedCalender: `${path}/images/other/PinnedCalender.png`,
  Book: `${path}/images/other/Book.png`,
});

const getOnboarding = path => ({
  AddTask: `${path}/images/onboarding/AddTask.png`,
  CheckInDemo: `${path}/images/onboarding/CheckInDemo.png`,
  Dashboard: `${path}/images/onboarding/Dashboard.png`,
  Engagement: `${path}/images/onboarding/Engagement.png`,
  HelpAndSupport: `${path}/images/onboarding/HelpAndSupport.png`,
});

export const getImages = path => ({
  Logo: getLogo(path),
  Other: getOther(path),
  Onboarding: getOnboarding(path),
});
