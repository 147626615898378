import {
  Container,
  styled,
} from '@mui/material';

import {
  Display,
  FlexDirection,
} from 'enums';

export const HContainer = styled(Container)`
  display: ${Display.Flex};
  flex-direction: ${FlexDirection.Row};

  @media (min-width: 1200px) {
    max-width: 100%;
  }
`;

HContainer.displayName = 'HContainer';
