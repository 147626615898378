import {
  styled,
} from '@mui/material';

import {
  AlignItems,
  JustifyContent,
} from 'enums';

import {
  HBox,
} from 'ui/common/components/boxes/HBox';

export const HBoxVH = styled(HBox)`
  align-items: ${AlignItems.Center};
  justify-content: ${JustifyContent.Center};
`;

HBoxVH.displayName = 'HBoxVH';
