import {
  RoutePath,
} from 'enums';

import {
  Login,
} from 'ui/identity';

/**
 * @type {RouteConfig[]}
 */
export const IdentityRoutes = [{
    element: <Login/>,
    path: RoutePath.Login,
    options: {
      default: true,
      unauthenticated: true,
    },
  },
];
