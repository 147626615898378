import {
  Environment,
} from 'enums';

/**
 * @typedef {Object} EnvironmentConfig
 * @property {string} name
 * @property {string[]} hostNames
 * @property {EnvironmentVariables} variables
 */

/**
 * @typedef {Object} EnvironmentVariables
 * @property {EnvironmentPaths} paths
 * @property {string} apiKey
 * @property {string} clientId
 * @property {string} embeddedSbgScopes
 * @property {string} embeddedSbgRescopingScopes
 * @property {string} embeddedClientId
 * @property {string} embeddedSecurityGatewayBase
 * @property {string} embeddedOAuthScopes
 * @property {string} scope
 * @property {boolean} sentryEnabled
 * @property {string[]} sentryTracingOrigins
 * @property {number} sentryTracesSampleRate
 * @property {string} locationApiKey
 * @property {string} intercomAppId
 * @property {string} assetsBaseUrl
 */

/**
 * @typedef {Object} EnvironmentPaths
 * @property {Object} gateway
 * @property {Object} idp
 * @property {Object} ui
 */

/**
 * @type {EnvironmentConfig}
 *
 */
export const CommonEnvironmentConfig = {
  variables: {
    paths: {
      gateway: {
        graphql: '/graphql',
      },
      idp: {
        openIdIssuer: '',
      },
      ui: {
        base: new URL(window.location.pathname, window.location.origin).href,
        redirectUri: '',
      },
    },
    scope: 'email openid phone profile',
    sentryTracesSampleRate: 0.1,
    sentryEnabled: true,
    assetsBaseUrl: './assets',
    channel: 'web-app',
    firebaseConfig: {
      apiKey: '',
      authDomain: '',
      databaseURL: '',
      projectId: '',
      storageBucket: '',
      messagingSenderId: '',
      appId: '',
      measurementId: '',
    },
    locationApiKey: 'v1.public.eyJqdGkiOiJlZTIwNGFiNi05MjY2LTRmMDctOTRmMC1lYzVhNjk1MmI5Y2QifWTMx8evgx774ggWma04twsVgxMFk1VoGqrZBgZwj6_0KTWP_Q9svjwaJKK2Av6v7s0tDxaXJ89mAGdB7naJwZOyAN5W2CHSdOaulPLvQfHVjZgwyXZqofLvu8CEvOYPzNKx7QbXz6x6t90hZd0UefaTl9cXPkOLeeqlLOreqC36R2AECfwZWjayxe7-SC5pR5H058Pm8g8JOYAKyOStAxlwGA2ctKYMOH1PfHIrHU74tZpMRSHWywo8WXNmIPKwUOhwL-S1DhL-ZMNIHdFmwzbm90CqpcqNK3AxMHNqiIIB5232HvkKxrROV5h3tFWDOuvHJpkv91DTAS8VxoZnE3E.N2IyNTQ2ODQtOWE1YS00MmI2LTkyOTItMGJlNGMxODU1Mzc2',
  },
};

/**
 * @type {Object.<string, EnvironmentConfig>}
 */
export const EnvironmentConfig = {
  [Environment.Local]: {
    name: Environment.Local,
    hostNames: [
      'localhost',
      '127.0.0.1',
      '10.0.2.2',
    ],
    variables: {
      paths: {
        gateway: {
          base: 'https://gql.dev.thutoapp.org',
        },
        idp: {
          base: 'https://cognito-idp.af-south-1.amazonaws.com/af-south-1_1Za6Y1zTE',
        },
        ui: {},
      },
      assetsBaseUrl: '/assets',
      clientId: '7q4746fjj67af1tg7gpdnoju8e',
      sentryEnabled: false,
      sentryTracingOrigins: [
        'gw.dev.online.pvt.thuto.co.za',
        'idp.dev.online.pvt.thuto.co.za',
      ],
      sentryTracesSampleRate: 0.0,
    },
  },
  [Environment.Development]: {
    name: Environment.Development,
    hostNames: [
      'https://dev.thutoapp.org/',
      'dev.thutoapp.org',
    ],
    variables: {
      paths: {
        gateway: {
          base: 'https://gql.dev.thutoapp.org',
        },
        idp: {
          base: 'https://cognito-idp.af-south-1.amazonaws.com/af-south-1_1Za6Y1zTE',
        },
        ui: {},
      },
      clientId: '7q4746fjj67af1tg7gpdnoju8e',
      sentryEnabled: false,
      sentryTracingOrigins: [
        'gw.dev.online.pvt.app.co.za',
        'idp.dev.online.pvt.app.co.za',
      ],
    },
  },
  [Environment.QualityAssurance]: {
    name: Environment.QualityAssurance,
    hostNames: [
      'https://qa.thutoapp.org/',
      'qa.thutoapp.org',
    ],
    variables: {
      paths: {
        gateway: {
          base: 'https://gql.qa.thutoapp.org',
        },
        idp: {
          base: 'https://auth.qa.thutoapp.org',
        },
        ui: {},
      },
      clientId: '',
      sentryTracingOrigins: [
        'gw.qa.online.thuto.co.za',
        'idp.qa.online.thuto.co.za',
      ],
    },
  },
  [Environment.Production]: {
    name: Environment.Production,
    hostNames: [
      'https://thutoapp.org/',
      'thutoapp.org',
    ],
    variables: {
      paths: {
        gateway: {
          base: 'https://gql.thutoapp.org',
        },
        idp: {
          base: 'https://cognito-idp.af-south-1.amazonaws.com/af-south-1_xJS9G3ZbQ',
        },
        ui: {},
      },
      clientId: '266grsk941k4oi3gkjrlevagli',
      sentryEnabled: false,
      sentryTracingOrigins: [
        'gw.online.thuto.co.za',
        'idp.online.thuto.co.za',
      ],
      sentryTracesSampleRate: 0.0,
    },
  },
};
