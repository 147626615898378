import {
  Typography,
} from '@mui/material';

import {
  TypographyVariant,
} from 'enums';

export const LabelSmall = props =>

  <Typography
    {...props}
    fontSize='0.562rem'
    variant={TypographyVariant.Body2}>

    {props.children}
  </Typography>
;

LabelSmall.displayName = 'LabelSmall';
