import React from 'react';
import PropTypes from 'prop-types';

import {
  Asset,
  JourneyName,
} from 'enums';

import {
  useInsights,
} from 'hooks';

import {
  useImagePrefetch,
} from 'hooks';

import {
  Spinner,
  VFillContainerVH,
} from 'ui/common';

export const AssetPrefetch = (props = {
  performPrefetch: true,
}) => {

  const {
    traceError,
  } = useInsights({
    context: AssetPrefetch.context,
  });

  const images = React.useMemo(() => ({
      ...Asset.Image,
    }
  ), []);

  const {
    loading,
    errors,
  } = useImagePrefetch({
    imageList: [
      images,
    ],
    run: props.performPrefetch,
  });

  React.useEffect(() => {

    if (errors?.length > 0) {
      traceError('Errors occurred while pre-fetching assets', errors);
    }
  }, [
    errors,
    traceError,
  ]);

  return (

    <React.Fragment>

      { !!loading &&

        <VFillContainerVH>
          <Spinner/>
        </VFillContainerVH>
      }

      {!loading && props.children}
    </React.Fragment>
  );
};

AssetPrefetch.displayName = 'AssetPrefetch';

AssetPrefetch.context = {
  journey: JourneyName.App,
  component: AssetPrefetch.displayName,
};

AssetPrefetch.propTypes = {
  performPrefetch: PropTypes.bool,
};
