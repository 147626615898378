import React from 'react';

import {
  useNavigate,
} from 'react-router-dom';

import {
  format,
  getHours,
} from 'date-fns';

import {
  Grid,
  Pagination,
  styled,
} from '@mui/material';

import {
  JourneyName,
  ScreenName,
  IdentityGroupType,
  Asset,
  RoutePath,
} from 'enums';

import {
  getWeekRange,
  buildFilterGroupsFromTasks,
  filterTasksBySelectedFilters,
  getCurrentDayRangeFormatted,
  getOldestTaskPerOwner,
} from 'common';

import {
  useTaskList,
  useIdentity,
  usePeopleList,
} from 'hooks';

import {
  BodyMedium,
  HeadlineLarge,
  Scene,
  TitleMedium,
  TitleSmall,
  TitleLarge,
  VContainer,
  VFillContainer,
  HFillContainerV,
  TextButton,
  Filter,
  TaskList,
  Feedback,
  HBoxV,
  PrimaryButton,
  VFillContainerV,
  BodySmall,
  ToggleButton,
} from 'ui/common';

import {
  tasksLessonPresentationQueryParams,
  tasksMentorQueryParams,
  tasksOnlineMentorActionQueryParams,
  tasksOnsiteMentorActionQueryParams,
} from 'gql/task/queryParams';

const StyledTitleSmall = styled(TitleSmall)`
  margin-top: 2.5rem;
`;

const TitleContainer = styled(VContainer)`
  padding: 2rem 0 0 0;
  @media (min-width: 600px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const SpaceBetweenContainer = styled(HFillContainerV)`
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0;

  @media (min-width: 600px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const BodyMediumMargin = styled(BodyMedium)`
  margin: 0.7rem 0 1rem;
`;

const StyledContainer = styled(VContainer)`
  padding-left: 0 !important;
`;

const StyledBaseContainer = styled(VFillContainer)`
  padding: 0 0.5rem;
  flex: 0;
`;

const ActionBox = styled(HBoxV)`
  border-radius: 12px;
  padding: 12px;
  margin-top: 12px;
  border-width: 1px;
  border-style: solid;
  height: 100%;
  min-height: 4em;
  max-height: 6em;
`;

const StyledGrid = styled(Grid)`
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const ActionBoxButton = styled(PrimaryButton)`
  width: unset;
  box-shadow: none;
`;

const StyledBodySmall = styled(BodySmall)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const mentorActions = [
  {
    title: ScreenName.Reporting,
    description: 'Click here to create and download reports, including assessment, task, person details, and attendance reports.',
    actionButtonText: 'Open',
    actionButtonNavigation: RoutePath.Reporting,
  },
  {
    title: ScreenName.TaskList,
    description: 'Click here to view a selected user\'s task list for their last, current, and next week.',
    actionButtonText: 'Open',
    actionButtonNavigation: RoutePath.TaskList,
  },
];

const Tabs = {
  AwaitingAction: 'Awaiting Action',
  YourTasks: 'Your Tasks',
  InternPresentations: 'Intern Presentations',
};

export const MentorHome = () => {
  const [yourTasksPage, setYourTasksPage] = React.useState(1);
  const [awaitingActionPage, setAwaitingActionPage] = React.useState(1);
  const [internPresentationsPage, setInternPresentationsPage] = React.useState(1);
  const [activeTab, setActiveTab] = React.useState(Tabs.AwaitingAction);
  const [filters, setFilters] = React.useState();
  const { currentWeek: currentWeekRange } = React.useMemo(() => getWeekRange(), []);
  const currentDayRange = React.useMemo(() => getCurrentDayRangeFormatted(), []);
  const { user } = useIdentity(MentorHome.context);
  const userType = React.useMemo(() => user.group || IdentityGroupType.Mentor, [user.group]);
  const navigate = useNavigate();
  const currentDate = new Date();

  const {
    loading: internsLoading,
    error: internsError,
    peopleList: interns,
    refetch: internsRefetch,
  } = usePeopleList({
    userId: user.id,
    userType,
  });

  // Your tasks
  const yourTasksParams = React.useMemo(() => tasksMentorQueryParams({
    userId: user.id,
    dateRange: currentWeekRange,
    page: yourTasksPage,
  }), [
    user.id,
    currentWeekRange,
    yourTasksPage,
  ]);

  const {
    loading: yourTasksLoading,
    error: yourTasksError,
    taskList: allYourTasks,
    refetch: yourTasksRefetch,
    pageInfo: yourTasksPageInfo,
  } = useTaskList({
    queryParams: yourTasksParams,
    userType,
  });

  const yourTasks = React.useMemo(() => {

    if (
      yourTasksLoading ||
      !Array.isArray(allYourTasks)
    ) {
      return [];
    }

    return filterTasksBySelectedFilters(allYourTasks, filters);
  }, [
    allYourTasks,
    filters,
    yourTasksLoading,
  ]);

  // Tasks awaiting action
  const awaitingActionTasksParams = React.useMemo(() => {
    return userType === IdentityGroupType.Mentor
      ? tasksOnsiteMentorActionQueryParams({
        userId: user.id,
        dateRange: currentWeekRange,
        page: awaitingActionPage,
      })
      : tasksOnlineMentorActionQueryParams({
        userId: user.id,
        dateRange: currentWeekRange,
        page: awaitingActionPage,
      });
  }, [
    userType,
    user.id,
    currentWeekRange,
    awaitingActionPage,
  ]);

  const {
    loading: awaitingActionTasksLoading,
    error: awaitingActionTasksError,
    taskList: allAwaitingActionTasks,
    refetch: awaitingActionTasksRefetch,
    pageInfo: awaitingActionTasksPageInfo,
  } = useTaskList({
    queryParams: awaitingActionTasksParams,
    userType,
  });

  const awaitingActionTasks = React.useMemo(() => {
    if (
      awaitingActionTasksLoading ||
      internsLoading ||
      !Array.isArray(allAwaitingActionTasks) ||
      !Array.isArray(interns)
    ) {
      return [];
    }

    const awaitingActionTasks = allAwaitingActionTasks.map(task => {

      const internDetails = interns.find(intern => intern.id === task.ownerPersonId);

      return {
        ...task,
        school: internDetails?.school || null,
      };
    });

    return filterTasksBySelectedFilters(awaitingActionTasks, filters);
  }, [
    allAwaitingActionTasks,
    awaitingActionTasksLoading,
    filters,
    interns,
    internsLoading,
  ]);

  // Intern lesson presentation tasks. Note that this will only load for on-site mentors.
  const internPresentationTasksParams = React.useMemo(() => {
    return userType === IdentityGroupType.Mentor
      ? tasksLessonPresentationQueryParams({
        userId: user.id,
        dateRange: [
          currentDayRange[0],
          currentWeekRange[1],
        ],
        page: internPresentationsPage,
      })
      : null;
  }, [
    currentDayRange,
    currentWeekRange,
    internPresentationsPage,
    user.id,
    userType,
  ]);

  const {
    loading: internPresentationTasksLoading,
    error: internPresentationTasksError,
    taskList: allInternPresentationTasks,
    refetch: internPresentationTasksRefetch,
    pageInfo: internPresentationTasksPageInfo,
  } = useTaskList({
    queryParams: internPresentationTasksParams,
    userType,
  });

  const internPresentationTasks = React.useMemo(() => {

    if (
      internPresentationTasksLoading ||
      !Array.isArray(allInternPresentationTasks)
    ) {
      return [];
    }

    return filterTasksBySelectedFilters(
      getOldestTaskPerOwner(allInternPresentationTasks),
      filters,
    );
  }, [
    allInternPresentationTasks,
    filters,
    internPresentationTasksLoading,
  ]);

  const error = internsError || yourTasksError || awaitingActionTasksError || internPresentationTasksError;
  const loading = internsLoading || yourTasksLoading || awaitingActionTasksLoading || internPresentationTasksLoading;

  const visibleTabs = React.useMemo(() => {
    const tabs = [
      {
        display: `${Tabs.AwaitingAction}` + (
          awaitingActionTasksPageInfo.totalResults != null
            ? ` (${awaitingActionTasksPageInfo.totalResults})`
            : ''
        ),
        value: Tabs.AwaitingAction,
      },
      {
        display: `${Tabs.YourTasks}` + (
          yourTasksPageInfo.totalResults != null
            ? ` (${yourTasksPageInfo.totalResults})`
            : ''
        ),
        value: Tabs.YourTasks,
      },
    ];

    if (userType === IdentityGroupType.Mentor) {

      tabs.push({
        display: `${Tabs.InternPresentations}` + (
          internPresentationTasksPageInfo.totalResults != null
            ? ` (${internPresentationTasksPageInfo.totalResults})`
            : ''
        ),
        value: Tabs.InternPresentations,
      });
    }

    return tabs;
  }, [
    awaitingActionTasksPageInfo.totalResults,
    internPresentationTasksPageInfo.totalResults,
    userType,
    yourTasksPageInfo.totalResults,
  ]);

  const allTasks = [
    ...allYourTasks,
    ...allAwaitingActionTasks,
    ...allInternPresentationTasks,
  ];

  const activeTabTasks = React.useMemo(() => {
    switch (activeTab) {
      case Tabs.AwaitingAction:
        return awaitingActionTasks;

      case Tabs.YourTasks:
        return yourTasks;

      case Tabs.InternPresentations:
        return internPresentationTasks;
    }
  }, [
    activeTab,
    awaitingActionTasks,
    internPresentationTasks,
    yourTasks,
  ]);

  const activeTabPageInfo = React.useMemo(() => {
    switch (activeTab) {
      case Tabs.AwaitingAction:
        return awaitingActionTasksPageInfo;

      case Tabs.YourTasks:
        return yourTasksPageInfo;

      case Tabs.InternPresentations:
        return internPresentationTasksPageInfo;
    }
  }, [
    activeTab,
    awaitingActionTasksPageInfo,
    internPresentationTasksPageInfo,
    yourTasksPageInfo,
  ]);

  const activeTabPage = React.useMemo(() => {
    switch (activeTab) {
      case Tabs.AwaitingAction:
        return awaitingActionPage;

      case Tabs.YourTasks:
        return yourTasksPage;

      case Tabs.InternPresentations:
        return internPresentationsPage;
    }
  }, [
    activeTab,
    awaitingActionPage,
    internPresentationsPage,
    yourTasksPage,
  ]);

  const setActiveTabPage = React.useCallback(page => {
    switch (activeTab) {
      case Tabs.AwaitingAction:
        setAwaitingActionPage(page);
        break;

      case Tabs.YourTasks:
        setYourTasksPage(page);
        break;

      case Tabs.InternPresentations:
        setInternPresentationsPage(page);
        break;
    }
  }, [
    activeTab,
  ]);

  const onAddTask = () => {
    navigate('/add-task');
  };

  const onFilterUpdate = (filters) => {
    setFilters(filters);
  };

  const onRefresh = () => {
    internsRefetch();
    yourTasksRefetch();
    awaitingActionTasksRefetch();
    internPresentationTasksRefetch();
  };

  return (

    <Scene
      loading={loading}
      headerVisible={true}
      footerVisible={true}>

      <StyledBaseContainer>

        <StyledContainer>
          <StyledTitleSmall>Good {getHours(currentDate) < 12 && 'Morning' || 'Afternoon'},
            it's {format(currentDate, 'HH:mm')} on</StyledTitleSmall>
          <HeadlineLarge>{format(currentDate, 'dd MMMM')}</HeadlineLarge>
        </StyledContainer>

        <TitleContainer>
          <SpaceBetweenContainer>
            <TitleMedium>TASK DASHBOARD</TitleMedium>
            <TextButton
              text="+ Add Task"
              onClick={onAddTask}/>
          </SpaceBetweenContainer>

          <BodyMediumMargin>Here, you can view all the tasks submitted by your interns for approval.
            Use the filters to streamline your view.</BodyMediumMargin>
        </TitleContainer>

        {!loading && !error && allTasks.length > 0 &&
          <React.Fragment>

            <Filter
              filters={filters}
              filterGroups={buildFilterGroupsFromTasks({
                tasks: allTasks,
                showSchoolFilter: true,
              })}
              onFilterUpdate={onFilterUpdate}
            />

            <ToggleButton
              value={activeTab}
              options={visibleTabs}
              onChange={(e) => setActiveTab(e.target.value)}/>

            <TaskList
              tasks={activeTabTasks}
              userType={userType}
              user={user}/>

            {activeTabPageInfo?.totalPages > 1 &&

              <Pagination
                count={activeTabPageInfo.totalPages}
                page={activeTabPage}
                onChange={(_event, page) => setActiveTabPage(page)}/>
            }

          </React.Fragment>
        }

        {!loading && !error && allTasks.length <= 0 &&
          <VContainer>
            <TitleLarge>All Caught Up!</TitleLarge>
            <BodyMedium>You're all set for now—there are no pending tasks on your
              dashboard.</BodyMedium>
            <BodyMedium>This is a great opportunity to check in on your interns'
              progress.</BodyMedium>

            <StyledContainer>
              <StyledGrid
                container
                rowSpacing={1}
                columnSpacing={{
                  xs: 1,
                  sm: 2,
                  md: 3,
                }}>
                {mentorActions.map((action) => (
                  <Grid
                    key={action.title}
                    item
                    xs={12}
                    sm={6}
                    md={4}>
                    <ActionBox
                      backgroundColor={'primary.p99'}
                      borderColor={'primary.p80'}>
                      <VFillContainerV>
                        <TitleMedium>{action.title}</TitleMedium>
                        <StyledBodySmall color="primary">{action.description}</StyledBodySmall>
                      </VFillContainerV>
                      <ActionBoxButton
                        text={action.actionButtonText}
                        onClick={() => navigate(action.actionButtonNavigation)}/>
                    </ActionBox>
                  </Grid>

                ))}
              </StyledGrid>
            </StyledContainer>

          </VContainer>
        }

        {error &&

          <Feedback
            imageUrl={Asset.Image.Other.BrokenPencil}
            headerText={'Something Went Wrong!'}
            bodyText={'We encountered a hiccup while fetching your tasks. Not to worry, though – we\'re on it!'}
            infoText={'Please try loading your task again. If the issue persists, reach out to us for support'}
            type={'Error'}
            buttonText={'Try Again'}
            buttonAction={() => onRefresh()}
          />
        }

      </StyledBaseContainer>
    </Scene>
  );
};

MentorHome.displayName = 'MentorHome';

MentorHome.context = {
  screen: ScreenName.Home,
  journey: JourneyName.Home,
  component: MentorHome.displayName,
};
