import React from 'react';

import {
  setContext as setInsightsContext,
} from 'insights';

import PropTypes from 'prop-types';

import {
  Navigate,
  useRoutes,
  useLocation,
  matchRoutes,
} from 'react-router-dom';

import {
  TagTypes,
} from 'enums';

import {
  ProtectedRoute,
} from 'ui/navigation/ProtectedRoute';

import {
  useInsights,
} from 'hooks';

/**
 * Route Contexts used for navigation breadcrumbs.
 * @typedef {Object} RouteContext
 * @property {string} path The route path
 * @property {Object} context The context associated with element on the route.
 * @Property {string} context.screen The screen name.
 * @property {string} context.journey The journey the screen is in.
 * @property {string} context.component The component name
 */

/**
 * The route contexts generated in createRoute
 * @type {RouteContext[]}
 */
let routeContexts = [];

/**
 * Additional options for a route
 * @typedef {Object} RouteOptions
 * @property {boolean} default Whether or not this route is the default route to render if no other
 * routes match the current path. Each array of routes can only have one default route. This means
 * nested routes (see "children" property in RouteConfig) can also have their own default route.
 * @property {boolean} unauthenticated If the route is unauthenticated. By default, all routes are
 * considered authenticated unless explicitly set to unauthenticated.
 */

/**
 * An object that represents a single route in the current journey.
 * @typedef {Object} RouteConfig
 * @property {React.ReactNode} element The content that will be rendered when this route has been
 * matched.
 * @property {string} path The path of this route. Must be unique.
 * @property {(RouteOptions|function(): RouteOptions)} [options] Additional options for this route.
 * Can be a static object or a function that gets triggered when routes are constructed.
 * @property {RouteConfig[]} [children] Child routes you want configured to display through an outlet.
 */

/**
 * Helper function to generate all the route components from an array of route config objects.
 * @param {RouteConfig[]} routes
 * @return {React.ReactNode[]}
 */
const createRoutes = routes => {

  if (!Array.isArray(routes)) {
    return [];
  }

  const routeObjects = [];
  let defaultRouteSet = false;

  for (const route of routes) {

    if (!route?.element || route?.path?.length <= 0) {
      continue;
    }

    const newRoute = {
      path: route.path,
    };

    /** @type {RouteOptions} */
    const routeOptions = {
      ...(typeof route.options === 'function' && route.options()) || route.options,
    };

    if (!defaultRouteSet && routeOptions.default) {

      defaultRouteSet = true;

      routeObjects.push({
        path: '*',
        element: (
          <Navigate
            to={route.path}
            replace={true}/>
        ),
        index: true
      });
    }

    const routeContext = (!!route?.element?.type?.context && route.element.type.context) || {};

    newRoute.element = (!!routeOptions.unauthenticated && route.element) || (
      <ProtectedRoute
        context={routeContext}>

        {route.element}
      </ProtectedRoute>
    );

    if (Array.isArray(route.children) && route.children.length > 0 ) {

      newRoute.children = createRoutes(route.children);
      routeObjects.push(newRoute);
      continue;
    }

    const contextRoute = {
      path: route.path,
      context: routeContext,
    };

    routeContexts.push(contextRoute);

    routeObjects.push(newRoute);
  }

  return routeObjects;
};

export const Navigator = props => {

  const {
    tag,
  } = useInsights({
    context: Navigator.context,
  });

  const routes = React.useMemo(
    () => {

      routeContexts = [];

      return createRoutes(props.routes)
    },
    [ props.routes ],
  );

  const prevLocation = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {

    const matchedRoutes = matchRoutes(routeContexts, location);

    if (!matchedRoutes || !Array.isArray(matchedRoutes) || matchedRoutes.length < 1) {
      return;
    }

    const route = matchedRoutes[0].route;

    if (!route?.context?.screen || !route?.context?.journey || !route?.context?.component) {
      return;
    }

    setInsightsContext(context => ({
      ...context,
      ...route.context,
    }));

    tag({
      type: TagTypes.View,
      category: 'navigation',
      parameters: {
        ...route.context,
        from: (!!prevLocation?.current?.context && prevLocation.current.context) || null,
      },
    });

    prevLocation.current = route;
  }, [
    location,
    tag,
    prevLocation,
  ]);

  return useRoutes(routes);
};

Navigator.displayName = 'Navigator';

Navigator.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      element: PropTypes.element.isRequired,
      path: PropTypes.string.isRequired,
      options: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
          title: PropTypes.string,
          default: PropTypes.bool,
        }),
      ]),
    }),
  ).isRequired,
};

Navigator.defaultProps = {};

Navigator.context = {
  component: Navigator.displayName,
};
