export const Environment = {
  Local: 'Local',
  Development: 'Development',
  QualityAssurance: 'Quality Assurance',
  PrivateProduction: 'Private Production',
  Production: 'Production',
};

export const DebounceDuration = {
  Short: 100,
  Medium: 300,
  Long: 500,
  ExtraLong: 1000,
};
