import React from 'react';
import PropTypes from 'prop-types';

import {
  styled,
} from '@mui/material';

import {
  Environment,
  Position,
  Spacing,
} from 'enums';

import {
  useEnvironment,
} from 'hooks';

import {
  LabelSmall,
} from 'ui/common/components/typography';

const Version = styled(LabelSmall)`
  position: ${Position.Absolute};
  bottom: ${Spacing.Small3};
  right: ${Spacing.Small3};
`;

export const VersionWatermark = (props = {
  forceWatermark: false,
}) => {

  const {
    environment,
  } = useEnvironment();

  return (

    <React.Fragment>

      { props.forceWatermark || environment.name !== Environment.Production &&

        <Version>
          {import.meta.env.PACKAGE_VERSION}
        </Version>
      }

    </React.Fragment>
  );
};

VersionWatermark.displayName = 'VersionWatermark';

VersionWatermark.propTypes = {
  forceWatermark: PropTypes.bool,
};
