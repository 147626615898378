import React from 'react';

import {
  IdentityGroupType,
  JourneyName,
  ScreenName,
} from 'enums';

import {
  Autochips,
  BodyMedium,
  DatePicker,
  Dropdown,
  Scene,
  SpinnerButton,
  ToggleButton,
  DateWeekMonthRangeSelector
} from 'ui/common';

import {
  getYear,
} from 'date-fns';

import {
  useReports,
  useGenerateReport,
  usePersons,
  useSchools,
  useTaskTemplateList,
  useIdentity
} from 'hooks';

import {
  buildGenerateReportVariables,
  capitalizeFirstLetter,
} from 'common';

import {
  styled
} from '@mui/material';

import {
  getNonAssessmentTasks,
  getAllAssessmentTasks
} from 'gql/taskTemplate/queryParams';

import {
  ReportDownloader,
} from 'ui/reporting/components';

const defaultFormFields = {
  year: new Date().toISOString(),
  timespan: 'Week'
};

const defaultFilterMap = {
  personId: false,
  schoolId: false,
  taskType: false,
  assessmentTaskType: false,
  hideManualTask: false,
};

const StyledDropdown = styled(Dropdown)`
  margin-bottom: 2rem;
`;

export const Reporting = () => {

  const { user } = useIdentity(Reporting.context);
  const userType = user.group || IdentityGroupType.Intern;

  const userTypeGroup = userType === IdentityGroupType.Admin ? [IdentityGroupType.Intern.toUpperCase(), IdentityGroupType.Mentor.toUpperCase()] : [IdentityGroupType.Intern.toUpperCase()];
  const [formFields, setFormFields] = React.useState(defaultFormFields);
  const [filterMap, setFilterMap] = React.useState(defaultFilterMap);
  const [fileId, setFileId] = React.useState('');

  const {loading, error, reportList} = useReports();

  const [generateReport, {loading: generateLoading, error: generateError, data: generateData}] = useGenerateReport();

  const {loading: personLoading, error: personError, persons} = usePersons(userType === IdentityGroupType.Admin && ['MENTOR', 'INTERN'] || ['INTERN']);
  const {loading: schoolLoading, error: schoolError, schools} = useSchools();
  const {loading: taskLoading, error: taskError, taskTemplateList} = useTaskTemplateList(userTypeGroup, null, getNonAssessmentTasks(userTypeGroup));
  const {loading: assessmentTaskLoading, error: assessmentTaskError, taskTemplateList: assessmentTaskTemplateList} = useTaskTemplateList(userTypeGroup, null, getAllAssessmentTasks(userTypeGroup));
  const hideManualTaskList = ["yes", "no"];

  const [ personOptions, setPersonOptions ] = React.useState();
  const [ schoolOptions, setSchoolOptions ] = React.useState();
  const [ taskOptions, setTaskOptions ] = React.useState();
  const [ assessmentTaskOptions, setAssessmentTaskOptions ] = React.useState();

  const getTaskLabel = React.useCallback(
    (mnemonic, title) => {
      if (userType === IdentityGroupType.Admin && mnemonic.includes('MENTOR')) {
        return `Mentor: ${title}`;
      } else if (userType === IdentityGroupType.Admin && mnemonic.includes('INTERN')) {
        return `Intern: ${title}`;
      } else {
        return title;
      }
    },
    [userType]
  );

  React.useEffect(() => {
    if (!personLoading && persons && persons.length > 0) {
      const mappedPersons = persons.map(p => {
        return {
          id: p.id,
          label: userType === IdentityGroupType.Admin ? `${capitalizeFirstLetter(p.userType)}: ${p.givenName} ${p.surname}` : `${p.givenName} ${p.surname}`,
        };
      });

      setPersonOptions(mappedPersons);
    }
  }, [personLoading, persons, userType]);

  React.useEffect(() => {
    if (!schoolLoading && schools && schools.length > 0) {
      const mappedSchools = schools.map(s => {
        return {
          id: s.id,
          label: s.title,
        };
      });

      setSchoolOptions(mappedSchools);
    }
  }, [schoolLoading, schools]);

  React.useEffect(() => {
    if (!taskLoading && taskTemplateList && taskTemplateList.length > 0) {
      const mappedTasks = taskTemplateList.map(t => {
        return {
          id: t.id,
          label: getTaskLabel(t.mnemonic, t.title),
          value: t.mnemonic
        };
      });

      setTaskOptions(mappedTasks);
    }
  }, [taskLoading, taskTemplateList, userType, getTaskLabel]);

  React.useEffect(() => {
    if (!assessmentTaskLoading && assessmentTaskTemplateList && assessmentTaskTemplateList.length > 0) {
      const mappedTasks = assessmentTaskTemplateList.map(t => {
        return {
          id: t.id,
          label: getTaskLabel(t.mnemonic, t.title),
          value: t.mnemonic
        };
      });

      setAssessmentTaskOptions(mappedTasks);
    }
  }, [assessmentTaskLoading, assessmentTaskTemplateList, userType, getTaskLabel]);

  React.useEffect(() => {
    if (formFields.reportType != null && formFields.reportType !== '') {
      const targetReport = reportList.find(r => r.title === formFields?.reportType);
      setFilterMap(targetReport.filterMap);
    }
  }, [formFields.reportType, reportList]);

  React.useEffect(() => {
    if (filterMap.hideManualTask) {
      handleFieldChange('selectedHideManualTask', "yes");
    }else{
      handleFieldChange('selectedHideManualTask', null);
    }
  }, [filterMap.hideManualTask]);

  React.useEffect(() => {
    handleFieldChange('startDate', null);
    handleFieldChange('endDate', null);
  }, [formFields.timespan, formFields.year]);

  const handleFieldChange = (field, value) => {
    setFileId('');

    setFormFields((prevState) => {
      const newFields = {
        ...prevState,
        [field]: value,
      };

      return newFields;
    });
  };

  React.useEffect(() => {
    if (!generateLoading && !generateError && generateData) {
      setFileId(generateData.reportGenerateByMnemonic?.body?.reportFileId || '');
    }
  }, [generateLoading, generateError, generateData]);

  const handleGenerateReport = () => {
    const selectedReport = reportList.find(r => r.title === formFields?.reportType);
    const year = getYear(formFields.year);
    const periodStart = formFields.startDate;
    const periodEnd = formFields.endDate;
    const filters = {};

    if (formFields.selectedPersons != null && formFields.selectedPersons.length > 0) {
      filters['personId'] = formFields.selectedPersons.map(p => p.id);
    }

    if (formFields.selectedSchools != null && formFields.selectedSchools.length > 0) {
      filters['schoolId'] = formFields.selectedSchools.map(s => s.id);
    }

    if (formFields.selectedTasks != null && formFields.selectedTasks.length > 0) {
      filters['taskType'] = formFields.selectedTasks.map(t => t.value);
    }

    if (formFields.selectedAssessmentTasks != null && formFields.selectedAssessmentTasks.length > 0) {
      filters['assessmentTaskType'] = formFields.selectedAssessmentTasks.map(t => t.value);
    }

    if (formFields.selectedHideManualTask != null) {
      filters['hideManualTask'] = formFields.selectedHideManualTask;
    }

    const params = {
      reportMnemonic: selectedReport?.mnemonic,
      filterProperties: {
        selectedYear: year,
        periodType: formFields.timespan,
        periodStart: periodStart,
        periodEnd: periodEnd,
        filter: filters,
      }
    };

    generateReport(buildGenerateReportVariables(params));
  };

  return (

    <Scene
      headerVisible={true}
      footerVisible={true}
      title={ScreenName.Reporting}
    >

      <DatePicker
        key={'year-picker'}
        value={formFields.year ?? ''}
        onChange={(event) => {
          handleFieldChange('year', event && new Date(event).toISOString() || '');
        }}
        label={'Year'}
        variant={'Date'}
        disableFuture
        groupName={'Timeframe'}
        views={['year']}
        />

        <BodyMedium>
          We generate reports based on weekly timeframes.
          We will include the weeks that your selected dates are in, based on your selection below.
        </BodyMedium>

        <ToggleButton
          options={['Month', 'Week']}
          value={formFields.timespan}
          onChange={(event) => {
            handleFieldChange('timespan', event && event.target?.value || '');
          }}
        />


      {
        !loading && !error && formFields.year && formFields.timespan &&
        <DateWeekMonthRangeSelector
          periodType={formFields.timespan}
          selectedYear={getYear(formFields.year)}
          onChange={(value) => {
            handleFieldChange('startDate', value?.selectedStart);
            handleFieldChange('endDate', value?.selectedEnd);
          }}
        />

      }

      {!loading && !error && reportList &&
        <StyledDropdown
          groupName={'Filters'}
          label={'Report Type'}
          value={formFields?.reportType}
          onChange={(event) => {
            handleFieldChange('reportType', event && event.target?.value || '');
          }}
          options={reportList.map(r => r.title)}
        />
      }

      { !personLoading && !personError && personOptions && filterMap.personId &&
        <Autochips
          key={'person-autochip'}
          label={'Person'}
          options={personOptions}
          onChange={(value) => formFields?.selectedPersons != value && handleFieldChange('selectedPersons', value)}
        />
      }

      { !schoolLoading && !schoolError && schoolOptions && filterMap.schoolId &&
        <Autochips
          key={'school-autochip'}
          label={'School'}
          options={schoolOptions}
          onChange={(value) => formFields?.selectedSchools != value && handleFieldChange('selectedSchools', value)}
        />
      }

      { !taskLoading && !taskError && taskOptions && filterMap.taskType &&
        <Autochips
          key={'task-autochip'}
          label={'Task'}
          options={taskOptions}
          onChange={(value) => formFields?.selectedTasks != value && handleFieldChange('selectedTasks', value)}
        />
      }

      { !assessmentTaskLoading && !assessmentTaskError && assessmentTaskOptions && filterMap.assessmentTaskType &&
        <Autochips
          key={'assessment-task-autochip'}
          label={'Assessment Task'}
          options={assessmentTaskOptions}
          onChange={(value) => formFields?.selectedAssessmentTasks != value && handleFieldChange('selectedAssessmentTasks', value)}
        />
      }

      {  filterMap.hideManualTask &&
          <Dropdown
            key={'hide-manual-task-autochip'}
            label={'Hide Manually Created Tasks'}
            options={hideManualTaskList}
            value={formFields?.selectedHideManualTask || hideManualTaskList[0]}
            onChange={(value) => formFields?.selectedHideManualTask != value.target.value && handleFieldChange('selectedHideManualTask', value.target.value)}
          />
      }

      {!fileId &&
        <SpinnerButton
          text='Generate'
          loading={generateLoading}
          onClick={handleGenerateReport}
        />
      }

      {!!fileId &&
        <ReportDownloader
          groupName={'Download Report'}
          reportFileId={fileId}/>
      }

    </Scene>
  );
};


Reporting.displayName = 'Reporting';

Reporting.context = {
  screen: ScreenName.Reporting,
  journey: JourneyName.Reporting,
  component: Reporting.displayName,
};
