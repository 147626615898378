import { useMutation } from '@apollo/client';
import { CommentUpsert } from 'gql/task/mutations';
import React from 'react';
import { useIdentity } from './identity';

export const useTaskInteractionUpsert = () => {

  const [upsertTask, { data, loading, error }] = useMutation(CommentUpsert);
  const [ upsertedInteractions, setUpsertedInteractions ] = React.useState();

  const { user } = useIdentity({
    screen: 'Add Comment Hook',
    journey: 'Comment',
    component: 'Add Comment Hook',
  });

  const buildVariables = (taskId, personId, commentField, questionId) => {
    return {
      variables: {
        params: {
          taskInteractions: [
            {
              taskId: taskId,
              personId: personId,
              commentField: commentField,
              questionId: questionId,
            }
          ]
        }
      }
    };
  }

  React.useEffect(() => {
    if (!loading && data && user) {
      
      data.taskInteractionUpsert.body.upsertResult
      if (data.taskInteractionUpsert?.body?.upsertResult && data.taskInteractionUpsert?.body?.upsertResult.length > 0) {
        const mappedInteractions = data.taskInteractionUpsert?.body?.upsertResult.map((resultItem) => {
          const rowData = JSON.parse(resultItem.row);

          return {
            id: rowData.id,
            createdTimestamp: rowData.createdTimestamp,
            commentField: rowData.commentField,
            givenName: user.name,
            surname: user.surname,
            personId: user.id,
            questionId: rowData.questionId,
            question: rowData.question,
          };
        });

        setUpsertedInteractions(mappedInteractions);
      }
    }
  }, [loading, data, user]);

  return [
    upsertTask,
    buildVariables,
    {
      loading,
      error,
      data,
      upsertedInteractions,
    }
  ];
}
