import React, { useState } from 'react';
import { BottomNavigation, BottomNavigationAction, styled } from '@mui/material';
import { Spacing, BorderRadius, FooterItems } from 'enums';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu } from '@mui/icons-material';
import { MainMenu } from 'ui/common/components/menus';
import { useIdentity } from 'hooks';

const StyledBottomNavigation = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 8px;
  justify-content: space-around;
  background-color: #F2F9F3;
  z-index: 69;
`;

const StyledBottomNavigationAction = styled(BottomNavigationAction)(({ theme }) => ({
  '&.Mui-selected': {
    color: theme.palette.primary.p20,
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },
  '&.Mui-selected .MuiSvgIcon-root': {
    backgroundColor: theme.palette.primary.p95,
    padding: `${Spacing.Small3} ${Spacing.Medium1}`,
    borderRadius: BorderRadius.IconBackground,
  },
  '&.MuiButtonBase-root': {
    minWidth: 0,
    padding: `0 ${Spacing.Small3}`,
  },
}));

const mapLocationToValue = (path) => {
  if (path.includes('/home')) {
    return 0;
  }
  if (path.includes('/task-list')) {
    return 1;
  }
  if (path.includes('/help')) {
    return 2;
  }
  if (path.includes('/account')) {
    return 3;
  }
  if (path.includes('/add-task')) {
    return 4;
  }
};

export const Footer = () => {
  const location = useLocation();
  const [value, setValue] = useState(mapLocationToValue(location.pathname));
  const [showMenu, setShowMenu] = useState(false);

  const {
    logout,
  } = useIdentity({
    context: Footer.context,
  });


  const navigate = useNavigate();

  const navigateTo = React.useCallback((path) => navigate(path), [navigate]);

  const handleMenuClose = () => {
    setShowMenu(false);
    setValue(mapLocationToValue(location.pathname));
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const menuItems = React.useMemo(() => {
    const items = [];

    for (const item of FooterItems) {
      if (!item || !item.path || !item.text) {
        continue;
      }

      const Icon = item.icon ? React.createElement(item.icon) : null;

      items.push(
        <StyledBottomNavigationAction
          key={item.path}
          label={item.text}
          icon={Icon}
          value={item.nav}
          onClick={() => navigateTo(item.path)}
        />
      );
    }

    items.push(
      <StyledBottomNavigationAction
        key={'menu-tab'}
        label={'Menu'}
        icon={React.createElement(Menu)}
        value={4}
        onClick={() => setShowMenu(true)}
      />
    );

    return items;
  }, [navigateTo]);

  return (
    <React.Fragment>

      <MainMenu
        visible={showMenu}
        logout={logout}
        onClose={() => handleMenuClose()}
      />

      <StyledBottomNavigation
        showLabels
        value={value}
        onChange={handleChange}
      >
        {menuItems}
      </StyledBottomNavigation>
    </React.Fragment>
  );
};

Footer.displayName = 'Footer';
