import { useMutation } from '@apollo/client';
import { PersonUpsertById } from 'gql/person/mutations';

export const usePersonByIdUpsert = () => {

  const [upsertPersonById, { data, loading, error }] = useMutation(PersonUpsertById);

  return [
    upsertPersonById,
    {
      loading,
      error,
      data,
    }
  ];
}
