import { useMutation } from '@apollo/client';
import { PersonSchoolUpsert } from 'gql/person/mutations';

export const usePersonSchoolUpsert = () => {

  const [upsertPersonSchool, { data, loading, error }] = useMutation(PersonSchoolUpsert);

  return [
    upsertPersonSchool,
    {
      loading,
      error,
      data,
    }
  ];
}
