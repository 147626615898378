import React from 'react';

import {
  useRecoilValue,
} from 'recoil';

import {
  StorageKeyPrefix,
} from 'enums';

import {
  IdentityAtom,
} from 'atoms';

import {
  getItem,
  setItem,
  removeItem,
  clear,
  getStorageKeys,
} from 'api';

import {
  useInsights,
} from 'hooks/useInsights';

const DefaultStorageApi = {
  getItem,
  setItem,
  removeItem,
  clear,
  getStorageKeys,
};

export const useStorage = ({
  storageApi = DefaultStorageApi,
  keyPrefix = StorageKeyPrefix,
  context,
} = {}) => {

  if (
    typeof storageApi?.getItem !== 'function' ||
    typeof storageApi?.setItem !== 'function' ||
    typeof storageApi?.removeItem !== 'function' ||
    typeof storageApi?.clear !== 'function' ||
    typeof storageApi?.getStorageKeys !== 'function'
  ) {

    throw new Error('Invalid storageApi passed to useStorage hook');
  }

  const {
    traceError,
  } = useInsights({
    context: {
      ...context,
      hook: 'useStorage',
    },
  });

  const userId = useRecoilValue(IdentityAtom)?.user?.id;

  const getFullKey = React.useCallback(({
    key,
    userSpecific,
  } = {}) => `${keyPrefix}${(userSpecific && `${userId}-`) || ''}${key}`, [
    keyPrefix,
    userId,
  ]);

  const getItem = React.useCallback(async ({
    key,
    session = false,
    userSpecific = true,
    fallback,
  } = {}) => {

    try {
      const fullKey = getFullKey({
        key,
        userSpecific,
      });

      return storageApi.getItem({
        key: fullKey,
        session,
        fallback,
      });
    } catch (error) {

      traceError(`Error trying to get '${key}' from storage`, error);

      return fallback;
    }
  }, [
    storageApi,
    getFullKey,
    traceError,
  ]);

  const setItem = React.useCallback(async ({
    key,
    value,
    session = false,
    userSpecific = true,
  }) => {

    const fullKey = getFullKey({
      userSpecific,
      key,
    });

    return storageApi.setItem({
      key: fullKey,
      value,
      session,
    });
  }, [
    storageApi,
    getFullKey,
  ]);

  const removeItem = React.useCallback(async ({
    key,
    session = false,
    userSpecific = true,
  }) => {

    const fullKey = getFullKey({
      userSpecific,
      key,
    });

    return storageApi.removeItem({
      key: fullKey,
      session,
    });
  }, [
    storageApi,
    getFullKey,
  ]);

  const clearItems = React.useCallback(async session => storageApi.clear({
    session,
  }), [
    storageApi,
  ]);

  const sanitiseItems = React.useCallback(async ({
    clearSession = true,
  } = {}) => {

    if (clearSession) {
      await storageApi.clear({
        session: true,
      });
    }

    const keys = await storageApi.getStorageKeys({
      session: false,
    });

    const promises = [];

    for (const key of keys) {

      if (key.startsWith(keyPrefix)) {
        continue;
      }

      promises.push(
        storageApi.removeItem({
          key,
          session: false,
        })
      );
    }

    await Promise.all(promises);
  }, [
    storageApi,
    keyPrefix,
  ]);

  return {
    getItem,
    setItem,
    removeItem,
    clearItems,
    sanitiseItems,
    isUserSpecificStorageReady: !!userId,
  };
};
