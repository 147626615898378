import {
  styled,
} from '@mui/material';

import {
  AlignItems,
} from 'enums';

import {
  HFillBox,
} from 'ui/common/components/boxes/HFillBox';

export const HFillBoxV = styled(HFillBox)`
  align-items: ${AlignItems.Center};
`;

HFillBoxV.displayName = 'HFillBoxV';
