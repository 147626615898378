import {
  trimString,
} from 'common/utility';

export const isValidEmail = email => {

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(trimString(email));
};

export const isValidPhoneNumber = phoneNo => {

  const phonelRegex = /^(?:\+27|0)(?:\d{2})?[\s-]?\d{3}[\s-]?\d{4}$/;
  return phonelRegex.test(trimString(phoneNo));
};

export const isEmptyArray = array => Array.isArray(array) && !array.length;

export const hasUpperCaseLetter = (string) => {

  const regexp = /[A-Z]/;
  return regexp.test(string);
};

export const hasLowerCaseLetter = (string) => {

  const regexp = /[a-z]/;
  return regexp.test(string);
};

export const hasDigit = (string) => {

  const regexp = /[0-9]/;
  return regexp.test(string);
};

export const hasSpecialCharacter = (string) => {

  const regexp = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
  return regexp.test(string);
};

export const isAlphanumeric = (string) => {

  const regexp = /^[a-zA-Z0-9]*$/;
  return regexp.test(string);
};

export const isNumeric = (string) => {

  const regexp = /^[0-9]*$/;
  return regexp.test(string);
};

export const isValidPassword = (password) => (
  typeof password === 'string' &&
  password.length >= 8 &&
  hasUpperCaseLetter(password) &&
  hasLowerCaseLetter(password) &&
  hasDigit(password) &&
  hasSpecialCharacter(password)
);

export const validatePassword = (
  newPassword,
  minPasswordLength,
) => {

  if (newPassword === '') {

    return {
      valid: false,
      validationText: 'This field is required',
    };
  }

  if (newPassword.length < minPasswordLength) {

    return {
      valid: false,
      validationText: `Must be at least ${minPasswordLength} characters long`,
    };
  }

  if (!hasUpperCaseLetter(newPassword) || !hasLowerCaseLetter(newPassword) || !hasDigit(newPassword) || !hasSpecialCharacter(newPassword)) {

    return {
      valid: false,
      validationText: 'Your password should contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character',
    };
  }

  return {
    valid: true,
    validationText: '',
  };
};

export const validatePasswordsIdentical = (
  newPassword,
  confirmPassword,
) => {

  if (confirmPassword === '') {

    return {
      valid: false,
      validationText: 'This field is required',
    };
  }

  if (confirmPassword !== newPassword) {

    return {
      valid: false,
      validationText: 'Password does not match',
    };
  }

  return {
    valid: true,
    validationText: '',
  };
};
