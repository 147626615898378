import {
  useQuery,
} from '@apollo/client';

import {
  FileQueryFilterDownload,
} from 'gql/task/queries';

/**
 * Hook to get a signed S3 URL to a file. This doesn't download the file itself.
 * @param {string} fileId
 */
export const useFileDownload = fileId => {

  const {
    loading,
    error,
    data: gqlData,
    refetch,
    startPolling,
    stopPolling,
  } = useQuery(FileQueryFilterDownload, {
      variables: {
        params: {
          filterProperties: [
            {
              operator: 'IN',
              fieldNames: ['fileId'],
              value: [fileId],
            },
          ],
        },
      },
      skip: !fileId,
    },
  );

  const data = !loading && gqlData?.fileQueryFilterDownload?.body?.files?.[0]
    ? gqlData?.fileQueryFilterDownload?.body?.files?.[0]
    : null;

  return {
    loading,
    error,
    data,
    refetch,
    startPolling,
    stopPolling,
  };
};
