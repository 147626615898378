import React from 'react';

import PropTypes from 'prop-types';

import {
  styled,
  Grid,
} from '@mui/material';

import {
  IdentityGroupType,
} from 'enums';

import {
  TaskCard,
} from 'ui/common/components/TaskCard';

import {
  BodyMedium,
} from 'ui/common/components/typography';

const StyledGrid = styled(Grid)`
  margin-bottom: 2rem;
`;

const EmptyText = styled(BodyMedium)`
  margin-top: 1rem;
`;

export const TaskList = ({
  tasks,
  userType,
  user,
}) => {

  return (

    <React.Fragment>

      {tasks && tasks.length > 0 &&

        <StyledGrid
          container
          rowSpacing={1}
          columnSpacing={{
            xs: 1,
            sm: 2,
            md: 3,
          }}>

          {tasks.map((task) => (

            <Grid
              key={task.id}
              item
              xs={12}
              sm={6}
              md={4}>

              <TaskCard
                id={task.id}
                title={task.title}
                scheduledEndTimestamp={task.scheduledEndTimestamp}
                task={task}
                userType={userType}
                user={user}
                internName={userType === IdentityGroupType.Intern
                  ? ''
                  : `${task.ownerPersonGivenName} ${task.ownerPersonSurname}`}
              />
            </Grid>
          ))}
        </StyledGrid>
      }

      {(!tasks || tasks.length === 0) &&
        <EmptyText>No tasks available.</EmptyText>
      }

    </React.Fragment>
  );
};

TaskList.displayName = 'TaskList';

TaskList.propTypes = {
  tasks: PropTypes.array.isRequired,
  userType: PropTypes.string.isRequired,
  user: PropTypes.any,
};

