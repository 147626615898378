import {
  atom,
} from 'recoil';

/**
 * Set containing the paths to all the assets that have been pre-cached already.
 * @type {RecoilState<Set<string>>}
 */
export const PrecachedAssetsAtom = atom({
  key: 'assets-precachedAssets',
  default: new Set(),
});

/**
 * Map containing the promises of all the assets that are currently being pre-cached. Once an asset
 * is done being cached it should be removed from this atom and added to `PrecachedAssetsAtom`.
 * @type {RecoilState<Map<string, Promise>>}
 */
export const AssetsBeingPrecachedAtom = atom({
  key: 'assets-assetsBeingPrecached',
  default: new Map(),
});

export const CachedSvgsAtom = atom({
  key: 'assets-prefetch-svgs',
  default: {},
});
