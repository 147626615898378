import {
  Typography,
} from '@mui/material';

import {
  TypographyVariant,
} from 'enums';

export const HeadlineSmall = props =>

  <Typography
    color='secondary'
    {...props}
    fontWeight={700}
    fontSize='1.438rem'
    variant={TypographyVariant.Heading6}>

    {props.children}
  </Typography>
;

HeadlineSmall.displayName = 'HeadlineSmall';
