import {
  Environment,
} from 'enums';

import {
  Operation,
} from 'insights/enums';

import {
  validateTagArguments,
} from 'insights/validation';

let globalConfig = {
  context: {
    environment: Environment.Production,
    user: 'Unknown',
  },
  providers: undefined,
};

const parseArgs = (
  config,
  args,
) => {

  let operationConfig = {
    context: undefined,
    providers: undefined,
  };

  if (!Array.isArray(args)) {

    return {
      ...(config || {}),
      args,
    };
  }

  let operationArgs = [];

  for (const arg of args) {

    if (arg && (arg.context || arg.providers)) {

      operationConfig = {
        ...operationConfig,
        ...arg,
      };

      continue;
    }

    operationArgs.push(arg);
  }

  const context = operationConfig.context || (config && config.context) || undefined;
  const providers = operationConfig.providers || (config && config.providers) || undefined;

  if (!providers || !Object.values(providers).length) {
    throw Error('No providers specified. You need to setProviders, use fromLocal or provide a custom config which specifies providers.');
  }

  return {
    context,
    providers,
    args: operationArgs,
  };
};

const send = ({
  context = {},
  providers,
  operation,
  args,
}) => {

  if (!Object.values(providers || {}).length || !operation) {
    return;
  }

  for (const provider of Object.values(providers)) {

    if (!provider || typeof provider[operation] !== 'function') {
      continue;
    }

    try {

      provider[operation]({
        context,
        args,
      });

    } catch (e) {
      context && context.environment !== Environment.Production && console.error(e);
    }
  }
};

export const setContext = (
  context = {},
) => {

  const updatedContext = (typeof context === 'function' && context(globalConfig.context || {})) || context;

  if (typeof updatedContext !== 'object') {
    throw Error('Context must be a valid object or function that returns a valid object.');
  }

  globalConfig = {
    ...globalConfig,
    context: updatedContext,
  };
};

export const setProviders = (
  providers = {},
) => {

  const updatedProviders = (typeof providers === 'function' && providers(globalConfig.providers || {})) || providers;

  if (typeof updatedProviders !== 'object') {
    throw Error('Providers must be a valid object or a function that returns a valid object.');
  }

  globalConfig = {
    ...globalConfig,
    providers: updatedProviders,
  };
};

export const traceInformation = (...args) => {

  let parsedArgs = parseArgs(
    globalConfig,
    args,
  );

  send({
    operation: Operation.Information,
    ...parsedArgs,
  });
};

export const traceWarning = (...args) => {

  let parsedArgs = parseArgs(
    globalConfig,
    args,
  );

  send({
    operation: Operation.Warning,
    ...parsedArgs,
  });
};

export const traceError = (...args) => {

  let parsedArgs = parseArgs(
    globalConfig,
    args,
  );

  send({
    operation: Operation.Error,
    ...parsedArgs,
  });
};

export const tag = (...args) => {

  let parsedArgs = parseArgs(
    globalConfig,
    args,
  );

  // If we are not in production then log validation errors as a warning for investigation.
  if (parsedArgs.context.environment !== Environment.Production) {

    const errors = validateTagArguments(parsedArgs);
    errors.length > 0 && traceWarning(`Tagging arguments validation errors: ${errors.join(', ')}`);
  }

  send({
    operation: Operation.Tag,
    ...parsedArgs,
  });
};

export const sendOperation = (
  operation,
  ...args
) => {

  if (!operation) {
    return;
  }

  let parsedArgs = parseArgs(
    globalConfig,
    args,
  );

  send({
    operation,
    ...parsedArgs,
  });
};

export const createLocalInsights = ({
  context = {},
  providers = {},
}) => {

  if (context !== undefined && typeof context !== 'object') {
    throw Error('Context must be a valid object');
  }

  if (providers !== undefined && typeof providers !== 'object') {
    throw Error('Providers must be a valid object');
  }

  const config = {
    get context () {
      return ({
        ...(globalConfig.context || {}),
        ...context,
      });
    },
    get providers () {
      return ({
        ...(globalConfig.providers || {}),
        ...providers,
      });
    },
  };

  return {
    traceInformation: (...args) => traceInformation(
      config,
      ...args,
    ),
    traceWarning: (...args) => traceWarning(
      config,
      ...args,
    ),
    traceError: (...args) => traceError(
      config,
      ...args,
    ),
    tag: (...args) => tag(
      config,
      ...args,
    ),
    sendOperation: (...args) => sendOperation(
      ...args,
      config,
    ),
  };
};
