import React from 'react';

import {
  useSetRecoilState,
} from 'recoil';

import {
  debounce,
} from 'common';

import {
  DefaultFormFactors,
  DebounceDuration,
} from 'enums';

import {
  FormFactorAtom,
} from 'atoms';

import {
  getFormFactorInfo,
} from 'common';

export const FormFactorContext = props => {

  const setFormFactor = useSetRecoilState(FormFactorAtom);

  // Effect to listen to screen resize events.
  React.useEffect(() => {

    const {
      debounced: onResize,
      clear: clearDebounce,
    } = debounce(
      () => setFormFactor(getFormFactorInfo({
        formFactors: props.formFactors,
        windowDimensions: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
      })),
      DebounceDuration.Short,
    );

    window.addEventListener('resize', onResize, {
      passive: true,
    });

    return () => {
      clearDebounce();
      window.removeEventListener('resize', onResize);
    };
  }, [
    setFormFactor,
    props.formFactors,
  ]);

  return <React.Fragment/>;
};

FormFactorContext.defaultProps = {
  formFactors: DefaultFormFactors,
};
