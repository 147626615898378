import PropTypes from 'prop-types';

import {
  styled,
  TextField as TextFieldProto,
} from '@mui/material';

import React from 'react';

import {
  calculateTimeAgo,
} from 'common/utility';

import { ButtonVariant } from 'enums';
import { BodyMedium, BodySmall, LabelLarge, TitleLarge, TitleMedium } from './typography';
import { SecondaryButton, SpinnerButton } from './buttons';
import { HFillContainerV, VContainer } from './containers';

const NoCommentsContainer = styled(VContainer)(({ theme }) => `
  padding: 1.5rem;
  background-color: ${theme.palette.secondary.s100};
  border-radius: 0.75rem;
  margin: 1.8rem 0 1.8rem 0;
`,
);

const StyledButtonContainer = styled(HFillContainerV)`
  padding: 0;
  margin: 0;
  margin-top: 1rem;

  @media (min-width: 600px) {
    max-width: 520px;
    padding: 0;
    margin: 0;
  }
`;

const StyledCancelButton = styled(SecondaryButton)`
  margin-right: 0.5rem;
`;

const CommentContainer =styled(VContainer)(({theme, iscurrentuser}) =>`
  border-radius: 4px;
  border: ${iscurrentuser == 'true' ? `3px solid ${theme.palette.primary.p40}` : `1px solid ${theme.palette.secondary.s40}`};
  padding: 1.3rem;
  margin: 0.5rem 0 1rem 0;
`);

const StyledTextField = styled(TextFieldProto)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p100,
  width: '100%',
  marginBottom: '1rem',
}));

const Text = styled(BodySmall)(({theme, iscurrentuser}) => `
  color: ${iscurrentuser == 'true' ? theme.palette.primary.p40 : theme.palette.secondary.s40};
`);

export const Comments = ({comments, onClick, error, userId, upsertCommentLoading, showCancelButton, onCancelClick}) => {

  const [newComment, setNewComment] = React.useState('');
  const [uploadError, setUploadError] = React.useState(null);

  const handleCommentUpload = async () => {
    try {
      setUploadError(null);
      onClick(newComment);
      setNewComment('');
    } catch (error) {
      setUploadError('Error uploading comment. Please try again.');
    }
  };

  return (

    <React.Fragment>

      <TitleMedium color='secondary'> COMMENTS</TitleMedium>
      
        { !error && comments && comments.length < 1 ? (
          <NoCommentsContainer>

            <TitleLarge color='secondary'>No Comments Yet</TitleLarge>
            <BodyMedium>Be the first to share your thoughts or ask a question.</BodyMedium>
            
          </NoCommentsContainer>
        ) : 
        (
          comments?.map((comment, index) => {

            const isCurrentUser = userId === comment.personId;
            const displayName = isCurrentUser ? 'You' : `${comment.givenName} ${comment.surname}`
            return(
            
            <React.Fragment key={index}>
              <Text iscurrentuser={isCurrentUser.toString()}>
                {displayName} | {calculateTimeAgo(comment.createdTimestamp)}
                {comment.question && (' | @' + (comment.question?.viewTitle != null && comment.question?.viewTitle !== '' && comment.question?.viewTitle || comment.question?.title)) || ''}
              </Text>
              <CommentContainer iscurrentuser={isCurrentUser.toString()}>
                {comment.commentField}
              </CommentContainer>

            </React.Fragment>
          )})
        )}

        { error && (
            <React.Fragment>
              <LabelLarge color='error'>
                We encountered a hiccup while fetching your comments. Not to worry, though – we're on it!
                If the issue persists, reach out to us for support.
              </LabelLarge><br/>
            </React.Fragment>
          )
        }

        <BodyMedium>
          Add a comment to share insights, ask questions, or provide feedback.
        </BodyMedium><br/>
        
        {uploadError && ( 
            <React.Fragment>
              <LabelLarge color='error'>
                {uploadError}
              </LabelLarge><br/>
            </React.Fragment>
          )
        }
        
        <StyledTextField
          label='New Comment' 
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />

        <StyledButtonContainer>

          {showCancelButton && 
            <StyledCancelButton 
              text={'Cancel'}
              onClick={onCancelClick}
            />
          }

          <SpinnerButton
            text='Submit Comment'
            variant={showCancelButton && ButtonVariant.Contained || ButtonVariant.Outlined}
            loading={upsertCommentLoading}
            disabled={newComment === ''}
            onClick={handleCommentUpload}
          />
        </StyledButtonContainer>

    </React.Fragment>
  );
};

Comments.displayName = 'Comments';

Comments.propTypes = {
  comments: PropTypes.array,
  onClick: PropTypes.func,
  upsertCommentLoading: PropTypes.bool,
  error: PropTypes.any,
  userId: PropTypes.string.isRequired,
  showCancelButton: PropTypes.bool,
  onCancelClick: PropTypes.func,
};

Comments.defaultProps = {
  upsertCommentLoading: false,
  comments: [],
  onClick: () => {},
  showCancelButton: false,
  onCancelClick: () => {},
};
