import {
  styled,
} from '@mui/material';

import {
  AlignItems,
} from 'enums';

import {
  VFillContainer,
} from 'ui/common/components/containers/VFillContainer';

export const VFillContainerH = styled(VFillContainer)`
  align-items: ${AlignItems.Center};
`;

VFillContainerH.displayName = 'VFillContainerH';
