import
  PropTypes
  from 'prop-types';

import {
  styled,
  Button,
} from '@mui/material';

import {
  ButtonVariant,
} from 'enums';

const StyledButton = styled(Button)`
  white-space: nowrap;
  width: 100%;
  padding: 10px 24px; 
  border-radius: 100px;
`;

export const SecondaryButton = props =>

  <StyledButton
    {...props}
    variant={ButtonVariant.Outlined}>

    {props.text || props.children}
  </StyledButton>
;

SecondaryButton.displayName = 'SecondaryButton';

SecondaryButton.propTypes = {
  text: PropTypes.string,
};

SecondaryButton.defaultProps = {
  text: '',
};
