import {
  gql,
} from '@apollo/client';

export const QueryTaskDetailsPage = gql`
  query QueryTaskDetailsPage($taskInteractionsParams: TaskInteractionQueryFilterParams, $taskDetailsParams: TaskQueryFilterParams, $childTaskParams: TaskQueryFilterParams, $linkedTaskParams: TaskQueryFilterParams, $internTaskParams: TaskQueryFilterParams, $personParams: PersonQueryFilterParams) {
    taskInteractions: taskInteractionQueryFilter(params: $taskInteractionsParams) {
      statusCode
      body {
        message
        ok
        taskInteractions {
          ...TaskInteractionFragment
          task {
            ...TaskFragment
          }
          person {
            ...PersonFragment
          }
        }
      }
    }

    taskDetails: taskQueryFilterDetails(params: $taskDetailsParams) {
      body {
        tasks {
          ...TaskFragment
        }
        ok
        message
      }
      statusCode
    }

    childTaskDetails: taskQueryFilterDetails(params: $childTaskParams) {
      body {
        tasks {
          ...TaskFragment
        }
        ok
        message
      }
      statusCode
    }

    linkedTaskDetails: taskQueryFilterDetails(params: $linkedTaskParams) {
      body {
        tasks {
          ...TaskFragment
        }
        ok
        message
      }
      statusCode
    }

    internTaskDetails: taskQueryFilterDetails(params: $internTaskParams) {
      body {
        tasks {
          ...TaskFragment
        }
        ok
        message
      }
      statusCode
    }

    ownerPersonDetails: personQueryFilter(params: $personParams) {
      statusCode
      body {
        persons {
          ...PersonFragment
          personType {
            ...PersonTypeFragment
          }
          coachPerson {
            ...PersonFragment
          }
          mentorPerson {
            ...PersonFragment
          }
          schools {
              ...SchoolFragment
          }
        }
      }
    }
  }
   
  fragment PersonTypeFragment on PersonType {
      id
      title
      description
      mnemonic
  }
   
  fragment SchoolFragment on School {
      id
      title
      address
      telNo
      description
      location
      createdTimestamp
      modifiedTimestamp
      active
      mnemonic
  } 

  fragment TaskTemplateFragment on TaskTemplate {
    id
    title
    description
    requiresReview
    requiresMentorResponse
    requiresCoachResponse
    requiresMentorRating
    requiresCoachRating
    requiresSelfRating
    mnemonic
    linkedTaskTemplateId
    childTaskTemplateId
    assessmentMnemonic
    manualTaskReviewBypass
    personType {
      ...PersonTypeFragment
    }
    questions {
      ...QuestionFragment
    }
  }

  fragment AnswerFragment on Answer {
    id
    answer
    questionId
    taskId
  }

  fragment QuestionTypeFragment on QuestionType {
    id
    title
    description
    mnemonic
  }

  fragment KeyValueFragment on KeyValue {
    key
    value
  }

  fragment QuestionFragment on Question {
    id
    title
    description
    viewTitle
    viewDescription
    questionProperties {
      ...KeyValueFragment
    }
    answer {
      ...AnswerFragment
    }
    questionType {
      ...QuestionTypeFragment
    }
  }

  fragment TaskFragment on Task {
    id
    title
    session
    description
    statusMnemonic
    location
    note
    ratingPerson {
      ...PersonFragment
    }
    ratingPercent
    ratingComment
    linkedTaskId
    parentTaskId
    scheduledStartTimestamp
    scheduledEndTimestamp
    startedTimestamp
    completedTimestamp
    createdTimestamp
    taskInteractionCount
    taskTemplate {
      ...TaskTemplateFragment
    }
    reporterPerson {
      ...PersonFragment
    }
    ownerPerson {
      ...PersonFragment
    }
    schedule {
      id
      year
      week
    }
  }

  fragment TaskInteractionFragment on TaskInteraction {
    active
    attachmentPath
    commentField
    createdTimestamp
    file
    fileId
    id
    modifiedTimestamp
    questionId
    question {
      ...QuestionFragment
    }
    personId
    taskId
    task {
      ...TaskFragment
    }
  }

  fragment PersonFragment on Person {
    active
    coachPersonId
    createdTimestamp
    email
    gender
    givenName
    id
    mentorPersonId
    modifiedTimestamp
    personTypeId
    surname
    telNo
    yearOfStudy
    teachingPhaseMnemonic
  }
`;
