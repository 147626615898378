import {
  gql,
} from '@apollo/client';

export const QueryTaskList = gql`
  query TaskQueryFilter($params: TaskQueryFilterParams) {
    taskQueryFilter(params: $params) {
      body {
        tasks {
          ...TaskFragment
        }
        ok
        message
        pageInfo {
          ...PageInfoFragment
        }
      }
      statusCode
    }
  }
  
  fragment PageInfoFragment on PageInfo {
    totalResults
    indexPage
    totalPages
  }

  fragment PersonFragment on Person {
    id
    givenName
    surname
    email
  }

  fragment TaskTemplateFragment on TaskTemplate {
    id
    title
    description
    shortDescription
    requiresReview
    requiresMentorResponse
    requiresCoachResponse
    requiresMentorRating
    requiresCoachRating
    requiresSelfRating
    mnemonic
    personTypeId
    linkedTaskTemplateId
    childTaskTemplateId
    manualTaskReviewBypass
  }

  fragment TaskFragment on Task {
    id
    title
    session
    description
    statusMnemonic
    location
    linkedTaskId
    parentTaskId
    note
    ratingPerson {
      ...PersonFragment
    }
    ratingPercent
    ratingComment
    scheduledStartTimestamp
    scheduledEndTimestamp
    startedTimestamp
    completedTimestamp
    taskInteractionCount
    weeklyPlanTaskStatusMnemonic
    taskTemplate {
      ...TaskTemplateFragment
    }
    reporterPerson {
      ...PersonFragment
    }
    ownerPerson {
      ...PersonFragment
    }
    schedule {
      id
      year
      week
    }
  }
`;

export const QueryTaskDetails = gql`
  query TaskQueryFilterDetails($params: TaskQueryFilterParams) {
    taskQueryFilterDetails(params: $params) {
      body {
        tasks {
          ...TaskFragment
        }
        ok
        message
      }
      statusCode
    }
  }

  fragment PersonFragment on Person {
    id
    givenName
    surname
    email
  }

  fragment TaskTemplateFragment on TaskTemplate {
    id
    title
    description
    requiresReview
    requiresMentorResponse
    requiresCoachResponse
    requiresMentorRating
    requiresCoachRating
    requiresSelfRating
    mnemonic
    linkedTaskTemplateId
    childTaskTemplateId
    manualTaskReviewBypass
    personType {
      ...PersonTypeFragment
    }
    questions {
      ...QuestionFragment
    }
  }

  fragment PersonTypeFragment on PersonType {
    id
    title
    mnemonic
  }

  fragment AnswerFragment on Answer {
    id
    answer
    questionId
    taskId
  }

  fragment QuestionTypeFragment on QuestionType {
    id
    title
    description
    mnemonic
  }

  fragment KeyValueFragment on KeyValue {
    key
    value
  }

  fragment QuestionFragment on Question {
    id
    title
    description
    viewTitle
    viewDescription
    questionProperties {
      ...KeyValueFragment
    }
    answer {
      ...AnswerFragment
    }
    questionType {
      ...QuestionTypeFragment
    }
  }

  fragment TaskFragment on Task {
    id
    title
    session
    description
    statusMnemonic
    location
    note
    ratingPerson {
      ...PersonFragment
    }
    ratingPercent
    ratingComment
    linkedTaskId
    parentTaskId
    scheduledStartTimestamp
    scheduledEndTimestamp
    startedTimestamp
    completedTimestamp
    taskInteractionCount
    weeklyPlanTaskStatusMnemonic
    taskTemplate {
      ...TaskTemplateFragment
    }
    reporterPerson {
      ...PersonFragment
    }
    ownerPerson {
      ...PersonFragment
    }
    schedule {
      id
      year
      week
    }
  }
`;

export const FileQueryFilterDownload = gql`
  query FileQueryFilterDownload($params: FileQueryFilterParams) {
    fileQueryFilterDownload(params: $params) {
      body {
        files {
          ...FileFragment
        }
        message
        ok
      }
      statusCode
    }
  }

  fragment FileFragment on File {
    id
    mime
    name
    s3BucketName
    s3Path
    signedUrl
    tag
  }
`;

export const TaskInteractions = gql`
  query TaskInteractionQueryFilter($params: TaskInteractionQueryFilterParams) {
    taskInteractionQueryFilter(params: $params) {
      statusCode
      body {
        message
        ok
        taskInteractions {
          ...TaskInteractionFragment
          task {
            ...TaskFragment
          }
          person {
            ...PersonFragment
          }
        }
      }
    }
  }

  fragment TaskInteractionFragment on TaskInteraction {
    active
    attachmentPath
    commentField
    createdTimestamp
    file
    fileId
    id
    modifiedTimestamp
    personId
    taskId
    task {
      ...TaskFragment
    }
  }

  fragment PersonFragment on Person {
    active
    coachPersonId
    createdTimestamp
    email
    gender
    givenName
    id
    mentorPersonId
    modifiedTimestamp
    personTypeId
    surname
    telNo
    yearOfStudy
  }

  fragment TaskFragment on Task {
    active
    completedTimestamp
    createdTimestamp
    id
    location
    modifiedTimestamp
    note
    ownerPersonId
    reporterPersonId
    scheduleId
    scheduledEndTimestamp
    scheduledStartTimestamp
    startedTimestamp
    statusMnemonic
    taskTemplateId
    title
  }
`;
