import {
  Typography,
} from '@mui/material';

import {
  TypographyVariant,
} from 'enums';

export const TitleLarge = props =>

  <Typography
    color="secondary"
    {...props}
    fontSize="1.188rem"
    variant={TypographyVariant.Subtitle2}>

    {props.children}
  </Typography>
;

TitleLarge.displayName = 'TitleLarge';
