import {
  isBefore,
  isSameWeek,
  add,
  isAfter,
} from 'date-fns';
import { IdentityGroupType, QuestionProperties, StatusMnemonic, TaskTypeMnemonic } from "enums";

export const addTwoDaysForMentorReview = (td, userType) => {
  const isMentor = userType === IdentityGroupType.Mentor || userType === IdentityGroupType.Coach;
  const requiresReview = td?.taskTemplate?.requiresReview;
  return isMentor && requiresReview ? add(new Date(td.scheduledEndTimestamp), { days: 2 }).toISOString() : td.scheduledEndTimestamp;
}

export const getCalculatedTaskFields = (task, userType) => {
  const currentDate = new Date();
  const effectiveStatus = mapEffectiveStatus(task.statusMnemonic);
  const isOverdue = isBefore(addTwoDaysForMentorReview(task, userType), currentDate) && effectiveStatus !== StatusMnemonic.Completed;
  const isMissed = isOverdue && !isSameWeek(addTwoDaysForMentorReview(task, userType), currentDate, {weekStartsOn: 1});
  const isUpcoming = isBefore(currentDate, task.scheduledStartTimestamp) && effectiveStatus !== StatusMnemonic.Completed;

  const metaStatuses = [];

  if (isOverdue) {
    metaStatuses.push('OVERDUE');
  }
  if (isMissed) {
    metaStatuses.push('MISSED');
  }

  return {
    isOverdue,
    isMissed,
    metaStatuses,
    effectiveStatus: mapEffectiveStatus(task.statusMnemonic),
    isUpcoming,
  }
};

const mapEffectiveStatus = (status) => {
  switch(status) {

    case StatusMnemonic.Open:
    case StatusMnemonic.Scheduled:
      return StatusMnemonic.Open;

    case StatusMnemonic.InProgress:
    case StatusMnemonic.Rejected:
    case StatusMnemonic.AwaitMentor:
    case StatusMnemonic.AwaitCoach:
    case StatusMnemonic.Review:
      return StatusMnemonic.InProgress;

    case StatusMnemonic.Completed:
    case StatusMnemonic.Approved:
    case StatusMnemonic.Absent:
      return StatusMnemonic.Completed;

    default:
      return StatusMnemonic.Open;
  }
};

export const buildTaskUpsertVariables = (tasks) => {

  const mappedTasks = tasks.map(task => {

    const resultTask = {};

      if (task.id) {
        resultTask['id'] = task.id;
      }

      if (task.taskTemplateId) {
        resultTask['taskTemplateId'] = task.taskTemplateId;
      }

      if (task.status) {
        resultTask['statusMnemonic'] = task.status;
      }

      if (task.title) {
        resultTask['title'] = task.title;
      }

      if (task.description) {
        resultTask['description'] = task.description;
      }

      if (task.location) {
        resultTask['location'] = task.location;
      }

      if (task.note) {
        resultTask['note'] = task.note;
      }

      if (task.ownerPersonId) {
        resultTask['ownerPersonId'] = task.ownerPersonId;
      }

      if (task.reporterPersonId) {
        resultTask['reporterPersonId'] = task.reporterPersonId;
      }

      if (task.ratingPersonId && task.ratingPercent) {
        resultTask['ratingPersonId'] = task.ratingPersonId;
        resultTask['ratingPercent'] = task.ratingPercent;
        resultTask['ratingComment'] = task.ratingComment || '';
      }

      if (task.scheduledStartTimestamp) {
        resultTask['scheduledStartTimestamp'] = task.scheduledStartTimestamp;
      }

      if (task.scheduledEndTimestamp) {
        resultTask['scheduledEndTimestamp'] = task.scheduledEndTimestamp;
      }

      if (task.startedTimestamp) {
        resultTask['startedTimestamp'] = task.startedTimestamp;
      }

      if (task.completedTimestamp) {
        resultTask['completedTimestamp'] = task.completedTimestamp;
      }

      if (task.linkedTaskId) {
        resultTask['linkedTaskId'] = task.linkedTaskId;
      }

      return resultTask;
  });

  return {
    variables: {
      params: {
        tasks: mappedTasks,
      },
    }
  };
}

export const getTaskCardSetup = (task, userType, user) => {

  return {
    buttonLabel: getButtonLabelFromStatus(task, userType, user),
    buttonColor: getButtonColorFromStatus(task, userType),
    statusLabel: getStatusLabelFromStatusAndOverdue(task.status, task.calculatedFields, userType),
    backgroundStyle: getCardStylingBackground(task.status, task.calculatedFields, userType),
    borderStyle: getCardStylingBorder(task.status, task.calculatedFields, userType),
    statusStyle: getCardStatusStyling(task.status, task.calculatedFields),
  };
}

export const invalidEarlySubmissionTaskTemplates = [
    TaskTypeMnemonic.InternCheckin,
    TaskTypeMnemonic.InternCheckout
];


const getButtonColorFromStatus = (task, userType) => {

  if (task.calculatedFields.isMissed) {
    return 'error';
  }

  if (userType === IdentityGroupType.Intern && task.status === StatusMnemonic.Rejected) {
    return 'error';
  }

  if (task.calculatedFields.isOverdue) {
    return 'tertiary';
  }

  return 'primary';
};

const getButtonLabelFromStatus = (task, userType, user) => {

  if (task.calculatedFields.isMissed) {
    return 'View';
  }

  if(userType === IdentityGroupType.Intern){
    switch (task.status) {
      case StatusMnemonic.Open:
      case StatusMnemonic.Overdue:
      case StatusMnemonic.Scheduled:
        return `Start`;
      case StatusMnemonic.InProgress:
        return `Continue`;
      case StatusMnemonic.Rejected:
        return `Review`;
      default:
        return `View`;
    }
  }

  if((userType === IdentityGroupType.Mentor || userType === IdentityGroupType.Coach) && task.ownerPersonId === user.id){
    switch (task.status) {
      case StatusMnemonic.Open:
      case StatusMnemonic.Overdue:
      case StatusMnemonic.Scheduled:
        return `Start`;
      case StatusMnemonic.InProgress:
        return `Continue`;
      case StatusMnemonic.Review:
        return `Review`;
      default:
        return `View`;
    }
  }

  return 'View';
};

const getStatusLabelFromStatusAndOverdue = (status, {isOverdue, isMissed}, userType) => {

  if (isMissed) {
    return 'Missed';
  }

  let statusText = isOverdue ? `Overdue | ` : ``;

  if(userType === IdentityGroupType.Intern || userType === IdentityGroupType.Admin){
    switch (status) {
      case StatusMnemonic.Scheduled:
        statusText = isOverdue ? 'Overdue': '';
        break;
      case StatusMnemonic.Open:
        statusText = isOverdue ? 'Overdue': '';
        break;
      case StatusMnemonic.Completed:
        statusText = 'Completed';
        break;
      case StatusMnemonic.InProgress:
        statusText += 'In Progress';
        break;
      case StatusMnemonic.Review:
        statusText += 'Pending Mentor Approval';
        break;
      case StatusMnemonic.AwaitMentor:
        statusText += 'Pending Mentor Comment';
        break;
      case StatusMnemonic.AwaitCoach:
        statusText += 'Pending Mentor Comment';
        break;
      case StatusMnemonic.Rejected:
        statusText += 'Declined';
        break;
      case StatusMnemonic.Approved:
        statusText += 'Approved';
        break;
      case StatusMnemonic.Absent:
        statusText = 'Absent';
        break;
      default:
        statusText += '';
    }
  }


  if(userType === IdentityGroupType.Mentor || userType === IdentityGroupType.Coach){
    switch (status) {
      case StatusMnemonic.Scheduled:
        statusText = isOverdue ? 'Overdue': '';
        break;
      case StatusMnemonic.Open:
        statusText = isOverdue ? 'Overdue': '';
        break;
      case StatusMnemonic.Completed:
        statusText = 'Completed';
        break;
      case StatusMnemonic.InProgress:
        statusText += 'Review In Progress';
        break;
      case StatusMnemonic.Review:
        statusText += 'Requesting Your Approval';
        break;
      case StatusMnemonic.Rejected:
        statusText += 'Pending Intern Revision';
        break;
      case StatusMnemonic.AwaitMentor:
        statusText += 'Awaiting Mentor Response';
        break;
      case StatusMnemonic.AwaitCoach:
        statusText += 'Awaiting Mentor Response';
        break;
      case StatusMnemonic.Approved:
        statusText += 'Approved';
        break;
      default:
        statusText += '';
    }
  }

  return statusText;

};

const getCardStylingBackground = (status, { isOverdue, isMissed }, userType) => {

  if (isMissed) {
    return 'error.e95';
  }

  if(userType === IdentityGroupType.Intern){
    if(isOverdue){
      switch (status) {
        case StatusMnemonic.Scheduled:
        case StatusMnemonic.Open:
        case StatusMnemonic.InProgress:
          return 'tertiary.t99';
        default:
          return 'primary.contrastText';
      }
    }

    switch (status) {
      case StatusMnemonic.Scheduled:
      case StatusMnemonic.Open:
      case StatusMnemonic.InProgress:
        return 'primary.p99';
      case StatusMnemonic.Rejected:
        return 'error.e99';
      case StatusMnemonic.Absent:
        return '#F4F5F5';
      default:
        return 'primary.contrastText';
    }


  }

  if(userType === IdentityGroupType.Mentor || userType === IdentityGroupType.Coach || userType === IdentityGroupType.Admin){
    if(isOverdue){
      switch (status) {
        case StatusMnemonic.Scheduled:
        case StatusMnemonic.InProgress:
        case StatusMnemonic.Open:
          return 'tertiary.t99';
        default:
          return 'primary.contrastText';
      }
    }

    switch (status) {
      case StatusMnemonic.Review:
      case StatusMnemonic.Scheduled:
      case StatusMnemonic.Open:
      case StatusMnemonic.InProgress:
        return 'primary.p99';
      default:
        return 'primary.contrastText';
    }
  }

  return 'primary.contrastText';

};

const getCardStylingBorder = (status, { isOverdue, isMissed }, userType) => {

  if (isMissed) {
    return 'error.e40';
  }

  if(userType === IdentityGroupType.Intern){
    if(isOverdue){
      switch (status) {
        case StatusMnemonic.Review:
          return 'tertiary.t30';
        default:
          return 'primary.contrastText';
      }
    }

    switch (status) {
      case StatusMnemonic.Review:
        return 'primary.p80';
      default:
        return 'primary.contrastText';
    }
  }

  if(userType === IdentityGroupType.Mentor || userType === IdentityGroupType.Coach || userType === IdentityGroupType.Admin){
    switch (status) {
      case StatusMnemonic.Rejected:
        return isOverdue ? 'tertiary.t80' : 'primary.p80'
      default:
        return 'primary.contrastText';
    }
  }


  return 'primary.contrastText';
};

const getCardStatusStyling = (status, { isOverdue, isMissed }) => {

  if (isMissed) {
    return 'error';
  }

  if(isOverdue){
    return 'tertiary.t20';
  }

  switch (status) {
    case StatusMnemonic.Rejected:
      return 'error';
    case StatusMnemonic.Absent:
      return 'secondary.s30';
    default:
      return 'primary.p30';
  }

};

export const getViewStatusSetup = (task) => {
  if (task == null) {
    return;
  }

  return {
    friendlyStatus: getFriendlyStatus(task),
    color: getStatusColor(task),
  }
}

const getStatusColor = (task) => {
  if (task.calculatedFields.isMissed) {
    return 'error.e30';
  }

  switch(task.status) {
    case StatusMnemonic.AwaitMentor:
    case StatusMnemonic.Review:
      return 'tertiary.t30';
    case StatusMnemonic.Rejected:
      return 'error.e30';
    case StatusMnemonic.Completed:
    case StatusMnemonic.Approved:
      return 'primary.p30';
    case StatusMnemonic.Open:
      return 'secondary.main'
  }
}

const getFriendlyStatus = (task) => {

  if (task.calculatedFields.isMissed) {
    return 'This task has been missed.';
  }

  const effectiveStatus = task.calculatedFields.effectiveStatus;

  let resultText = '';

  if (effectiveStatus === StatusMnemonic.Completed) {
    resultText = 'This task has been ';
    switch (task.status) {
      case StatusMnemonic.Absent:
        resultText += 'marked as absent';
        break;
      case StatusMnemonic.Approved:
        resultText += 'approved';
        break;
      default:
        resultText += 'completed' ;
    }
  }

  if (effectiveStatus === StatusMnemonic.InProgress) {
    resultText = 'This task is ';
    switch (task.status) {
      case StatusMnemonic.Review:
        resultText += 'pending mentor approval' ;
        break;
      case StatusMnemonic.Rejected:
        resultText += 'awaiting revision after being declined';
        break;
      case StatusMnemonic.AwaitMentor:
        resultText += 'pending mentor comment';
        break;
      default:
        resultText += 'in progress';
    }
  }

  if (effectiveStatus === StatusMnemonic.Open) {
    resultText += 'This task is open';
  }

  if (task.calculatedFields.isOverdue) {
    resultText += ' and is overdue';
  }

  return resultText + '.';
};

export const shouldShowTaskEditButton = (task) => {
  const forbiddenMap = {
    [TaskTypeMnemonic.InternCheckin]: true,
    [TaskTypeMnemonic.InternCheckout]: true,
  };

  return !forbiddenMap[task.taskMnemonic] && !task.calculatedFields.isMissed &&
    (task.calculatedFields.effectiveStatus === StatusMnemonic.Open || task.status === StatusMnemonic.InProgress);
}

export const mapTaskFromQuery = (taskDetails, userType = null) => {

  if (taskDetails == null) {
    return;
  }

  return {
    id: taskDetails.id,
    title: taskDetails.title,
    session: taskDetails.session,
    description: taskDetails.description || taskDetails.taskTemplate?.description,
    shortDescription: taskDetails.shortDescription || taskDetails.taskTemplate?.shortDescription,
    status: taskDetails.statusMnemonic,
    location: taskDetails.location,
    note: taskDetails.note,
    scheduleId: taskDetails.scheduleId || taskDetails.schedule?.id,
    scheduledStartTimestamp: taskDetails.scheduledStartTimestamp,
    scheduledEndTimestamp: taskDetails.scheduledEndTimestamp,
    startedTimestamp: taskDetails.startedTimestamp,
    completedTimestamp: taskDetails.completedTimestamp,
    createdTimestamp: taskDetails.createdTimestamp,
    taskMnemonic: taskDetails.taskTemplate?.mnemonic,
    requiresReview: taskDetails.taskTemplate?.requiresReview,
    requiresMentorResponse: taskDetails.taskTemplate?.requiresMentorResponse,
    requiresCoachResponse: taskDetails.taskTemplate?.requiresCoachResponse,
    requiresMentorRating: taskDetails.taskTemplate?.requiresMentorRating,
    requiresCoachRating: taskDetails.taskTemplate?.requiresCoachRating,
    requiresSelfRating: taskDetails.taskTemplate?.requiresSelfRating,
    ratingPercent: taskDetails.ratingPercent,
    ratingComment: taskDetails.ratingComment,
    ratingPersonId: taskDetails.ratingPerson?.id,
    ratingPersonGivenName: taskDetails.ratingPerson?.givenName,
    ratingPersonSurname: taskDetails.ratingPerson?.surname,
    ratingPersonEmail: taskDetails.ratingPerson?.email,
    taskInteractionCount: taskDetails.taskInteractionCount,
    ownerPersonId: taskDetails.ownerPerson?.id,
    ownerPersonGivenName: taskDetails.ownerPerson?.givenName,
    ownerPersonSurname: taskDetails.ownerPerson?.surname,
    ownerPersonEmail: taskDetails.ownerPerson?.email,
    parentTaskId: taskDetails.parentTaskId,
    linkedTaskId: taskDetails.linkedTaskId,
    taskTemplateId: taskDetails.taskTemplate?.id,
    taskTemplateTitle: taskDetails.taskTemplate?.title,
    childTaskTemplateId: taskDetails.taskTemplate?.childTaskTemplateId,
    linkedTaskTemplateId: taskDetails.taskTemplate?.linkedTaskTemplateId,
    questions: buildTaskQuestions(taskDetails.taskTemplate?.questions),
    calculatedFields: getCalculatedTaskFields(taskDetails, userType),
    assessmentMnemonic: taskDetails?.taskTemplate?.assessmentMnemonic,
    manualTaskReviewBypass: taskDetails?.taskTemplate?.manualTaskReviewBypass,
  }
};

const buildTaskQuestions = (questions) => {
  if (questions == null) {
    return;
  }

  const mnemonicIdMap = {};

  questions.forEach(q => {
    const kv = q.questionProperties?.find(qp => qp.key === QuestionProperties.Mnemonic);
    if (kv != null) {
      mnemonicIdMap[kv.value] = q.id;
    }
  });

  return questions.map(q => {
    return {
      id: q.id,
      title: q.title,
      description: q.description,
      viewTitle: q.viewTitle,
      viewDescription: q.viewDescription,
      inputType: q.questionType?.mnemonic,
      answer: q.answer?.answer,
      ...processQuestionProperties(q.questionProperties, mnemonicIdMap),
    };
  }).sort((a, b) => {
    if (a.group === b.group) {
      return a.order - b.order;
    } else {
      return a.group - b.group;
    }
  });
};

const processQuestionProperties = (questionProperties, mnemonicIdMap) => {
  if (questionProperties == null || questionProperties.length < 1) {
    return {};
  }

  const KVDictionary = {};

  questionProperties.forEach(p => {
    KVDictionary[p.key] = p.value;
  });

  return {
    required: KVDictionary[QuestionProperties.Required] === 'true',
    groupName: KVDictionary[QuestionProperties.Group] && Number(KVDictionary[QuestionProperties.Order]) === 1 && JSON.parse(KVDictionary[QuestionProperties.Group])?.Name || undefined,
    group: KVDictionary[QuestionProperties.Group] && Number(JSON.parse(KVDictionary[QuestionProperties.Group])?.Order) || -1,
    sectionTitle: KVDictionary[QuestionProperties.Group] && JSON.parse(KVDictionary[QuestionProperties.Group])?.SectionTitle || undefined,
    order: KVDictionary[QuestionProperties.Order] && Number(KVDictionary[QuestionProperties.Order]) || -1,
    mnemonic: KVDictionary[QuestionProperties.Mnemonic] && KVDictionary[QuestionProperties.Mnemonic] || undefined,
    pqMnemonic: KVDictionary[QuestionProperties.ParentQuestion] && JSON.parse(KVDictionary[QuestionProperties.ParentQuestion])?.questionMnemonic || undefined,
    pqId: KVDictionary[QuestionProperties.ParentQuestion] && mnemonicIdMap[JSON.parse(KVDictionary[QuestionProperties.ParentQuestion])?.questionMnemonic] || undefined,
    pqValue: KVDictionary[QuestionProperties.ParentQuestion] && JSON.parse(KVDictionary[QuestionProperties.ParentQuestion])?.questionValue || undefined,
    default: KVDictionary[QuestionProperties.Default] && KVDictionary[QuestionProperties.Default] || undefined,
    options: KVDictionary[QuestionProperties.Options] && JSON.parse(KVDictionary[QuestionProperties.Options]) || [],
    placeholder: KVDictionary[QuestionProperties.Placeholder] && KVDictionary[QuestionProperties.Placeholder] || undefined,
    min: KVDictionary[QuestionProperties.Min] && Number(KVDictionary[QuestionProperties.Min]) || undefined,
    max: KVDictionary[QuestionProperties.Max] && Number(KVDictionary[QuestionProperties.Max]) || undefined,
    requireReview: KVDictionary[QuestionProperties.RequireReview] && KVDictionary[QuestionProperties.RequireReview] || undefined,
    childUpdateField: KVDictionary[QuestionProperties.ChildUpdateField] && KVDictionary[QuestionProperties.ChildUpdateField] || undefined,
    regex: KVDictionary[QuestionProperties.Regex]  || undefined,
    evalCritTitle: KVDictionary[QuestionProperties.EvalCrit] && JSON.parse(KVDictionary[QuestionProperties.EvalCrit])?.title || undefined,
    evalCritItems: KVDictionary[QuestionProperties.EvalCrit] && JSON.parse(KVDictionary[QuestionProperties.EvalCrit])?.items || undefined,
  }
};

export const mapTaskInteractionsFromQuery = (t) => {
  if (t == null) {
    return;
  }

  let question;

  if (t?.question) {
    const result = buildTaskQuestions([t?.question]);

    question = result[0];
  }

  return {
    id: t?.id,
    createdTimestamp: t?.createdTimestamp,
    commentField: t?.commentField,
    givenName: t?.person?.givenName,
    surname: t?.person?.surname,
    personId: t?.person?.id,
    questionId: t?.questionId,
    question: question,
  };
};

/**
 * Iterates over the list of tasks and sets the `calculatedFields.inactive` field to true if its
 * parent task is not completed; false otherwise. Note that this operation mutates the tasks in the
 * array, but the array's identity will remain unchanged.
 * @param {Object[]} tasks The array of tasks to mutate
 * @returns {Object[]} The same array of tasks with the `calculatedFields.inactive` field set for
 * each task.
 */
export const markInactiveChildTasks = (tasks) => {

  if (!Array.isArray(tasks)) {
    return tasks;
  }

  for (const task of tasks) {

    if (task.parentTaskId == null) {

      task.calculatedFields.inactive = false;
      continue;
    }

    const parentTask = tasks.find(task => task.id === task.parentTaskId);

    // A task is inactive if its parent task is *not* completed.
    task.calculatedFields.inactive = !!(parentTask && parentTask.calculatedFields.effectiveStatus !== StatusMnemonic.Completed);
  }

  return tasks;
};

/**
 * Filters the list of tasks so that only the oldest task per owner is returned.
 * @param {Object[]} tasks The list of tasks to filter.
 * @returns {Object[]}
 */
export const getOldestTaskPerOwner = (tasks) => {

  if (!Array.isArray(tasks) || tasks.length <= 0) {
    return [];
  }

  const internTaskMap = new Map();

  for (const task of tasks) {

    if (
      !task?.ownerPersonId ||
      (
        internTaskMap.has(task.ownerPersonId) &&
        isAfter(
          task.scheduledStartTimestamp,
          internTaskMap.get(task.ownerPersonId).scheduledStartTimestamp,
        )
      )
    ) {
      continue;
    }

    internTaskMap.set(task.ownerPersonId, task);
  }

  return Array.from(internTaskMap.values());
};
