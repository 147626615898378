import {
    RoutePath,
  } from 'enums';
  
  import {
    HelpAndSupport,
  } from 'ui/help-support';
  
  /**
   * @type {RouteConfig[]}
   */
  export const SupportRoutes = [{
      element: <HelpAndSupport/>,
      path: RoutePath.HelpAndSupport,
      children: [],
    },
  ];
  