import {
  styled,
} from '@mui/material';

import {
  AlignItems,
  JustifyContent,
} from 'enums';

import {
  HFillBox,
} from 'ui/common/components/boxes/HFillBox';

export const HFillBoxVH = styled(HFillBox)`
  align-items: ${AlignItems.Center};
  justify-content: ${JustifyContent.Center};
`;

HFillBoxVH.displayName = 'HFillBoxVH';
