import {
  JourneyName,
  RoutePath,
  ScreenName,
} from 'enums';

import {
  BodySmall,
  HBoxV,
  HeadlineLarge,
  PrimaryButton,
  Scene,
  TitleMedium,
  TitleSmall,
  VContainer,
  VFillContainer,
  VFillContainerV,
} from 'ui/common';


import { format, getHours } from 'date-fns';
import { Grid, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const StyledTitleSmall = styled(TitleSmall)`
  margin-top: 2.5rem;
`;


const StyledContainer = styled(VContainer)`
  padding-left: 0 !important;
`;

const StyledBaseContainer = styled(VFillContainer)`
  padding: 0 0.5rem;
  flex: 0;
`;

const ActionBox = styled(HBoxV)`
  border-radius: 12px;
  padding: 12px;
  margin-top: 12px;
  border-width: 1px;
  border-style: solid;
  height: 100%;
  max-height:7em;
  min-height:4em;
  `
;

const StyledGrid = styled(Grid)`
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const ActionBoxButton = styled(PrimaryButton)`
  width: unset;
  box-shadow: none;
`;

export const AdminHome = () => {
  const navigate = useNavigate();
  const currentDate = new Date();

  const adminActions = [
    {
      title: ScreenName.AdminUserOnboarding,
      description: 'Click here to easily add new users, update existing user details, and add schools to the system.',
      actionButtonText: 'Open',
      actionButtonNavigation: RoutePath.AdminUserOnboarding
    },
    {
      title: ScreenName.Reporting,
      description: 'Click here to create and download reports, including assessment, task, person details, and attendance reports.',
      actionButtonText: 'Open',
      actionButtonNavigation: RoutePath.Reporting 
    },
    {
      title: ScreenName.TaskList,
      description: "Click here to view a selected user's task list for their last, current, and next week.",
      actionButtonText: 'Open',
      actionButtonNavigation: RoutePath.TaskList
    }
  ]

  return (

    <Scene
    loading={false}
    headerVisible={true}
    footerVisible={true}>

      <StyledBaseContainer>

        <StyledContainer>
          <StyledTitleSmall>Good {getHours(currentDate) < 12 && 'Morning' || 'Afternoon'}, it's {format(currentDate, 'HH:mm')} on</StyledTitleSmall>
          <HeadlineLarge>{format(new Date(), 'dd MMMM')}</HeadlineLarge>
          <TitleMedium>Get started with your Admin tasks</TitleMedium>
        </StyledContainer>

        <StyledContainer>
          <StyledGrid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {adminActions.map((action) => (
              <Grid key={action.title} item xs={12} sm={6} md={4}>
                <ActionBox backgroundColor={"primary.p99"} borderColor={"primary.p80"}>
                  <VFillContainerV>
                    <TitleMedium>{action.title}</TitleMedium>
                    <BodySmall color="primary">{action.description}</BodySmall>
                  </VFillContainerV>
                  <ActionBoxButton text={action.actionButtonText} onClick={() => navigate(action.actionButtonNavigation)} />
                </ActionBox>
              </Grid>

            ))}
          </StyledGrid>
        </StyledContainer> 

    </StyledBaseContainer>
  </Scene>
  );
};

AdminHome.displayName = 'AdminHome';

AdminHome.context = {
  screen: ScreenName.Home,
  journey: JourneyName.Home,
  component: AdminHome.displayName,
};
