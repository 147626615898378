import React from 'react';

import {
  useRecoilState,
} from 'recoil';

import {
  AccessTokenExpiryBuffer,
  DefaultIdentity,
  IdentityStatus,
  LogoutReason,
} from 'enums';

import {
  IdentityAtom,
} from 'atoms';

import {
  areTokensValid,
  getStoredIdentity,
  parseJwt,
  removeStoredIdentity,
  storeIdentity,
} from 'common';

import {
  useInsights,
} from 'hooks/useInsights';

import {
  useAuthentication,
} from 'hooks/identity/useAuthentication';

import {
  useStorage,
} from 'hooks/useStorage';

/**
 * @typedef {Object} LoginResult
 * @property {boolean} success Whether or not the login succeeded.
 * @property {Tokens} [tokens] The auth tokens, if the login succeeded.
 * @property {Error} [error] The error, if the login failed.
 * @property {Object} [extras] Any kind of extra data (the shape depends on what auth method is
 * being used.)
 */

/**
 * @callback loginCallback
 * @param {Object} [args] Whatever args need to be passed in to the login function. The shape of
 * these arguments depends on what auth method is being used.
 * @returns {Promise<LoginResult>}
 */

/**
 * @callback forgotPasswordCallback
 * @param {Object} args Whatever args need to be passed in to the forgotPassword function. The shape
 * of these arguments depends on what auth method is being used.
 * @returns {Promise<void>}
 */

/**
 * @callback resetPasswordCallback
 * @param {Object} args Whatever args need to be passed in to the resetPassword function. The shape
 * of these arguments depends on what auth method is being used.
 * @returns {Promise<void>}
 */

/**
 * @callback logoutCallback
 * @param {Tokens} tokens
 * @returns {Promise<void>}
 */

/**
 * @callback refreshAuthenticationCallback
 * @param {Tokens} tokens
 * @returns {Promise<Tokens>}
 */

/**
 * @callback isAuthenticationValidCallback
 * @param {Tokens} tokens
 * @returns {boolean}
 */

/**
 * @callback getAuthHeadersCallback
 * @param {Tokens} tokens
 * @returns {Object.<string, string>} An object where each key represents a header and each value
 * represents the value for that header.
 */

/**
 * @typedef {Object} AuthenticationHook
 * @property {loginCallback} login
 * @property {logoutCallback} logout
 * @property {refreshAuthenticationCallback} [refreshAuthentication]
 * @property {getAuthHeadersCallback} getAuthHeaders
 */

/**
 * @param {Object} params
 * @param {Object} params.context The context object to use when logging errors to insights.
 */
export const useIdentity = ({
  context,
}) => {

  const {
    traceError,
    traceWarning,
    traceInformation,
  } = useInsights({
    context,
  });

  const [
    identity,
    setIdentity,
  ] = useRecoilState(IdentityAtom);

  const authentication = useAuthentication({
    context,
  });

  const {
    sanitiseItems,
  } = useStorage({
    context,
  });

  /**
   * Attempts to authenticate the current user
   * @param {Object} [args] An array of arguments that will be passed in to the underlying API
   * function. What arguments are required depends on the current platform.
   * @returns {Promise<{success: boolean, [extras]: Object}>} Success will be true if the user was
   * successfully authenticated and false otherwise. The `extras` object returns additional data
   * depending on what auth method is being used. If authentication failed then the `extras` object
   * will contain an `error` property which is the error that caused the failure.
   */
  const login = async (args) => {

    traceInformation('Starting login...');

    if (identity.identityStatus === IdentityStatus.LoggedIn) {

      traceInformation('User is already logged in.');

      return {
        success: true,
      };
    }

    try {

      const {
        success,
        tokens,
        error,
        extras,
      } = await authentication.login(args);

      if (!success) {

        traceError(error);

        return {
          success: false,
          extras,
        };
      }

      // If not even an access token has been returned by the login function then we know that the
      // user isn't logged in.
      if (!tokens.access) {

        traceError(new Error('No access tokens provided after login'), tokens);
        setIdentity(DefaultIdentity);

        return {
          success: false,
          extras,
        };
      }

      let user = {};

      if (tokens.id) {

        const parsedId = parseJwt(tokens.id);

        let group = parsedId['cognito:groups'] && parsedId['cognito:groups'][0];

        if (parsedId['cognito:groups'] && parsedId['cognito:groups'].length > 0) {
          if (parsedId['cognito:groups'].find(g => g === 'Admin')) {
            group = 'Admin';
          }
        }

        user = {
          id: parsedId.sub,
          email: parsedId.email,
          name: parsedId.given_name,
          surname: parsedId.family_name,
          group: group,
        };
      }

      setIdentity(prevIdentity => ({
        ...prevIdentity,
        tokens,
        user: {
          ...DefaultIdentity.user,
          ...user,
        },
        identityStatus: IdentityStatus.LoggedIn,
      }));

      return {
        success: true,
        extras,
      };
    } catch (error) {

      // No need to trace error, already traced in useAuthentication.

      setIdentity(DefaultIdentity);

      return {
        success: false,
        extras: {
          error: error.message,
        },
      };
    }
  };

  /**
   * @param {Object} [params]
   * @param {boolean} [params.sessionExpired] Indicates if this logout is called as a result of the
   * session expiring. If true then a "Session Expired" modal will be
   * @returns {Promise<void>}
   */
  const logout = React.useCallback(async ({
    sessionExpired = false,
  } = {}) => {

    // Assumption is that all storage items that were set with session=false are expected to persist
    sanitiseItems();

    authentication.logout(identity.tokens)
      .catch(() => {
        // Ignore, error is traced in useAuthentication.
      });

    setIdentity({
      ...DefaultIdentity,
      logoutReason: (sessionExpired && LogoutReason.SessionExpired) || LogoutReason.UserInitiated,
      initialized: true,
    });
  }, [
    authentication,
    identity.tokens,
    setIdentity,
    sanitiseItems,
  ]);

  /**
   * @returns {Promise<void>}
   */
  const forgotPassword = React.useCallback(async (args) => {

    if (!authentication.forgotPassword) {

      traceWarning('forgotPassword is undefined.');
      return;
    }

    await authentication.forgotPassword(args);
  }, [
    authentication,
    traceWarning,
  ]);

  /**
   * @returns {Promise<void>}
   */
  const resetPassword = React.useCallback(async (args) => {

    if (!authentication.resetPassword) {
      traceWarning('resetPassword is undefined.');
      return;
    }

    await authentication.resetPassword({
      ...args,
      tokens: identity.tokens,
    });
  }, [
    authentication,
    traceWarning,
    identity,
  ]);

  /**
   * @returns {Promise<Tokens|undefined>}
   */
  const getTokens = React.useCallback(async () => {

    // If we are logged out then simply return.
    if (identity.identityStatus === IdentityStatus.LoggedOut) {
      traceInformation('User is logged out, no tokens to check.');
      return;
    }

    // If we are logged in and the current tokens are still valid then return them.
    if (
      areTokensValid({
        tokens: identity.tokens,
        buffer: AccessTokenExpiryBuffer,
      })
    ) {
      return identity.tokens;
    }

    // Log out when the current authentication method doesn't have a refresh method.
    if (!authentication.refreshAuthentication) {

      traceInformation('No refresh mechanism specified, user will be logged out.');

      await logout({
        sessionExpired: true,
      });

      return;
    }

    // Try to refresh the tokens. If an error occurs then log the user out.
    try {

      const tokens = await authentication.refreshAuthentication(identity.tokens);

      setIdentity(prevIdentity => ({
        ...prevIdentity,
        tokens,
        identityStatus: IdentityStatus.LoggedIn,
      }));

      return tokens;
    } catch (error) {

      // No need to trace error, already traced in useAuthentication.

      await logout({
        sessionExpired: true,
      });
    }
  }, [
    authentication,
    identity.identityStatus,
    identity.tokens,
    logout,
    setIdentity,
    traceInformation,
  ]);

  /**
   * @returns {Promise<Object<string, string>|undefined>}
   */
  const getAuthHeaders = async () => {

    const tokens = await getTokens();

    if (!tokens) {
      traceWarning('No tokens retrieved from getTokens');
      return;
    }

    return authentication.getAuthHeaders(tokens);
  };

  // This effect stores the current identity while the user is logged in, and clears it once he is
  // logged out.
  React.useEffect(() => {

    if (!identity.initialized) {

      getStoredIdentity()
        .then(storedIdentity => {

          setIdentity({
            ...storedIdentity,
            initialized: true,
          });
        });

      return;
    }

    if (identity.identityStatus === IdentityStatus.LoggedOut) {

      removeStoredIdentity();
      return;
    }

    storeIdentity({
      identity,
    });

  }, [
    identity,
    setIdentity,
  ]);

  return {
    loading: !identity.initialized,
    loggedIn: identity.identityStatus === IdentityStatus.LoggedIn,
    identityStatus: identity.identityStatus,
    logoutReason: identity.logoutReason,
    user: identity.user,
    login,
    logout,
    getAuthHeaders,
    getTokens,
    forgotPassword,
    resetPassword,
  };
};
