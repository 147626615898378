import { 
  AdminHome,
  InternHome,
  MentorHome
 } from './dashboards';

import {
  JourneyName,
  ScreenName,
  IdentityGroupType
} from 'enums';

import {
  useIdentity,
  useStorage,
} from 'hooks';

import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Home = () => {
  const navigate = useNavigate();
  const { getItem } = useStorage();
  const { user } = useIdentity(Home.context);
  const userType = user.group || IdentityGroupType.Intern;

  React.useEffect(() => {
    const checkOnboarded = async () => {
      const onboardedValue = await getItem({ key: 'onboarded' });
  
      if (onboardedValue === null || !onboardedValue ) {
        navigate('/onboarding');
      }
    };
  
    checkOnboarded();
  }, [getItem, navigate]);
  
  return (

    <React.Fragment>
      {
        userType === IdentityGroupType.Intern &&
        <InternHome/>
      }

      {
        (userType === IdentityGroupType.Mentor || userType === IdentityGroupType.Coach) &&
        <MentorHome/>
      }

      {
        userType === IdentityGroupType.Admin &&
        <AdminHome/>
      }

    </React.Fragment>
  );
};

Home.displayName = 'Home';

Home.context = {
  screen: ScreenName.Home,
  journey: JourneyName.Home,
  component: Home.displayName,
};
