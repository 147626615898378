import {
  styled,
} from '@mui/material';

import {
  AlignItems,
} from 'enums';

import {
  VContainer,
} from 'ui/common/components/containers/VContainer';

export const VContainerH = styled(VContainer)`
  align-items: ${AlignItems.Center};
`;

VContainerH.displayName = 'VContainerH';
