import {
  getImages,
} from 'enums/assets/images';

export const Asset = {
  baseUrl: './assets',
  get Image () {
    return getImages(Asset.baseUrl);
  },
};
