export const buildSchoolUpsertVariables = (schools) => {

  const mappedSchools = schools.map(school => {

    const resultSchool = {};

      if (school.id) {
        resultSchool['id'] = school.id;
      }

      if (school.title) {
        resultSchool['title'] = school.title;
      }

      if (school.mnemonic) {
        resultSchool['mnemonic'] = school.mnemonic;
      }

      if (school.address) {
        resultSchool['address'] = school.address;
      }

      if (school.telNo) {
        resultSchool['telNo'] = school.telNo;
      }

      if (school.description) {
        resultSchool['description'] = school.description;
      }


      return resultSchool;
  });

  return {
    variables: {
      params: {
        schools: mappedSchools,
      },
    }
  };
}