import React from 'react';
import PropTypes from 'prop-types';
import {
  Chip,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList as MenuListProto,
  styled,
} from '@mui/material';
import { SecondaryButton } from './buttons';
import {
  HFillBoxV,
  VFillBox,
} from './boxes';
import { Spacing } from 'enums';
import {
  ArrowBack,
  CheckBox,
  CheckBoxOutlineBlank,
  Close,
  FilterList,
} from '@mui/icons-material';
import {
  HFillContainerVH,
  VContainer,
} from './containers';
import { BodyLarge } from './typography';
import { useIdentity } from 'hooks';

const MenuList = styled(MenuListProto)`
  width: 260px;
`;

const MenuBox = styled(VFillBox)`
  margin-bottom: ${Spacing.Small4};
`;

const StyledButton = styled(SecondaryButton)`
  width: fit-content;
`;

const StyledButtonContainer = styled(HFillContainerVH)`
  justify-content: flex-end;
  padding: 0;
  margin: 0 0 1rem 0;
`;

const StyledChipContainer = styled(HFillContainerVH)`
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 1rem 0;
  overflow-x: auto;
`;

const StyledChip = styled(Chip)(
  ({ theme }) => `
  border-color: ${theme.palette.primary.p40};
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;

  & .MuiSvgIcon-root {
    color: ${theme.palette.primary.p20};
  }
  `,
);

export const Filter = ({
  filterGroups,
  onFilterUpdate,
  filters,
}) => {

  const { user } = useIdentity({});
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState();

  const renderSelectedFilters = () => {

    if (!filters) {
      return <React.Fragment/>;
    }

    const chips = [];

    for (const [field, filterGroup] of Object.entries(filters)) {

      if (!filterGroup) {
        continue;
      }

      for (const [item, filter] of Object.entries(filterGroup)) {

        if (!filter) {
          continue;
        }

        chips.push(
          <StyledChip
            key={`filter-${field}-${item}`}
            variant={'outlined'}
            label={item}
            onDelete={() => onItemClick(field, item)}
            deleteIcon={<Close/>}/>,
        );
      }
    }

    if (chips.length <= 0) {
      return <React.Fragment/>;
    }

    return (
      <StyledChipContainer>
        {chips}
      </StyledChipContainer>
    );
  };

  const onFiltersClose = () => {
    setSelectedGroup(undefined);
    setShowDrawer(false);
  };

  const onGroupClick = (group) => {
    setSelectedGroup(group);
  };

  const onItemClick = (field, item) => {

    onFilterUpdate({
      ...filters ?? {},
      [field]: {
        ...filters?.[field] ?? {},
        [item]: !filters?.[field]?.[item],
      },
    });
  };

  React.useEffect(() => {

    if (filters || !Array.isArray(filterGroups)) {
      return;
    }

    const defaultFilters = filterGroups.reduce((defaultFilters, filterGroup) => {

      const defaultTaskFilters = filterGroup?.default?.[user?.group];

      return defaultTaskFilters
        ? {
          ...defaultFilters,
          ...defaultTaskFilters,
        }
        : defaultFilters;
    }, {});

    if (Object.values(defaultFilters).length <= 0) {
      return;
    }

    onFilterUpdate(defaultFilters);
  }, [
    filterGroups,
    filters,
    onFilterUpdate,
    user.group,
  ]);

  return (

    <React.Fragment>

      <VContainer>
        <StyledButtonContainer>
          <StyledButton
            text={'Filter'}
            onClick={() => setShowDrawer(true)}
            startIcon={<FilterList/>}
          />
        </StyledButtonContainer>

        {renderSelectedFilters()}
      </VContainer>

      <Drawer
        anchor={'right'}
        open={showDrawer}
        onClose={onFiltersClose}>

        <VContainer>
          <HFillBoxV>

            {!!selectedGroup &&

              <IconButton
                color={'secondary'}
                onClick={() => setSelectedGroup(undefined)}>

                <ArrowBack/>
              </IconButton>
            }

            <HFillBoxV>
              <BodyLarge>
                {selectedGroup?.title || 'Filter'}
              </BodyLarge>
            </HFillBoxV>

            {!selectedGroup &&

              <IconButton
                color={'secondary'}
                onClick={() => setShowDrawer(false)}>

                <Close/>
              </IconButton>
            }
          </HFillBoxV>
        </VContainer>

        <MenuBox>
          {!selectedGroup &&

            <MenuList>

              {filterGroups.map((group) => (

                <MenuItem
                  key={group.field}
                  onClick={() => onGroupClick(group)}>

                  <ListItemText>
                    {group.title}
                  </ListItemText>
                </MenuItem>
              ))}
            </MenuList>
          }

          {!!selectedGroup &&

            <MenuList>

              {selectedGroup.items.map((item) => (

                <MenuItem
                  key={item}
                  onClick={() => onItemClick(selectedGroup.field, item)}>

                  <ListItemIcon>
                    {filters?.[selectedGroup.field]?.[item] && <CheckBox/> ||
                      <CheckBoxOutlineBlank/>}
                  </ListItemIcon>

                  <ListItemText>
                    {item}
                  </ListItemText>
                </MenuItem>
              ))}
            </MenuList>
          }
        </MenuBox>
      </Drawer>
    </React.Fragment>
  );
};

Filter.displayName = 'Filter';

Filter.propTypes = {
  filterGroups: PropTypes.array.isRequired,
  onFilterUpdate: PropTypes.func,
  filters: PropTypes.any,
};

Filter.defaultProps = {
  filterGroups: [
    {
      field: 'status',
      title: 'Task Status',
      items: [
        'COMPLETED',
        'IN PROGRESS',
        'OPEN',
      ],
    },
  ],
};

