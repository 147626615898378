import {
  styled,
} from '@mui/material';

import {
  HContainer,
} from 'ui/common/components/containers/HContainer';

export const HFillContainer = styled(HContainer)`
  flex: 1 1 auto;
`;

HFillContainer.displayName = 'HFillContainer';
