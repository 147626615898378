import React from 'react';
import PropTypes from 'prop-types';

import { 
  styled,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import {
  TextButton,
} from './buttons';

const StyledTitleLarge = styled(DialogTitle)`
  margin-bottom: 0.6rem;
`;

const Description = styled(DialogContentText)`
  color: secondary.s40;
`;


const StyledDialog = styled(Dialog)(({ theme }) => `
  .MuiPaper-root {
    border-radius: 1.625rem;
    max-width: 19.5rem;
    background-color: ${theme.palette.primary.p99};
    padding: 1.2rem 0.6rem
  }
`);

export const CustomDialog = ({
  title,
  description,
  closeBtnLabel,
  additionalBtnLabel,
  onClose,
  open,
  additionalBtnAction,
  
}) => {



  return (
    <React.Fragment>

       <StyledDialog
        open={open}
        onClose={onClose}
        maxWidth={'sm'}
      >
          <StyledTitleLarge id="alert-dialog-title">
            {title}
          </StyledTitleLarge>

          <DialogContent>
            <Description id="alert-dialog-description">
              {description}
            </Description>
          </DialogContent>
          
          <DialogActions>
            <TextButton text={closeBtnLabel} onClick={onClose}/>
            <TextButton text={additionalBtnLabel} onClick={additionalBtnAction}/>
          </DialogActions>

      </StyledDialog>

    </React.Fragment>
  );
};

CustomDialog.displayName = 'CustomDialog';

CustomDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  closeBtnLabel: PropTypes.string,
  additionalBtnLabel: PropTypes.string,
  additionalBtnAction: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
