import {
  atom,
} from 'recoil';

import {
  FormFactor,
  Orientation,
} from 'enums';

export const FormFactorAtom = atom({
  key: 'app-formFactor',
  default: {
    formFactor: FormFactor.Mobile,
    dimensions: {
      width: 0,
      height: 0,
    },
    isFeature: false,
    isMobile: true,
    isDesktop: false,
    isTablet: false,
    getFormFactorValue: () => ({}),
    orientation: Orientation.Portrait,
  },
});
