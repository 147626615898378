import { useMutation } from '@apollo/client';
import { SchoolUpsert } from 'gql/school/mutations';

export const useSchoolUpsert = () => {

  const [upsertSchool, { data, loading, error }] = useMutation(SchoolUpsert);

  return [
    upsertSchool,
    {
      loading,
      error,
      data,
    }
  ];
}
