import {
  useInsights,
} from 'hooks/useInsights';

import {
  useOIDCAuthentication,
} from 'hooks/identity/useOIDCAuthentication';

/**
 * Promise that's set while the is logging in. This means that multiple concurrent attempts to
 * log in will result in only one network call.
 * @type {Promise<Tokens>|null}
 */
let loginPromise = null;

/**
 * Promise that's set while the is logging out. This means that multiple concurrent attempts to
 * log out will result in only one network call.
 * @type {Promise<void>|null}
 */
let logoutPromise = null;

/**
 * Promise that's set while the user's tokens are being refreshed. This means that multiple
 * concurrent attempts to refresh the tokens will result in only one network call.
 * @type {Promise<Tokens>|null}
 */
let refreshPromise = null;

/**
 * Promise that's set while the user's forgot password email is being sent. This means that multiple
 * concurrent attempts to send the forgot password email will result in only one network call.
 * @type {Promise<void>|null}
 */
let forgotPasswordPromise = null;

/**
 * Promise that's set while the user's password reset is being processed. This means that multiple
 * concurrent attempts to send reset the password will result in only one network call.
 * @type {Promise<void>|null}
 */
let resetPasswordPromise = null;

/**
 * @returns {AuthenticationHook}
 */
export const useAuthentication = ({
  context,
} = {}) => {

  const {
    traceError,
    traceInformation,
  } = useInsights({
    context,
  });

  /**
   * @type {AuthenticationHook}
   */
  let authentication = useOIDCAuthentication();

  /**
   * @type {loginCallback}
   */
  const login = async (args) => {

    if (!loginPromise) {

      traceInformation(`Initiating login`);

      loginPromise = authentication
        .login(args)
        .catch(error => {

          traceError(error);
          throw error;

        })
        .finally(() => {
          loginPromise = null;
        });
    }

    return loginPromise;
  };

  /**
   * @type {logoutCallback}
   */
  const logout = async (tokens) => {

    if (!logoutPromise) {

      traceInformation(`Initiating logout`);

      logoutPromise = authentication
        .logout(tokens)
        .catch(error => {

          traceError(error);
          throw error;
        })
        .finally(() => {
          logoutPromise = null;
        });
    }

    return logoutPromise;
  };

  /**
   * @type {refreshAuthenticationCallback}
   */
  const refreshAuthentication = async (tokens) => {

    if (!refreshPromise) {

      traceInformation(`Initiating refresh`);

      refreshPromise = authentication
        .refreshAuthentication(tokens)
        .catch(error => {

          traceError(error);
          throw error;
        })
        .finally(() => {
          refreshPromise = null;
        });
    }

    return refreshPromise;
  };

  /**
   * @type {forgotPasswordCallback}
   */
  const forgotPassword = async (args) => {

    if (!forgotPasswordPromise) {

      traceInformation(`Initiating forgotPassword`);

      forgotPasswordPromise = authentication
        .forgotPassword(args)
        .catch(error => {

          traceError(error);
          throw error;
        })
        .finally(() => {
          forgotPasswordPromise = null;
        });
    }

    return forgotPasswordPromise;
  };

  /**
   * @type {resetPasswordCallback}
   */
   const resetPassword = async (args) => {

    if (!resetPasswordPromise) {

      traceInformation(`Initiating resetPassword`);

      resetPasswordPromise = authentication
        .resetPassword(args)
        .catch(error => {

          traceError(error);
          throw error;
        })
        .finally(() => {
          resetPasswordPromise = null;
        });
    }

    return resetPasswordPromise;
  };

  return {
    login,
    logout,
    refreshAuthentication: (authentication.refreshAuthentication && refreshAuthentication) || undefined,
    getAuthHeaders: authentication.getAuthHeaders,
    forgotPassword: (authentication.forgotPassword && forgotPassword) || (() => {}),
    resetPassword: (authentication.resetPassword && resetPassword) || (() => {}),
  };
};

