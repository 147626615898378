import React from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardContent,
  Modal as MaterialModal,
  css,
  styled,
} from '@mui/material';

import {
  Info,
  Warning,
  Error,
} from '@mui/icons-material';

import {
  ModalType,
  Spacing,
  Display,
} from 'enums';

import {
  VFillBoxH,
} from 'ui/common/components/boxes';

import {
  VFillContainerH,
  VFillContainerVH,
} from 'ui/common/components/containers';

import {
  BodyMedium,
  HeadlineMedium,
} from 'ui/common/components/typography';

import {
  PrimaryButton,
} from 'ui/common/components/buttons';

const IconStyle = props => css`
  font-size: 44px;
`;

const InfoIcon = styled(Info)`
  ${IconStyle};
`;

const WarningIcon = styled(Warning)`
  ${IconStyle};
`;

const ErrorIcon = styled(Error)`
  ${IconStyle};
`;

const ModalProto = styled(MaterialModal)`
  display: flex;
`;

const Content = styled(CardContent)`
  display: flex;
  flex: 1;
`;

const KnownBox = styled(VFillBoxH)`
  margin: ${Spacing.Medium1} ${Spacing.None};
`;

const Icon = {
  [ModalType.Custom]: () => <React.Fragment/>,
  [ModalType.Info]: () => <InfoIcon/>,
  [ModalType.Warning]: () => <WarningIcon/>,
  [ModalType.Error]: () => <ErrorIcon/>,
};

export const Modal = props =>

  <ModalProto
    open={props.open}
    onClose={props.onClose}>

    <VFillContainerVH
      sx={{
        padding: props.isFullscreen && 0 || undefined,
      }}>

      <Card
        sx={{
          display: Display.Flex,
          minWidth: props.minWidth,
          minHeight: props.minHeight,
        }}>

        <Content>

          { props.type !== ModalType.Custom &&

            <VFillContainerH>

              {Icon[props.type]()}

              <KnownBox>

                <HeadlineMedium>
                  {props.title}
                </HeadlineMedium>

                <BodyMedium>
                  {props.message}
                </BodyMedium>

              </KnownBox>

              <PrimaryButton
                text={'Close'}
                onClick={props.onClose}/>

            </VFillContainerH>
          }

          {props.type === ModalType.Custom && props.children}
        </Content>
      </Card>

    </VFillContainerVH>
  </ModalProto>
;

Modal.displayName = 'Modal';

Modal.propTypes = {
  minWidth: PropTypes.string,
  minHeight: PropTypes.string,
  open: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(ModalType)),
  title: PropTypes.string,
  message: PropTypes.string,
  isFullscreen: PropTypes.bool,

  onClose: PropTypes.func,
};

Modal.defaultProps = {
  minWidth: '300px',
  minHeight: '200px',
  open: false,
  type: ModalType.Custom,
  title: '',
  message: '',
  isFullscreen: false,

  onClose: undefined,
};
