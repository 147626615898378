import React from 'react';

import {
  useRecoilValue,
} from 'recoil';

import {
  setContext as setInsightsContext,
  setProviders,
} from 'insights';

import {
  useInsightsConsole,
} from 'insights/console';

/*import {
  useInsightsFirebase,
} from 'insights/firebase';*/

import {
  useInsightsSentry,
} from 'insights/sentry';

import {
  CommonSentryIgnoreErrors,
  FormFactor,
} from 'enums';

import {
  IdentityAtom,
} from 'atoms';

import {
  useFormFactor,
} from 'hooks';

import {
  useEnvironment,
} from 'hooks';

import {
  sentryBeforeBreadcrumb,
  sentryBeforeSend,
} from 'common';

const SentryIgnoreErrors = [
  ...CommonSentryIgnoreErrors,
];

const FormFactorValues = {
  [FormFactor.Feature]: 'feature',
  [FormFactor.Mobile]: 'mobile',
  [FormFactor.Tablet]: 'tablet',
  [FormFactor.Desktop]: 'desktop',
};

export const InsightsContext = () => {

  const {
    getFormFactorValue,
  } = useFormFactor();

  const {
    environment,
  } = useEnvironment();

  const userId = useRecoilValue(IdentityAtom)?.user?.id;
  const formFactorTag = getFormFactorValue(FormFactorValues);

  setProviders({
    console: useInsightsConsole(),
    sentry: useInsightsSentry({
      dsn: 'sentry.io',
      environment: environment?.name,
      enabled: !!environment?.variables?.sentryEnabled,
      release: `${import.meta.env.PACKAGE_VERSION}`,
      tracesSampleRate: environment?.variables?.sentryTracesSampleRate ?? 0.0,
      tracingOrigins: environment?.variables?.sentryTracingOrigins,
      replaysSessionSampleRate: environment?.variables?.replaysSessionSampleRate ?? 0.1,
      replaysOnErrorSampleRate: environment?.variables?.replaysOnErrorSampleRate ?? 1.0,
      ignoreErrors: SentryIgnoreErrors,
      beforeSend: sentryBeforeSend,
      beforeBreadcrumb: sentryBeforeBreadcrumb,
    }),
    /*firebase: useInsightsFirebase({
      firebaseConfig: environment?.variables?.firebaseConfig,
      channel: environment?.variables?.channel,
      environment: environment?.name,
      consented: true,
    }),*/
  });

  React.useEffect(() => {

    setInsightsContext(context => ({
      ...context,
      release: import.meta.env.PACKAGE_VERSION,
      environment: environment?.name,
      formFactor: formFactorTag,
      user: userId,
    }));

  }, [
    formFactorTag,
    environment,
    userId,
  ]);

  return (
    <React.Fragment />
  );
};

InsightsContext.displayName = 'InsightsContext';

InsightsContext.propTypes = {};

InsightsContext.defaultProps = {};

InsightsContext.context = {
  component: InsightsContext.displayName,
};
