import {
  styled,
} from '@mui/material';

import {
  AlignItems,
  JustifyContent,
} from 'enums';

import {
  VBox,
} from 'ui/common/components/boxes/VBox';

export const VBoxVH = styled(VBox)`
  align-items: ${AlignItems.Center};
  justify-content: ${JustifyContent.Center};
`;

VBoxVH.displayName = 'VBoxVH';
