import {
  Box,
  styled,
} from '@mui/material';

import {
  Display,
  FlexDirection,
} from 'enums';

export const HBox = styled(Box)`
  display: ${Display.Flex};
  flex-direction: ${FlexDirection.Row};
`;

HBox.displayName = 'HBox';
