import {
  Container,
  styled,
} from '@mui/material';

import {
  Display,
  FlexDirection,
} from 'enums';

export const VContainer = styled(Container)`
  display: ${Display.Flex};
  flex-direction: ${FlexDirection.Column};

  @media (min-width: 1200px) {
    max-width: 100%;
  }
`;

VContainer.displayName = 'VContainer';
