import React from 'react';

import PropTypes from 'prop-types';

import {
  useTaskUpsert,
} from 'hooks';

import {
  buildTaskUpsertVariables,
} from 'common';

import { format } from 'date-fns';
import { styled } from '@mui/material';
import { InfoText } from '../../InfoText';
import { HFillContainerV } from 'ui/common/components/containers';
import { PrimaryButton, SecondaryButton } from 'ui/common/components/buttons';
import { validateTaskForm } from 'common/forms';
import { TaskForm } from '../../TaskForm';

const CancelButton = styled(SecondaryButton)`
  margin-right: 1rem;
`;

export const ScheduleTask = ({ task, clearSelectedTask, onTaskSave }) => {

  const [ upsertTask ] = useTaskUpsert();

  const [formFields, setFormFields] = React.useState();

  const [isFormValid, setIsFormValid] = React.useState(true);
  const [validation, setValidation] = React.useState({});

  React.useEffect(() => {
    if (task) {

      const fields = {
        title: task.title,
        description: task.description || task.shortDescription,
        scheduledStartTimestamp: task.scheduledStartTimestamp,
        scheduledEndTimestamp: task.scheduledEndTimestamp,
        location: task.location || '',
        note: task.note || '',
      };

      setFormFields(fields);
    }
  }, [task]);

  const handleFormFieldChange = (e, field) => {
    setFormFields(prevFields => {

      let value = e.target?.value;

      if (field === 'scheduledStartTimestamp' || field === 'scheduledEndTimestamp') {
        value = e.toISOString();
      }

      const newFields = {
        ...prevFields,
        [field]: value,
      };

      const errorMap = validateTaskForm(newFields);

      setValidation(errorMap);
      setIsFormValid(Object.keys(errorMap).length === 0);

      return newFields;
    });
  };

  const handleCancelClick = () => {
    clearSelectedTask();
  };

  const handleSaveClick = () => {
    const taskToSave = {
      id: task.id,
      title: formFields.title,
      description: formFields.description !== '' && formFields.description || task.description,
      scheduledStartTimestamp: formFields.scheduledStartTimestamp,
      scheduledEndTimestamp: formFields.scheduledEndTimestamp,
      location: formFields.location !== '' && formFields.location || null,
      note: formFields.note !== '' && formFields.note || null,
    };

    upsertTask(buildTaskUpsertVariables([taskToSave]));
    onTaskSave(taskToSave);
  };

  return (

    <React.Fragment>

      <InfoText
        groupName={format(task.scheduledEndTimestamp, 'EEEE')}
        title={task.title}
        description={`Enter the task details for your ${task.title}.`}
      />

      { formFields &&
        <React.Fragment>
          <TaskForm
            formFields={formFields}
            handleFormFieldChange={handleFormFieldChange}
            validation={validation}
          />

          <HFillContainerV>
            <CancelButton
              text='Cancel'
              onClick={() => handleCancelClick()}
            />
            <PrimaryButton
              text='Save'
              onClick={() => handleSaveClick()}
              disabled={!isFormValid}
            />
          </HFillContainerV>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

ScheduleTask.displayName = 'SchedulerViewer';

ScheduleTask.propTypes = {
  task: PropTypes.object.isRequired,
  clearSelectedTask: PropTypes.func,
  onTaskSave: PropTypes.func,
};

ScheduleTask.defaultProps = {
  clearSelectedTask: () => {},
  onTaskSave: () => {},
};


