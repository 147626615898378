import React from 'react';

import PropTypes from 'prop-types';

import {
  Rating as MuiRating,
  styled,
} from '@mui/material';

import {
  isFunction,
} from 'common';

import {
  ThutoTheme,
} from 'ui/app/themes';

import {
  InfoText,
} from 'ui/common/components/form/InfoText';

const StyledRating = styled(MuiRating)`
  color: ${ThutoTheme.palette.primary.main};
  margin-bottom: 0.5rem;
`;

export const Rating = props => {

  const {
    title,
    description,
    groupName,
    min,
    max,
    value,
    onChange,
    name,
    disabled,
    questionId,
    questionComments,
    upsertCommentLoading,
    handleComment,
    userId,
    size,
  } = props;

  const onRatingChange = newValue => {
    if (
      !isFunction(onChange) ||
      newValue == null ||
      isNaN(newValue)
    ) {
      return;
    }

    const rating = isNaN(min) ? newValue : Math.max(newValue, min);

    if (rating === value) {
      return;
    }

    isFunction(onChange) && onChange(rating);
  };

  return (
    <React.Fragment>

      <InfoText
        title={title}
        description={description}
        groupName={groupName}
        questionId={questionId}
        questionComments={questionComments}
        upsertCommentLoading={upsertCommentLoading}
        handleComment={handleComment}
        userId={userId}/>

      <StyledRating
        name={name}
        max={max}
        value={value}
        onChange={(_event, value) => onRatingChange(value)}
        disabled={disabled}
        size={size}/>
    </React.Fragment>
  );
};

Rating.displayName = 'Rating';

Rating.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  groupName: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  questionId: PropTypes.any,
  questionComments: PropTypes.any,
  upsertCommentLoading: PropTypes.bool,
  handleComment: PropTypes.any,
  userId: PropTypes.string,
  size: PropTypes.oneOf([
    'small',
    'medium',
    'large',
  ]),
};

Rating.defaultProps = {
  title: '',
  description: '',
  groupName: '',
  helperText: '',
  error: false,
  min: 1,
  max: 5,
  name: '',
  disabled: false,
  userId: '',
  size: 'large',
};
