import React from 'react';

import {
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import {
  styled,
} from '@mui/material';

import {
  Asset,
  IdentityStatus,
  JourneyName,
  ModalType,
  ScreenName,
  SpinnerType,
} from 'enums';

import {
  useInsights,
} from 'hooks';

import {
  getErrorObjectFromLocation,
  hasAuthCodeInLocation,
  clearAuthSearchParameters,
} from 'api';

import {
  RoutePath,
  Spacing,
} from 'enums';

import {
  useIdentity,
} from 'hooks';

import {
  BodyMedium,
  BodyLarge,
  PrimaryButton,
  Scene,
  VFillContainerVH,
  VContainerTextCenterVH,
  HeadlineLarge,
} from 'ui/common';

const TreeImage = styled('img')`
  width: 100%;
  margin: ${Spacing.None} ${Spacing.None} ${Spacing.Large3} ${Spacing.None};
`;

const WidthContainer = styled(VFillContainerVH)`
  padding: ${Spacing.None};
  max-width: 472px;
  @media (min-width: 1200px) {
    max-width: 472px;
  }
`;

const Heading6WM = styled(BodyLarge)`
  margin: 2rem 0 1rem 0;
`;

const PrimaryButtonWTM = styled(PrimaryButton)`
  margin-top: ${Spacing.Large4};
`;

export const Login = () => {

  const [
    state,
    setState,
  ] = React.useState({
    performingLogin: false,
    spinnerText: undefined,
    loginError: undefined,
  });

  const {
    state: locationState,
  } = useLocation();

  const {
    redirectPath,
  } = (locationState || {});

  const {
    traceError,
  } = useInsights({
    context: Login.context,
  });

  const {
    login,
    identityStatus,
  } = useIdentity({
    context: Login.context,
  });

  const navigate = useNavigate();

  const [
    searchParams,
  ] = useSearchParams();

  const performLogin = React.useCallback(spinnerText => {

    setState(prevState => ({
      ...prevState,
      performingLogin: true,
      spinnerText,
    }));

    let encodedState;

    try {
      encodedState = JSON.stringify({
        location: locationState,
        search: searchParams.toString(),
      });
    } catch (error) {
      traceError(error);
    }

    login({
      state: encodedState,
    })
      .then(({
        success,
        extras,
      }) => {

        if (success) {

          let decodedState;

          try {
            decodedState = JSON.parse(extras?.state);
          } catch (error) {
            traceError(error);
          }

          navigate({
            pathname: decodedState?.location?.redirectPath || RoutePath.Home,
            search: decodedState?.search,
          }, {
            replace: true,
          });

          return;
        }

        const loginError = extras?.error;

        if (loginError) {
          clearAuthSearchParameters();
        }

        setState(prevState => ({
          ...prevState,
          loginError,
          performingLogin: false,
          spinnerText: undefined,
        }));
      });
  }, [
    login,
    navigate,
    locationState,
    traceError,
    searchParams,
  ]);

  const onLoginClick = React.useCallback(() => {

    performLogin('Redirecting...');
  }, [
    performLogin,
  ]);

  React.useEffect(() => {

    if (!!state.loginError || state.performingLogin || !hasAuthCodeInLocation()) {
      return;
    }

    performLogin('Logging in...');
  }, [
    performLogin,
    state.performingLogin,
    state.loginError,
  ]);

  React.useEffect(() => {

    const errorObject = getErrorObjectFromLocation();

    if (!errorObject) {
      return;
    }

    traceError(new Error(`[${errorObject.error}]: ${errorObject.description}`));

    setState(prevState => ({
      ...prevState,
      loginError: 'An error occurred during login. Please try again later.',
    }));
  }, [
    traceError,
  ]);

  React.useEffect(() => {

    if (identityStatus === IdentityStatus.LoggedIn) {

      const defaultPath = RoutePath.Home;

      navigate({
        pathname: redirectPath || defaultPath,
        search: searchParams.toString(),
      }, {
        replace: true,
      });
    }
  }, [
    identityStatus,
    redirectPath,
    navigate,
    searchParams,
  ]);

  return (

    <Scene
      headerVisible={false}
      loading={state.performingLogin}
      spinnerText={state.spinnerText}
      spinnerType={SpinnerType.Modal}
      modalVisible={!!state.loginError}
      modalType={ModalType.Error}
      modalTitle={'Login failed'}
      modalMessage={state.loginError}
      onModalClose={() => setState(prevState => ({
        ...prevState,
        loginError: undefined,
      }))}>

      <WidthContainer>

        <TreeImage
          src={Asset.Image.Other.Tree}/>

          <VContainerTextCenterVH>

            <HeadlineLarge>
              WELCOME TO THUTO
            </HeadlineLarge>

            <Heading6WM>
              Begin Your Educational Journey
            </Heading6WM>

            <BodyMedium>
              Step into the world of Thuto, where learning is engaging and collaborative. Explore, connect, and grow in a community dedicated to educational excellence
            </BodyMedium>

          </VContainerTextCenterVH>

        <PrimaryButtonWTM
          text={'Login'}
          onClick={onLoginClick}/>

      </WidthContainer>
    </Scene>
  );
};

Login.displayName = 'Login';

Login.context = {
  screen: ScreenName.Login,
  journey: JourneyName.Identity,
  component: Login.displayName,
};
