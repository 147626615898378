import {
  Box,
  styled,
} from '@mui/material';

import {
  Display,
  FlexDirection,
} from 'enums';

export const VBox = styled(Box)`
  display: ${Display.Flex};
  flex-direction: ${FlexDirection.Column};
`;

VBox.displayName = 'VBox';
