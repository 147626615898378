import PropTypes from 'prop-types';

import {
  styled,
} from '@mui/material';

import React from 'react';

import {
  BodyMedium,
} from '../typography';

import { useFileDownload } from 'hooks';
import { Spacing } from 'enums';

const Text = styled(BodyMedium)`
  margin-top: ${Spacing.Small2};
  margin-bottom: ${Spacing.Small2};
`;

export const DocumentName = props => {

  const {data, loading} = useFileDownload(props.fileId);

  return (

    <React.Fragment>

      {!loading && data &&
        <Text>{data.name}</Text>
      }
    </React.Fragment>
  );
};

DocumentName.displayName = 'DocumentName';

DocumentName.propTypes = {
  fileId: PropTypes.string.isRequired,
};

DocumentName.defaultProps = {
};
