import {
  TagTypes,
} from 'enums';

export const validateTagArguments = parsedArgs => {

  const context = parsedArgs?.context;
  const args = parsedArgs?.args;
  const errors = [];

  if (!Array.isArray(args)) {
    return errors;
  }

  for (const arg of args) {

    const type = arg?.type;
    const name = arg?.name;
    const parameters = {
      ...context,
      ...arg?.parameters,
    };

    switch (type) {

      case TagTypes.View:

        !parameters.screen && errors.push('View tag requires parameter "screen"');
        !parameters.journey && errors.push('View tag requires parameter "journey"');
        !parameters.component && errors.push('View tag requires parameter "component"');

        break;

      case TagTypes.Event:

        !name && errors.push('Event tag requires "name"');
        break;

      default:
        break;
    }
  }

  return errors;
};
