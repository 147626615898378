import PropTypes from 'prop-types';

import {
  styled,
  TextField as TextFieldProto,
} from '@mui/material';

import {
  TextFieldVariant,
} from 'enums';

import React from 'react';

import { InfoText } from '../InfoText';

const StyledTextField = styled(TextFieldProto)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p100,
  width: '100%',
  marginBottom: '1rem',
}));

export const LongText = props => {

  const {
    title,
    description,
    groupName,
    questionId,
    questionComments,
    upsertCommentLoading,
    handleComment,
    userId,
    ...other
  } = props;

  return (
    <React.Fragment>
      <InfoText
        title={title}
        description={description}
        groupName={groupName}
        questionId={questionId}
        questionComments={questionComments}
        upsertCommentLoading={upsertCommentLoading}
        handleComment={handleComment}
        userId={userId}
      />

      <StyledTextField
        {...other}/>

    </React.Fragment>
  );
};

LongText.displayName = 'LongText';

LongText.propTypes = {
  variant: PropTypes.oneOf(Object.values(TextFieldVariant)),
  multiline: PropTypes.bool,
  minRows: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  groupName: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  endAdornment: PropTypes.any,
  questionId: PropTypes.any,
  questionComments: PropTypes.any,
  upsertCommentLoading: PropTypes.bool,
  handleComment: PropTypes.any,
  userId: PropTypes.string,
};

LongText.defaultProps = {
  variant: TextFieldVariant.Filled,
  multiline: true,
  minRows: 1,
  title: '',
  description: '',
  groupName: '',
  placeholder: '',
  helperText: '',
  error: false,
  userId: '',
};
