import
  PropTypes
  from 'prop-types';

import {
  Button,
} from '@mui/material';

import {
  ButtonVariant,
} from 'enums';

export const TextButton = props =>

  <Button
    {...props}
    variant={ButtonVariant.Text}>

    {props.text || props.children}
  </Button>
;

TextButton.displayName = 'TextButton';

TextButton.propTypes = {
  text: PropTypes.string,
};

TextButton.defaultProps = {
  text: '',
};
