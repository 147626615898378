import {
  styled,
} from '@mui/material';

import {
  AlignItems,
} from 'enums';

import {
  VBox,
} from 'ui/common/components/boxes/VBox';

export const VBoxH = styled(VBox)`
  align-items: ${AlignItems.Center};
`;

VBoxH.displayName = 'VBoxH';
