import {
  RoutePath,
} from 'enums';

import {
  Home,
} from 'ui/home';

/**
 * @type {RouteConfig[]}
 */
export const HomeRoutes = [{
    element: <Home/>,
    path: RoutePath.Home,
    children: [],
  },
];
