import { useQuery } from '@apollo/client';
import { mapTaskFromQuery } from 'common';
import { QueryTaskDetails } from 'gql/task/queries';
import { useEffect, useState } from 'react';

export const useTaskDetails = (taskId, field = 'taskId') => {

  const { loading, error, data, refetch } = useQuery(QueryTaskDetails, {
    variables: {
      params: {
        filterProperties:[{
            operator: 'IN',
            fieldNames: [field],
            value: [taskId],
        }],
        filterLogicGate: 'AND',
      },
    },
    fetchPolicy: 'no-cache',
    skip: taskId == null,
  });

  const [taskDetail, setTaskDetail] = useState();

  useEffect(() => {
    if (!loading && data
      && data.taskQueryFilterDetails?.body?.tasks
      && data.taskQueryFilterDetails?.body?.tasks[0]) {

      const mappedDetail = mapTaskFromQuery(data.taskQueryFilterDetails?.body?.tasks[0]);

      setTaskDetail(mappedDetail);
    }
  }, [loading, data, taskId]);

  return {
    loading,
    error,
    data,
    taskDetail,
    refetch,
  };
}

