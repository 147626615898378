import {
  styled,
} from '@mui/material';

import {
  AlignItems,
  JustifyContent,
} from 'enums';

import {
  VFillBox,
} from 'ui/common/components/boxes/VFillBox';

export const VFillBoxVH = styled(VFillBox)`
  align-items: ${AlignItems.Center};
  justify-content: ${JustifyContent.Center};
`;

VFillBoxVH.displayName = 'VFillBoxVH';
