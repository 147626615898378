import {
  HomeRoutes,
  IdentityRoutes,
  TaskListRoutes,
  TasksRoutes,
  AdminRoutes,
  SupportRoutes,
  OnboardingRoutes,
  ProfileRoutes,
  ReportingRoutes,
} from 'ui/navigation/routes';

export const Routes = [
  ...HomeRoutes,
  ...IdentityRoutes,
  ...TasksRoutes,
  ...TaskListRoutes,
  ...AdminRoutes,
  ...SupportRoutes,
  ...OnboardingRoutes,
  ...ProfileRoutes,
  ...ReportingRoutes,
];

export * from 'ui/navigation/Navigator';
