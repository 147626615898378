import PropTypes from 'prop-types';

import {
  Navigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import {
  IdentityStatus,
} from 'enums';

import {
  RoutePath,
} from 'enums';

import {
  useIdentity,
} from 'hooks';

export const ProtectedRoute = props => {

  const {
    loading,
    identityStatus,
  } = useIdentity({
    context: props.context,
  });

  const location = useLocation();

  const [
    searchParams,
  ] = useSearchParams();

  if (identityStatus === IdentityStatus.LoggedOut && !loading) {

    return (
      <Navigate
        to={{
          pathname: RoutePath.Login,
          search: searchParams.toString(),
        }}
        state={{
          redirectPath: location.pathname,
        }}
        replace={true} />
    );
  }

  return props.children;
};

ProtectedRoute.displayName = 'ProtectedRoute';

ProtectedRoute.propTypes = {
  context: PropTypes.shape({
    screen: PropTypes.string.isRequired,
    journey: PropTypes.string.isRequired,
    component: PropTypes.string.isRequired,
  }).isRequired,
};

ProtectedRoute.defaultProps = {
  context: {
    screen: 'Scene',
    journey: 'Scene',
    component: 'Scene',
  },
};
