export const uploadFileToS3 = async ({
  file,
  metadata,
}) => {

  const form = new FormData();

  form.append('Cache-Control', metadata.cacheControl);
  form.append('Content-Type', metadata.contentType);
  form.append('acl', metadata.acl);
  form.append('key', metadata.key);
  form.append('success_action_status', metadata.successActionStatus);
  form.append('x-amz-algorithm', metadata.amzAlgorithm);
  form.append('x-amz-credential', metadata.amzCredential);
  form.append('x-amz-date', metadata.amzDate);
  form.append('policy', metadata.policy);
  form.append('x-amz-signature', metadata.amzSignature);
  form.append('x-amz-server-side-encryption', metadata.amzServerSideEncryption);
  form.append('filename', metadata.bucketFileName);
  form.append('file', file);

  const response = await fetch(metadata.bucketUrl, {
    method: 'POST',
    body: form,
  });

  if (!response.ok) {
    throw new Error('Error uploading to S3');
  }

  return response;
};

/**
 * Utility to save a blob as a file to the user's local machine
 * @param {Blob} blob
 * @param {string} fileName
 */
export const saveBlob = ({
  blob,
  fileName,
}) => {

  const objectUrl = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = objectUrl;
  a.download = fileName;
  a.click();

  URL.revokeObjectURL(objectUrl);
};

/**
 * Utility to save a work book as an XLSX file to the user's local machine.
 * @param {import('exceljs').Workbook} workBook The workbook to save.
 * @param {string} fileName The filename including extension.
 * @returns {Promise<void>}
 */
export const saveWorkBook = async ({
  workBook,
  fileName,
}) => {
  const buffer = await workBook.xlsx.writeBuffer();

  const blob = new Blob(
    [buffer],
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  );

  saveBlob({
    blob,
    fileName,
  });
};
