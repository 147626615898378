import {
  RoutePath,
} from 'enums';

import {
  UserOnboarding
} from 'ui/admin';

/**
 * @type {RouteConfig[]}
 */
export const AdminRoutes = [
  {
    element: <UserOnboarding />,
    path: RoutePath.AdminUserOnboarding,
    children: [],
  }
];
