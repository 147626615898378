import React from 'react';

import {
  Asset,
  JourneyName,
  ScreenName,
  StatusMnemonic,
} from 'enums';

import {
  Feedback,
  HeadlineSmall,
  PrimaryButton,
  Scene,
  TaskForm,
} from 'ui/common';

import {
  useIdentity,
  useTaskDetails,
  useTaskTemplate,
  useTaskUpsert,
} from 'hooks';

import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import {
  add,
  endOfDay,
  startOfDay,
  sub,
} from 'date-fns';

import {
  buildTaskUpsertVariables,
} from 'common';
import { validateTaskForm } from 'common/forms';
import { byId } from 'gql/taskTemplate/queryParams';
import { styled } from '@mui/material';

const StyledHeader = styled(HeadlineSmall)`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const StyledButton = styled(PrimaryButton)`
  margin-top: 1rem;
`;

export const CreateTask = () => {

  const [searchParams] = useSearchParams();

  const identity = useIdentity(CreateTask.context);

  const navigate = useNavigate();

  const templateId = searchParams.get('id');
  const linkedTaskId = searchParams.get('linked');

  const {
    loading,
    error,
    taskTemplate,
    refetch,
  } = useTaskTemplate(byId(templateId));

  const {
    loading: linkedLoading,
    taskDetail: linkedTaskDetail,
  } = useTaskDetails(linkedTaskId);

  const [upsertTask, {loading: upsertLoading, data: upsertData, error: upsertError}] = useTaskUpsert();
  const [upsertLinkedTask] = useTaskUpsert();

  React.useEffect(() => {
    if (!loading && !linkedLoading && taskTemplate) {

      let startDate = add(startOfDay(new Date()), {hours: 8});
      let endDate = sub(endOfDay(new Date()), {hours: 6, minutes: 59});

      if (linkedTaskDetail) {
        startDate = new Date(linkedTaskDetail.scheduledStartTimestamp);
        endDate = new Date(linkedTaskDetail.scheduledEndTimestamp);
      }

      const fields = {
        title: taskTemplate.title,
        description: taskTemplate.description || taskTemplate.shortDescription,
        scheduledStartTimestamp: startDate,
        scheduledEndTimestamp: endDate,
        location: linkedTaskDetail?.location || '',
        note: linkedTaskDetail?.note || '',
      };

      setFormFields(fields);
    }
  }, [loading, taskTemplate, linkedLoading, linkedTaskDetail]);

  const upsertCallback = React.useCallback((variables) => {
    upsertLinkedTask(variables);
  }, [upsertLinkedTask]);

  React.useEffect(() => {
    if (!upsertLoading && upsertData && linkedTaskId) {

      if (upsertData.taskUpsert?.body?.upsertResult[0] && upsertData.taskUpsert?.body?.upsertResult[0].row) {
        const upsertedTask = JSON.parse(upsertData.taskUpsert?.body?.upsertResult[0].row);

        upsertCallback(buildTaskUpsertVariables([{
          id: linkedTaskId,
          linkedTaskId: linkedTaskId && upsertedTask.id || null,
        }]));
      }
    }
  }, [upsertLoading, upsertCallback, upsertData, linkedTaskId]);

  const [formFields, setFormFields] = React.useState();

  const [isFormValid, setIsFormValid] = React.useState(true);
  const [validation, setValidation] = React.useState({});

  const handleFormFieldChange = (e, field) => {
    setFormFields(prevFields => {

      let value = e.target?.value;

      if (field === 'scheduledStartTimestamp' || field === 'scheduledEndTimestamp') {
        value = e.toISOString();
      }

      const newFields = {
        ...prevFields,
        [field]: value,
      };

      const errorMap = validateTaskForm(newFields);

      setValidation(errorMap);
      setIsFormValid(Object.keys(errorMap).length === 0);

      return newFields;
    });
  };

  const handleOnCreate = () => {
    upsertTask(buildTaskUpsertVariables([{
      title: formFields.title,
      description: formFields.description !== '' && formFields.description || taskTemplate.description,
      scheduledStartTimestamp: formFields.scheduledStartTimestamp,
      scheduledEndTimestamp: formFields.scheduledEndTimestamp,
      location: formFields.location !== '' && formFields.location || null,
      note: formFields.note !== '' && formFields.note || null,
      taskTemplateId: taskTemplate.id,
      ownerPersonId: identity.user.id,
      reporterPersonId: identity.user.id,
      status: StatusMnemonic.Scheduled,
      linkedTaskId: linkedTaskId && linkedTaskId || null,
    }]));
  };

  return (

    <Scene
      headerVisible={true}
      title={ScreenName.CreateTask}
      footerVisible={true}
      loading={loading || upsertLoading}
    >

      <React.Fragment>

        {!loading && !linkedLoading && formFields && !upsertData &&
          <React.Fragment>
            <StyledHeader>
              {taskTemplate.title}
            </StyledHeader>

            <TaskForm
              formFields={formFields}
              handleFormFieldChange={handleFormFieldChange}
              validation={validation}
              isLinked={linkedTaskId != null}
            />

            <StyledButton
              text={'Create'}
              onClick={handleOnCreate}
              disabled={!isFormValid}
            />
          </React.Fragment>
        }

        {!loading && error &&
        <Feedback 
          imageUrl={Asset.Image.Other.BrokenPencil}
          headerText='Something Went Wrong!'
          bodyText="We encountered a hiccup while fetching your task details. Not to worry, though – we're on it!"
          infoText="Please try loading your task again. If the issue persists, reach out to us for support"
          type="Error"
          buttonText='Try Again'
          buttonAction={() => refetch()}/>
        }

        {!upsertLoading && (upsertError || (upsertData?.taskUpsert?.body?.ok != null && !upsertData?.taskUpsert?.body?.ok)) &&
          <Feedback 
            imageUrl={Asset.Image.Other.BrokenPencil}
            headerText='Something Went Wrong!'
            bodyText="We encountered a hiccup while creating your task. Not to worry, though – we're on it!"
            infoText="Please try creating your task again. If the issue persists, reach out to us for support"
            type="Error"
            buttonText='Try Again'
            buttonAction={() => handleOnCreate()}/>
        }

        { !upsertLoading && !upsertError && upsertData && upsertData?.taskUpsert?.body?.upsertResult &&
          <Feedback 
            imageUrl={Asset.Image.Other.PinnedCalender}
            headerText={'Task Created'}
            bodyText={'Great job creating your task! Your dedication shines bright.'}
            infoText={"Your task has been added to your task list."}
            buttonText='Go Back'
            buttonAction={() => navigate('/home')}/>
          }
      </React.Fragment>
      
    </Scene>
  );
};

CreateTask.displayName = 'Create Task';

CreateTask.context = {
  screen: ScreenName.CreateTask,
  journey: JourneyName.CreateTask,
  component: CreateTask.displayName,
};
