import { useMutation } from '@apollo/client';
import { buildTaskUpsertVariables } from 'common';
import { InputTypeMnemonic, StatusMnemonic, SubmitType } from 'enums';
import { FileUpsert, TaskAnswerUpsert, TaskUpsert } from 'gql/task/mutations';

export const useFormSubmit = () => {

  const [submitAnswers, { data: submitData, loading: submitLoading, error: submitError }] = useMutation(TaskAnswerUpsert);
  const [upsertFile, { loading: upsertLoading, error: upsertError }] = useMutation(FileUpsert);
  const [upsertTask] = useMutation(TaskUpsert);

  const handleChildTaskUpdates = (answers, taskDetails, childTask) => {

    const childUpdateFields = {};

    taskDetails.questions.forEach(q => {

      if (q.childUpdateField) {
        const targetAnswer = answers.find(a => a.questionId === q.id);
        if (targetAnswer) {
          childUpdateFields[q.childUpdateField] = targetAnswer.answer;
        }
      }
    });

    upsertTask(buildTaskUpsertVariables(
      [{
        id: childTask.id,
        ...childUpdateFields
      }]
    ));
  };

  const handleFileUpload = async (file, questionId, taskDetails) => {
    const { data, error } = await upsertFile({
      variables: {
        params: {
          files: [{
            name: file.name,
            mime: file.type,
            s3Path: `${taskDetails.id}/${questionId}/${file.name}`,
          }],
        }
      }
    });

    if (error) {
      throw new Error(error);
    }

    if (data.fileUpsert?.body && data.fileUpsert.body.upsertResult[0]?.row) {
      const rowData = JSON.parse(data.fileUpsert.body.upsertResult[0]?.row);

      const response = await fetch(rowData.signedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        }
      });

      if (!response.ok) {
        throw new Error('Error uploading to S3');
      }

      return rowData.id;
    }
  };

  const getSaveQuestionSkipMap = (taskDetails) => {

    const saveSkipMap = {};

    taskDetails.questions.forEach(q => {

      if (q.inputType === InputTypeMnemonic.Geolocation) {
        saveSkipMap[q.id] = true;
      }
    });

    return saveSkipMap;
  };

  const getSubmissionStatus = (taskDetails, answers) => {
    if (!taskDetails.scheduleId && taskDetails.manualTaskReviewBypass) {
      return StatusMnemonic.Completed;
    }

    if (taskDetails.requiresReview) {
      return StatusMnemonic.Review;
    }

    const questionReview = taskDetails.questions.find(q => q.requireReview != null);

    if (
      questionReview &&
      answers.some((a) => a.questionId === questionReview.id && a.answer === questionReview.requireReview)
    ) {
      return StatusMnemonic.Review;
    }

    if (taskDetails.requiresMentorResponse) {
      return StatusMnemonic.AwaitMentor;
    } else if (taskDetails.requiresCoachResponse) {
      return StatusMnemonic.AwaitCoach;
    }

    return StatusMnemonic.Completed;
  }

  const submitForm = async (taskDetails, formFields, validation, submitType, childTask, weeklyPlanRange) => {

    const fileAnswerList = [];
    let saveSkipMap = {};

    if (submitType === SubmitType.Save) {
      saveSkipMap = getSaveQuestionSkipMap(taskDetails);
    }

    const answers = Object.entries(formFields).map(([key, value]) => {
      if (!(validation[key].hasError || validation[key].isHidden)) {

        if (saveSkipMap[key]) {
          return;
        } else if (value instanceof File) {
          fileAnswerList.push(key);
        } else {
          return {
            answer: value,
            questionId: key,
            taskId: taskDetails.id,
          };
        }
      }
    }).filter(a => a != null && a.answer != null);

    for (let i = 0; i < fileAnswerList.length; i++) {

      const file = formFields[fileAnswerList[i]];

      const fileId = await handleFileUpload(file, fileAnswerList[i], taskDetails);

      answers.push({
        answer: fileId,
        questionId: fileAnswerList[i],
        taskId: taskDetails.id,
      });
    }

    let submitStatus = StatusMnemonic.InProgress;

    if (submitType === SubmitType.Submit) {
      submitStatus = getSubmissionStatus(taskDetails, answers);
    }

    if (submitType === SubmitType.Submit && childTask !=null) {
      handleChildTaskUpdates(answers, taskDetails, childTask);
    }

    const newTitle = (!weeklyPlanRange || taskDetails.title.indexOf(weeklyPlanRange) !== -1) ? taskDetails.title : `${taskDetails.title}: ${weeklyPlanRange}`;

    return submitAnswers({
      variables: {
        answerParams: {
          answers: answers,
        },
        taskParams: {
          tasks: [{
            id: taskDetails.id,
            title: newTitle,
            statusMnemonic: submitStatus,
            ratingPersonId: taskDetails?.ratingPersonId,
            ratingPercent: taskDetails?.ratingPercent ?? 20,
            ratingComment: taskDetails?.ratingComment,
            completedTimestamp: submitType === SubmitType.Submit && new Date().toISOString() || null,
          }],
        }
      },
    });
  };

  return [
    submitForm,
    {
      loading: upsertLoading || submitLoading || false,
      error: submitError || upsertError,
      data: submitData,
    }
  ];
}
