import { useQuery } from '@apollo/client';
import { QueryTaskTemplate } from 'gql/taskTemplate/queries';
import { useEffect, useState } from 'react';


export const useTaskTemplate = (variables) => {
  const [taskTemplate, setTaskTemplate] = useState();

  const { loading, error, data, refetch } = useQuery(QueryTaskTemplate, variables);

  useEffect(() => {

    if (loading || data == null || taskTemplate) {
      return;
    }

    const resultTemplate = data?.taskTemplateQueryFilter?.body?.taskTemplates?.map(t => ({
      id: t.id,
      title: t.title,
      mnemonic: t.mnemonic,
      description: t.description,
      shortDescription: t.shortDescription,
      requiresReview: t.requiresReview,
      active: t.active,
      yearOfStudy: t.yearOfStudy,
      assessmentMnemonic: t.assessmentMnemonic,
      showOnCreateTaskList: t.showOnCreateTaskList,
    }));

    if (resultTemplate != null && resultTemplate.length > 0) {
      setTaskTemplate(resultTemplate[0]);
    }
  }, [
    loading,
    data,
    taskTemplate,
    setTaskTemplate,
  ]);

  return {
    loading,
    error,
    data,
    taskTemplate,
    refetch,
  };
}
