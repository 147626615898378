import PropTypes from 'prop-types';
import React from 'react';

import {
  styled,
} from '@mui/material';

import {
  BodySmall,
  LabelMedium,
  TitleMedium
} from '../typography';

import {
  formatDate,
  shouldShowTaskEditButton,
} from 'common';

import {
  HFillContainer,
  VContainer,
} from '../containers';

import { SecondaryButton, TextButton } from '../buttons';
import { useNavigate } from 'react-router-dom';
import { IdentityGroupType, StatusMnemonic, TaskTypeMnemonic } from 'enums';

const KeyValueContainer = styled(HFillContainer)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-left: 0;
  padding-right: 0;

  @media (min-width: 600px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const BodySmallStyled = styled(BodySmall)`
  text-align: end;
`;

const StyledTextButton = styled(TextButton)`
  font-size: 0.812rem;
  padding: 0;
  max-width: 50%;
  text-align: right;
`;

const TitleMediumStyled = styled(TitleMedium)`
  margin: 1rem 0 1rem 0;
`;

const StyledButton = styled(SecondaryButton)`
  margin: 1rem 0 1.2rem 0;
`;

export const TaskDetail = ({taskDetail, personDetails, userType, childTask, linkedTask, internTask, onEditClick, onDownloadClick }) => {

  const navigate = useNavigate();

  let taskUserDetail = {};
  let showEditButton = false;

  if (taskDetail && personDetails) {

    showEditButton = shouldShowTaskEditButton(taskDetail);

    taskUserDetail = {
      "Task Name" : taskDetail.title,
      "Task Description" : taskDetail.description || '-',
      "Task Template Title": taskDetail.taskTemplateTitle,
      "Task Created": formatDate(taskDetail?.createdTimestamp),
      "Task Scheduled Start": formatDate(taskDetail?.scheduledStartTimestamp),
      "Task Scheduled End": formatDate(taskDetail?.scheduledEndTimestamp),
    };

    if (taskDetail.startedTimestamp) {
      taskUserDetail['Task Started'] = formatDate(taskDetail?.startedTimestamp);
    }

    if (taskDetail.completedTimestamp) {
      taskUserDetail['Task Completed'] = formatDate(taskDetail?.completedTimestamp);
    }

    if (taskDetail?.location) {
      taskUserDetail['Place'] = taskDetail?.location;
    }

    if (taskDetail?.note) {
      taskUserDetail['Notes'] = taskDetail?.note;
    }

    if (
      !taskDetail.manualTaskReviewBypass &&
      (
        taskDetail?.requiresMentorRating ||
        taskDetail?.requiresCoachRating ||
        taskDetail?.requiresSelfRating
      )
    ) {
      if (taskDetail?.ratingPersonId) {
        // Note: The rating is a percentage, but needs to be displayed as a value out of 5.
        taskUserDetail['Rating'] = `${Math.floor(taskDetail?.ratingPercent / 20)}/5`;
        taskUserDetail['Rating Person Name'] = `${taskDetail?.ratingPersonGivenName} ${taskDetail?.ratingPersonSurname}`
        taskUserDetail['Rating Comment'] = taskDetail?.ratingComment || '-';
      } else {
        taskUserDetail['Rating'] = 'No rating';
      }
    }

    if (personDetails?.mentorGivenName != null && personDetails?.mentorGivenName !== '') {
      taskUserDetail['Intern Name'] = `${taskDetail?.ownerPersonGivenName} ${taskDetail?.ownerPersonSurname}`;
      taskUserDetail['Intern Email'] = taskDetail?.ownerPersonEmail;
      taskUserDetail['Intern School'] = personDetails?.schools.join(', ');
    } else {
      taskUserDetail['Owner Name'] = `${taskDetail?.ownerPersonGivenName} ${taskDetail?.ownerPersonSurname}`;
      taskUserDetail['Owner Email'] = taskDetail?.ownerPersonEmail;
      taskUserDetail['Owner School'] = personDetails?.schools.join(', ');
    }

    if (personDetails?.mentorGivenName != null && personDetails?.mentorGivenName !== '') {
      taskUserDetail['On-site Mentor Name'] = `${personDetails?.mentorGivenName} ${personDetails?.mentorSurname}`;
    }

    if (personDetails?.coachGivenName != null && personDetails?.coachGivenName !== '') {
      taskUserDetail['Online Mentor Name'] = `${personDetails?.coachGivenName} ${personDetails?.coachSurname}`;
    }
  }

  return (
    <VContainer>
      <TitleMediumStyled>TASK OVERVIEW</TitleMediumStyled>
      {Object.entries(taskUserDetail)?.map(([key, value]) => (
        <React.Fragment key={key}>
          {(key === 'Intern Name' || key === 'Owner Name') && (
            <React.Fragment>
              {showEditButton && (
                <StyledButton text="Edit Details" onClick={onEditClick} />
              )}
              <TitleMediumStyled>PARTICIPANT DETAILS</TitleMediumStyled>
            </React.Fragment>
          )}
          <KeyValueContainer>
            <LabelMedium color="secondary.s40">{key}</LabelMedium>
            <BodySmallStyled color="secondary.s40">{value}</BodySmallStyled>
          </KeyValueContainer>
        </React.Fragment>
      ))}

      {((childTask && taskDetail.assessmentMnemonic == null) || linkedTask || internTask) && <TitleMediumStyled>ADDITIONAL DETAILS</TitleMediumStyled>}
      {childTask && taskDetail.assessmentMnemonic == null &&
        <KeyValueContainer>
          <LabelMedium color="secondary.s40">Follow-Up Task</LabelMedium>
          <StyledTextButton
            text={childTask.title}
            onClick={() => navigate(`/tasks/${childTask.id}?owner=${childTask.ownerPersonId}${childTask.linkedTaskId && '&linked=' + childTask.linkedTaskId || ''}${childTask.parentTaskId && '&parent=' + childTask.parentTaskId || ''}`)}
            disabled={taskDetail.calculatedFields.effectiveStatus !== StatusMnemonic.Completed}>
          </StyledTextButton>
        </KeyValueContainer>
      }

      {userType === IdentityGroupType.Intern && linkedTask &&
        <KeyValueContainer>
          <LabelMedium color="secondary.s40">Mentor Task</LabelMedium>
          <StyledTextButton
            text={linkedTask.title}
            onClick={() =>{}}
            disabled={true}>
          </StyledTextButton>
        </KeyValueContainer>
      }

      {internTask &&
        <KeyValueContainer>
          <LabelMedium color="secondary.s40">Related Task</LabelMedium>
          <StyledTextButton
            text={internTask.title}
            onClick={() => navigate(`/tasks/${internTask.id}?owner=${internTask.ownerPersonId}${internTask.linkedTaskId && '&linked=' + internTask.linkedTaskId || ''}${internTask.parentTaskId && '&parent=' + internTask.parentTaskId || ''}`)}
          >
          </StyledTextButton>
        </KeyValueContainer>
      }

      {(userType === IdentityGroupType.Mentor || userType === IdentityGroupType.Coach) && linkedTask &&
        <KeyValueContainer>
          <LabelMedium color="secondary.s40">Linked Task</LabelMedium>
          <StyledTextButton
            text={linkedTask.title}
            onClick={() => navigate(`/tasks/${linkedTask.id}?owner=${linkedTask.ownerPersonId}${linkedTask.linkedTaskId && '&linked=' + linkedTask.linkedTaskId || ''}${linkedTask.parentTaskId && '&parent=' + linkedTask.parentTaskId || ''}`)}
            disabled={false}>
          </StyledTextButton>
        </KeyValueContainer>
      }

      {(userType === IdentityGroupType.Mentor || userType === IdentityGroupType.Coach) && linkedTask == null && taskDetail.linkedTaskTemplateId &&
        <KeyValueContainer>
          <LabelMedium color="secondary.s40">Observe Task?</LabelMedium>
          <StyledTextButton
            text={'Create'}
            onClick={() => navigate(`/add-task/create?id=${taskDetail.linkedTaskTemplateId}&linked=${taskDetail.id}`)}
            disabled={false}>
          </StyledTextButton>
        </KeyValueContainer>
      }

      {taskDetail.completedTimestamp && taskDetail.taskMnemonic !== TaskTypeMnemonic.InternPlanUpcomingWeek &&
            <React.Fragment>
            <StyledButton text="Download Task" onClick={onDownloadClick} />
          </React.Fragment>
      }

    </VContainer>
  );
};

TaskDetail.displayName = 'TaskDetail';

TaskDetail.propTypes = {
  taskDetail: PropTypes.object.isRequired,
  personDetails: PropTypes.object.isRequired,
  userType: PropTypes.string,
  childTask: PropTypes.any,
  linkedTask: PropTypes.any,
  internTask: PropTypes.any,
  onEditClick: PropTypes.func,
  onDownloadClick: PropTypes.func,
};

TaskDetail.propTypes = {
  onEditClick: () => {},
  onDownloadClick: () => {},
};
