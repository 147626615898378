import {
  styled,
} from '@mui/material';

import {
  AlignItems,
} from 'enums';

import {
  HContainer,
} from 'ui/common/components/containers/HContainer';

export const HContainerV = styled(HContainer)`
  align-items: ${AlignItems.Center};
`;

HContainerV.displayName = 'HContainerV';
