import React from 'react';

import {
  useRecoilState,
} from 'recoil';

import {
  PrecachedAssetsAtom,
  AssetsBeingPrecachedAtom,
} from 'atoms';

export const walkAssetList = ({
  assetList,
  callback,
}) => {

  if (typeof assetList === 'string') {
    return callback(assetList);
  }

  try {

    for (const asset of Object.values(assetList)) {

      walkAssetList({
        assetList: asset,
        callback,
      });
    }
  } catch {
    // In case `Object.values` throws then an invalid data type was passed in for the `assetList`
    // parameter. In that case it's safe to ignore the error and not invoke the callback.
  }
};

/**
 * Creates a new image for the specified image source and resolves a promise once the image was
 * loaded. Otherwise, the promise is rejected.
 * @param {string} imageSource The source URL of the image to load.
 * @return {Promise<void>} Resolves once the image is loaded. Otherwise, rejects with an error when
 * the image errored out or the loading was aborted.
 */
export const preloadImage = imageSource => {

  return new Promise((resolve, reject) => {

    const img = new Image();

    img.onload = () => {
      resolve();
    };

    img.onerror = () => {
      reject(new Error(`Error pre-loading image ${imageSource}`));
    };

    img.onabort = () => {
      reject(new Error(`Aborted pre-loading image ${imageSource}`));
    };

    img.src = imageSource;
  });
};

export const useImagePrefetch = ({
  imageList,
  run = true,
}) => {

  const [
    state,
    setState,
  ] = React.useState({
    loading: run,
    errors: [],
  });

  const [
    precachedAssets,
    setPrecachedAssets,
  ] = useRecoilState(PrecachedAssetsAtom);

  const [
    assetsBeingPrecached,
    setAssetsBeingPrecached,
  ] = useRecoilState(AssetsBeingPrecachedAtom);

  React.useEffect(() => {

    if (!run) {

      if (state.loading) {

        setState(prevState => ({
          ...prevState,
          loading: false,
        }));
      }

      return;
    }

    let mounted = true;

    /**
     * All the assets that are being pre-cached as part of the current instance.
     * @type {Map<string, Promise>}
     */
    const precachingAssetsPromises = new Map();

    /**
     * All the assets that are not part of the asset pre-cache atoms.
     * @type {Map<string, Promise>}
     */
    const assetPrecacheUpdates = new Map();

    walkAssetList({
      assetList: imageList,
      callback: (imageSource) => {

        if (precachedAssets.has(imageSource)) {
          return;
        }

        if (assetsBeingPrecached.has(imageSource)) {

          precachingAssetsPromises.set(imageSource, assetsBeingPrecached.get(imageSource));
          return;
        }

        const promise = preloadImage(imageSource)
          .then(value => ({
            success: true,
            value,
          }))
          .catch(error => ({
            success: false,
            error,
          }));

        precachingAssetsPromises.set(imageSource, promise);
        assetPrecacheUpdates.set(imageSource, promise);
      },
    });

    if (assetPrecacheUpdates.size > 0) {

      setAssetsBeingPrecached(prevAssetsBeingPrecached => new Map([
        ...prevAssetsBeingPrecached,
        ...assetPrecacheUpdates,
      ]));
    }

    if (precachingAssetsPromises.size > 0) {

      if (!state.loading) {

        setState(prevState => ({
          ...prevState,
          loading: true,
        }));
      }

      Promise.all(precachingAssetsPromises.values()).then(results => {

        if (!mounted) {
          return;
        }

        const newPrecachedAssets = new Set([
          ...precachedAssets,
          ...precachingAssetsPromises.keys(),
        ]);

        const newAssetsBeingPrecached = new Map(assetsBeingPrecached);

        for (const key of newPrecachedAssets) {
          newAssetsBeingPrecached.delete(key);
        }

        setPrecachedAssets(newPrecachedAssets);
        setAssetsBeingPrecached(newAssetsBeingPrecached);

        const errors = [];

        for (const result of results) {
          if (!result.success) {
            errors.push(result.error);
          }
        }

        setState(prevState => ({
          ...prevState,
          loading: false,
          errors,
        }));
      })
    }

    if (precachingAssetsPromises.size === 0 && state.loading) {

      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }

    return () => {
      mounted = false;
    };
  }, [
    assetsBeingPrecached,
    imageList,
    run,
    precachedAssets,
    setAssetsBeingPrecached,
    setPrecachedAssets,
    state.loading,
  ]);

  return {
    loading: state.loading,
    errors: state.errors,
  };
};
