import {
  gql,
} from '@apollo/client';

export const QuerySchools = gql`
  query MyQuery($params : SchoolQueryFilterParams) {
    schoolQueryFilter(params : $params) {
      body {
        schools {
          title
          telNo
          modifiedTimestamp
          mnemonic
          location
          id
          description
          address
        }
      }
    }
  }
`;