import PropTypes from 'prop-types';

import {
  styled,
} from '@mui/material';

import React from 'react';

import { DatePicker, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { getDay, isSameWeek } from 'date-fns';
import { enGB } from 'date-fns/locale';

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p99,
  marginBottom: '16px',
}));

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
  }),
  ...(getDay(day) === 1 && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(getDay(day) === 0 && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));

const isInSameWeek = (dayA, dayB) => {
  if (dayB == null) {
    return false;
  }

  return isSameWeek(dayA, dayB, {weekStartsOn: 1});
};

const Day = (props) => {
  const { day, selectedDay, hoveredDay, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  );
}

export const WeeklyPicker = props => {

const [hoveredDay, setHoveredDay] = React.useState(null);
const [value, setValue] = React.useState(new Date().toISOString());

  return (
  <React.Fragment>

    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <StyledDatePicker
        {...props}
        value={value}
        onChange={(newValue) => setValue(newValue)}
        showDaysOutsideCurrentMonth
        displayWeekNumber
        disableFuture
        slots={{ day: Day }}
        slotProps={{
          textField: {
            content: 'Test',
          },
          day: (ownerState) => {
            return {
              selectedDay: value,
              hoveredDay,
              onPointerEnter: () => {console.log('HIT'); setHoveredDay(ownerState.day);},
              onPointerLeave: () => setHoveredDay(null),
            };
          }
        }}
      />
    </LocalizationProvider>

  </React.Fragment>
  )
};

WeeklyPicker.displayName = 'ShortText';

WeeklyPicker.propTypes = {
  value: PropTypes.any,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  endAdornment: PropTypes.any,
};

WeeklyPicker.defaultProps = {
  placeholder: '',
  helperText: '',
  error: false,
};
