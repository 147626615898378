import {
  gql,
} from '@apollo/client';

export const GenerateReport = gql`
  mutation generateReport($params: ReportGenerateByMnemonicParams) {
    reportGenerateByMnemonic(params: $params) {
      body {
        message
        ok
        reportFileId
      }
    }
  }
`;