import React from 'react';
import PropTypes from 'prop-types';

import {
  IconButton,
  InputAdornment,
  styled,
  TextField as TextFieldProto,
} from '@mui/material';

import {
  TextFieldVariant,
} from 'enums';

import {
  InfoText,
} from '../InfoText';

import {
  useGeolocation,
} from 'hooks';

import { 
  LocationOnOutlined,
  Refresh,
} from '@mui/icons-material';

const StyledTextField = styled(TextFieldProto)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p100,
  width: '100%',
  marginBottom: '16px',
}));

const extractCoordinates = (input) => {
  const regex = /-?\d+\.\d+, -?\d+\.\d+/;
  const match = input.match(regex);
  
  if (match) {
      return match[0];
  } else {
      return null;
  }
}

export const Geolocation = props => {

  const {
    title,
    readOnly,
    description,
    groupName,
    value,
    onChange,
    questionId,
    userId,
    questionComments,
    upsertCommentLoading,
    handleComment,
    ...other
  } = props;

  const existingLocation = value && extractCoordinates(value);

  const {
    loading,
    error,
    location,
    address,
    refetch,
  } = useGeolocation(existingLocation, value);

  React.useEffect(() => {
    if (!loading && !readOnly && address != null && address != value) {
      onChange({
        target: {
          value: address,
        }
      });
    }
  }, [location, address, loading, readOnly, value, existingLocation, onChange]);

  return (
    <React.Fragment>
      {!readOnly &&
        <InfoText
          title={title}
          description={description}
          groupName={groupName}
          questionId={questionId}
          questionComments={questionComments}
          upsertCommentLoading={upsertCommentLoading}
          handleComment={handleComment}
          userId={userId}
        />
      }

      {readOnly &&
        <InfoText
          title={title}
          description={loading && 'Loading...' || address}
          groupName={groupName}
          questionId={questionId}
          questionComments={questionComments}
          upsertCommentLoading={upsertCommentLoading}
          handleComment={handleComment}
          userId={userId}
        />
      }

      {!readOnly &&
        <StyledTextField
          {...other}
          value={loading && 'Loading...' || address}
          onChange={() => {}}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: true,
            endAdornment:
              <InputAdornment position="end">
                <IconButton
                  aria-label="locatio input icon"
                  onClick={() => {
                    if (error) {
                      refetch();
                    }
                  }}
                  edge="end"
                >
                  {error ? <Refresh color="error" /> : <LocationOnOutlined />}
                </IconButton>
              </InputAdornment>,
          }}
          error={error}
          helperText={error && 'Location required. Please enable location services.' || ''}
        />
      }

    </React.Fragment>
  );
};

Geolocation.displayName = 'Geolocation';

Geolocation.propTypes = {
  variant: PropTypes.oneOf(Object.values(TextFieldVariant)),
  multiline: PropTypes.bool,
  minRows: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  groupName: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  questionId: PropTypes.any,
  questionComments: PropTypes.any,
  upsertCommentLoading: PropTypes.bool,
  handleComment: PropTypes.any,
  userId: PropTypes.string,
};

Geolocation.defaultProps = {
  variant: TextFieldVariant.Filled,
  multiline: true,
  minRows: 1,
  placeholder: '',
  readOnly: false,
  groupName: '',
  userId: '',
  upsertCommentLoading: false,
};
