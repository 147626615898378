import { DefaultSchedule } from 'enums';

import {
  atom,
} from 'recoil';

export const CurrentScheduleAtom = atom({
  key: 'app-current-schedule',
  default: DefaultSchedule,
});

export const NextWeekScheduleAtom = atom({
  key: 'app-next-week-schedule',
  default: DefaultSchedule,
});

export const ScheduleIdAtom = atom({
  key: 'app-schedule-id',
  default: {},
});