import { useMutation } from '@apollo/client';
import { TaskUpsert } from 'gql/task/mutations';

export const useTaskUpsert = () => {

  const [upsertTask, { data, loading, error }] = useMutation(TaskUpsert);

  return [
    upsertTask,
    {
      loading,
      error,
      data,
    }
  ];
}
