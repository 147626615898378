import React from 'react';

import {
  Asset,
  IdentityGroupType,
  JourneyName,
  ScreenName,
  StatusMnemonic,
} from 'enums';

import {
  styled,
} from '@mui/material';

import {
  Feedback,
  FormController,
  Scene,
  TaskViewTabs,
  VContainerV,
  HeadlineLarge,
  BodyMedium,
  TaskDetail,
  LabelLarge,
  SecondaryButton,
  Comments,
} from 'ui/common';

import {
  useTaskUpsert,
  useTaskInteractionUpsert,
  useIdentity,
  useStorage,
} from 'hooks';

import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import {
  buildFormDetails,
  isReadOnlyFromTaskDetails,
} from 'common/forms';

import {
  buildTaskUpsertVariables,
  downloadTaskPdf,
  getViewStatusSetup,
} from 'common';

import {
  format,
} from 'date-fns';

import { useTaskDetailsPage } from 'hooks/useTaskDetailsPage';

const SpaceContainer = styled(VContainerV)`
  padding: 1rem 0;

  @media (min-width: 600px) {
    padding: 1rem 0;
  }
`;

const Text = styled(BodyMedium)`
  margin-top: 1.3rem;
`;

const StyledLabel = styled(LabelLarge)`
  margin-top: 0.5rem;
`;

export const TaskDetails = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [formDetails, setFormDetails] = React.useState();
  const [readOnly, setReadOnly] = React.useState(false);
  const [showReviseButton, setShowReviseButton] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);

  const { getItem } = useStorage();

  const { user } = useIdentity(TaskDetails.context);
  const navigate = useNavigate();
  const userType = user.group || IdentityGroupType.Intern;

  const ownerPersonId = searchParams.get('owner');
  const linkedTaskId = searchParams.get('linked') || '00000000-0000-0000-0000-000000000000';
  const parentTaskId = searchParams.get('parent') || '00000000-0000-0000-0000-000000000000';


  const {
    loading,
    error,
    pageDetails,
    setPageDetails,
    refetch,
  } = useTaskDetailsPage(params.id, ownerPersonId, linkedTaskId, user.group, parentTaskId);

  const [
    upsertComment,
    buildCommentVariables,
    {
      upsertedInteractions,
      loading: upsertCommentLoading
    },
  ] = useTaskInteractionUpsert();

  const [ upsertTask ] = useTaskUpsert();

  const getItemCallback = React.useCallback(async (key) => {
    return await getItem(key);
  }, [getItem]);

  React.useEffect(() => {

    const fetchForm = async () => {
      if (!loading && pageDetails?.taskDetails && (formDetails == null || (formDetails.id !== pageDetails?.taskDetails.id))) {

        const localForm = await getItemCallback({key: pageDetails?.taskDetails.id})

        const details = buildFormDetails(pageDetails.taskDetails, localForm, pageDetails?.internTaskDetails);
  
        if (details.status === StatusMnemonic.Scheduled) {
  
          const date = new Date().toISOString();
          upsertTask(buildTaskUpsertVariables(
            [{
              id: details.id,
              status: StatusMnemonic.Open,
              startedTimestamp: date,
            }]
          ));
  
          details.status = StatusMnemonic.Open;
          details.startedTimestamp = date;
        }
  
        setFormDetails(details);
        setReadOnly(isReadOnlyFromTaskDetails(details, user));
  
        if (details.status === StatusMnemonic.Rejected && userType === IdentityGroupType.Intern) {
          setShowReviseButton(true);
          setReadOnly(true);
        }
      }
    }

    fetchForm();

  }, [loading, upsertTask, formDetails, pageDetails, user, userType, getItemCallback]);

  React.useEffect(() => {
    if (upsertedInteractions != null && upsertedInteractions.length > 0) {

      setPageDetails(prevDetails => {

        const addedQuestionInteractions = upsertedInteractions.map(i => {
          if (i.questionId && i.question == null && formDetails != null) {
            const targetQuestion = formDetails.questions.find(q => q.id === i.questionId);
            return {
              ...i,
              question: targetQuestion,
            }
          }

          return i;
        });

        const newDetails = {
          ...prevDetails,
          taskInteractionList: [
              ...prevDetails.taskInteractionList,
              ...addedQuestionInteractions,
          ],
        };

        return newDetails;
      });
    }
  }, [upsertedInteractions, formDetails, setPageDetails]);

  const handleCommentUpload = async (commentText, questionId) => {
    try {
      const variables = buildCommentVariables(formDetails.id, user.id, commentText, questionId);
      await upsertComment(variables);

      if ((formDetails.status === StatusMnemonic.AwaitMentor || formDetails.status === StatusMnemonic.AwaitCoach) && (userType === IdentityGroupType.Mentor || userType === IdentityGroupType.Coach)) {
        upsertTask(buildTaskUpsertVariables(
          [{
            id: formDetails.id,
            status: StatusMnemonic.Completed,
            completedTimestamp: new Date().toISOString(),
          }]
        ));
      }
    } catch (error) {
      console.error('Error uploading comment:', error);
    }
  };

  const handleReviseTask = () => {
    setReadOnly(!readOnly);
  };

  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
  };

  const tabs = [
    { label: 'Responses' },
    { label: 'Comments' },
    { label: 'Task Details' },
  ];

  const renderTabContent = (tabIndex) => {

    switch (tabIndex) {
      case 0:
        return (
          <React.Fragment>

            {!loading && !error && formDetails &&
              <React.Fragment>
                <FormController 
                  formDetails={formDetails} 
                  readOnly={readOnly} 
                  showReviseButton={showReviseButton} 
                  userType={userType} 
                  userId={user?.id}
                  taskInteractionList={pageDetails.taskInteractionList}
                  upsertCommentLoading={upsertCommentLoading}
                  handleCommentUpload={handleCommentUpload} 
                  handleReviseTask={handleReviseTask}
                  childTask={pageDetails?.childTaskDetails}
                  internTask={pageDetails?.internTaskDetails}
                  scheduleQuestionId={pageDetails?.taskDetails?.questions?.find(f => f.inputType === 'SCHDLR')?.id}
                />
              
                { (userType === IdentityGroupType.Mentor || userType === IdentityGroupType.Coach) && 
                  (formDetails.status=== StatusMnemonic.AwaitMentor || formDetails.status === StatusMnemonic.AwaitCoach) && (
                  <SecondaryButton
                    text='Add Comment'
                    onClick={() => handleTabChange(1)}
                  />
                )}
              </React.Fragment>
            }

            {!loading && error &&
            <Feedback 
              imageUrl={Asset.Image.Other.BrokenPencil}
              headerText='Something Went Wrong!'
              bodyText="We encountered a hiccup while fetching your task details. Not to worry, though – we're on it!"
              infoText="Please try loading your tasks again. If the issue persists, reach out to us for support"
              type="Error"
              buttonText='Try Again'
              buttonAction={() => refetch()}/>
            }
          </React.Fragment>
        );
      case 1:
        return (
        <React.Fragment>
          {!loading && pageDetails?.taskInteractionList && (
              <Comments
                comments={pageDetails.taskInteractionList}
                userId={user.id}
                upsertCommentLoading={upsertCommentLoading}
                onClick={handleCommentUpload}
              />
            )
          }
          {!loading && error && (
              <Comments error={error} />
            )
          }
        </React.Fragment>);
      case 2:
        return (
          <React.Fragment>
            {!loading && pageDetails.taskDetails && pageDetails?.taskInteractionList &&
              <TaskDetail
                taskDetail={pageDetails?.taskDetails}
                personDetails={pageDetails?.personDetails}
                userType={userType}
                childTask={pageDetails?.childTaskDetails}
                linkedTask={pageDetails?.linkedTaskDetails}
                internTask={pageDetails?.internTaskDetails}
                onEditClick={() => navigate(`/add-task/edit?id=${pageDetails.taskDetails.id}`)}
                onDownloadClick={() => downloadTaskPdf(pageDetails)}
              />
            }

            {!loading && error &&
              <Feedback
                imageUrl={Asset.Image.Other.BrokenPencil}
                headerText='Something Went Wrong!'
                bodyText="We encountered a hiccup while fetching your task details. Not to worry, though – we're on it!"
                infoText="Please try loading your tasks again. If the issue persists, reach out to us for support"
                type="Error"
                buttonText='Try Again'
                buttonAction={() => refetch()}
              />
            }
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  const viewStatusSetup = getViewStatusSetup(formDetails);

  return (
    <Scene
      headerVisible={true}
      title={formDetails && formDetails?.taskTemplateTitle || ScreenName.TaskDetails}
      footerVisible={true}
      loading={loading}
    >
      {!loading && formDetails &&
        <React.Fragment>
          <SpaceContainer>
            { !readOnly &&
              <React.Fragment>
                <HeadlineLarge>{formDetails?.title}</HeadlineLarge>
                <Text>{formDetails?.description}</Text>
              </React.Fragment>
            }
            { readOnly &&
              <React.Fragment>
                <HeadlineLarge>{formDetails?.title}</HeadlineLarge>
                <Text dangerouslySetInnerHTML={{ __html:
                  `You're reviewing a ${formDetails?.taskTemplateTitle} task for <b>${format(formDetails?.scheduledEndTimestamp, 'dd MMMM yyyy')}</b>.`
                }}></Text>
                <StyledLabel>Please note that this information is now locked for editing.</ StyledLabel>
                <StyledLabel color={viewStatusSetup.color}>{viewStatusSetup.friendlyStatus}</ StyledLabel>
              </React.Fragment>
            }
          </SpaceContainer>

          <TaskViewTabs tabs={tabs} defaultTab={0} activeTab={activeTab} renderTabContent={renderTabContent} onChange={handleTabChange} />

        </React.Fragment>
      }
    </Scene>
  );
};

TaskDetails.displayName = 'View Task';

TaskDetails.context = {
  screen: ScreenName.TaskDetails,
  journey: JourneyName.TaskDetails,
  component: TaskDetails.displayName,
};
