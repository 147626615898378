import PropTypes from 'prop-types';

import {
  styled,
  TextField as TextFieldProto,
} from '@mui/material';

import {
  Rating,
} from 'ui/common/components/form/Inputs/Rating';

import React from 'react';

const StyledTextField = styled(TextFieldProto)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p99,
  margin: '1em 0 1rem 0',
  width: '100%',
  paddingRight: '0.2em',
}));

const FieldName = {
  Rating: 'rating',
  Response: 'response',
};

export const RatingLongtext = props => {

  const {
    title,
    description,
    groupName,
    id,
    onChange,
    value,
    min,
    max,
    placeholder,
    questionId,
    questionComments,
    upsertCommentLoading,
    handleComment,
    userId,
  } = props;

  const getDefaultState = (value) => {
    const defaultState = {
      rating: 1,
      response: undefined,
    };

    if (value != null && value !== '' && JSON.parse(value)) {
      const existingValue = JSON.parse(value);

      Object.keys(existingValue).forEach(v => {
        defaultState[v] = existingValue[v];
      });
    }

    return defaultState;
  };

  const [state, setState] = React.useState(getDefaultState(value));

  const handleChange = ({
    name,
    value,
  }) => {
    setState((prevState) => {

      const newState = {
        ...prevState,
        [name]: value,
      };

      if (onChange) {
        onChange(JSON.stringify(newState));
      }

      return newState;
    });
  };

  return (
    <React.Fragment>

      <Rating
        key={`${id}-rating`}
        name={FieldName.Rating}
        value={state?.rating || 0}
        onChange={rating => handleChange({
          name: FieldName.Rating,
          value: rating,
        })}
        title={title}
        description={description}
        groupName={groupName}
        questionId={questionId}
        questionComments={questionComments}
        upsertCommentLoading={upsertCommentLoading}
        handleComment={handleComment}
        userId={userId}
        max={max}
        min={min}/>

      <StyledTextField
        name={FieldName.Response}
        id={`${id}-response`}
        placeholder={placeholder}
        value={state?.response || ''}
        onChange={event => handleChange({
          name: FieldName.Response,
          value: event.target.value,
        })}
      />
    </React.Fragment>
  );
};

RatingLongtext.displayName = 'RatingLongtext';

RatingLongtext.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  groupName: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  questionId: PropTypes.any,
  questionComments: PropTypes.any,
  upsertCommentLoading: PropTypes.bool,
  handleComment: PropTypes.any,
  userId: PropTypes.string,
};

RatingLongtext.defaultProps = {
  id: `RatingLongtext-${Math.random()}`,
  title: '',
  description: '',
  groupName: '',
  value: '',
  placeholder: '',
  label: 'Label',
  min: 1,
  max: 5,
  onChange: () => console.log('RatingLongtext Changed'),
  userId: '',
};
