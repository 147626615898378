import {
  gql,
} from '@apollo/client';

export const QuerySchedule = gql`
  query ScheduleQueryFilter($params: ScheduleQueryFilterParams) {
    scheduleQueryFilter(params: $params) {
      body {
        schedules {
          ...ScheduleFragment
        }
      }
    }
  }

  fragment ScheduleFragment on Schedule {
    active
    createdTimestamp
    id
    modifiedTimestamp
    personId
    week
    year
    tasks {
      ...TaskFragment
    }
  }

  fragment TaskFragment on Task {
      id
      title
      scheduledStartTimestamp
      scheduledEndTimestamp
      scheduleId
      location
      note
  }
`;
