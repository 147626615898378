import {
    RoutePath,
  } from 'enums';
  
  import {
    Onboarding
  } from 'ui/onboarding';
  
  /**
   * @type {RouteConfig[]}
   */
  export const OnboardingRoutes = [
    {
      element: <Onboarding />,
      path: RoutePath.Onboarding,
      children: [],
    }
  ];
  