import React from 'react';

import {
  Accordion,
  AccordionDetails, 
  AccordionSummary, 
  Divider, 
  Grid, 
  styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { 
  IdentityGroupType,
  JourneyName, 
  ScreenName 
} from 'enums';

import { 
  BodyMedium, 
  HeadlineLarge, 
  Scene, 
  TitleMedium, 
  HBoxV, 
  BodySmall, 
  TaskViewTabs,
  SupportForm,
  PrimaryButton,
} from 'ui/common';

import { validateSupportForm } from 'common/forms';

import { useIdentity } from 'hooks';

import { 
  contactInformation, 
  helpTabs,
  getFAQData, 
} from './contactInformation';

const StyledIcon = styled(({ icon, ...props }) => React.createElement(icon, props))(({ theme }) => ({
  '&.MuiSvgIcon-root': {
    color: theme.palette.primary.p30,
  },
}));

const StyledBodyMedium = styled(BodyMedium)`
  margin: 1rem 0;
`;

const StyledTitleMedium = styled(TitleMedium)`
  margin: 1rem 0;
`;

const StyledGrid = styled(Grid)`
  margin: 1rem 0 2rem 0;
`;

const InfoBox = styled(HBoxV)`
  border-radius: 12px;
  padding: 12px;
  margin-top: 12px;
  height: 100%;
  max-height: 65%;
  min-height: 65%;
`;

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  border: 'none',
  backgroundColor: 'transparent',
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-root': {
    minHeight: '64px', 
  },
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: 'transparent',
}));

const StyledButton = styled(PrimaryButton)`
  margin-top: 1.8rem;
  @media (min-width: 600px) {
    max-width: 228px;
  }
`;

export const HelpAndSupport = () => {
  const { user, loading } = useIdentity(HelpAndSupport.context);
  const userType = user.group || IdentityGroupType.Intern;

  const [activeTab, setActiveTab] = React.useState(0);
  const [expandedAccordion, setExpandedAccordion] = React.useState(null);
  const [formFields, setFormFields] = React.useState();
  const [isFormValid, setIsFormValid] = React.useState(true);
  const [validation, setValidation] = React.useState({});

  React.useMemo(() => {
    if (!loading && user) {

      const fields = {
        givenName: user.name,
        surname: user.surname,
        email: user.email,
        queryType: '',
        subject: '',
        message: '',
        
      };

      setFormFields(fields);
    }
  }, [loading, user]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
  };

  const handleButtonClick = () => {
    const { givenName, surname, email, queryType, subject, message } = formFields;
    const mailtoLink = `mailto:desmond.macauley@thutotrust.org?subject=${encodeURIComponent(`${queryType} - ${subject}`)}&body=${encodeURIComponent(`Name: ${givenName} ${surname}\nEmail: ${email}\n\nQuery Type: ${queryType}\n\nMessage: ${message}`)}`

    window.location.href = mailtoLink;
  };

  const handleFormFieldChange = (e, field) => {
    setFormFields(prevFields => {

      let value = e.target?.value;

      const newFields = {
        ...prevFields,
        [field]: value,
      };

      const errorMap = validateSupportForm(newFields);

      setValidation(errorMap);
      setIsFormValid(Object.keys(errorMap).length === 0);

      return newFields;
    });
  };

  const renderTabContent = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return (
          <React.Fragment>
            <br/>
            <TitleMedium>FREQUENTLY ASKED QUESTIONS</TitleMedium><br/>
            <BodyMedium>Find answers to common questions about using the app, managing tasks, and more.</BodyMedium><br/>
            <Divider />
            <Grid container direction="column" spacing={1}>
              {getFAQData(userType).map((faq, index) => (
                <React.Fragment key={index}>
                  <Grid item key={index}>
                    <CustomAccordion
                      expanded={expandedAccordion === index}
                      onChange={handleAccordionChange(index)}
                    >
                      <CustomAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <TitleMedium>{faq.question}</TitleMedium>
                      </CustomAccordionSummary>
                      <CustomAccordionDetails>
                        <BodyMedium dangerouslySetInnerHTML={{ __html: faq.answer }}></BodyMedium>
                      </CustomAccordionDetails>
                    </CustomAccordion>
                  </Grid>
                  <Divider />
                </React.Fragment>
              ))}
            </Grid>
          </React.Fragment>
        );
        
      case 1:
        return (
          <React.Fragment>
            <br/>
           <TitleMedium>CONTACT SUPPORT</TitleMedium><br/>
            <BodyMedium>
              Can't find what you're looking for? Fill out the form below, and we'll get back to you as soon as possible.
            </BodyMedium><br/>

            <SupportForm
              formFields={formFields}
              validation={validation}
              handleFormFieldChange={handleFormFieldChange}
            />

            <StyledButton 
              text='Send Message' 
              onClick={handleButtonClick}
              disabled={!isFormValid}
            />

          </React.Fragment>
        );
      default:
        return null;
    }
  };

  return (
    <Scene
      headerVisible={true}
      title={ScreenName.HelpAndSupport}
      footerVisible={true}
    >
      <React.Fragment>
        <br/>
        <HeadlineLarge>HELP & SUPPORT</HeadlineLarge>
        <StyledBodyMedium>
          Need a hand? Whether you have a question or need direct assistance, you're in the right place.
        </StyledBodyMedium>
        <StyledTitleMedium>CONTACT INFORMATION</StyledTitleMedium>
        <BodyMedium>Here are direct ways to reach our team for immediate assistance.</BodyMedium>
        <StyledGrid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {contactInformation.map((contact) => {
            const Icon = contact.icon ? StyledIcon : null;
            return (
              <Grid key={contact.type} item xs={12} sm={6} md={4}>
                <InfoBox backgroundColor={'primary.p99'}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={2} md={2}>
                      {Icon && <Icon icon={contact.icon} color={'primary.p30'} />}
                    </Grid>
                    <Grid item xs={10} md={10}>
                      <TitleMedium>{contact.contact}</TitleMedium>
                      <BodySmall>{contact.additonal}</BodySmall>
                    </Grid>
                  </Grid>
                </InfoBox>
              </Grid>
            );
          })}
        </StyledGrid>

        <TaskViewTabs tabs={helpTabs} defaultTab={0} activeTab={activeTab} renderTabContent={renderTabContent} onChange={handleTabChange} />
      </React.Fragment>
    </Scene>
  );
};

HelpAndSupport.displayName = 'HelpAndSupport';

HelpAndSupport.context = {
  screen: ScreenName.HelpAndSupport,
  journey: JourneyName.HelpAndSupport,
  component: HelpAndSupport.displayName,
};
