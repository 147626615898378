import {
  RoutePath,
} from 'enums';

import {
  TaskDetails,
  TaskTemplate,
  CreateTask,
  EditTask,
  CreateAssessmentTask,
} from 'ui/tasks';

/**
 * @type {RouteConfig[]}
 */
export const TasksRoutes = [
  {
    element: <TaskDetails />,
    path: RoutePath.TaskDetails,
    children: [],
  },
  {
    element: <TaskTemplate/>,
    path: RoutePath.TaskTemplate,
    children: [],
  },
  {
    element: <CreateTask/>,
    path: RoutePath.CreateTask,
    children: [],
  },
  {
    element: <EditTask/>,
    path: RoutePath.EditTask,
    children: [],
  },
  {
    element: <CreateAssessmentTask/>,
    path: RoutePath.CreateAssessmentTask,
    children: [],
  },
];
