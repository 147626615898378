import PropTypes from 'prop-types';

import {
  styled,
  TextField as TextFieldProto,
  MenuItem,
} from '@mui/material';

import { InfoText } from '../InfoText';
import React from 'react';
import { generateCountingArray, getDaysLeftInWeek } from 'common';

const StyledTextField = styled(TextFieldProto)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p99,
  marginBottom: '1rem',
}));

export const AbsenceLength = props => {

  const { title, description, groupName, onChange, dateOfTask,  ...other} = props;

  const daysLeft = getDaysLeftInWeek(dateOfTask)
  const dayRange = generateCountingArray(daysLeft)


  const onChangeCallback = React.useCallback((value) => {
    onChange({target: {value: value}});
  }, [onChange]);


  return (
    <React.Fragment>

      <InfoText
        title={title}
        description={description}
        groupName={groupName}
      />

      <StyledTextField
        {...other}
        onChange={(e) => onChangeCallback(e.target.value)}
        select>
        {dayRange.map((option) =>
          <MenuItem key={option} value={option}>{option}</MenuItem>
        )}
      </StyledTextField>

    </React.Fragment>
  );
};

AbsenceLength.displayName = 'AbsenceLength';

AbsenceLength.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  groupName: PropTypes.string,
  dateOfTask: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

AbsenceLength.defaultProps = {
  id: `dropdown-absence-${Math.random()}`,
  title: '',
  description: '',
  groupName: '',
  value: '',
  onChange: () => console.log('Absence Length Dropdown Changed'),
};
