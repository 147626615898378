import {
  JourneyName,
  ScreenName,
} from 'enums';

import {
  Dropdown,
  HFillContainerV,
  LabelMedium,
  PrimaryButton,
  Scene,
  ShortText,
} from 'ui/common';

import {
  usePersons,
  usePersonDetails,
} from 'hooks';

import React, { useState } from 'react';

import { 
  styled,
  FormControl,
} from '@mui/material';

import { personDetailsByEmailQueryParams } from 'gql/person/queryParams';

import { 
  School , 
  User
} from './forms';

const StyledFormControl = styled(FormControl)`
  padding: 0 0 16px 0;
  color: primary;
`;

const StyledFormControlDivider = styled(StyledFormControl)(({ theme }) => `
  background-color: ${theme.palette.secondary.s100};
  padding: 1em;
  margin-bottom: 1em;
`,
);

const StyledLabelMedium = styled(LabelMedium)`
  margin-top: 1rem;
`;

export const UserOnboarding = () => {

  const{
    persons
  } = usePersons(['MENTOR']);

  const {
    persons : coachPersons
  } = usePersons(['COACH']);


  const adminActions = [
    {title: 'Create New User', action: 'Create'},
    {title: 'Edit Existing Intern', action: 'Edit'},
    {title: 'Register New School', action: 'RegisterSchool'},
  ]

  const [adminAction, setAdminAction] = useState(adminActions[0].title);
  const [activeEditingPersonEmail, setActiveEditingPersonEmail] = useState('');
  const [newPersonDetails, setNewPersonDetails] = useState({});


  const{
    personDetails: personDetailsData
  } = usePersonDetails(personDetailsByEmailQueryParams(activeEditingPersonEmail), true);

  const handleAdminActionChange = (event) => {
    setAdminAction(event.target.value);
    setActiveEditingPersonEmail('');
    setNewPersonDetails(() => {
      return {};
    });
  };

  const handleActiveEditingPersonEmailChange = (event) => {
    setActiveEditingPersonEmail(event.target.value);
    setNewPersonDetails(() => {
      return {};
    });
  };

  const handleSearchUserByEmail = () => {
    if(personDetailsData[0]){
      setNewPersonDetails(() => {
        
        let onlineMentor = coachPersons.find((person) => person.id === personDetailsData[0].coachid);
        onlineMentor = onlineMentor ? `${onlineMentor.givenName} ${onlineMentor.surname}` : undefined ;

        let onSiteMentor = persons.find((person) => person.id === personDetailsData[0].mentorid);
        onSiteMentor = onSiteMentor ? `${onSiteMentor.givenName} ${onSiteMentor.surname}` : undefined ;

        const newFields = {
          id: personDetailsData[0].id,
          yearOfStudy: personDetailsData[0].yearOfStudy ? personDetailsData[0].yearOfStudy : undefined,
          onlinementor: onlineMentor,
          onsitementor: onSiteMentor,
          teachingPhaseMnemonic: personDetailsData[0].teachingPhaseMnemonic ? personDetailsData[0].teachingPhaseMnemonic.charAt(0).toUpperCase() + personDetailsData[0].teachingPhaseMnemonic.slice(1) : undefined,
          school: personDetailsData[0].schools?.[0] ? personDetailsData[0].schools[0] : undefined,
          email: personDetailsData[0].email,
          gender: personDetailsData[0].gender,
          givenName: personDetailsData[0].givenName,
          surname: personDetailsData[0].surname,
          active: personDetailsData[0].active,
        };
  
        return newFields;
  
      });
    } else {
      setNewPersonDetails({error: `No intern with the email '${activeEditingPersonEmail}' exist.`});
    }
  };

  return (

    <Scene
      headerVisible={true}
      title={ScreenName.AdminUserOnboarding}
      footerVisible={true}
    >

        <StyledFormControlDivider>
            <Dropdown
              key='adminAction'
              label='Current Admin Action'
              options={adminActions.map((action) => action.title)}
              value={adminAction || adminActions[0].title}
              onChange={handleAdminActionChange}
            />


          {
            adminAction === adminActions[1].title &&
            <React.Fragment>
              <ShortText
                key='searchEmail'
                title='Provide the email for the intern you are searching for'
                placeholder='Email of the existing intern'
                label='Email'
                value={activeEditingPersonEmail}
                onChange={handleActiveEditingPersonEmailChange}
              />
              <HFillContainerV>
                <PrimaryButton
                  text='Search Intern'
                  onClick={() => handleSearchUserByEmail()}
                />
              </HFillContainerV>
              { newPersonDetails.error &&
                <StyledLabelMedium color='error'>{newPersonDetails.error}</StyledLabelMedium>
              }
            </React.Fragment>
          }

        </StyledFormControlDivider>

      {
        adminAction === adminActions[2].title &&
        <School></School>
      }


      {
        (adminAction === adminActions[0].title || adminAction === adminActions[1].title) &&
        <User
          mode={adminAction === adminActions[0].title ? 'create' : 'edit'}
          selectedUser={!!activeEditingPersonEmail && newPersonDetails ? newPersonDetails : undefined }
        ></User>
      }
      
    </Scene>
  );
};

UserOnboarding.displayName = 'Onboard New Users';

UserOnboarding.context = {
  screen: ScreenName.AdminUserOnboarding,
  journey: JourneyName.AdminUserOnboarding,
  component: UserOnboarding.displayName,
};
