import { useQuery } from '@apollo/client';
import { getAllReportsParams } from 'gql/reporting/params';
import { QueryAllReports } from 'gql/reporting/queries';
import { useEffect, useState } from 'react';

const buildFilterMap = (filters) => {

  const resultMap = {};

  filters.forEach(f => {
    resultMap[f] = true;
  });

  return resultMap;
};


export const useReports = () => {
  const [reportList, setReportList] = useState();

  const { loading, error, data, } = useQuery(QueryAllReports, {
    variables: getAllReportsParams(),
  });

  useEffect(() => {

    if (loading || data == null || reportList) {
      return;
    }

    const mappedReports = data?.reportQueryFilter?.body?.reports?.map(r => ({
      id: r.id,
      title: r.title,
      mnemonic: r.mnemonic,
      description: r.description,
      filters: r.filters && JSON.parse(r.filters)?.filters,
      filterMap: buildFilterMap(r.filters && JSON.parse(r.filters)?.filters),
    }));

    setReportList(mappedReports);
  }, [
    loading,
    data,
    reportList,
    setReportList,
  ]);

  return {
    loading,
    error,
    data,
    reportList,
  };
}
