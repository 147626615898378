import {
  RoutePath,
  ScreenName,
} from 'enums/navigation';

import {
  HomeOutlined,
  HelpOutline,
  CheckCircleOutline,
  AccountCircleOutlined,
  AddCircleOutlineOutlined,
  AdminPanelSettings,
  InfoOutlined,
  AssessmentOutlined
} from '@mui/icons-material';

export const MainMenuItems = [
  {
    id: 'footer-item--add-tasks',
    icon: AddCircleOutlineOutlined,
    text: ScreenName.TaskTemplate,
    path: RoutePath.TaskTemplate,
  },
  {
    id: 'main-menu-item-onboarding',
    icon: InfoOutlined,
    text: ScreenName.Onboarding,
    path: RoutePath.Onboarding,
  },
];

export const MainMenuMentorItems = [{
  id: 'main-menu-item-reporting',
  icon: AssessmentOutlined,
  text: ScreenName.Reporting,
  path: RoutePath.Reporting,
},];

export const MainMenuAdminItems = [{
  id: 'main-menu-admin-item-user-onboarding',
  icon: AdminPanelSettings,
  text: ScreenName.AdminUserOnboarding,
  path: RoutePath.AdminUserOnboarding,
},];

export const FooterItems = [{
  id: 'footer-item-home',
  icon: HomeOutlined,
  text: ScreenName.Home,
  path: RoutePath.Home,
  nav: 0,
},
{
  id: 'main-menu-item-task-list',
  icon: CheckCircleOutline,
  text: ScreenName.MyTasks,
  path: RoutePath.TaskList,
  nav: 1,
},
{
  id: 'footer-item-help',
  icon: HelpOutline,
  text: ScreenName.Help,
  path: RoutePath.HelpAndSupport,
  nav: 2,
},
{
  id: 'footer-item-account',
  icon: AccountCircleOutlined ,
  text: ScreenName.Account,
  path: RoutePath.Profile,
  nav: 3,
}];
