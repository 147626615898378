import {
  gql,
} from '@apollo/client';

export const AnswerUpsert = gql`
  mutation AnswerUpsert($params: AnswerUpsertParams) {
    answerUpsert(params: $params) {
      body {
        upsertResult {
          command
          ok
        }
        ok
        message
      }
      statusCode
    }
  }
`;

export const TaskUpsert = gql`
mutation TaskUpsert($params: TaskUpsertParams) {
  taskUpsert(params: $params) {
    body {
      message
      ok
      upsertResult {
        command
        ok
        row
      }
    }
  }
}
`;

export const TaskAnswerUpsert = gql`
  mutation TaskAnswerUpsert($answerParams: AnswerUpsertParams, $taskParams: TaskUpsertParams) {
    answerUpsert(params: $answerParams) {
      body {
        upsertResult {
          command
          ok
          row
        }
        ok
        message
      }
      statusCode
    }
    
    taskUpsert(params: $taskParams) {
      body {
        message
        ok
        upsertResult {
          command
          ok
          row
        }
      }
    }

    
  }
`;

export const FileUpsert = gql`
  mutation FileUpsert($params: FileUpsertParams) {
    fileUpsert(params: $params) {
      body {
        message
        ok
        upsertResult {
          command
          ok
          row
        }
      }
    }
  }
`;

export const CommentUpsert = gql`
  mutation TaskInteractionUpsert($params: TaskInteractionUpsertParams) {
    taskInteractionUpsert(params: $params) {
      body {
        message
        ok
        upsertResult {
          command
          ok
          row
        }
      }
      statusCode
    }
  }
`;
