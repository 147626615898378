import React from 'react';
import PropTypes from 'prop-types';

import {
  Badge,
  styled,
} from '@mui/material';

import {
  format,
} from 'date-fns';

import {
  VFillContainerV,
} from 'ui/common/components/containers';

import {
  HBoxV,
} from 'ui/common/components/boxes';

import {
  LabelMedium,
  TitleSmall,
  BodySmall,
} from 'ui/common/components/typography';

import {
  PrimaryButton,
} from 'ui/common/components/buttons';

import {
  CustomDialog
} from './CustomDialog';

import {
  StatusMnemonic,
  IdentityGroupType
} from 'enums';

import { useNavigate } from 'react-router-dom';
import { getTaskCardSetup, getDayOfWeekAndDate, invalidEarlySubmissionTaskTemplates } from 'common';

const TaskBox = styled(HBoxV)`
  border-radius: 12px;
  padding: 12px;
  margin-top: 12px;
  border-width: 1px;
  border-style: solid;
  height: 100%;
  max-height:65%;
  min-height:65%;
  `
;

const TaskButton = styled(PrimaryButton)`
  width: unset;
  box-shadow: none;
`;

const TitleText = styled(TitleSmall)`
  margin: 0.25rem 0;
`;
const BadgeStyled = styled(Badge)`
  margin-left: 1rem;
`;
const SessionText = styled(LabelMedium)`
  display: inline-block;
  margin-left: 0.25rem;
`;

export const TaskCard = ({
  id,
  title,
  scheduledEndTimestamp,
  task,
  userType,
  user,
  internName,
}) => {

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [dialogueInfo, setDialogueInfo] = React.useState({title: 'Task Expired' , description: 'This task is overdue and the submission period has expired. You can still submit it, but be aware that late submissions may not be fully recognized.'});

  const {
    buttonLabel,
    buttonColor,
    statusLabel,
    backgroundStyle,
    borderStyle,
    statusStyle,
  } = getTaskCardSetup(task, userType, user);


  const handleClick = () => {
    if(
      user.id === task.ownerPersonId &&
      task.calculatedFields?.isUpcoming &&
        (
            task.status === StatusMnemonic.InProgress ||
            (
              task.calculatedFields.effectiveStatus !== StatusMnemonic.InProgress &&
              task.calculatedFields.effectiveStatus !== StatusMnemonic.Completed
            )
        ) &&
        invalidEarlySubmissionTaskTemplates.includes(task.taskMnemonic)
    ){
      setDialogueInfo({title: 'Upcoming Task' , description: 'This task is not yet due. You can submit your response now, but please note that early submissions will be saved but may not be fully recognized.'});
      setOpen(true);
    } else if (
        user.id === task.ownerPersonId &&
        !task.calculatedFields?.isMissed &&
        task.calculatedFields?.isOverdue &&
        (
          task.status === StatusMnemonic.InProgress ||
          (
            task.calculatedFields.effectiveStatus !== StatusMnemonic.InProgress &&
            task.calculatedFields.effectiveStatus !== StatusMnemonic.Completed
          )
        )
      )
    {
      setDialogueInfo({title: 'Task Expired' , description: 'This task is overdue and the submission period has expired. You can still submit it, but be aware that late submissions may not be fully recognized.'});
      setOpen(true);
    } else {
      navigate(`/tasks/${id}?owner=${task.ownerPersonId}${task.linkedTaskId && '&linked=' + task.linkedTaskId || ''}${task.parentTaskId && '&parent=' + task.parentTaskId || ''}`);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const additionalBtnAction = () => {
      navigate(`/tasks/${id}?owner=${task.ownerPersonId}${task.linkedTaskId && '&linked=' + task.linkedTaskId || ''}${task.parentTaskId && '&parent=' + task.parentTaskId || ''}`);
  };

  return (

    <React.Fragment>

      <TaskBox backgroundColor={backgroundStyle} borderColor={borderStyle} >

        { userType === IdentityGroupType.Intern &&
          <VFillContainerV>
            <LabelMedium color={statusStyle}>
              {statusLabel}
              {task.taskInteractionCount > 0 &&
                <BadgeStyled badgeContent={task.taskInteractionCount} color='error'/>
              }
            </LabelMedium>
            <TitleText color="primary.p10">
              {title}
              <SessionText>({task?.session})</SessionText>
            </TitleText>
            { scheduledEndTimestamp !== '' &&
              <BodySmall>{`Due ${getDayOfWeekAndDate(scheduledEndTimestamp).sortableDate === format(new Date(), 'yyyy-MM-dd') ?
              'Today'
              : getDayOfWeekAndDate(scheduledEndTimestamp).dayOfWeek}, 
              ${getDayOfWeekAndDate(scheduledEndTimestamp).formattedDate}`}</BodySmall>
            }
          </VFillContainerV>
        }

        { (userType === IdentityGroupType.Mentor || userType === IdentityGroupType.Coach || userType === IdentityGroupType.Admin) &&
          <VFillContainerV>
            <BodySmall>{internName}</BodySmall>
            <LabelMedium color={statusStyle}>
              {statusLabel}
              {task.taskInteractionCount > 0 &&
                <BadgeStyled badgeContent={task.taskInteractionCount} color='error'/>
              }
            </LabelMedium>
            <TitleSmall
              color="primary.p10">{title}
              <SessionText>({task?.session})</SessionText>
            </TitleSmall>
            { scheduledEndTimestamp !== '' &&
              <BodySmall>{`Due ${getDayOfWeekAndDate(scheduledEndTimestamp).sortableDate === format(new Date(), 'yyyy-MM-dd') ?
              'Today'
              : getDayOfWeekAndDate(scheduledEndTimestamp).dayOfWeek}, 
              ${getDayOfWeekAndDate(scheduledEndTimestamp).formattedDate}`}</BodySmall>
            }
          </VFillContainerV>
        }

        {
          task.status !== StatusMnemonic.Absent
          &&
          <TaskButton text={buttonLabel} color={`${buttonColor}`} onClick={handleClick} />
        }

      </TaskBox>

      <CustomDialog
        title={dialogueInfo.title}
        description={dialogueInfo.description}
        closeBtnLabel='Cancel'
        additionalBtnLabel='Submit Anyway'
        open={open}
        onClose={handleClose}
        additionalBtnAction={additionalBtnAction}
      />

    </React.Fragment>
  );
};


TaskCard.displayName = 'TaskCard';

TaskCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  scheduledEndTimestamp: PropTypes.string,
  task: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
  user: PropTypes.any,
  internName: PropTypes.string,
};

TaskCard.defaultProps = {
  scheduledEndTimestamp: '',
  internName: '',
};
