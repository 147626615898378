import React from 'react';

import PropTypes from 'prop-types';

import {
  LongText,
} from './Inputs/LongText';

import {
  ShortText,
} from './Inputs/ShortText';

import { 
  Dropdown 
} from './Inputs/Dropdown';

export const SupportForm = ({ formFields, handleFormFieldChange, validation }) => {

  return (
    <React.Fragment>

      <ShortText
        value={formFields.givenName}
        onChange={(e) => handleFormFieldChange(e, 'givenName')}
        label={'First Name'}
        placeholder={'First Name'}
        error={validation.givenName?.hasError}
        helperText={validation.givenName?.errorText}
      />

      <ShortText
        value={formFields.surname}
        label={'Surname'}
        onChange={(e) => handleFormFieldChange(e, 'surname')}
        placeholder={'Surname'}
        error={validation.surname?.hasError}
        helperText={validation.surname?.errorText}
      />

      <ShortText
        label={'Email Address'}
        value={formFields.email}
        onChange={(e) => handleFormFieldChange(e, 'email')}
        placeholder={'Email Address'}
        error={validation.email?.hasError}
        helperText={validation.email?.errorText}
      />

      <Dropdown
        label={'Query Type'}
        placeholder={'Query Type'}
        options={[
          'Technical Issues', 
          'Account Help',
          'Task Management',
          'Mentorship Guidance',
          'Educational Content',
          'Privacy and Security',
          'Feedback',
          'Other',
        ]}
        value={formFields.queryType}
        onChange={(e) => {handleFormFieldChange(e, 'queryType')}}
        error={validation.queryType?.hasError}
        helperText={validation.queryType?.errorText}
      />

      <ShortText
        value={formFields.subject}
        onChange={(e) => handleFormFieldChange(e, 'subject')}
        label={'Subject'}
        placeholder={'Subject'}
        error={validation.subject?.hasError}
        helperText={validation.subject?.errorText}
      />

      <LongText
        value={formFields.message}
        onChange={(e) => handleFormFieldChange(e, 'message')}
        label={'Message'}
        placeholder={'Message'}
        error={validation.message?.hasError}
        helperText={validation.message?.errorText}
      />

    </React.Fragment>
    );
};

SupportForm.displayName = 'SupportForm';

SupportForm.propTypes = {
  formFields: PropTypes.object.isRequired,
  handleFormFieldChange: PropTypes.func.isRequired,
  validation: PropTypes.object,
};

SupportForm.defaultProps = {
  validation: {},
};