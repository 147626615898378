import {
  styled,
} from '@mui/material';

import {
  AlignItems,
} from 'enums';

import {
  HBox,
} from 'ui/common/components/boxes/HBox';

export const HBoxV = styled(HBox)`
  align-items: ${AlignItems.Center};
`;

HBoxV.displayName = 'HBoxV';
