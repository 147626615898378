import pdfMake from "pdfmake"

pdfMake.fonts = {
    Roboto: {
      normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
      bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
      italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
      bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
  };

import { thutoTrustLogo } from "ui/common/components/base64Images/thuto-trust"
import { format , parseISO, isValid } from 'date-fns';
import { InputTypeMnemonic } from 'enums';

const excludedQuestionTypes = [InputTypeMnemonic.FileUpload, InputTypeMnemonic.Schedular];

export const downloadTaskPdf = (pageDetails) => {
    const coverDetails = coverTableStructure(pageDetails.taskDetails, pageDetails.personDetails);

    const questionAnswers = (pageDetails.taskDetails.questions.map( question => {
      if(!excludedQuestionTypes.includes(question.inputType) ){
        const questionRelatedComments = pageDetails.taskInteractionList.filter((interaction) => interaction?.question?.id === question.id);
        return questionAnswerTableStructure(question, questionRelatedComments)
      }
    }));

    const questionUnrelatedComments = pageDetails.taskInteractionList.filter((interaction) => interaction?.question === undefined);
    let generalCommentSection = [[]];
    if(questionUnrelatedComments.length > 0){
        const unrelatedCommentsTable = questionAnswerTableCommentsStructure(questionUnrelatedComments) ;
        generalCommentSection = [
            { 
                marginTop: 30,
                canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*30, y2: 5, lineWidth: 0.5 }] },
            { text: 'General Comments' , margin: [10,10,10,0] , bold: true, fontSize: 12},
            { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*30, y2: 5, lineWidth: 0.5 }]  }, 
            unrelatedCommentsTable
        ]

        generalCommentSection = generalCommentSection.flat(1);
    }
    

    const docDefinition = {
      pageMargins: [  30, 30, 30, 30  ],
      footer: function (currentPage, pageCount) {
          return {
              table: {
                  body: [
                      [
                          { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', style: 'normalText', margin: [10,10,10, 10], fontSize: 8 }
                      ]
                  ]
              },
              layout: 'noBorders'
          };
      },
      content: [
        coverDetails,
        { 
            marginBottom: 30,
            canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*30, y2: 5, lineWidth: 0.5 }] 
            
        },
        questionAnswers.flat(1),
        generalCommentSection
      ],
      styles: pdfGlobalStyling()
    };

    pdfMake.createPdf(docDefinition).download(`${pageDetails.taskDetails.title.toLowerCase().replace(/\s+/g, '_')}_${pageDetails.taskDetails.ownerPersonEmail}_${new Date().toISOString()}.pdf`);
  }

const coverTableStructure = (taskDetails, personDetails) => {
    return {
        layout: 'noBorders',
        marginBottom: 10,
        table: {
            widths: ['*', 200],
            body: [
                [
                    {
                        layout: 'noBorders',
                        table: {
                            widths: ['*'],
                            body: [
                                [{ text: taskDetails.title, margin: [10, 10, 10, 0], bold: true, fontSize: 17 }],
                                [{ text: `${taskDetails.ownerPersonGivenName} ${taskDetails.ownerPersonSurname}`, margin: [10, 0, 10, 0], fontSize: 12 }],
                                [
                                    {
                                        layout: 'noBorders',
                                        margin: [10, 15, 10, 0],
                                        fontSize: 8,
                                        color: '#5C6E72',
                                        table: {
                                            widths: [75, '*'],
                                            body: [
                                                [{ text: 'School' }, { text: `${personDetails.schools[0]}` }],
                                                [{ text: 'Teaching Phase' }, { text: `${personDetails.teachingPhaseMnemonic}`}],
                                                [{ text: 'Onsite Mentor' }, {text: `${personDetails.mentorGivenName} ${personDetails.mentorSurname}`}],
                                                [{ text: 'Online Mentor' }, {text: `${personDetails.coachGivenName} ${personDetails.coachSurname}` }],
                                                [{ text: 'Completed At:' }, { text: `${format(taskDetails.completedTimestamp, 'EEEE, do MMMM h:mm aa')}` }],
                                                [{ text: 'Downloaded At:' }, { text: `${format(new Date().toUTCString(), 'EEEE, do MMMM h:mm aa')}` }]
                                            ]
                                        }
                                    }
                                ]
                            ]
                        }
                    },
                    {
                        image: thutoTrustLogo,
                        width: 250,
                        margin: [0, 35, 10, 0],
                        alignment: 'right'
                    }
                ]
            ]
        }
    }
}

const questionAnswerTableStructure = (question, comments) => {
    if(question.answer && question.title){
        const answer = structuredAnswer(question.answer);

        const commentTable = {
            marginBottom: 30,
            table: {
                headerRows: 1,
                widths: ['*'],
                body: [
                    [{ text: question.title, style: "questionTableHeader", border: [true, true, true, true], borderColor: ['#e6f4e8', '#3caa49', '#e6f4e8', '#e6f4e8'] }],
                    [{ text: answer, style: "questionTableCell", border: [true, true, true, true], borderColor: ['#e6f4e8', '#e6f4e8', '#e6f4e8', '#e6f4e8'] }]
                    
                ]
            }
        }

    if(comments.length > 0){
        commentTable.table.body.push(questionAnswerTableCommentsStructure(comments))
    }

    return commentTable;
    }
}

const questionAnswerTableCommentsStructure = (comments) => {
    const structuredComments = comments.sort((a , b) => new Date(a.createdTimestamp) - new Date(b.createdTimestamp)).map((comment) => {
        return [
            [  { text: `${comment.givenName} ${comment.surname} - ${format(comment.createdTimestamp, 'EEEE, do MMMM h:mm aa')}`, style: ["questionTableCommentCell", "questionTableCommentSecondaryText"]} ],
            [  { text: comment.commentField, style: ["questionTableCommentCell", "questionTableCommentPrimaryText"]} ]
        ]
    }).flat(1) ;

    return [ 
        {
            layout: 'noBorders',
            marginTop: 30,
            border: [false, false, false, false] ,
            table: {
              headerRows: 1,
              widths: ['*'],
              body: structuredComments.length > 0 ? structuredComments : [[]]
            }
        }
        
        
    ]

}

const pdfGlobalStyling = () => {
    return {
        questionTableHeader: {
            margin: [5, 7, 5, 7],
            fillColor: '#e6f4e8',
            color: '#5C6E72',
            fontSize: 11
        },
        questionTableCell: {
            margin: [5, 5, 5, 5],
            fontSize: 10
        },
        questionTableCommentCell: {
            margin: [5, 5, 5, 5],
            fillColor: '#f4f5f5'
        },
        questionTableCommentSecondaryText: {
            color: '#5c6e72',
            fontSize: 8
        },
        questionTableCommentPrimaryText: {
            fontSize: 9,
            fillColor: 'white',
            color: '#5c6e72',
        }
    };
}


const structuredAnswer = (answer) => {
    if(answer?.length > 0 && ((answer.startsWith('{') && answer.endsWith('}')) || (answer.startsWith('[') && answer.endsWith(']')) )){
        let answerObj = JSON.parse(answer);
        if(Array.isArray(answerObj)) {
            answer = answerObj.join(', ')
        }else{
            answer = Object.entries(answerObj).map(([key, value]) => `${camelCaseToReadable(key)}: ${value} `).join('\n');
        }
    }else if(!isValidNumberString(answer) && isValidDateString(answer)){
        answer = format(answer, 'EEEE, do MMMM h:mm aa')
    }
    
    
    return answer ;
}

const camelCaseToReadable = (str) => {   
    const result = str.replace(/([A-Z])/g, ' $1').toLowerCase();
    return result.charAt(0).toUpperCase() + result.slice(1); 
}

const isValidNumberString = (str) =>{
    const num = Number(str);   
    return !isNaN(num) && typeof num === 'number';
}

const isValidDateString = (dateString) =>{
    const parsedDate = parseISO(dateString);
    return isValid(parsedDate);
}