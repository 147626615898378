import
  PropTypes
from 'prop-types';

import {
  styled,
} from '@mui/material';

import {
  ButtonVariant,
} from 'enums';

import LoadingButton from '@mui/lab/LoadingButton';

const StyledButton = styled(LoadingButton)`
  white-space: nowrap;
  width: 100%;
  padding: 10px 24px; 
  border-radius: 100px;
`;

export const SpinnerButton = props =>{
 const {variant, text, children} = props;

 return (
  <StyledButton
    variant={variant}
    {...props}>

    {text || children}
  </StyledButton>
 )
};

SpinnerButton.displayName = 'SpinnerButton';

SpinnerButton.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
};

SpinnerButton.defaultProps = {
  text: '',
  variant: ButtonVariant.Outlined,
};
