import React from 'react';

import {
  Asset,
  IdentityGroupType,
} from 'enums';

import {
  buildFilterGroupsFromTasks,
  getWeekRange,
} from 'common';

import {
  useTaskList,
} from 'hooks';

import {
  Feedback,
  Filter,
  ScheduleTabs,
  Spinner,
} from 'ui/common';

import {
  tasksQueryParams,
} from 'gql/task/queryParams';

export const TaskTabs = ({
  user,
  targetUser,
}) => {

  const [filterTasks, setFilterTasks] = React.useState();
  const [taskListLastWeekPage, setTaskListLastWeekPage] = React.useState(1);
  const [taskListCurrentWeekPage, setTaskListCurrentWeekPage] = React.useState(1);
  const [taskListNextWeekPage, setTaskListNextWeekPage] = React.useState(1);

  const userType = user.group || IdentityGroupType.Intern;
  const targetUserType = targetUser.group || IdentityGroupType.Intern;

  const weekRange = React.useMemo(() => getWeekRange(), []);

  // Last week tab
  const taskListLastWeekParams = React.useMemo(() => tasksQueryParams({
    userType: targetUserType,
    userId: targetUser.id,
    dateRange: weekRange.lastWeek,
    page: taskListLastWeekPage,
  }), [
    targetUserType,
    targetUser.id,
    weekRange.lastWeek,
    taskListLastWeekPage,
  ]);

  const {
    loading: loadingLastWeek,
    error: errorLastWeek,
    taskList: taskListLastWeek,
    pageInfo: pageInfoLastWeek,
    refetch: refetchLastWeek,
  } = useTaskList({
    queryParams: taskListLastWeekParams,
    userType: targetUserType,
  });

  // Current week tab
  const taskListCurrentWeekParams = React.useMemo(() => tasksQueryParams({
    userType: targetUserType,
    userId: targetUser.id,
    dateRange: weekRange.currentWeek,
    page: taskListCurrentWeekPage,
  }), [
    targetUserType,
    targetUser.id,
    weekRange.currentWeek,
    taskListCurrentWeekPage,
  ]);

  const {
    loading: loadingCurrentWeek,
    error: errorCurrentWeek,
    taskList: taskListCurrentWeek,
    pageInfo: pageInfoCurrentWeek,
    refetch: refetchCurrentWeek,
  } = useTaskList({
    queryParams: taskListCurrentWeekParams,
    userType: targetUserType,
  });

  // Next week tab
  const taskListNextWeekParams = React.useMemo(() => tasksQueryParams({
    userType: targetUserType,
    userId: targetUser.id,
    dateRange: weekRange.nextWeek,
    page: taskListNextWeekPage,
  }), [
    targetUserType,
    targetUser.id,
    weekRange.nextWeek,
    taskListNextWeekPage,
  ]);

  const {
    loading: loadingNextWeek,
    error: errorNextWeek,
    taskList: taskListNextWeek,
    pageInfo: pageInfoNextWeek,
    refetch: refetchNextWeek,
  } = useTaskList({
    queryParams: taskListNextWeekParams,
    userType: targetUserType,
  });

  const tabData = [
    {
      title: 'Last Week',
      layoutType: 'week',
      data: taskListLastWeek || [],
      indexPage: taskListLastWeekPage,
      totalPages: pageInfoLastWeek?.totalPages || 1,
      onPageChange: (page) => setTaskListLastWeekPage(page),
    },
    {
      title: 'This Week',
      layoutType: 'week',
      data: taskListCurrentWeek || [],
      indexPage: taskListCurrentWeekPage,
      totalPages: pageInfoCurrentWeek?.totalPages || 1,
      onPageChange: (page) => setTaskListCurrentWeekPage(page),
    },
    {
      title: 'Next Week',
      layoutType: 'week',
      data: taskListNextWeek || [],
      indexPage: taskListNextWeekPage,
      totalPages: pageInfoNextWeek?.totalPages || 1,
      onPageChange: (page) => setTaskListNextWeekPage(page),
    },
  ];

  const handleFilterChange = (filters) => {
    setFilterTasks(filters);
  };

  const clearFilters = () => {
    setFilterTasks();
  };

  const onRetry = () => {
    refetchLastWeek();
    refetchCurrentWeek();
    refetchNextWeek();
  };

  return (

    <React.Fragment>

      {(loadingLastWeek || loadingCurrentWeek || loadingNextWeek) &&
        <Spinner/>
      }

      {!errorLastWeek && !errorCurrentWeek && !errorNextWeek &&

        <React.Fragment>
          <Filter
            filterGroups={buildFilterGroupsFromTasks({
              tasks: [
                ...(taskListLastWeek || []),
                ...(taskListCurrentWeek || []),
                ...(taskListNextWeek || []),
              ],
            })}
            onFilterUpdate={handleFilterChange}
            filters={filterTasks}
          />

          <ScheduleTabs
            tabData={tabData}
            userType={userType}
            defaultTab={1}
            filters={filterTasks}
            user={user}
            clearFilters={clearFilters}/>
        </React.Fragment>
      }

      {(errorLastWeek || errorCurrentWeek || errorNextWeek) &&
        <Feedback
          imageUrl={Asset.Image.Other.BrokenPencil}
          headerText={'Something Went Wrong!'}
          bodyText={'We encountered a hiccup while fetching your tasks. Not to worry, though – we\'re on it!'}
          infoText={'Please try loading your task again. If the issue persists, reach out to us for support'}
          type={'Error'}
          buttonText={'Try Again'}
          buttonAction={onRetry}
        />
      }
    </React.Fragment>
  );
};

TaskTabs.displayName = 'TaskTabs';
