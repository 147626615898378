import {
  styled,
} from '@mui/material';

import {
  JustifyContent,
} from 'enums';

import {
  VFillContainer,
} from 'ui/common/components/containers/VFillContainer';

export const VFillContainerV = styled(VFillContainer)`
  justify-content: ${JustifyContent.Center};
`;

VFillContainerV.displayName = 'VFillContainerV';
