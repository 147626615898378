export const byId = (templateId) => {
  return {
    variables: {
      params: {
        filterProperties:[{
            operator: 'IN',
            fieldNames: ['taskTemplateId'],
            value: [templateId],
        }],
      }
    },
  };
};

export const byMnemonic = (mnemonic) => {
  return {
    variables: {
      params: {
        filterProperties:[{
            operator: 'IN',
            fieldNames: ['taskTemplateMnemonic'],
            value: [mnemonic],
        }],
      }
    },
  };
};

export const getNonAssessmentTasks = (groups) => {
  return {
    variables: {
      params: {
        filterProperties:[{
            operator: 'IN',
            fieldNames: ['personTypeMnemonic'],
            value: groups,
        },
        {
          operator: 'NULL',
          fieldNames: ['assessmentMnemonic'],
          value: [],
        }],
      }
    }
  }
}

export const getAllAssessmentTasks = (groups) => {
  return {
    variables: {
      params: {
        filterProperties:[{
            operator: 'IN',
            fieldNames: ['personTypeMnemonic'],
            value: groups,
        },
        {
          operator: 'NOTNULL',
          fieldNames: ['assessmentMnemonic'],
          value: [],
        }],
      }
    }
  }
}