import { useQuery } from '@apollo/client';
import { QueryTaskTemplate } from 'gql/taskTemplate/queries';
import { useEffect, useState } from 'react';
import { IdentityGroupType } from 'enums';

export const useTaskTemplateList = (groups, personYearOfStudy = null, params) => {
  const [taskTemplateList, setTaskTemplateList] = useState();
  const [personSpecificTaskTemplateList, setPersonSpecificTaskTemplateList] = useState();

  const { loading, error, data, } = useQuery(QueryTaskTemplate, params);

  useEffect(() => {

    if (loading || data == null || taskTemplateList) {
      return;
    }

    const taskTemplate = data?.taskTemplateQueryFilter?.body?.taskTemplates?.map(t => ({
      id: t.id,
      title: t.title,
      mnemonic: t.mnemonic,
      description: t.description,
      shortDescription: t.shortDescription,
      requiresReview: t.requiresReview,
      active: t.active,
      yearOfStudy: t.yearOfStudy,
      assessmentMnemonic: t.assessmentMnemonic,
      showOnCreateTaskList: t.showOnCreateTaskList,
    }));

    const filterByAllowShow = taskTemplate.filter(tt => tt.showOnCreateTaskList);

    const personSpecificYearTemplateList = filterByAllowShow.filter((tt) => !groups.includes(IdentityGroupType.Intern.toUpperCase()) || tt.yearOfStudy === null || tt.yearOfStudy === personYearOfStudy)

    setTaskTemplateList(taskTemplate);
    setPersonSpecificTaskTemplateList(personSpecificYearTemplateList);
  }, [
    groups,
    personYearOfStudy,
    loading,
    data,
    personSpecificTaskTemplateList,
    taskTemplateList,
    setTaskTemplateList,
  ]);

  return {
    loading,
    error,
    data,
    personSpecificTaskTemplateList,
    taskTemplateList,
  };
}
