import {
  gql,
} from '@apollo/client';

export const QueryPeople = gql`
  query PersonQueryFilter($params: PersonQueryFilterParams) {
    personQueryFilter(params: $params) {
      statusCode
      body {
        persons {
          ...PersonFragment
          personType {
            ...PersonTypeFragment
          }
          coachPerson {
            ...PersonFragment
          }
          mentorPerson {
            ...PersonFragment
          }
          schools {
            ...SchoolFragment
          }
        }
        pageInfo {
          ...PageInfoFragment
        }
      }
    }
  }

  fragment PageInfoFragment on PageInfo {
    totalResults
    indexPage
    totalPages
  }

  fragment PersonFragment on Person {
    givenName
    surname
    personTypeId
    yearOfStudy
    teachingPhaseMnemonic
    telNo
    id
    gender
    email
    active
  }

  fragment PersonTypeFragment on PersonType {
    id
    title
    description
    mnemonic
  }

  fragment SchoolFragment on School {
    id
    title
    address
    telNo
    description
    location
    createdTimestamp
    modifiedTimestamp
    active
    mnemonic
  }
`;


export const QueryPersonTypes = gql`
  query MyQuery($params: PersonTypeQueryFilterParams) {
    personTypeQueryFilter(params: $params) {
      body {
        personTypes {
          description
          mnemonic
          modifiedTimestamp
          title
          id
        }
      }
    }
  }
`;
