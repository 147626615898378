import { useQuery } from '@apollo/client';
import { mapTaskFromQuery, mapTaskInteractionsFromQuery } from 'common';
import { mapPersonFromQuery } from 'common/person';
import { QueryTaskDetailsPage } from 'gql/combined/queries';
import { taskDetailsPageParams } from 'gql/combined/queryParams';
import { useEffect, useState } from 'react';

const sortInteractions = (data) => {
  return data.taskInteractions?.body?.taskInteractions &&
  data.taskInteractions?.body?.taskInteractions.length > 0 &&
  data.taskInteractions?.body?.taskInteractions?.map(mapTaskInteractionsFromQuery) || [];
};

export const useTaskDetailsPage = (taskId, personId, linkedTaskId, userType, parentTaskId) => {

  const { loading, error, data, refetch } = useQuery(QueryTaskDetailsPage, {
    variables: taskDetailsPageParams(taskId, personId, linkedTaskId, parentTaskId),
    fetchPolicy: 'no-cache',
    skip: taskId == null,
  });

  const [pageDetails, setPageDetails] = useState();

  useEffect(() => {
    if (!loading && data) {

      const details = {
        taskDetails: mapTaskFromQuery(data.taskDetails?.body?.tasks?.[0], userType),
        taskInteractionList: sortInteractions(data),
        personDetails: mapPersonFromQuery(data.ownerPersonDetails?.body?.persons?.[0]),
        childTaskDetails: mapTaskFromQuery(data.childTaskDetails?.body?.tasks?.[0], userType),
        linkedTaskDetails: mapTaskFromQuery(data.linkedTaskDetails?.body?.tasks?.[0], userType),
        internTaskDetails: mapTaskFromQuery(data.internTaskDetails?.body?.tasks?.[0], userType),
      };

      setPageDetails(details);
    }
  }, [loading, data, taskId, personId, linkedTaskId, userType, parentTaskId]);

  return {
    loading,
    error,
    data,
    pageDetails,
    setPageDetails,
    refetch,
  };
}

