import {
  Typography,
} from '@mui/material';

import {
  TypographyVariant,
} from 'enums';

export const HeadlineMedium = props =>

  <Typography
    {...props}
    fontWeight={700}
    fontSize='1.75rem'
    variant={TypographyVariant.Heading5}>

    {props.children}
  </Typography>
;

HeadlineMedium.displayName = 'HeadlineMedium';
