/**
 * The poll (in milliseconds) to use to check if a report has been generated yet.
 * @type {number}
 */
export const ReportPollInterval = 1_000;

/**
 * Config for the generated XLSX report.
 * @type {Object}
 */
export const ReportConfig = {
  HeaderColumnSpan: 3,
  HeaderColumnWidth: 20,
  LogoRowHeight: 100,
  LogoImageHeight: 100,
  WorkSheetName: 'Report',
};

/**
 * List of image extensions that are allowed to be added to a workbook.
 * *Note:* Mime types would make much more sense, but this is a limitation by the exceljs library
 * which requires file extensions to be used instead.
 * @type {string[]}
 */
export const AllowedWorkbookImages = [
  'jpeg',
  'png',
  'gif',
];
