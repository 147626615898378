export const ButtonVariant = {
  Contained: 'contained',
  Outlined: 'outlined',
  Text: 'text',
};

export const TypographyVariant = {
  Body1: 'body1',
  Body2: 'body2',
  Button: 'button',
  Caption: 'caption',
  Heading1: 'h1',
  Heading2: 'h2',
  Heading3: 'h3',
  Heading4: 'h4',
  Heading5: 'h5',
  Heading6: 'h6',
  Overline: 'overline',
  Subtitle1: 'subtitle1',
  Subtitle2: 'subtitle2',
};

export const TextFieldVariant = {
  Outlined: 'outlined',
  Filled: 'filled',
  Standard: 'standard',
};
