import { useQuery } from '@apollo/client';
import { UserPersonDetailsAtom } from 'atoms';
import { mapPersonFromQuery } from 'common/person';
import { QueryPeople } from 'gql/person/queries';
import { useEffect, useState, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

export const usePersonDetails = (queryParms, isLoggedUser = false) => {

  const { loading, error, data, refetch } = useQuery(QueryPeople,
    {
      variables: queryParms,
      skip: queryParms == null,
    }
  );

  const [personDetails, setPersonDetails] = useState([]);
  const setUserPersonDetails = useSetRecoilState(UserPersonDetailsAtom);

  const setDetailsCallback = useCallback((details) => setUserPersonDetails(details), [setUserPersonDetails]);

  useEffect(() => {
    if (!loading && data) {
      const details = data.personQueryFilter.body.persons?.map(mapPersonFromQuery) || [];

      if (isLoggedUser && details.length > 0) {
        setDetailsCallback(details[0]);
      }

      setPersonDetails(details);
    }
  }, [loading, data, isLoggedUser, setDetailsCallback]);
  
  return {
    loading,
    error,
    data,
    personDetails,
    refetch,
  };
}
