import React from 'react';

import PropTypes from 'prop-types';

import {
  useNavigate,
} from 'react-router-dom';

import {
  format,
} from 'date-fns';

import {
  styled,
} from '@mui/material';

import {
  StatusMnemonic,
  TaskTypeMnemonic,
} from 'enums';

import {
  useTaskList,
  useTaskTemplate,
  useUpcomingSchedules,
} from 'hooks';

import {
  getCurrentDayRangeFormatted,
  getWeekRange,
} from 'common';

import {
  HFillContainerV,
  VContainerV,
} from 'ui/common/components/containers';

import {
  BodyMedium,
  BodySmall,
  HeadlineSmall,
  LabelMedium,
} from 'ui/common/components/typography';

import {
  PrimaryButton,
} from 'ui/common/components/buttons';

import {
  byMnemonic,
} from 'gql/taskTemplate/queryParams';

import {
  taskListWeeklyPlansQueryParams,
  tasksInternCheckInAndOutParams,
} from 'gql/task/queryParams';

const StyledButton = styled(PrimaryButton)`
  @media (min-width: 600px) {
    max-width: 19.625rem;
  }
`;

const BaseContainer = styled(VContainerV, {
  shouldForwardProp: (prop) => prop !== 'isError',
})(
  ({
    theme,
    isError,
  }) => `
  padding: 1.5rem 1rem;
  margin: 1rem 0;
  background-color: ${isError ? theme.palette.error.e95 : theme.palette.primary.p99};
  border-radius: 12px;

  @media (min-width: 600px) {
    padding: 1.5rem 1rem;
  }
  `,
);

const ButtonContainer = styled(HFillContainerV)`
  padding: 0;
  margin-top: 1rem;
  justify-content: space-evenly;

  @media (min-width: 600px) {
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledPreText = styled(BodySmall)`
  margin-bottom: 0.5rem;
`;

const StyledSubTitle = styled(BodyMedium)`
  margin-bottom: 0.5rem;
`;

const StyledInfoText = styled(LabelMedium)`
  margin-bottom: 0.5rem;
`;

export const DashboardBanner = ({
  userId,
  userType,
}) => {

  const navigate = useNavigate();

  const {
    loading: internWeeklyPlanLoading,
    error: internWeeklyPlanError,
    taskTemplate: internWeeklyPlanTaskTemplate,
  } = useTaskTemplate(byMnemonic(TaskTypeMnemonic.InternPlanUpcomingWeek));

  // Schedule
  const [
    getUpcomingSchedules, {
      loading: scheduleLoading,
      error: scheduleError,
      currentSchedule,
    },
  ] = useUpcomingSchedules();

  // Check in and check out tasks for today
  const taskListCheckInAndOutParams = React.useMemo(() => tasksInternCheckInAndOutParams({
    userId: userId,
    dateRange: getCurrentDayRangeFormatted(),
  }), [
    userId,
  ]);

  const {
    loading: loadingCheckInAndOut,
    error: errorCheckInAndOut,
    taskList: taskListCheckInAndOut,
  } = useTaskList({
    queryParams: taskListCheckInAndOutParams,
    userType: userType,
  });

  // Current weekly plan
  const taskListWeeklyPlansParams = React.useMemo(() => taskListWeeklyPlansQueryParams(
    userId,
    getWeekRange().currentWeek,
  ), [
    userId,
  ]);

  const {
    loading: loadingWeeklyPlans,
    error: errorWeeklyPlans,
    taskList: taskListWeeklyPlans,
  } = useTaskList({
    queryParams: taskListWeeklyPlansParams,
    userType: userType,
  });

  const loading = scheduleLoading || loadingCheckInAndOut || loadingWeeklyPlans || internWeeklyPlanLoading;
  const error = scheduleError || errorCheckInAndOut || errorWeeklyPlans || internWeeklyPlanError;

  // Effect to get the upcoming schedule only once for the lifetime of the component.
  React.useEffect(() => {
    if (scheduleLoading || scheduleError || currentSchedule?.id) {
      return;
    }

    getUpcomingSchedules();
  }, [
    currentSchedule?.id,
    getUpcomingSchedules,
    scheduleError,
    scheduleLoading,
  ]);

  const renderContent = () => {

    if (loading || error) {
      return <React.Fragment/>;
    }

    let isError;
    let preText;
    let title;
    let subtitle;
    let infoText;
    let buttonText;
    let onClick;

    const checkinTask = taskListCheckInAndOut?.find(t => t.taskMnemonic === TaskTypeMnemonic.InternCheckin);
    const checkoutTask = taskListCheckInAndOut?.find(t => t.taskMnemonic === TaskTypeMnemonic.InternCheckout);

    const currentWeeklyPlanTask = currentSchedule.weeklyPlanTask || taskListWeeklyPlans?.find(t => t.taskMnemonic === TaskTypeMnemonic.InternPlanUpcomingWeek && !t.scheduleId);
    const weeklyPlanStatusMnemonic = currentWeeklyPlanTask?.statusMnemonic || currentWeeklyPlanTask?.status;

    const currentDate = new Date();

    if (checkinTask && checkinTask.calculatedFields.effectiveStatus === StatusMnemonic.Open) {

      preText = format(currentDate, 'dd MMMM');
      title = `START YOUR DAY`;
      subtitle = (
        <React.Fragment>
          You haven’t <b>checked in</b> yet today
        </React.Fragment>
      );
      infoText = 'Please check into the school, a workshop, or report absence';
      buttonText = 'Check In';
      onClick = () => navigate(`/tasks/${checkinTask.id}?owner=${checkinTask.ownerPersonId}${checkinTask.linkedTaskId && '&linked=' + checkinTask.linkedTaskId || ''}${checkinTask.parentTaskId && '&parent=' + checkinTask.parentTaskId || ''}`);
    } else if (checkinTask && checkinTask.status === StatusMnemonic.Review) {

      preText = format(currentDate, 'dd MMMM');
      title = `REPORTED ABSENT`;
      subtitle = (
        <React.Fragment>
          Your absence request approval is <b>pending</b>.
        </React.Fragment>
      );
    } else if (checkinTask && checkinTask.status === StatusMnemonic.Approved) {

      preText = format(currentDate, 'dd MMMM');
      title = `REPORTED ABSENT`;
      subtitle = (
        <React.Fragment>
          Your absence request was <b>approved</b>.
        </React.Fragment>
      );
    } else if (checkinTask && checkinTask.status === StatusMnemonic.Rejected) {

      isError = true;
      preText = format(currentDate, 'dd MMMM');
      title = `REPORTED ABSENT`;
      subtitle = (
        <React.Fragment>
          Your absence request was <b>rejected</b>.
        </React.Fragment>
      );
      buttonText = 'Revise';
      onClick = () => navigate(`/tasks/${checkinTask.id}?owner=${checkinTask.ownerPersonId}${checkinTask.linkedTaskId && '&linked=' + checkinTask.linkedTaskId || ''}${checkinTask.parentTaskId && '&parent=' + checkinTask.parentTaskId || ''}`);
    } else if (checkoutTask && checkoutTask.calculatedFields.effectiveStatus === StatusMnemonic.Open) {

      preText = format(currentDate, 'dd MMMM');
      title = `END YOUR DAY`;
      subtitle = (
        <React.Fragment>
          You haven’t <b>checked out</b> yet today
        </React.Fragment>
      );
      buttonText = 'Check Out';
      onClick = () => navigate(`/tasks/${checkoutTask.id}?owner=${checkoutTask.ownerPersonId}${checkoutTask.linkedTaskId && '&linked=' + checkoutTask.linkedTaskId || ''}${checkoutTask.parentTaskId && '&parent=' + checkoutTask.parentTaskId || ''}`);
    } else if (checkoutTask && checkoutTask.calculatedFields.effectiveStatus === StatusMnemonic.Completed) {

      preText = format(currentDate, 'dd MMMM');
      title = `CHECKED OUT`;
      subtitle = (
        <React.Fragment>
          You’ve already checked out today
        </React.Fragment>
      );
    } else if (!currentWeeklyPlanTask) {

      isError = true;
      preText = format(currentDate, 'dd MMMM');
      title = `NO WEEKLY PLAN HAS BEEN CREATED.`;
      subtitle = (
        <React.Fragment>
          Create a <b>weekly plan</b> to get started.
        </React.Fragment>
      );
      buttonText = 'Create Plan';
      onClick = () => navigate(`/add-task/create?id=${internWeeklyPlanTaskTemplate.id}`);
    } else if ((weeklyPlanStatusMnemonic === StatusMnemonic.Review)) {

      isError = true;
      preText = format(currentDate, 'dd MMMM');
      title = `WEEKLY PLAN AWAITING APPROVAL`;
      subtitle = (
        <React.Fragment>
          Please follow up with your mentor to get it approved.
        </React.Fragment>
      );
      buttonText = currentWeeklyPlanTask && 'View' || '';
      onClick = () => currentWeeklyPlanTask && navigate(`/tasks/${currentWeeklyPlanTask.id}?owner=${currentWeeklyPlanTask.ownerPersonId}${currentWeeklyPlanTask.linkedTaskId && '&linked=' + currentWeeklyPlanTask.linkedTaskId || ''}${currentWeeklyPlanTask.parentTaskId && '&parent=' + currentWeeklyPlanTask.parentTaskId || ''}`) || undefined;
    } else if (([StatusMnemonic.InProgress, StatusMnemonic.Open, StatusMnemonic.Scheduled].includes(weeklyPlanStatusMnemonic))) {

      isError = true;
      preText = format(currentDate, 'dd MMMM');
      title = `WEEKLY PLAN INCOMPLETE`;
      subtitle = (
        <React.Fragment>
          Please complete your <b>weekly plan</b> to get started.
        </React.Fragment>
      );
      buttonText = currentWeeklyPlanTask ? 'Continue' : '';
      onClick = () => currentWeeklyPlanTask && navigate(`/tasks/${currentWeeklyPlanTask.id}?owner=${currentWeeklyPlanTask.ownerPersonId}${currentWeeklyPlanTask.linkedTaskId && '&linked=' + currentWeeklyPlanTask.linkedTaskId || ''}${currentWeeklyPlanTask.parentTaskId && '&parent=' + currentWeeklyPlanTask.parentTaskId || ''}`) || undefined;
    } else if (weeklyPlanStatusMnemonic === StatusMnemonic.Rejected) {

      isError = true;
      preText = format(currentDate, 'dd MMMM');
      title = `YOUR WEEKLY PLAN HAS BEEN REJECTED.`;
      subtitle = (
        <React.Fragment>
          Please review your weekly plan.
        </React.Fragment>
      );
      buttonText = currentWeeklyPlanTask && 'Review' || '';
      onClick = () => currentWeeklyPlanTask && navigate(`/tasks/${currentWeeklyPlanTask.id}?owner=${currentWeeklyPlanTask.ownerPersonId}${currentWeeklyPlanTask.linkedTaskId && '&linked=' + currentWeeklyPlanTask.linkedTaskId || ''}${currentWeeklyPlanTask.parentTaskId && '&parent=' + currentWeeklyPlanTask.parentTaskId || ''}`) || undefined;
} else if ((taskListWeeklyPlans == null || taskListWeeklyPlans.length === 0) && internWeeklyPlanTaskTemplate) {

      isError = true;
      preText = format(currentDate, 'dd MMMM');
      title = `NO TASKS THIS WEEK`;
      subtitle = (
        <React.Fragment>
          Create a <b>weekly plan</b> to get started.
        </React.Fragment>
      );
      buttonText = 'Create Plan';
      onClick = () => navigate(`/add-task/create?id=${internWeeklyPlanTaskTemplate.id}`);
    } else {

      preText = format(currentDate, 'dd MMMM');
      title = `NO CHECK IN TODAY`;
      subtitle = (
        <React.Fragment>
          Enjoy the day off!
        </React.Fragment>
      );
    }

    return (
      <BaseContainer
        isError={isError}>

        {!!preText &&

          <StyledPreText>
            {preText}
          </StyledPreText>
        }

        {!!title &&

          <HeadlineSmall>
            {title}
          </HeadlineSmall>
        }

        {!!subtitle &&

          <StyledSubTitle>
            {subtitle}
          </StyledSubTitle>
        }

        {!!infoText !== '' &&

          <StyledInfoText>
            {infoText}
          </StyledInfoText>
        }

        {!!buttonText && !!onClick &&

          <ButtonContainer>

            <StyledButton
              color={isError ? 'error' : undefined}
              text={buttonText}
              onClick={onClick}/>
          </ButtonContainer>
        }
      </BaseContainer>
    );
  };

  if (loading || error) {
    return <React.Fragment/>;
  }

  return renderContent();
};

DashboardBanner.displayName = 'DashboardBanner';

DashboardBanner.propTypes = {
  userId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
};

DashboardBanner.defaultProps = {};
