import { useEnvironment } from './useEnvironment';
import { withAPIKey } from "@aws/amazon-location-utilities-auth-helper";
import {
  LocationClient,
  SearchPlaceIndexForPositionCommand,
} from "@aws-sdk/client-location";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UserPersonDetailsAtom } from 'atoms';

const getLocation = (environment, setLoading, setError, setAddress, setLocation) => {
  
  setLoading(true);
  setError(false);

  navigator.geolocation.getCurrentPosition(async (geolocation) => {
    // AWS requires inverse format to Google Maps, so for usability storing it as copy paste to maps.
    setLocation(`${geolocation.coords.latitude}, ${geolocation.coords.longitude}`);
  }, (e) => {
    setAddress('Not Available');
    setLoading(false);
    setError(true);
  });
};

const getPhysicalAddress = async (environment, IndexName, MaxResults, Position) => {
  try {
    const authHelper = await withAPIKey(environment?.variables?.locationApiKey);
    const locationClient = new LocationClient({
      region: 'eu-north-1',
      ...authHelper.getLocationClientConfig(),
    });
    const command = new SearchPlaceIndexForPositionCommand({ IndexName, MaxResults, Position });
    const locationResponse = await locationClient.send(command);
    return locationResponse?.Results?.length > 0 ? locationResponse.Results[0].Place?.Label : null
  } catch {
    return null;
  }
};

const calculateDistance = (coord1, coord2) => {
  const R = 6371000; // Radius of the Earth in meters
  const lat1 = coord1.latitude * Math.PI / 180; // Convert degrees to radians
  const lon1 = coord1.longitude * Math.PI / 180;
  const lat2 = coord2.latitude * Math.PI / 180;
  const lon2 = coord2.longitude * Math.PI / 180;

  const dlat = lat2 - lat1;
  const dlon = lon2 - lon1;

  const a = Math.sin(dlat / 2) * Math.sin(dlat / 2) +
            Math.cos(lat1) * Math.cos(lat2) *
            Math.sin(dlon / 2) * Math.sin(dlon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // Distance in meters
  return distance.toFixed(0);
}

const handleLocation = async (currentLocation, schoolLocation, environment, setLoading, setAddress) => {

  setLoading(true);
  const physicalAddressResponse = await getPhysicalAddress(environment, 'thutoindex', '1', [currentLocation.longitude, currentLocation.latitude]);

  const gpsLocation = `GPS: ${currentLocation.latitude}, ${currentLocation.longitude}`;
  const distance = schoolLocation && ` | Distance from school: ~${calculateDistance(schoolLocation, currentLocation)}m`; 
  const physicalAddress = physicalAddressResponse && ` | Address: ${physicalAddressResponse}`;
  
  setAddress(`${gpsLocation || ''}${distance || ''}${physicalAddress || ''}`);
  setLoading(false);
};

export const useGeolocation = (existingLocation, saved) => {
  const { environment } = useEnvironment();
  const [ address, setAddress ] = React.useState();
  const [ location, setLocation ] = React.useState();
  const [ loading, setLoading ] = React.useState(false);
  const [ error, setError ] = React.useState();

  const personDetails = useRecoilValue(UserPersonDetailsAtom);


  React.useEffect(() => {
    if (existingLocation !== saved) {
      setLocation(existingLocation);
      setAddress(saved);
    } else if (existingLocation && !location) {
      setLocation(existingLocation)
    } else if(!existingLocation && !location) 
    {
      getLocation(environment, setLoading, setError, setAddress, setLocation);
    } else {
      const currentLocations = location.split(',');

      const schoolLocationStr = personDetails.schoolsObj?.[0].location;
      const schoolLocations = schoolLocationStr.split(',');
      
      const schoolLocation = { latitude: schoolLocations[0].trim(), longitude: schoolLocations[1].trim() };
      const currentLocation = { latitude: currentLocations[0].trim(), longitude: currentLocations[1].trim() };
      
      handleLocation(currentLocation, schoolLocation, environment, setLoading, setAddress);
    }
  }, [existingLocation, environment, location, personDetails.schoolsObj, saved]);

  const refetch = () => {
    getLocation(environment, setLoading, setError, setAddress, setLocation);
  }

  return {
    loading,
    error,
    location,
    address,
    refetch,
  };
}