import
  PropTypes
from 'prop-types';

import {
  CircularProgress,
  styled,
} from '@mui/material';

import {
  Spacing,
} from 'enums';

import {
  VBoxVH
} from 'ui/common/components/boxes';

import {
  BodyMedium,
} from 'ui/common/components/typography';

const Text = styled(BodyMedium)`
  margin-top: ${Spacing.Medium1};
`;

export const Spinner = props =>

  <VBoxVH>
    <CircularProgress/>
    <Text>
      {props.text}
    </Text>
  </VBoxVH>
;

Spinner.displayName = 'Spinner';

Spinner.propTypes = {
  text: PropTypes.string,
};

Spinner.defaultProps = {
  text: 'Loading...'
};
