import PropTypes from 'prop-types';

import {
  styled,
  TextField as TextFieldProto,
  MenuItem,
} from '@mui/material';

import {
    HFillContainerV
  } from 'ui/common/components/containers';

import { InfoText } from '../InfoText';
import React from 'react';

const StyledTextField = styled(TextFieldProto)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p99,
  marginBottom: '1rem',
  width: '100%',
  paddingRight: '0.2em',
}));

const StyledHFillContainerV = styled(HFillContainerV)(({ theme }) => ({
    display: 'flex',
    padding: '0 !important',
}));

export const DurationPicker = props => {

  const {
    title,
    description,
    groupName,
    id,
    onChange,
    value,
    questionId,
    questionComments,
    upsertCommentLoading,
    handleComment,
    userId,
  } = props;

  const hourOptions = ['0','1','2','3','4','5','6','7','8'];
  const minuteOptions = ['0','5','10','15','30','45'];

  const getDurationDefaultState = (value) => {
    const defaultState = {hours: 0, minutes: 0};

    if (value != null && value !== '' && JSON.parse(value)) {
      const existingValue = JSON.parse(value);

      Object.keys(existingValue).forEach(v => {
        defaultState[v] = existingValue[v];
      });

    }
  
    return defaultState;
  };

  const [state, setState] = React.useState(getDurationDefaultState(value));

  const handleChange = (event) => {
    setState((prevState) => {

      const newState = {
        ...prevState,
        [event.target.name]: event.target.value,
      };
      
      if(!newState['hours']){
        newState['hours'] = '0';
      }

      if(!newState['minutes']){
        newState['minutes'] = '0';
      }

      if (onChange) {
        onChange(JSON.stringify(newState));
      }

      
      return newState;
    }); 
  };


  return (
    <React.Fragment>
      <InfoText
        title={title}
        description={description}
        groupName={groupName}
        questionId={questionId}
        questionComments={questionComments}
        upsertCommentLoading={upsertCommentLoading}
        handleComment={handleComment}
        userId={userId}
      />

    <StyledHFillContainerV>
        <StyledTextField
            label='Hours'
            name='hours'
            id={`${id}-hours`}
            value={state.hours}
            onChange={handleChange}
            select>
            {hourOptions.map((option) =>
            <MenuItem key={option} value={option}>{option}</MenuItem>
            )}
        </StyledTextField>

        <StyledTextField
            label='Minutes'
            name='minutes'
            id={`${id}-minutes`}
            value={state.minutes}
            onChange={handleChange}
            select>
            {minuteOptions.map((option) =>
            <MenuItem key={option} value={option}>{option}</MenuItem>
            )}
        </StyledTextField>
      </StyledHFillContainerV>

    </React.Fragment>
  );
};

DurationPicker.displayName = 'DurationPicker';

DurationPicker.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  groupName: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  questionId: PropTypes.any,
  questionComments: PropTypes.any,
  upsertCommentLoading: PropTypes.bool,
  handleComment: PropTypes.any,
  userId: PropTypes.string,
};

DurationPicker.defaultProps = {
  id: `DurationPicker-${Math.random()}`,
  title: '',
  description: '',
  groupName: '',
  value: '',
  label: 'Label',
  onChange: () => console.log('DurationPicker Changed'),
  userId: '',
};
