export const FormFactor = {
  Feature: 0,
  Mobile: 1,
  Tablet: 2,
  Desktop: 3,
};

export const DefaultFormFactors = {
  [FormFactor.Feature]: 0,
  [FormFactor.Mobile]: 240,
  [FormFactor.Tablet]: 640,
  [FormFactor.Desktop]: 1200,
};
