import React from 'react';

import {
  Asset,
  ButtonVariant,
  IdentityGroupType,
  JourneyName,
  ScreenName,
  TeachingPhaseMnemonic,
} from 'enums';

import {
  BodyMedium,
  Dropdown,
  Feedback,
  HeadlineLarge,
  Scene,
  ShortText,
  SpinnerButton,
  VContainer,
} from 'ui/common';


import { styled } from '@mui/material';
import { usePersonDetails, useIdentity, usePersonByIdUpsert, usePersonTypes } from 'hooks';
import { personDetailsByIdQueryParams } from 'gql/person/queryParams';
import { validateEditProfileForm } from 'common/forms';
import { buildPersonUpsertVariables } from 'common/person';
import { useNavigate } from 'react-router-dom';

const MarginContainer = styled(VContainer)`
 margin-bottom: 1.8rem;
`;

const StyledButton = styled(SpinnerButton)`
@media (min-width: 600px) {
  max-width: 228px;
  }
`;

export const EditProfile = () => {
  const navigate = useNavigate();

  const {
     user 
  } = useIdentity(EditProfile.context);

  const {
    loading: PersonTypesLoading, 
    personTypes
  } = usePersonTypes();

  const {
    personDetails,
    loading, 
    error, 
    refetch
  } = usePersonDetails(personDetailsByIdQueryParams(user?.id), true);

  const [
    upsertPersonById, 
    {
      loading: upsertPersonLoading, 
      data: upsertPersonData, 
      error: upsertPersonError
    }
  ] = usePersonByIdUpsert();

  const userType = user.group || IdentityGroupType.Intern; 

  const [formFields, setFormFields] = React.useState();
  const [isFormValid, setIsFormValid] = React.useState(true);
  const [validation, setValidation] = React.useState({});

  React.useMemo(() => {
    if (!loading && personDetails) {
      const fields = {
        givenName: personDetails[0]?.givenName,
        surname: personDetails[0]?.surname,
        email: personDetails[0]?.email,
        telNo: personDetails[0]?.telNo,
        schools: personDetails[0]?.schools?.join(', '),
        yearOfStudy: personDetails[0]?.yearOfStudy,
        teachingPhaseMnemonic: personDetails[0]?.teachingPhaseMnemonic,
        userType: userType,
        mentorFullName: `${personDetails[0]?.mentorGivenName} ${personDetails[0]?.mentorSurname}`,
        coachFullName: `${personDetails[0]?.coachGivenName} ${personDetails[0]?.coachSurname}`,
      };

      setFormFields(fields);
    }
  }, [loading, personDetails, userType]);

  const handleEdit = () => {
    upsertPersonById(buildPersonUpsertVariables([{
      id: user.id,
      givenName: formFields.givenName,
      surname: formFields.surname,
      email: formFields.email,
      telNo: formFields.telNo,
      yearOfStudy: formFields.yearOfStudy,
      teachingPhaseMnemonic: formFields.teachingPhaseMnemonic,
      personTypeId: personTypes.find((p) => p.title === user?.group)?.id
    }]));
  }

  const handleFormFieldChange = (e, field) => {
    setFormFields(prevFields => {

      let value = e.target?.value;

      const newFields = {
        ...prevFields,
        [field]: value,
      };

      const errorMap = validateEditProfileForm(newFields, userType);

      setValidation(errorMap);
      setIsFormValid(Object.keys(errorMap).length === 0);

      return newFields;
    });
  }

  return (

    <Scene
      headerVisible={true}
      title={ScreenName.EditProfile}
      footerVisible={true}
      loading={loading || PersonTypesLoading}
    >

      <React.Fragment>
        <MarginContainer>
          <HeadlineLarge>PERSONAL INFORMATION</HeadlineLarge>
        </MarginContainer>
        <MarginContainer>
          <BodyMedium>Keep your personal details up to date to ensure seamless communication and service.</BodyMedium>
        </MarginContainer>

        {formFields && !error && !upsertPersonError && !upsertPersonData &&

          <MarginContainer>
            <ShortText
              value={formFields.givenName}
              onChange={(e) => handleFormFieldChange(e, 'givenName')}
              label={'Given Name'}
              placeholder={'Given Name'}
              error={validation.givenName?.hasError}
              helperText={validation.givenName?.errorText}
            />
            <ShortText
              value={formFields.surname}
              onChange={(e) => handleFormFieldChange(e, 'surname')}
              label={'Surname'}
              placeholder={'Surname'}
              error={validation.surname?.hasError}
              helperText={validation.surname?.errorText}
            />
            <ShortText
              value={formFields.email}
              onChange={(e) => handleFormFieldChange(e, 'email')}
              label={'Email Address'}
              placeholder={'Email Address'}
              disabled={true}
            />
            <ShortText
              value={formFields.telNo}
              onChange={(e) => handleFormFieldChange(e, 'telNo')}
              label={'Contact Number'}
              placeholder={'Contact Number'}
              error={validation.telNo?.hasError}
              helperText={validation.telNo?.errorText}
            />
            <ShortText
              value={formFields.schools}
              onChange={(e) => handleFormFieldChange(e, 'schools')}
              label={'School/Organisation Affiliation'}
              placeholder={'School/Organisation Affiliation'}
              disabled={true}
            />
            {userType === IdentityGroupType.Intern &&
            <React.Fragment>
                <Dropdown
                  label={'Year of Study'}
                  placeholder={'Year of Study'}
                  options={[1,2,3,4]}
                  value={formFields.yearOfStudy}
                  onChange={(e) => {handleFormFieldChange(e, 'yearOfStudy')}}
                  error={validation.yearOfStudy?.hasError}
                  helperText={validation.yearOfStudy?.errorText}
                  disabled={true}
                />
                <Dropdown
                  label={'Phase'}
                  placeholder={'Phase'}
                  options={[
                    TeachingPhaseMnemonic.Foundation, 
                    TeachingPhaseMnemonic.Intermediate,
                  ]}
                  value={formFields.teachingPhaseMnemonic}
                  onChange={(e) => {handleFormFieldChange(e, 'teachingPhaseMnemonic')}}
                  error={validation.teachingPhaseMnemonic?.hasError}
                  helperText={validation.teachingPhaseMnemonic?.errorText}
                />
                <ShortText
                  value={formFields.mentorFullName}
                  onChange={(e) => handleFormFieldChange(e, 'mentorFullName')}
                  label={'On-site Mentor Name'}
                  placeholder={'On-site Mentor Name'}
                  disabled={true}
                />

                <ShortText
                  value={formFields.coachFullName}
                  onChange={(e) => handleFormFieldChange(e, 'mentorFullName')}
                  label={'Online Mentor Name'}
                  placeholder={'Online Mentor Name'}
                  disabled={true}
                />
              </React.Fragment>
              }
            <ShortText
                  value={formFields.userType}
                  onChange={(e) => handleFormFieldChange(e, 'userType')}
                  label={'Role'}
                  placeholder={'Role'}
                  disabled={true}
                />
            <StyledButton 
              variant={ButtonVariant.Contained} 
              text='Save Details' 
              onClick={handleEdit} 
              loading={upsertPersonLoading}
              disabled={!isFormValid}
            />
          </MarginContainer>
        }

        {!upsertPersonLoading && upsertPersonData  &&
          <Feedback 
            imageUrl={Asset.Image.Other.BookStack}
            headerText={'Details Updated'}
            bodyText={'Great job updating your details!'}
            infoText={"Your details has been successfully updated."}
            buttonText='Go Back'
            buttonAction={() => navigate('/account')}/>
        }

        {error && 
          <Feedback 
            imageUrl={Asset.Image.Other.BrokenPencil}
            headerText='Something Went Wrong!'
            bodyText="We encountered a hiccup while fetching your details. Not to worry, though – we're on it!"
            infoText="Please try loading your details again. If the issue persists, reach out to us for support"
            type="Error"
            buttonText='Try Again'
            buttonAction={() => refetch()}
          />
        }

        {upsertPersonError || upsertPersonData?.personUpsert?.body?.ok == false && 
          <Feedback 
            imageUrl={Asset.Image.Other.BrokenPencil}
            headerText='Something Went Wrong!'
            bodyText={upsertPersonData?.personUpsert.body.message ? upsertPersonData?.personUpsert.body.message : "We encountered a hiccup while editing your details. Not to worry, though – we're on it!"}
            infoText="Please try editing your details again. If the issue persists, reach out to us for support"
            type="Error"
            buttonText='Try Again'
            buttonAction={() => handleEdit()}
          />
        }
      </React.Fragment>
      
    </Scene>
  );
};

EditProfile.displayName = 'EditProfile';

EditProfile.context = {
  screen: ScreenName.EditProfile,
  journey: JourneyName.EditProfile,
  component: EditProfile.displayName,
};
